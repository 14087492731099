<div class="c-contact-card">
  @if (branch || department) {
    <div class="c-contact-card__item">
      <span class="c-contact-card__label">{{ contactLabel }}</span>
      <div class="c-contact-card__value">
        @if (branch) {
          <span class="c-contact-card__value--highlight">{{ branch }}</span>
        }

        @if (department) {
          <span>{{ department }}</span>
        }
      </div>
    </div>
  }

  @if (telephone) {
    <div class="c-contact-card__item">
      <span class="c-contact-card__label">{{ telephoneLabel }}</span>
      <div class="c-contact-card-card__value">
        <span>
          <a href="tel:{{ telephone }}">{{ telephone }}</a>
        </span>
      </div>
    </div>
  }

  @if (contactUrl) {
    <div class="c-contact-card__item">
      <span class="c-contact-card__label">{{ contactLabel }}</span>
      <div class="c-contact-card-card__value">
        <span style="cursor: pointer">
          <a (click)="onClickUrl(contactUrl)">{{ contactUrl }}</a>
        </span>
      </div>
    </div>
  }

  @if (email) {
    <div class="c-contact-card__item">
      <span class="c-contact-card__label">{{ emailLabel }}</span>
      <div class="c-contact-card-card__value">
        <span>
          <a href="mailto:{{ email }}">{{ email }}</a>
        </span>
      </div>
    </div>
  }

  @if (url) {
    <div class="c-contact-card__item">
      <span class="c-contact-card__label">{{ websiteLabel }}</span>
      <div class="c-contact-card-card__value">
        <span style="cursor: pointer">
          <a (click)="onClickUrl(url)">{{ url }}</a>
        </span>
      </div>
    </div>
  }
</div>
