import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output
} from '@angular/core';
import { DomSanitizerPipe } from './dom-sanitizer.pipe';
import { NgStyle } from '@angular/common';
import { NgxOvButtonComponent } from '../../default-layout-blokken/button/button.component';
import { NgxOvComponentBaseClass } from '../../ngx-ov-component-base-class';
import { TranslateService } from '@ngx-translate/core';
import { TranslationsService } from '../../../services/translations.service';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-document-viewer',
  templateUrl: './document-viewer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgStyle, DomSanitizerPipe, NgxOvButtonComponent]
})
export class NgxOvDocumentViewerComponent extends NgxOvComponentBaseClass {
  // -------------------------------------------------------------------------
  // Variables
  // -------------------------------------------------------------------------
  _file: string | Blob;
  documentSrc: string;
  documentType: 'pdf' | 'image';
  degreesRotated = 0;
  imgContainerWidth = 'auto';
  imgContainerHeight = 'auto';
  downloadImageActionNameCopy;
  rotateImageLeftActionNameCopy;
  rotateImageRightActionNameCopy;

  @Input() title: string;
  @Input() imgAlt: string;
  @Input() height = '100%';
  @Input() width = '100%';
  @Input() allowRotate = false;
  @Input() set file(value: string | Blob) {
    this._file = value;
    this.loadFile();
  }
  get file() {
    return this._file;
  }

  @Input() downloadImageActionName;

  @Output() rotateImage: EventEmitter<any> = new EventEmitter();
  @Output() downloadImage: EventEmitter<any> = new EventEmitter();

  constructor(
    public translationsService: TranslationsService,
    @Optional() public translateService: TranslateService
  ) {
    super(translationsService, translateService);
  }

  loadFile() {
    if (this.file instanceof Blob) {
      this.loadBlob();
    } else {
      this.loadSrc();
    }
  }

  loadBlob(): void {
    const blobFile = this.file as Blob;
    const blob = new Blob([this.file], { type: blobFile.type });
    this.documentSrc = URL.createObjectURL(blob);

    if (blob.type?.includes('pdf')) {
      this.documentType = 'pdf';
    } else if (blob.type?.includes('image')) {
      this.documentType = 'image';
    } else {
      this.documentType = null;
    }
  }

  loadSrc(): void {
    const stringFile = this.file as string;
    this.documentSrc = stringFile;

    const extention = this.getUrlExtension(stringFile)?.toLowerCase();

    switch (extention) {
      case 'jpg':
      case 'png':
      case 'jpeg':
        this.documentType = 'image';
        break;
      case 'pdf':
        this.documentType = 'pdf';
        break;
      default:
        this.documentType = null;
    }
  }

  getUrlExtension(url: string): string {
    return url?.split(/[#?]/)[0].split('.').pop().trim();
  }

  onRotateImageRight() {
    if (this.degreesRotated === 270) {
      this.degreesRotated = 0;
    } else {
      this.degreesRotated = this.degreesRotated + 90;
    }
    this.recalculateImageContainer();
    this.rotateImage.emit('right');
  }

  onRotateImageLeft() {
    if (this.degreesRotated === 0) {
      this.degreesRotated = 270;
    } else {
      this.degreesRotated = this.degreesRotated - 90;
    }
    this.recalculateImageContainer();
    this.rotateImage.emit('left');
  }

  onDownloadImage() {
    this.downloadImage.emit();
  }

  recalculateImageContainer() {
    const imgElement = document.querySelector('img');
    const { width, height } = imgElement;
    this.imgContainerWidth = '' + (width + 32);
    this.imgContainerHeight = '' + (height + 32);
  }

  setLabels() {
    const prefix = 'FORMS.DOCUMENT_VIEWER';
    this.rotateImageLeftActionNameCopy = this.getTranslation(
      prefix,
      'ROTATE_LEFT_ACTION_NAME'
    );
    this.rotateImageRightActionNameCopy = this.getTranslation(
      prefix,
      'ROTATE_RIGHT_ACTION_NAME'
    );
    this.downloadImageActionNameCopy =
      this.downloadImageActionName ??
      this.getTranslation(prefix, 'DOWNLOAD_ACTION_NAME');
  }
}
