import { Injectable } from '@angular/core';

import { NgxOvDataTableSortingOrder } from '../data-table.utils';

@Injectable()
export class NgxOvDataTableService {
  /**
   * params:
   * - data: any[]
   * - searchTerm: string
   * - ignoreCase: boolean = false
   *
   * Searches [data] parameter for [searchTerm] matches and returns a new array with them.
   */
  filterData(
    data: any[],
    searchTerm: string,
    ignoreCase: boolean = false
  ): any[] {
    // eslint-disable-next-line no-nested-ternary
    const filter: string = searchTerm
      ? ignoreCase
        ? searchTerm.toLowerCase()
        : searchTerm
      : '';
    if (filter) {
      // eslint-disable-next-line no-param-reassign
      data = data.filter((item: any) => {
        const res: any = Object.keys(item).find((key: string) => {
          const preItemValue = `${item[key]}`;
          const itemValue: string = ignoreCase
            ? preItemValue.toLowerCase()
            : preItemValue;
          return itemValue.indexOf(filter) > -1;
        });
        return typeof res !== 'undefined';
      });
    }
    return data;
  }

  /**
   * params:
   * - data: any[]
   * - sortBy: string
   * - sortOrder: NgxOvDataTableSortingOrder = NgxOvDataTableSortingOrder.Ascending
   *
   * Sorts [data] parameter by [sortBy] and [sortOrder] and returns the sorted data.
   */
  sortData(
    data: any[],
    sortBy: string,
    sortOrder: NgxOvDataTableSortingOrder = NgxOvDataTableSortingOrder.Ascending
  ): any[] {
    if (sortBy) {
      data.sort((a: any, b: any) => {
        const compA: any = a[sortBy];
        const compB: any = b[sortBy];
        let direction = 0;
        if (
          !Number.isNaN(Number.parseFloat(compA)) &&
          !Number.isNaN(Number.parseFloat(compB))
        ) {
          direction = Number.parseFloat(compA) - Number.parseFloat(compB);
        } else if (compA < compB) {
          direction = -1;
        } else if (compA > compB) {
          direction = 1;
        }
        return (
          direction *
          (sortOrder === NgxOvDataTableSortingOrder.Descending ? -1 : 1)
        );
      });
    }
    return data;
  }

  /**
   * params:
   * - data: any[]
   * - fromRow: number
   * - toRow: : number
   *
   * Returns a section of the [data] parameter starting from [fromRow] and ending in [toRow].
   */
  pageData(data: any[], fromRow: number, toRow: number): any[] {
    if (fromRow >= 1) {
      // eslint-disable-next-line no-param-reassign
      data = data.slice(fromRow - 1, toRow);
    }
    return data;
  }
}
