<div class="container my-10">
  <ngx-ov-status-message
    [title]="'Geen toegang'"
    [ctaLabel]="'Terug naar landingpagina'"
    [img]="'/assets/images/undraw_road_sign.png'"
  >
    Je hebt geen toegang voor deze pagina te bekijken

    <ul class="fa-ul u-list-overview">
      @for (hyperlink of hyperlinks; track trackByFn) {
        <li>
          <span class="fa-li">
            <i class="fas fa-chevron-right" aria-hidden="true"></i>
          </span>
          <a (click)="goto(hyperlink)">{{ hyperlink }} </a>
        </li>
      }
      <li></li>
    </ul>
  </ngx-ov-status-message>
</div>
