<div
  class="highlight-item__container"
  [class.highlight-item__cta]="link"
  (click)="onNavigate()"
>
  @if (icon) {
    <div class="hidden-mobile mt:0">
      <span class="{{ icon }} highlight-item__icon-xl mb:large"></span>
    </div>
  }

  <h4 class="highlight-item__title" [innerText]="title | uppercase"></h4>

  <p class="highlight-item__info" [innerText]="info"></p>

  @if (showSeparator) {
    <div class="l-section--underlined-white hidden-desktop"></div>
  }
</div>
