/**
 * Naric
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.3.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpResponse} from '@angular/common/http';

import {Observable} from 'rxjs';

import {StringRestDto} from '../model/stringRestDto';

import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';


@Injectable()
export class OpenAPIApplicatieService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  protected basePath = 'https://localhost:8080';

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * Raadpleeg het huidige build nummer van de applicatie.
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */



  public buildNrUsingGET(observe?: 'body', reportProgress?: boolean):
    Observable<StringRestDto>;

  public buildNrUsingGET(observe?: 'response', reportProgress?: boolean):
    Observable<HttpResponse<StringRestDto>>;

  public buildNrUsingGET(observe?: 'events', reportProgress?: boolean):
    Observable<HttpEvent<StringRestDto>>;

  public buildNrUsingGET(observe: any = 'body', reportProgress: boolean = false):
    Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [];

    return this.httpClient.get<StringRestDto>(`${this.basePath}/open-api/v1/application/build_nr`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Raadpleeg de waarde die als &#39;environment&#39; geconfigureerd is voor de applicatie.
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */



  public environmentUsingGET(observe?: 'body', reportProgress?: boolean):
    Observable<StringRestDto>;

  public environmentUsingGET(observe?: 'response', reportProgress?: boolean):
    Observable<HttpResponse<StringRestDto>>;

  public environmentUsingGET(observe?: 'events', reportProgress?: boolean):
    Observable<HttpEvent<StringRestDto>>;

  public environmentUsingGET(observe: any = 'body', reportProgress: boolean = false):
    Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [];

    return this.httpClient.get<StringRestDto>(`${this.basePath}/open-api/v1/application/environment`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Raadpleeg de waarde die als &#39;subsystem&#39; geconfigureerd is voor de applicatie.
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */



  public subsystemUsingGET(observe?: 'body', reportProgress?: boolean):
    Observable<StringRestDto>;

  public subsystemUsingGET(observe?: 'response', reportProgress?: boolean):
    Observable<HttpResponse<StringRestDto>>;

  public subsystemUsingGET(observe?: 'events', reportProgress?: boolean):
    Observable<HttpEvent<StringRestDto>>;

  public subsystemUsingGET(observe: any = 'body', reportProgress: boolean = false):
    Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [];

    return this.httpClient.get<StringRestDto>(`${this.basePath}/open-api/v1/application/subsystem`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (let consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

}
