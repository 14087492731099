import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges
} from '@angular/core';
import { PortalCardDirective } from './portals/portal-card.directive';
import { LinkService } from '../../../services/link.service';
import { PortalCardTitleDirective } from './portals/portal-card-title.directive';
import { TranslationsService } from '../../../services/translations.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxOvComponentBaseClass } from '../../ngx-ov-component-base-class';
import { PortalModule } from '@angular/cdk/portal';
import { StopPropagationDirective } from '../../../directives/stop-propagation';
import { CommonModule, NgStyle } from '@angular/common';
import { ClickEqualsEnterDirective } from '../../../directives/click-equals-enter';

type CardAlignment = 'centered' | 'right' | 'left';

@Component({
  selector: 'ngx-ov-card',
  templateUrl: './card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgStyle,
    CommonModule,
    StopPropagationDirective,
    PortalModule,
    ClickEqualsEnterDirective
  ]
})
export class NgxOvCardComponent
  extends NgxOvComponentBaseClass
  implements OnChanges, OnInit
{
  iconCloseLabel;
  iconOpenLabel;
  hasLinkTitle = false;
  cardIsClickable = false;
  cardStyle;

  // -------------------------------------------------------------------------
  // private variables
  // -------------------------------------------------------------------------
  private _borderColor = '#0055CC';
  private _minHeight: number;

  // -------------------------------------------------------------------------
  // Input variables
  // -------------------------------------------------------------------------
  @Input() set borderColor(color: string) {
    this._borderColor = color || this._borderColor;
  }

  get borderColor(): string {
    return this._borderColor;
  }

  @Input() set minHeight(value: number) {
    if (value > 0) {
      this._minHeight = value;
    }
  }

  get minHeight(): number {
    return this._minHeight;
  }

  @Input() title: string;

  @Input() isSecondary = false;
  @Input() isExpandable = false;
  @Input() expandableOpen = false;
  @Input() showClickIcon = true;
  @Input() icon: string;

  @Input() alignment: CardAlignment = 'left';

  @ContentChild(PortalCardDirective, { static: false })
  footer: PortalCardDirective;
  @ContentChild(PortalCardTitleDirective, { static: false })
  portalTitle: PortalCardTitleDirective;

  @Input() url: string;

  // -------------------------------------------------------------------------
  // Output variables
  // -------------------------------------------------------------------------
  /**
   * Emits event on click when no url has been configured.
   */
  @Output()
  cardClicked: EventEmitter<any> = new EventEmitter();

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  constructor(
    private linkService: LinkService,
    public translationsService: TranslationsService,
    @Optional() public translateService: TranslateService
  ) {
    super(translationsService, translateService);
  }

  ngOnInit(): void {
    this.cardStyle = this.getStyles();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.cardIsClickable = this.isClickable();

    if (changes.url) {
      this.hasLinkTitle = this.hasUrl();
    }

    if (changes.minHeight || changes.isSecondary || changes.borderColor) {
      this.cardStyle = this.getStyles();
    }
  }

  private getStyles(): Map<string, any> {
    const styles = new Map<string, any>();

    if (this.minHeight) {
      styles.set('minHeight.rem', this.minHeight);
    }
    if (!this.isSecondary) {
      styles.set('boxShadow', this.getBoxShadowStyle());
    }

    return styles;
  }

  private isClickable(): boolean {
    return this.hasUrl() || !!this.cardClicked.observed;
  }

  private hasUrl(): boolean {
    return !!(this.url && this.url.length);
  }

  onClickCardTitle() {
    if (this.isExpandable) {
      this.expandableOpen = !this.expandableOpen;
    } else if (this.url) {
      this.linkService.navigate(this.url);
    } else {
      this.cardClicked.emit();
    }
  }

  onClickCard() {
    if (this.url) {
      this.linkService.navigate(this.url);
    } else {
      this.cardClicked.emit();
    }
  }

  private getBoxShadowStyle() {
    const shadows =
      '0px 10px 15px -3px rgba(26, 32, 44, 0.1), 0px 4px 6px -2px rgba(26, 32, 44, 0.05)';
    const borderShadow = `0px -3px 0px ${this._borderColor}`;
    return `${shadows} , ${borderShadow}`;
  }

  setLabels() {
    this.iconCloseLabel = this.getTranslation(
      'CONTENT_BLOKKEN.CARD',
      'ICON.CLOSE'
    );
    this.iconOpenLabel = this.getTranslation(
      'CONTENT_BLOKKEN.CARD',
      'ICON.OPEN'
    );
  }
}
