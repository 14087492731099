<div
  class="c-input-field {{ classes }}"
  [class.is-error]="ngControl?.touched && ngControl?.status === 'INVALID'"
  [class.is-disabled]="isDisabled"
>
  @if (label) {
    <ngx-ov-input-label
      [text]="label"
      [required]="isRequired || showRequired"
      [for]="identifier"
      [isReadonly]="isReadonly"
    ></ngx-ov-input-label>
  }
  @if (!isReadonly) {
    <div class="c-input-field_container">
      <input
        id="{{ id }}"
        class="c-input-field__input"
        [type]="hideTextPassword ? 'password' : 'text'"
        name="input-field"
        [(ngModel)]="value"
        [autocomplete]="autocomplete"
        #spy="ngModel"
        [placeholder]="placeholder"
        mask="{{ maskExpr }}"
        thousandSeparator="{{ thousandSeparator }}"
        [decimalMarker]="decimalMarker"
        [allowNegativeNumbers]="allowNegativeNumbers"
        [dropSpecialCharacters]="dropSpecialCharacters"
        [patterns]="patterns"
        suffix="{{ suffix }}"
        prefix="{{ prefix }}"
        (blur)="onBlur(value)"
        (input)="onChange($any($event.target).value)"
        tabindex="{{ tabindex }}"
      />
      @if (isPassword) {
        <div
          class="c-input-field__icon-password-alignment"
          (click)="changeHideTextPassword()"
          tabindex="{{ tabindex }}"
        >
          <span
            [class]="
              hideTextPassword
                ? 'fa fa-eye-slash c-input-field__icon-password'
                : 'fa fa-eye c-input-field__icon-password'
            "
          ></span>
        </div>
      }
    </div>

    @if (extraInfo) {
      <ngx-ov-extra-info
        class="c-input-field__extra-info"
        [text]="extraInfo"
      ></ngx-ov-extra-info>
    }
  } @else {
    @if (value) {
      @if (maskExpr) {
        <div
          class="u-form-readonly__input-readonly c-input-field__input-readonly"
          data-cy="readonly-value"
        >
          {{ prefix }}{{ value | mask: maskExpr }}{{ suffix }}
        </div>
      } @else {
        <div
          class="u-form-readonly__input-readonly c-input-field__input-readonly"
          data-cy="readonly-value"
        >
          {{ prefix }}{{ value }}{{ suffix }}
        </div>
      }
    } @else {
      <div
        class="u-form-readonly__input-readonly c-input-field__input-readonly"
        data-cy="readonly-value"
      >
        {{ isReadonlyEmptyText }}
      </div>
    }
  }
</div>

@if (errors$ | async; as errors) {
  <div class="mt-xsmall">
    @if (ngControl?.dirty && ngControl?.status === 'INVALID') {
      <ngx-ov-input-validation [messages]="errors"></ngx-ov-input-validation>
    }
  </div>
}
