import { InjectionToken } from '@angular/core';
import { NgxOvSnackbarService } from '../../../components/meldingen/snackbar/snackbar.service';
import { NgxOvSnackbarPlugin } from './snackbar.plugin';
import { TranslationsService } from '../../../services/translations.service';
import { TranslateService } from '@ngx-translate/core';

export const ngxOvSnackbarPluginFactory = (
  snackbarService: NgxOvSnackbarService,
  options: NgxOvSnackbarPluginOptions,
  translationsService: TranslationsService,
  translateService: TranslateService
) => {
  return new NgxOvSnackbarPlugin(
    options,
    snackbarService,
    translationsService,
    translateService
  );
};

export interface NgxOvSnackbarPluginOptions {
  showDefaultSnackbarOnSaveSuccess?: boolean;
  showDefaultSnackbarOnSaveFailure?: boolean;
  showDefaultSnackbarOnDeleteSuccess?: boolean;
  showDefaultSnackbarOnDeleteFailure?: boolean;
  showDefaultSnackbarOnLoadFailure?: boolean;
}

export const NGX_OV_SNACKBAR_PLUGIN_OPTIONS = new InjectionToken(
  'NGX_OV_SNACKBAR_PLUGIN_OPTIONS'
);
