<div class="c-ov-stepper">
  <div class="c-ov-stepper__container">
    @for (step of steps; let i = $index; track trackByFn) {
      <div>
        @if (step.isLocked) {
          <ng-container
            [ngTemplateOutlet]="stepTemplate"
            [ngTemplateOutletContext]="{
              $implicit: step,
              index: i
            }"
          ></ng-container>
        } @else {
          <div
            (click)="
              selectedIndex !== i && step.canBeExpanded && !step.stepControl
                ? step.toggleShowContent()
                : null
            "
          >
            <ng-container
              [ngTemplateOutlet]="stepTemplate"
              [ngTemplateOutletContext]="{
                $implicit: step,
                index: i
              }"
            ></ng-container>
          </div>
        }
      </div>
    }
  </div>
</div>

<ng-template #stepTemplate let-step let-i="index">
  <div
    class="c-ov-stepper__title"
    [class.is-succes]="selectedIndex > i"
    [class.is-upcoming]="selectedIndex < i"
    [class.is-active]="selectedIndex === i && !step.isCta"
    [class.is-alert]="selectedIndex === i && step.isCta"
    [class.no-pointer]="
      !(selectedIndex !== i && step.canBeExpanded && !step.stepControl)
    "
    [attr.aria-posinset]="i + 1"
    [attr.aria-setsize]="steps.length"
    [attr.aria-selected]="selectedIndex == i"
    [attr.aria-controls]="step.content"
  >
    <div class="c-ov-stepper__stepnumber">
      <div class="c-ov-step__stepnumber-content">
        @if (
          (selectedIndex < i && !step.isLocked) ||
          (!step.isCta && selectedIndex === i)
        ) {
          <div>
            {{ i + 1 }}
          </div>
        }
        @if (selectedIndex === i && step.isCta) {
          <i class="fas fa-exclamation" aria-hidden="true"></i>
        }
        @if (selectedIndex < i && step.isLocked) {
          <i class="fa-solid fa-lock fa-sm" aria-hidden="true"></i>
        }
        @if (selectedIndex > i) {
          <i
            class="fas fa-check"
            aria-hidden="true"
            [title]="stepNumberCompletedIconTitle"
          ></i>
        }
        @if (selectedIndex > i) {
          <span class="sr-only">{{ stepNumberCompletedIconTitle }}</span>
        }
      </div>
    </div>
    <label> {{ step.label }}</label>
    @if (step.canBeExpanded && selectedIndex > i && !step.stepControl) {
      <i
        [@indicatorRotate]="
          step.showContent && selectedIndex > i ? 'expanded' : 'collapsed'
        "
        class="fas fa-chevron-down c-ov-stepper__chevron"
        aria-hidden="true"
        [title]="
          step.showContent && selectedIndex > i
            ? closeStepIconTitle
            : openStepIconTitle
        "
      ></i>
    }
    @if (selectedIndex > i) {
      <span class="sr-only">{{
        step.showContent ? closeStepIconTitle : openStepIconTitle
      }}</span>
    }
  </div>
  <div
    class="c-ov-stepper__content_container"
    [class.c-ov-stepper__vertical_line]="step !== steps.last"
  >
    <div
      class="c-ov-stepper__content"
      [@stepOpenTransition]="
        step.showContent || selectedIndex === i ? 'expanded' : 'collapsed'
      "
      (@stepOpenTransition.done)="_animationDone.next($event)"
      [@verticalStepTransition]="
        (step.canBeExpanded && !step.stepControl) || step.defaultShowContent
          ? null
          : {
              value: _getAnimationDirection(i)
            }
      "
      (@verticalStepTransition.done)="_animationDone.next($event)"
    >
      @if (step.showContent || selectedIndex === i) {
        <div
          [attr.aria-labelledby]="step.label"
          [attr.aria-expanded]="step.showContent || selectedIndex === i"
          [ngTemplateOutlet]="step ? step.content : null"
        ></div>
      }
    </div>
  </div>
</ng-template>
