<input
  style="display: none"
  type="checkbox"
  [id]="id"
  [name]="name"
  [(ngModel)]="value"
  [attr.data-cy]="code"
/>
@if (label) {
  <ngx-ov-input-label
    [text]="label"
    [required]="isRequired || showRequired"
    [isReadonly]="isReadonly"
  ></ngx-ov-input-label>
}

@if (!isReadonly) {
  @for (option of options; track trackByFn) {
    <ngx-ov-checkbox-nested
      [id]="option.id ? id + option.id : null"
      [classes]="
        ngControl?.touched && ngControl?.status === 'INVALID'
          ? 'is-error-group'
          : ''
      "
      [label]="bindLabel ? option[bindLabel] : option"
      [ngModel]="getNgModelValue(value, option)"
      [showAsBlock]="showAsBlock"
      [checked]="isChecked(option)"
      [isDisabled]="option[bindIsDisabled]"
      [extraInfo]="option[bindExtraInfo]"
      [icon]="option[bindIcon]"
      [code]="option[code]"
      (valueChange)="checkboxChange($event, option)"
    ></ngx-ov-checkbox-nested>
  }

  @for (checkbox of checkboxes; track trackByFn) {
    <ngx-ov-checkbox-nested
      [id]="checkbox.id ? id + checkbox.id : null"
      [classes]="
        ngControl?.touched && ngControl?.status === 'INVALID'
          ? 'is-error-group'
          : ''
      "
      [label]="checkbox.label"
      [ngModel]="getNgModelValue(value, checkbox)"
      [showAsBlock]="showAsBlock"
      [checked]="isChecked(checkbox)"
      [extraInfo]="checkbox.extraInfo"
      [icon]="checkbox.icon"
      [isDisabled]="checkbox.isDisabled"
      [code]="checkbox.code ? checkbox.code : id"
      (valueChange)="checkboxChange($event, checkbox)"
    >
      @if (checkbox.contentPortal) {
        <ng-template ngxOvCheckboxContent>
          <ng-template [cdkPortalOutlet]="checkbox.contentPortal"></ng-template>
        </ng-template>
      }
    </ngx-ov-checkbox-nested>
  }
} @else {
  @if (value && value.length) {
    <ul
      class="u-form-readonly__input-readonly c-checkbox-group__input-readonly"
    >
      @for (option of _sortedValues; track trackByFn) {
        <li>
          <div>
            <i
              data-testid="iconCheckGroup"
              class="fa fa-check c-checkbox__icon-check-readonly"
              title="Checked"
              aria-hidden="true"
            ></i>
            {{ option }}
            <span class="sr-only">checked</span>
          </div>
        </li>
      }
    </ul>
  } @else {
    <div class="u-form-readonly__input-readonly">-</div>
  }
}

@if (errors$ | async; as errors) {
  <div class="mt-xsmall">
    @if (!ngControl?.pristine && ngControl?.status === 'INVALID') {
      <ngx-ov-input-validation [messages]="errors"></ngx-ov-input-validation>
    }
  </div>
}
