<div class="c-expansion-panel-list">
  @if (!isEmpty) {
    <ng-container>
      <ng-content></ng-content>
    </ng-container>
  }

  @if (isEmpty && emptyStatePortal) {
    <ng-template [cdkPortalOutlet]="emptyStatePortal"></ng-template>
  }
</div>
