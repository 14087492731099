import { Component } from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';
import { NgxOvButtonComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/button/button.component';

@Component({
    templateUrl: './page-utils.component.html',
    styleUrls: ['./page-utils.component.scss'],
    standalone: true,
    imports: [NgxOvButtonComponent, NgTemplateOutlet]
})
export class PageUtilsComponent {}
