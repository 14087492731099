import { Pipe, PipeTransform } from '@angular/core';
import { INgxOvDataTableColumn } from '../data-table.utils';

const getNestedValue = (name: string, value: any) => {
  if (!(value instanceof Object) || !name) {
    return value;
  }
  if (name.indexOf('.') > -1) {
    const splitName: string[] = name.split(/\.(.+)/, 2);
    return getNestedValue(splitName[1], value[splitName[0]]);
  }
  return value[name];
};

//TODO: testen schrijven
// https://mvgond.atlassian.net/browse/OAM-1007
@Pipe({
  name: 'cellValue',
  standalone: true
})
export class CellValuePipe implements PipeTransform {
  transform(column: INgxOvDataTableColumn, value: any): string | boolean {
    if (column.nested === undefined || column.nested) {
      return getNestedValue(column.name, value);
    }
    return value[column.name];
  }
}
