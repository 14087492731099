<input
  class="l-grid__col:12/12"
  #newFile
  [multiple]="allowMultiple && maxNumberFiles !== 1"
  [accept]="accept"
  [id]="identifier"
  type="file"
  (change)="onUploadChange($event)"
  style="display: none"
/>

<div class="c-upload">
  <div class="c-upload-mobile">
    <ng-container *ngTemplateOutlet="uploadZoneMobile"></ng-container>
    @if (_showRows) {
      <ng-container *ngTemplateOutlet="uploadZoneTitle"></ng-container>
      <ng-container *ngTemplateOutlet="uploadRowsMobile"></ng-container>
    }
  </div>

  <div class="c-upload-desktop">
    <ng-container *ngTemplateOutlet="uploadZoneDesktop"></ng-container>

    @if (_showRows) {
      <ng-container *ngTemplateOutlet="uploadZoneTitle"></ng-container>

      @if (canDragDrop) {
        <div
          (dragover)="onDragEnter($event)"
          (dragleave)="onDragExit($event)"
          (drop)="onDropFile($event)"
        >
          <ng-container *ngTemplateOutlet="uploadRowsDesktop"></ng-container>
        </div>
      } @else {
        <ng-container *ngTemplateOutlet="uploadRowsDesktop"></ng-container>
      }
    }
  </div>
</div>

<ng-template #uploadZoneMobile>
  <!-- OAM-832: centreren inhoud -->
  @if (canDragDrop) {
    <div class="c-upload__field">
      @if (!isLoading) {
        <ngx-ov-link
          (click)="openUploadFile($event)"
          [iconBefore]="'fas fa-paperclip'"
          [text]="uploadActionNameCopy"
          [isDisabled]="maxNumberFiles && rows.length >= maxNumberFiles"
        ></ngx-ov-link>
      } @else {
        <ngx-ov-loader
          [text]="loadingTextCopy"
          [oneLine]="true"
        ></ngx-ov-loader>
      }
      @if (additionalActionText !== '') {
        <br />
        <ng-container *ngTemplateOutlet="additionalAction"></ng-container>
      }
    </div>
  }
</ng-template>

<ng-template #uploadZoneDesktop>
  @if (canDragDrop) {
    <div
      class="c-upload__dragdrop"
      #dragdropzone
      (click)="openUploadFile($event)"
      (dragover)="onDragEnter($event)"
      (dragleave)="onDragExit($event)"
      (drop)="onDropFile($event)"
    >
      @if (!isLoading) {
        <span class="c-upload__dragdrop_action-name">
          {{ dragDropActionNameCopy }}
        </span>
        &nbsp;
        <span class="c-upload__dragdrop_select-files">
          <i
            class="fas fa-paperclip c-upload__action-icon mr:xsmall"
            aria-hidden="true"
          ></i>
          {{ dragDropSelectActionNameCopy }}
        </span>
        <br />
        <span class="c-upload__explanation-files">
          {{ allowedFilesText }}
        </span>
        @if (additionalActionText) {
          <br />
          <ng-container *ngTemplateOutlet="additionalAction"></ng-container>
        }
      } @else {
        <span class="c-upload__dragdrop_action-name">
          <ngx-ov-loader
            [text]="loadingTextCopy"
            [oneLine]="true"
          ></ngx-ov-loader>
        </span>
      }
    </div>
  }
</ng-template>

<ng-template #uploadZoneTitle>
  @if (title) {
    <div class="flex flex-row items-center c-upload__title-container">
      @if (title) {
        <div class="c-upload__title">{{ title }}</div>
      }
      @if (isRequired) {
        <ngx-ov-required-mark></ngx-ov-required-mark>
      }
      <ng-container [ngTemplateOutlet]="tooltipTemplate"></ng-container>
      @if (tooltip && !tooltipTemplate) {
        <ngx-ov-tooltip
          [tooltip]="tooltip"
          [position]="'right'"
        ></ngx-ov-tooltip>
      }
    </div>
  }
</ng-template>

<ng-template #uploadRowsDesktop>
  <table
    class="c-upload__rows"
    [id]="'upload-table-' + identifier"
    [attr.aria-label]="uploadedDocumentsCopy"
  >
    <tr
      class="c-upload__row c-upload__header"
      [ngStyle]="{
        'grid-template-columns': 'repeat(' + columns.length + ', 1fr) 60px'
      }"
    >
      @for (column of columns; track trackByFn) {
        <th class="c-upload__cell" scope="col">
          {{ column.header }}
        </th>
      }

      @if (hasAtLeastOneAction()) {
        <th class="c-upload__cell" scope="col">
          <p></p>
        </th>
      }
    </tr>
    @if (rows?.length === 0) {
      <tr class="c-upload__row c-upload__empty no-pointer-events">
        {{
          emptyTableTextCopy
        }}
      </tr>
    }

    @for (row of rows; let i = $index; track trackByFn) {
      <tr
        class="c-upload__row"
        [ngStyle]="{
          'grid-template-columns': 'repeat(' + columns.length + ', 1fr) 60px'
        }"
      >
        <td
          (click)="onDownloadFileColumn(columns[0], row, i)"
          class="c-upload__cell"
          [ngStyle]="_styles"
        >
          <div
            class="c-upload__value c-upload__value--states"
            [class.is-error]="row.state === 'error'"
            [class.is-succes]="row.state === 'succes' || !row.state"
          >
            <span class="c-upload__value-text">{{ row[columns[0].id] }}</span>
          </div>
        </td>

        <!---
           Other columns
         --->
        @for (column of columns.slice(1); track trackByFn) {
          <td [class.c-upload__row--closed]="!row.isOpen">
            <span class="c-upload__value"> {{ row | path: column.id }}</span>
          </td>
        }

        @if (hasAtLeastOneAction(row)) {
          <td
            [class.c-upload__row--closed]="!row.isOpen"
            class="c-upload__actions"
          >
            @if (row.actions.canDownload && canDownload) {
              <span class="c-upload__action" (click)="onDownloadFile(row, i)">
                <span class="c-upload__action-name">{{
                  downloadActionNameCopy
                }}</span
                ><i
                  class="fas fa-download c-upload__action-icon"
                  aria-hidden="true"
                ></i
              ></span>
            }
            @if (row.actions.canEdit && canEdit) {
              <span class="c-upload__action" (click)="onEditFile(row, i)">
                <span class="c-upload__action-name">{{
                  editActionNameCopy
                }}</span>
                <i
                  class="fas fa-edit c-upload__action-icon"
                  aria-hidden="true"
                ></i>
              </span>
            }
            @if (row.actions.canDelete && canDelete) {
              <span class="c-upload__action" (click)="onDeleteFile(row, i)">
                <span class="c-upload__action-name">{{
                  deleteActionNameCopy
                }}</span>
                <i
                  class="fas fa-trash-alt c-upload__action-icon"
                  aria-hidden="true"
                ></i>
              </span>
            }
          </td>
        }
      </tr>
    }
    <ng-container *ngTemplateOutlet="uploadFooter"></ng-container>
  </table>
</ng-template>

<ng-template #uploadRowsMobile>
  <div class="c-upload__rows">
    @if (rows?.length === 0 && !isLoading) {
      <div class="c-upload__row c-upload__empty">
        {{ emptyTableTextCopy }}
      </div>
    }

    @for (row of rows; let i = $index; track trackByFn) {
      <div class="c-upload__row">
        <!--
          Mobile => first row is header row
        -->
        <div
          (click)="toggleRow(row)"
          [ngStyle]="_styles"
          class="c-upload__cell c-upload__cell__first--tablet"
        >
          <div
            class="c-upload__toggle-title c-upload__file"
            [class.is-error]="row.state === 'error'"
            [class.is-succes]="row.state === 'succes' || !row.state"
          >
            <span class="c-upload__value-text">{{ row[columns[0].id] }}</span>
          </div>
          @if (!row.isNotExpandable) {
            <div class="c-upload__toggle">
              @if (row.isOpen) {
                <i
                  class="fas fa-chevron-up"
                  aria-hidden="true"
                  [title]="iconRowToggleClosedTitle"
                ></i>
              } @else {
                <i
                  class="fas fa-chevron-down"
                  aria-hidden="true"
                  [title]="iconRowToggleOpenTitle"
                ></i>
              }
              <span class="sr-only">{{
                row.isOpen ? iconRowToggleClosedTitle : iconRowToggleOpenTitle
              }}</span>
            </div>
          }
        </div>

        <!---
           Other columns
         --->
        @if (!row.isNotExpandable) {
          @for (column of columns.slice(1); track trackByFn) {
            <div [class.c-upload__row--closed]="!row.isOpen">
              <span class="c-upload__name">{{ column.header }}</span>
              <span class="c-upload__value">{{ row[column.id] }}</span>
            </div>
          }

          @if (hasAtLeastOneAction(row)) {
            <div
              [class.c-upload__row--closed]="!row.isOpen"
              class="c-upload__actions"
            >
              @if (row.actions.canDownload && canDownload) {
                <span class="c-upload__action" (click)="onDownloadFile(row, i)">
                  <span class="c-upload__action-name">{{
                    downloadActionNameCopy
                  }}</span>
                  <i
                    class="fas fa-download c-upload__action-icon"
                    aria-hidden="true"
                  ></i>
                </span>
              }
              @if (row.actions.canEdit && canEdit) {
                <span class="c-upload__action" (click)="onEditFile(row, i)">
                  <span class="c-upload__action-name">{{
                    editActionNameCopy
                  }}</span>
                  <i
                    class="fas fa-edit c-upload__action-icon"
                    aria-hidden="true"
                  ></i>
                </span>
              }
              @if (row.actions.canDelete && canDelete) {
                <span class="c-upload__action" (click)="onDeleteFile(row, i)">
                  <span class="c-upload__action-name">{{
                    deleteActionNameCopy
                  }}</span>
                  <i
                    class="fas fa-trash-alt c-upload__action-icon"
                    aria-hidden="true"
                  ></i>
                </span>
              }
            </div>
          }
        }
      </div>
    }

    <ng-container *ngTemplateOutlet="uploadFooter"></ng-container>
  </div>
</ng-template>

<ng-template #additionalAction>
  <ngx-ov-link
    text="{{ additionalActionText }}"
    (click)="onClickAdditionalAction()"
  ></ngx-ov-link>
</ng-template>

<ng-template #uploadFooter>
  @if (errorMessage) {
    <div class="c-upload__cell">
      <div class="c-upload__error_message">
        <span class="c-upload__error_message-text">{{ errorMessage }}</span>
      </div>
    </div>
  }
  @if (canUpload && !canDragDrop) {
    <div>
      <div class="c-upload__footer">
        <!-- Upload action -->
        @if (!isLoading) {
          <div class="c-upload__footer__left">
            <ngx-ov-link
              (click)="openUploadFile($event)"
              [iconBefore]="'fas fa-paperclip'"
              [text]="uploadActionNameCopy"
              [isDisabled]="maxNumberFiles && rows.length >= maxNumberFiles"
            ></ngx-ov-link>
          </div>
          @if (allowedFilesText) {
            <div class="c-upload__footer__right c-upload__explanation-files">
              {{ allowedFilesText }}
            </div>
          }
        } @else {
          <!-- Loading state -->
          <div class="c-upload__footer__left_loading">
            <span class="c-upload__footer__left_loading_icon">
              <i
                class="fas fa-sync fa-spin"
                aria-hidden="true"
                title="Loading indicator"
              ></i>
              <span class="sr-only">Loading indicator</span>
            </span>
            <span class="c-upload__footer__left_loading_text">
              {{ loadingTextCopy }}
            </span>
          </div>
        }
      </div>
    </div>
  }

  @if (additionalActionText && !canDragDrop) {
    <div class="c-upload__additional_action">
      <ng-container *ngTemplateOutlet="additionalAction"></ng-container>
    </div>
  }
</ng-template>
