import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Optional
} from '@angular/core';
import { LinkService } from '../../../services/link.service';
import { TranslationsService } from '../../../services/translations.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxOvComponentBaseClass } from '../../ngx-ov-component-base-class';
import { ClickEqualsEnterDirective } from '../../../directives/click-equals-enter';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-contact-card',
  templateUrl: './contact-card.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ClickEqualsEnterDirective]
})
export class NgxOvContactCardComponent extends NgxOvComponentBaseClass {
  // -------------------------------------------------------------------------
  // Variables
  // -------------------------------------------------------------------------
  contactLabel;
  telephoneLabel;
  emailLabel;
  websiteLabel;

  @Input() branch: string;
  @Input() department: string;
  @Input() telephone: string;
  @Input() email: string;
  @Input() contactUrl: string;
  @Input() url: string;

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  constructor(
    private linkService: LinkService,
    public translationsService: TranslationsService,
    @Optional() public translateService: TranslateService
  ) {
    super(translationsService, translateService);
  }

  onClickUrl(url: string) {
    this.linkService.navigate(url);
  }

  setLabels() {
    const prefix = 'DEFAULT_LAYOUT_BLOKKEN.CONTACT_CARD';
    this.contactLabel = this.getTranslation(prefix, 'CONTACT_LABEL');
    this.telephoneLabel = this.getTranslation(prefix, 'TELEPHONE_LABEL');
    this.emailLabel = this.getTranslation(prefix, 'EMAIL_LABEL');
    this.websiteLabel = this.getTranslation(prefix, 'WEBSITE_LABEL');
  }
}
