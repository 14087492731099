<div class="container u-container-width">
  <div class="my-10 p-large">
    <ngx-ov-news-teaser-list [title]="'Nieuws'">
      <ngx-ov-news-teaser
        [title]="'Onderwijsdoelen in het secundair onderwijs'"
        [label]="'video'"
        [image]="'./assets/images/undraw_empty.png'"
        [type]="'video'"
        [hyperlink]="'forms'"
        [picturePortrait]="true"
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non, sed amet
        egestas, non, sed amet egestas
      </ngx-ov-news-teaser>
      <ngx-ov-news-teaser
        [title]="'Onderwijsdoelen in het secundair onderwijs'"
        [label]="'video'"
        [image]="'./assets/images/undraw_fixing_bugs.png'"
        [hyperlink]="'https://www.vlaanderen.be'"
        [picturePortrait]="true"
      >
        Content nieuws item 2
      </ngx-ov-news-teaser>
      <ngx-ov-news-teaser
        [title]="'Onderwijsdoelen in het secundair onderwijs'"
        [label]="'Nieuws'"
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non, sed amet
        egestas, non, sed amet egestas
      </ngx-ov-news-teaser>
      <ngx-ov-news-teaser
        [title]="'Onderwijsdoelen in het secundair onderwijs'"
        [label]="'video'"
        [image]="'./assets/images/banner_1.jpeg'"
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non, sed amet
        egestas, non, sed amet egestas
      </ngx-ov-news-teaser>
    </ngx-ov-news-teaser-list>
  </div>
</div>
<div class="u-divider"></div>

<div class="container">
  <ngx-ov-button
    [classes]="'tablet:hidden'"
    label="Open messagebar mobile"
    (buttonClick)="toggleOpenMessagebar()"
    [attr.data-cy]="'messagebar-button-mobile'"
  ></ngx-ov-button>
  <mat-stepper labelPosition="bottom" #stepper>
    <mat-step [completed]="true" [editable]="false">
      <form>
        <ng-template matStepLabel>Verplichte documenten</ng-template>
      </form>
    </mat-step>
    <mat-step optional>
      <form>
        <ng-template matStepLabel>Optionele Documenten</ng-template>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Alle documenten zijn opgeladen</ng-template>
    </mat-step>
  </mat-stepper>

  <mat-stepper orientation="vertical" #stepper [linear]="true">
    <mat-step>
      <div
        style="
          width: 100px;
          height: 50px;
          background-color: #00b0e8;
          overflow: hidden;
          padding: 5px;
        "
      >
        <ngx-ov-tooltip [position]="'bottom'">TEST TOOLTIP</ngx-ov-tooltip>
      </div>
    </mat-step>
  </mat-stepper>

  <div
    style="
      width: 100px;
      height: 50px;
      background-color: #e8b5ec;
      overflow: hidden;
      padding: 5px;
    "
  >
    <ngx-ov-tooltip
      [triggerType]="'mouseenter'"
      [position]="'bottom'"
      (tooltipClicked)="onTooltipClicked('modal-tooltip')"
    ></ngx-ov-tooltip>
  </div>

  <ngx-ov-pill
    (click)="testClick('pill clicked')"
    [isFilter]="true"
    [text]="'Pill 1'"
  >
  </ngx-ov-pill>

  <ngx-ov-profile
    [name]="'Vermeulen'"
    [firstname]="'Joske'"
    [imageToShow]="'../../../assets/images/dog.jpg'"
    [extraInfo]="'Maximale resolutie van 600x600 <a>www.google.be</a>'"
  ></ngx-ov-profile>

  <div class="my-10">
    <ngx-ov-pill [text]="'Standard'"></ngx-ov-pill>
    <ngx-ov-pill [text]="'Filter'" [isFilter]="true"></ngx-ov-pill>
    <ngx-ov-pill [text]="'Error'" [isError]="true"></ngx-ov-pill>
    <ngx-ov-pill [text]="'Warning'" [isWarning]="true"></ngx-ov-pill>
    <ngx-ov-pill [text]="'Success'" [isSuccess]="true"></ngx-ov-pill>
    <ngx-ov-pill
      [text]="'Mix'"
      [isFilter]="true"
      [isWarning]="true"
    ></ngx-ov-pill>
  </div>

  <div class="my-10 grid">
    <div class="col-span-12 desktop:col-span-6">
      <mat-icon fontSet="fas" fontIcon="fa-birthday-cake"></mat-icon>
      <mat-vertical-stepper [linear]="true" #verticalStepper>
        <mat-step state="phone" [completed]="true" [editable]="false">
          <ng-template matStepLabel>
            Vul je opleiding historiek aan
          </ng-template>
          <form>
            <ngx-ov-progress-block
              [title]="'Vul je gegevens aan'"
              [percentage]="66"
              url="/wdhuiwd"
              [isCompleted]="true"
            >
            </ngx-ov-progress-block>
          </form>
        </mat-step>
        <div class="cta">
          <mat-step [label]="'Laad je document op'" [completed]="false">
            <ngx-ov-progress-block
              [title]="'Vul je gegevens aan'"
              [percentage]="66"
              url="/wdhuiwd"
              (progressClicked)="onClickAction('progressblock works!')"
            >
            </ngx-ov-progress-block>
          </mat-step>
        </div>
        <mat-step [label]="'Betaal je aanvraag'" [completed]="false">
          <ngx-ov-progress-block
            [title]="'Vul je gegevens aan'"
            [percentage]="66"
            url="/wdhuiwd"
            [isDisabled]="true"
          >
          </ngx-ov-progress-block>
        </mat-step>
        <ng-template matStepperIcon="phone">
          <mat-icon fontSet="fas" fontIcon="fa-birthday-cake"></mat-icon>
        </ng-template>
      </mat-vertical-stepper>
    </div>
  </div>

  <div class="my-10">
    <ngx-ov-agenda>
      <ngx-ov-agenda-item [title]="'Examen Engels'" [url]="'/'">
        Content agendasdfs sdfsdfs Content agendasdfs sdfsdfsContent agendasdfs
        sdfsdfs
        <ngx-ov-tooltip [position]="'bottom'">
          Test tooltip qsgfsdqfsqdf qsdf1 qsdf2 qsd3 af scherm
        </ngx-ov-tooltip>
      </ngx-ov-agenda-item>

      <ngx-ov-agenda-item [title]="'Examen Nederlands'">
        Content agenda
        <ng-template ngxOvAgendaActions>
          <ngx-ov-flyout
            [trigger]="
              '<i class=\'fas fa-ellipsis-v\' aria-hidden=\'true\'></i>'
            "
            [secondary]="false"
            [position]="'left'"
            [header]="'acties'"
          >
            <ngx-ov-flyout-item [link]="''">Link 1</ngx-ov-flyout-item>
            <ngx-ov-flyout-item [link]="''">Link 2</ngx-ov-flyout-item>
          </ngx-ov-flyout>
        </ng-template>
      </ngx-ov-agenda-item>
    </ngx-ov-agenda>
  </div>

  <ngx-ov-button
    [label]="'button with badge'"
    ngxOvBadge="test badge"
    [ngxOvBadgeBackgroundColor]="'#000000'"
    [ngxOvBadgeColor]="'#ffffff'"
  ></ngx-ov-button>
  <br />
  <i class="fas fa-eye pr-20" ngxOvBadge="1" aria-hidden="true"></i>

  <br />
  <ngx-ov-global-alert
    [message]="'Test global alert with badge'"
    [showIcon]="false"
    >test extra content
    <ngx-ov-badge [backgroundColor]="'#ffffff'" [color]="'#000000'">
      test badge
    </ngx-ov-badge>
  </ngx-ov-global-alert>
  <ngx-ov-badge [backgroundColor]="'#15465B'" [color]="'#ffffff'">
    test badge
  </ngx-ov-badge>

  <ngx-ov-badge [backgroundColor]="'#15465B'" [color]="'#ffffff'">
    1
  </ngx-ov-badge>
  <ngx-ov-badge [color]="'success'"> test badge </ngx-ov-badge>
  <ngx-ov-badge [color]="'warning'"> test badge </ngx-ov-badge>
  <ngx-ov-badge [color]="'error'"> test badge </ngx-ov-badge>
  {{ showTab$ | async }}
  <ngx-ov-tabs (tabClicked)="onTabClicked()">
    @if (showTab$ | async) {
      <ngx-ov-tab [tabId]="'1'" [tabTitle]="'Tab 1'">
        Content tab 1
      </ngx-ov-tab>
    }

    <ngx-ov-tab [tabTitle]="'Tab 2'" [isActive]="true">
      Content tab 2
    </ngx-ov-tab>
  </ngx-ov-tabs>

  <ngx-ov-global-alert [message]="'Test global alert'" [showIcon]="false">
    test extra content
  </ngx-ov-global-alert>

  <br />
  <ngx-ov-doormat-acm
    [title]="'Doormat ACM'"
    [linkUrl]="'/forms'"
    [url]="'/forms'"
    [linkLabel]="'Forms'"
  >
    Test doormat
  </ngx-ov-doormat-acm>

  <ngx-ov-doormat
    [title]="'Doormat met URL'"
    [url]="'/forms'"
    [icon]="'fas fa-user'"
    [moreInfoLabel]="'Meer info'"
    (doormatClicked)="onDoormatClicked($event)"
    (moreInfoClicked)="onDoormatClicked('info')"
  >
    <p>Dit is content van een doormat met URL</p>
  </ngx-ov-doormat>

  <br />

  <ngx-ov-doormat
    [title]="'Doormat met een kleurtje'"
    [url]="'/forms'"
    [icon]="'fas fa-user'"
    [moreInfoLabel]="'Meer info'"
    [type]="'warning'"
    (doormatClicked)="onDoormatClicked($event)"
    (moreInfoClicked)="onDoormatClicked('info')"
  >
    <p>Dit is content van een doormat met een kleurtje</p>
  </ngx-ov-doormat>

  <br />

  <ngx-ov-doormat
    [title]="'Doormat zonder URL'"
    [icon]="'fas fa-user'"
    [moreInfoLabel]="'Meer info'"
    (doormatClicked)="onDoormatClicked($event)"
    (moreInfoClicked)="onDoormatClicked('info')"
  >
    <p>Dit is content van een doormat zonder URL</p>
    <p>Zin een</p>
    <p>Zin twee</p>
    <p>Zin drie</p>
    <p>Zin vier</p>
    <p>Zin vier</p>
    <p>Zin vier</p>
    <p>Zin vier</p>
    <p>Zin vier</p>
  </ngx-ov-doormat>

  <br />

  <div>
    <h5>Basis oplijsting</h5>
    <ul class="u-list">
      <li>item een</li>
      <li>item twee</li>
      <li>item drie</li>
      <li>item vier</li>
      <li>item vijf</li>
    </ul>

    <br />
    <h5>Nested oplijsting</h5>
    <ul class="u-list">
      <li>
        item een
        <ul class="fa-ul">
          <li>
            <span class="fa-li"
              ><i class="fas fa-minus" aria-hidden="true"></i
            ></span>
            item een
          </li>
          <li>
            <span class="fa-li"
              ><i class="fas fa-minus" aria-hidden="true"></i
            ></span>
            item twee
          </li>
          <li>
            <span class="fa-li"
              ><i class="fas fa-minus" aria-hidden="true"></i
            ></span>
            item drie
          </li>
        </ul>
      </li>
      <li>item twee</li>
      <li>item drie</li>
      <li>item vier</li>
      <li>item vijf</li>
    </ul>

    <br />
    <h5>overzicht oplijsting</h5>
    <ul class="fa-ul u-list-overview">
      <li>
        <span class="fa-li"
          ><i class="fas fa-chevron-right" aria-hidden="true"></i
        ></span>
        <strong>Aagje Reynders</strong> <br />
        INSZ:15487454817
      </li>
      <li>
        <span class="fa-li"
          ><i class="fas fa-chevron-right" aria-hidden="true"></i
        ></span>
        <strong>Aagje Reynders</strong> <br />
        INSZ:15487454817
      </li>
      <li>
        <span class="fa-li"
          ><i class="fas fa-chevron-right" aria-hidden="true"></i
        ></span>
        <strong>Aagje Reynders</strong> <br />
        INSZ:15487454817
      </li>
    </ul>

    <br />
    <h5>CTA</h5>
    <ul class="fa-ul u-list-overview">
      <li>
        <span class="fa-li"
          ><i class="fas fa-chevron-right" aria-hidden="true"></i></span
        ><a>Hallo CTA </a>
      </li>
      <li>
        <span class="fa-li"
          ><i class="fas fa-chevron-right" aria-hidden="true"></i></span
        ><a>Hallo CTA </a>
      </li>
      <li>
        <span class="fa-li"
          ><i class="fas fa-chevron-right" aria-hidden="true"></i></span
        ><a>Hallo CTA </a>
      </li>
    </ul>
  </div>

  <ngx-ov-expansion-panel-list>
    <ngx-ov-expansion-panel-item
      [title]="
        'Hier komt een titel van een item met een hele lange titel om te kijken hoe de uitlijning zich dan gedraagt'
      "
    >
      <ng-template ngxOvPortalTitleContent>
        Extra info when collapsed
      </ng-template>
      <ng-template ngxOvPortalRightContent>Label 1 | Label 2</ng-template>

      <div>
        <p>Dit is een beetje content.</p>
        <p>
          We voegen dit toe om te bekijken hoe het getoond wordt binnen een
          search results item.
        </p>
        <p>
          En wat dan met een hele lange zin die er waarschijnlijk voor zorgt dat
          hij niet volledig op dezelfde lijn kan en dus naar de volgende lijn
          moet?
        </p>
        <p class="ov-ui-p-bold ov-ui-p-medium">Also: 43 is the answer</p>
      </div>
    </ngx-ov-expansion-panel-item>
    <ngx-ov-expansion-panel-item [title]="'Item 2'">
      <p>Item 2</p>
    </ngx-ov-expansion-panel-item>
    <ngx-ov-expansion-panel-item [title]="'Item 3'">
      <p>Item 3</p>
    </ngx-ov-expansion-panel-item>
  </ngx-ov-expansion-panel-list>

  <ngx-ov-contact-card
    [branch]="'Agentschap Vlees en Vis'"
    [department]="'Afdeling Rundsvlees'"
    [telephone]="'02 111 11 11'"
    [email]="'info@afdeling.be'"
    [url]="'www.google.be'"
  ></ngx-ov-contact-card>

  <ngx-ov-arrow-navigation
    [title]="'Huidig: <strong>CURR_ITEM</strong>'"
    [items]="arrowNavigationItems"
    [titleItemPlaceholder]="'CURR_ITEM'"
    [selectedIndex]="2"
  ></ngx-ov-arrow-navigation>

  <ngx-ov-arrow-navigation
    [title]="'Huidig: <strong>CURR_ITEM</strong>'"
    [items]="arrowNavigationItems"
    [titleItemPlaceholder]="'CURR_ITEM'"
    [previousItemPlaceholder]="'Vorig'"
    [nextItemPlaceholder]="'Volgend'"
    [selectedIndex]="2"
  ></ngx-ov-arrow-navigation>

  <ngx-ov-button
    [label]="'Click me!'"
    [isLoading]="_buttonLoading"
    (buttonClick)="buttonClicked($event)"
    classes="mr-4"
  ></ngx-ov-button>
  <ngx-ov-button
    [label]="'Click me!'"
    [clickTimeout]="0"
    (buttonClick)="buttonClicked($event)"
  ></ngx-ov-button>

  <ngx-ov-button
    [label]="'FAB'"
    [floatingActionButtonPosition]="fabButtonPosition"
    (buttonClick)="buttonClicked($event)"
  ></ngx-ov-button>

  <ngx-ov-alert
    [title]="'Opgelet!'"
    [icon]="'exclamation-circle'"
    [type]="'success'"
  >
    <div
      [innerHTML]="
        'Vanaf schooljaar 2019-2020 wordt de schooltoeslag niet meer uitbetaald door de afdeling Studietoelagen. Meer info op <a href=\'http:\/\/www.groeipakket.be\'>www.groeipakket.be</a>'
      "
    ></div>
  </ngx-ov-alert>

  <ngx-ov-alert
    [title]="'Opgelet!'"
    [icon]="'exclamation-circle'"
    [type]="'warning'"
  >
    <div
      [innerHTML]="
        'Vanaf schooljaar 2019-2020 wordt de schooltoeslag niet meer uitbetaald door de afdeling Studietoelagen. Meer info op <a href=\'http:\/\/www.groeipakket.be\'>www.groeipakket.be</a>'
      "
    ></div>
  </ngx-ov-alert>

  <ngx-ov-alert
    [title]="'Alert met content!'"
    [icon]="'exclamation-circle'"
    [type]="'error'"
  >
    <div
      [innerHTML]="
        'Vanaf schooljaar 2019-2020 wordt de schooltoeslag niet meer uitbetaald door de afdeling Studietoelagen. Meer info op <a href=\'http:\/\/www.groeipakket.be\'>www.groeipakket.be</a>'
      "
    ></div>
  </ngx-ov-alert>
  <ngx-ov-alert
    [title]="'Alert met message als input parameter'"
    [message]="
      'Vanaf schooljaar 2019-2020 wordt de schooltoeslag niet meer uitbetaald door de afdeling Studietoelagen. Meer info op <a href=\'http:\\/\\/www.groeipakket.be\'>www.groeipakket.be</a>'
    "
    [icon]="'exclamation-circle'"
    [type]="'error'"
  >
  </ngx-ov-alert>

  <ngx-ov-action-list>
    <ngx-ov-action-list-item
      [title]="'Action list item 1'"
      [subtitle]="'Eerste item'"
      (itemClick)="onClickActionItem('Uitvoeren action - bekijken item 1')"
    >
      <ng-template ngxOvActionListItemActionsPortal>
        <i
          (click)="onClickAction('Uitvoeren action - bekijken item 1')"
          class="fas fa-eye"
          aria-hidden="true"
          title="Bekijken"
        ></i>
        <span class="sr-only">Bekijken</span>
        <i
          (click)="onClickAction('Uitvoeren action - verwijderen item 1')"
          class="fas fa-trash"
          aria-hidden="true"
          title="Verwijderen"
        ></i>
        <span class="sr-only">Verwijderen</span>
      </ng-template>

      <ng-template ngxOvActionListItemDetailPortal>
        <p>Some detail content</p>
      </ng-template>
    </ngx-ov-action-list-item>

    <ngx-ov-action-list-item
      [title]="'Action list item 1'"
      [subtitle]="'Eerste item'"
      (itemClick)="onClickActionItem('Uitvoeren action - bekijken item 1')"
    >
      <ng-template ngxOvActionListItemActionsPortal>
        <ngx-ov-3-way-toggle [isLightMode]="true"></ngx-ov-3-way-toggle>
      </ng-template>

      <ng-template ngxOvActionListItemDetailPortal>
        <p>Some detail content</p>
      </ng-template>
    </ngx-ov-action-list-item>

    <ngx-ov-action-list-item
      [title]="'Action list item 2'"
      [subtitle]="'Tweede item'"
    ></ngx-ov-action-list-item>
  </ngx-ov-action-list>

  <ngx-ov-input-label [text]="'Label'" [required]="true"></ngx-ov-input-label>
  <table ngx-ov-data-table>
    <caption>
      Demo table
    </caption>
    <thead>
      <tr ngx-ov-data-table-row>
        <th ngx-ov-data-table-column scope="row">Kolom 1</th>
        <th ngx-ov-data-table-column scope="row">Kolom 2</th>
      </tr>
    </thead>
    <tbody>
      <tr ngx-ov-data-table-row>
        <td ngx-ov-data-table-cell>Val 1</td>
        <td ngx-ov-data-table-cell>Val 2</td>
      </tr>
    </tbody>
  </table>

  <div>
    <table ngx-ov-data-table>
      <caption>
        Demo table 2
      </caption>
      <thead>
        <tr ngx-ov-data-table-row>
          <th ngx-ov-data-table-column class="w-[500px]" scope="col">Test</th>
          <th ngx-ov-data-table-column class="w-[500px]" scope="col">
            Form field
          </th>
          <th ngx-ov-data-table-column scope="col">Value</th>
        </tr>
      </thead>
      <tbody>
        <tr ngx-ov-data-table-row>
          <td ngx-ov-data-table-cell>Zonder label</td>
          <td ngx-ov-data-table-cell>
            <ngx-ov-input-field
              [(ngModel)]="_input_field_val"
            ></ngx-ov-input-field>
          </td>
          <td ngx-ov-data-table-cell>{{ _input_field_val }}</td>
        </tr>
        <tr ngx-ov-data-table-row>
          <td ngx-ov-data-table-cell>Default val / not required</td>
          <td ngx-ov-data-table-cell>
            <ngx-ov-input-field
              [label]="'Input field'"
              [(ngModel)]="_input_field_val"
            ></ngx-ov-input-field>
          </td>
          <td ngx-ov-data-table-cell>{{ _input_field_val }}</td>
        </tr>
        <tr ngx-ov-data-table-row>
          <td ngx-ov-data-table-cell>Default val / required</td>
          <td ngx-ov-data-table-cell>
            <ngx-ov-input-field
              [label]="'Input field'"
              [(ngModel)]="_input_field_val"
              required
            ></ngx-ov-input-field>
          </td>
          <td ngx-ov-data-table-cell>{{ _input_field_val }}</td>
        </tr>
        <tr ngx-ov-data-table-row>
          <td ngx-ov-data-table-cell>Default leeg / required</td>
          <td ngx-ov-data-table-cell>
            <ngx-ov-input-field
              [label]="'Input field'"
              [(ngModel)]="_input_field_val_empty"
              required
            ></ngx-ov-input-field>
          </td>
          <td ngx-ov-data-table-cell>{{ _input_field_val_empty }}</td>
        </tr>
        <tr ngx-ov-data-table-row>
          <td ngx-ov-data-table-cell>Mask voorbeeld</td>
          <td ngx-ov-data-table-cell>
            <ngx-ov-input-field
              [label]="'Masked input field'"
              maskExpr="000"
              [(ngModel)]="_input_field_max_200"
              required
              aria-valuemax="200"
            ></ngx-ov-input-field>
          </td>
          <td ngx-ov-data-table-cell>{{ _input_field_max_200 }}</td>
        </tr>
        <tr ngx-ov-data-table-row>
          <td ngx-ov-data-table-cell>Default leeg / required</td>
          <td ngx-ov-data-table-cell>
            <ngx-ov-input-field
              [label]="'Input field'"
              [(ngModel)]="_input_field_val_empty"
              required
            ></ngx-ov-input-field>
          </td>
          <td ngx-ov-data-table-cell>{{ _input_field_val_empty }}</td>
        </tr>
        <tr ngx-ov-data-table-row>
          <td ngx-ov-data-table-cell>Readonly</td>
          <td ngx-ov-data-table-cell>
            <ngx-ov-input-field
              [label]="'Input field readonly'"
              [(ngModel)]="_input_field_val_empty"
              [isReadonly]="true"
            ></ngx-ov-input-field>
          </td>
          <td ngx-ov-data-table-cell>{{ _input_field_val_empty }}</td>
        </tr>
        <tr ngx-ov-data-table-row>
          <td ngx-ov-data-table-cell>Default leeg / email</td>
          <td ngx-ov-data-table-cell>
            <ngx-ov-input-field
              [label]="'Email'"
              [(ngModel)]="_input_field_val_empty"
              [placeholder]="'email adres'"
              email
            ></ngx-ov-input-field>
          </td>
          <td ngx-ov-data-table-cell>{{ _input_field_val_empty }}</td>
        </tr>
        <tr ngx-ov-data-table-row>
          <td ngx-ov-data-table-cell>Default leeg / maxlength 4</td>
          <td ngx-ov-data-table-cell>
            <ngx-ov-input-field
              [label]="'Maxlength voorbeeld'"
              [(ngModel)]="_input_field_val_empty"
              [placeholder]="'maximum 4 karakters'"
              [maxlength]="4"
            ></ngx-ov-input-field>
          </td>
          <td ngx-ov-data-table-cell>{{ _input_field_val_empty }}</td>
        </tr>

        <tr ngx-ov-data-table-row>
          <td ngx-ov-data-table-cell>Checkbox / empty</td>
          <td ngx-ov-data-table-cell>
            <ngx-ov-checkbox [(ngModel)]="_checkbox_value"></ngx-ov-checkbox>
          </td>
          <td ngx-ov-data-table-cell>{{ _checkbox_value }}</td>
        </tr>
        <tr ngx-ov-data-table-row>
          <td ngx-ov-data-table-cell>Checkbox / default checked</td>
          <td ngx-ov-data-table-cell>
            <ngx-ov-checkbox
              [(ngModel)]="_checkbox_value_default_checked"
              [checked]="true"
            ></ngx-ov-checkbox>
          </td>
          <td ngx-ov-data-table-cell>
            {{ _checkbox_value_default_checked }}
          </td>
        </tr>
        <tr ngx-ov-data-table-row>
          <td ngx-ov-data-table-cell>Datepicker</td>
          <td ngx-ov-data-table-cell>
            <ngx-ov-datepicker
              [label]="'Geboortedatum'"
              [(ngModel)]="_selectedDate"
              [allowInput]="true"
              [convertModelValue]="true"
              [mode]="'single'"
            ></ngx-ov-datepicker>
            <div (click)="clearGeboorteDatum()">clear</div>
          </td>
          <td ngx-ov-data-table-cell>{{ _selectedDate | json }}</td>
        </tr>
        <tr ngx-ov-data-table-row>
          <td ngx-ov-data-table-cell>Datepicker</td>
          <td ngx-ov-data-table-cell>
            <ngx-ov-datepicker
              [label]="'Geboortedatum default'"
              [(ngModel)]="_selectedDateDefaultNow"
              [altInput]="true"
              [altFormat]="'d/m/Y'"
              [allowInput]="true"
              [convertModelValue]="true"
              [mode]="'single'"
            ></ngx-ov-datepicker>
            <div (click)="clearGeboorteDatum()">clear</div>
          </td>
          <td ngx-ov-data-table-cell>
            {{ _selectedDateDefaultNow | json }}
          </td>
        </tr>
        <tr ngx-ov-data-table-row>
          <td ngx-ov-data-table-cell>Datepicker range</td>
          <td ngx-ov-data-table-cell>
            <ngx-ov-datepicker
              [label]="'Geboortedatum'"
              [(ngModel)]="_selectedDate"
              [altInput]="true"
              [convertModelValue]="true"
              [mode]="'range'"
            ></ngx-ov-datepicker>
          </td>
          <td ngx-ov-data-table-cell>{{ _selectedDate | json }}</td>
        </tr>
        <tr ngx-ov-data-table-row>
          <td ngx-ov-data-table-cell>Select</td>
          <td ngx-ov-data-table-cell>
            <ngx-ov-select
              [label]="'Select'"
              [name]="'TEST2'"
              [items]="_data2"
              bindLabel="naam"
              [closeOnSelect]="true"
              [groupBy]="'country'"
              [isLoading]="false"
              [multiple]="true"
              [placeholder]="'Selecteer een persoon'"
              [searchable]="false"
              [isReadonly]="false"
              [(ngModel)]="_select_val_2"
              [isDisabled]="false"
              required
            ></ngx-ov-select>
          </td>
          <td ngx-ov-data-table-cell>{{ _select_val_2 | json }}</td>
        </tr>

        <tr>
          <td ngx-ov-data-table-cell>Toggle</td>
          <td ngx-ov-data-table-cell>
            <ngx-ov-toggle
              [(ngModel)]="_toggle_test"
              [isLarge]="true"
              [label]="'toggle test'"
            >
            </ngx-ov-toggle>
          </td>
          <td ngx-ov-data-table-cell>{{ _toggle_test | json }}</td>
        </tr>

        <tr>
          <td ngx-ov-data-table-cell>Three way toggle</td>
          <td ngx-ov-data-table-cell>
            <ngx-ov-3-way-toggle
              [(ngModel)]="_three_way_toggle_test"
              [isLightMode]="false"
              [label]="'3-way toggle'"
            >
            </ngx-ov-3-way-toggle>
          </td>
          <td ngx-ov-data-table-cell>
            {{ _three_way_toggle_test | json }}
          </td>
        </tr>
      </tbody>
    </table>

    Reactive forms:
    <form [formGroup]="form">
      <ngx-ov-input-field
        [label]="'Reactive'"
        formControlName="job_name"
        [placeholder]="'email adres'"
        email
      ></ngx-ov-input-field>

      <ngx-ov-textarea
        [formControlName]="'job_description'"
        [placeholder]="'job description'"
        [maxChar]="50"
      ></ngx-ov-textarea>

      <ngx-ov-checkbox
        formControlName="isChecked"
        [label]="'Checked?'"
      ></ngx-ov-checkbox>

      <ngx-ov-toggle
        formControlName="toggle"
        [isLarge]="true"
        [label]="'toggle test'"
      >
      </ngx-ov-toggle>

      <ngx-ov-3-way-toggle
        formControlName="three_way_toggle"
        [isLightMode]="false"
        [label]="'3-way toggle'"
      >
      </ngx-ov-3-way-toggle>

      <hr />

      <ngx-ov-radio-group
        [name]="'job_enabled'"
        formControlName="job_enabled"
        [options]="_radio_options"
      ></ngx-ov-radio-group>

      <hr />

      <ngx-ov-checkbox-group
        [name]="'job_window'"
        formControlName="job_window"
        [options]="_checkbox_options"
      ></ngx-ov-checkbox-group>

      <hr />

      <ngx-ov-radio-group
        [name]="'job_enabled'"
        formControlName="job_enabled"
        [options]="_radio_options"
      >
        <ngx-ov-radio
          [id]="'1'"
          [for]="1"
          [value]="true"
          [label]="'YesYesYes'"
        ></ngx-ov-radio>
      </ngx-ov-radio-group>

      <ngx-ov-input-field
        [label]="'Masked input field'"
        maskExpr="000"
        formControlName="waarde_max_200"
        required
        aria-valuemax="200"
      ></ngx-ov-input-field>

      {{ form.getRawValue() | json }}
    </form>
  </div>
  <ngx-ov-message-bar title="Message bar" icon="fa-regular fa-envelope">
    <ngx-ov-expansion-panel-list>
      @for (i of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]; track trackByFn) {
        <ngx-ov-expansion-panel-item [title]="'Test voor lange notities'">
          Test voor lange notities. Test voor lange notities.Test voor lange
          notities. Test voor lange notities.Test voor lange notities. Test voor
          lange notities.Test voor lange notities. Test voor lange notities.Test
          voor lange notities. Test voor lange notities.Test voor lange
          notities. Test voor lange notities.Test voor lange notities. Test voor
          lange notities.Test voor lange notities. Test voor lange notities.Test
          voor lange notities. Test voor lange notities.Test voor lange
          notities. Test voor lange notities.Test voor lange notities. Test voor
          lange notities.Test voor lange notities. Test voor lange notities.Test
          voor lange notities. Test voor lange notities.Test voor lange
          notities. Test voor lange notities.Test voor lange notities. Test voor
          lange notities.Test voor lange notities. Test voor lange notities.Test
          voor lange notities. Test voor lange notities.Test voor lange
          notities. Test voor lange notities.Test voor lange notities. Test voor
          lange notities.Test voor lange notities. Test voor lange notities.Test
          voor lange notities. Test voor lange notities.Test voor lange
          notities. Test voor lange notities.Test voor lange notities. Test voor
          lange notities.Test voor lange notities. Test voor lange notities.Test
          voor lange notities. Test voor lange notities.
        </ngx-ov-expansion-panel-item>
      }
    </ngx-ov-expansion-panel-list>

    <ng-template ngxOvMessageBarActions
      ><i
        class="fa-solid fa-plus"
        [attr.data-cy]="'messagebar-alert'"
        (click.stop)="onClickAction(' + in messagebar clicked')"
      ></i>
    </ng-template>
  </ngx-ov-message-bar>

  <ngx-ov-info-block [title]="'Contactenlijst'" [icon]="'fas fa-book'">
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt commodo
      nec sed mauris ullamcorper pretium sit rhoncus tortor. Faucibus purus eros
      ullamcorper cursus feugiat blandit tortor. Consectetur lobortis lobortis
      eget nunc lobortis luctus purus, amet, habitant. Ac ornare mauris tempus
      enim, tellus gravida consectetur. Turpis aliquam, malesuada pellentesque
      nunc in elit, morbi sed ut. Facilisis odio arcu nam accumsan sed egestas.
      Facilisis cursus dignissim donec suspendisse non ac cras. Facilisis
      pellentesque velit in purus faucibus diam. Et tortor libero quisque lectus
      ut. Phasellus sit ultricies pretium elit. Enim in ipsum ut vitae. Amet sed
      mattis imperdiet amet, volutpat. Tincidunt morbi condimentum facilisis
      vestibulum, mus odio. Tempus, justo, suspendisse diam scelerisque sit quis
      sagittis. Velit vitae proin nec aliquet. Pharetra, cras neque amet, sed
      sapien. Suspendisse massa nunc maecenas morbi purus mus. Sollicitudin
      magna a congue risus tempus, et congue bibendum dictum. Aliquet gravida
      amet ultricies aliquam cursus suscipit massa amet. Integer iaculis in
      sodales arcu blandit arcu hendrerit pellentesque et. Feugiat venenatis
      vulputate viverra sit leo facilisi. Porta egestas ut amet imperdiet cras
      ultrices posuere. Mi sit amet, nisl, lorem feugiat tempus, vestibulum.
      Tortor ut tempus nisi arcu.
    </p>
  </ngx-ov-info-block>

  <ngx-ov-info-block [title]="'Titel'" [icon]="'fas fa-book'">
    <p>Content</p>
  </ngx-ov-info-block>
</div>

<ngx-ov-status-message
  [title]="'Page not found'"
  [ctaLabel]="'Scroll terug naar boven'"
  [img]="'./assets/images/undraw_road_sign.png'"
  (ctaClicked)="onScrollTop()"
>
  De pagina die u zoekt vinden we niet meer terug

  <ul class="fa-ul u-list-overview">
    <li>
      <span class="fa-li"
        ><i class="fas fa-chevron-right" aria-hidden="true"></i></span
      ><a>Hallo CTA </a>
    </li>
    <li>
      <span class="fa-li"
        ><i class="fas fa-chevron-right" aria-hidden="true"></i></span
      ><a>Hallo CTA </a>
    </li>
    <li>
      <span class="fa-li"
        ><i class="fas fa-chevron-right" aria-hidden="true"></i></span
      ><a>Hallo CTA </a>
    </li>
  </ul>
</ngx-ov-status-message>
