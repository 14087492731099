import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { trackItemByIndex } from '../../../helper';
import { ClickEqualsEnterDirective } from '../../../directives/click-equals-enter';
import { Version } from '../footer/footer.models';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-internal-footer',
  templateUrl: './internal-footer.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ClickEqualsEnterDirective]
})
export class NgxOvInternalFooterComponent {
  // -------------------------------------------------------------------------
  // Variables
  // -------------------------------------------------------------------------
  trackByFn = trackItemByIndex;
  @Input() classes: string;
  @Input() versions: Version[];
}
