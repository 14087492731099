<div class="container">
  <div class="my-10">
    <h2>Logging</h2>
    <ngx-ov-button
      [label]="'Log custom event'"
      (buttonClick)="onClickLog()"
      [classes]="'mb-small'"
    ></ngx-ov-button>
    <br />
    <ngx-ov-button
      [label]="'Trigger error'"
      (buttonClick)="onClickTriggerError()"
    ></ngx-ov-button>
  </div>
</div>
<ngx-ov-web-fab-survey
  #survey
  buttontekst="Geef feedback"
  tooltiptekst="Heb je 2 min tijd om feedback te geven op onze website? Dat helpt ons enorm!"
></ngx-ov-web-fab-survey>
