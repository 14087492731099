import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { NgxOvAlertComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/meldingen/alert/alert.component';
import { NgxOvButtonComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/button/button.component';
import { StepperFormOverzichtComponent } from './components/overzicht/overzicht.component';
import { StepperFormKiesBetaalmethodeComponent } from './components/kies-betaalmethode/kies-betaalmethode.component';
import { StepperFormUploadBonComponent } from './components/upload-bon/upload-bon.component';
import { StepperFormKiesLevermethodeComponent } from './components/kies-levermethode/kies-levermethode.component';
import { StepperFormKiesExtraComponent } from './components/kies-extra/kies-extra.component';
import { StepperFormKiesGerechtComponent } from './components/kies-gerecht/kies-gerecht.component';
import { JsonPipe } from '@angular/common';

// =============================================================================
// Config
// =============================================================================
@Component({
  selector: 'ngx-ov-page-stepper-form',
  templateUrl: './page-stepper-form.component.html',
  standalone: true,
  imports: [
    MatStepperModule,
    StepperFormKiesGerechtComponent,
    StepperFormKiesExtraComponent,
    StepperFormKiesLevermethodeComponent,
    StepperFormUploadBonComponent,
    StepperFormKiesBetaalmethodeComponent,
    StepperFormOverzichtComponent,
    NgxOvButtonComponent,
    NgxOvAlertComponent,
    JsonPipe
  ]
})

// =============================================================================
// Definition
// =============================================================================
export class PageStepperFormComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;
  bestelling: UntypedFormGroup;
  bestellingGeplaatst = false;

  completed = false;

  completeStep(): void {
    this.completed = true;
  }

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.bestelling = this.fb.group({
      gerecht: this.fb.group({
        naam: [null, Validators.required]
      }),
      extra: this.fb.group({
        saus: [null, Validators.required]
      }),
      levering: this.fb.group({
        levering: [null, Validators.required]
      }),
      bon: this.fb.group({
        bon: [null]
      }),
      betaalmethode: this.fb.group({
        naam: [null, Validators.required]
      }),
      gebruikersvoorwaarden: [false, Validators.required],
      totaalBedrag: ['1234,56']
    });
  }

  geefFormGroup(naam: string): UntypedFormGroup {
    return this.bestelling.controls[naam] as UntypedFormGroup;
  }

  isButtonVolgendeDisabled(index: number) {
    switch (index) {
      case 0: {
        return !this.geefFormGroup('gerecht').valid;
      }
      case 1: {
        return !this.geefFormGroup('extra').valid;
      }
      case 2: {
        return !this.geefFormGroup('levering').valid;
      }
      case 3: {
        return !this.geefFormGroup('bon').valid;
      }
      case 4: {
        return !this.geefFormGroup('betaalmethode').valid;
      }
      default: {
        return !this.bestelling.controls['gebruikersvoorwaarden'].value;
      }
    }
  }

  onNavigeerStepper(change: number, indienen?: boolean) {
    if (!indienen) {
      this.stepper.selectedIndex = this.stepper.selectedIndex + change;
    } else {
      this.bestellingGeplaatst = true;
    }
  }

  onResetFormEnStepper() {
    this.bestellingGeplaatst = false;
    this.bestelling.reset();
  }

  kiesLeverMethode(value: any) {
    console.log('kiesLeverMethode', value);
    this.bestelling.controls['levering'].patchValue({
      levering: value
    });
    this.stepper.steps.find((st) => st.label === 'Levering').completed = true;
    console.log('bestelling', this.bestelling);
    this.stepper.selectedIndex = 3;
  }
}
