import {
  Action,
  createSelector,
  NgxsOnInit,
  State,
  StateContext
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ResetErrorMessages, ResetSuccessMessages } from './actions.actions';
import { deNormalizeArray, normalizeArray } from '../../../utils/normalize';
import { ActionsStateModel } from '../store.model';

@State<ActionsStateModel>({
  name: 'actions',
  defaults: {}
})
@Injectable()
export class NgxOvActionsState implements NgxsOnInit {
  static getMappedType(type: string, item: string | string[]): string[] {
    let mappeditems;
    if (!(item instanceof Array)) {
      mappeditems = [item];
    } else {
      mappeditems = item;
    }
    return [...mappeditems].map((x) => type + '-' + x.toUpperCase());
  }

  static getStatus(type: string | string[]) {
    const res = createSelector(
      [
        NgxOvActionsState.isLoading(type),
        NgxOvActionsState.isLoadSuccess(type),
        NgxOvActionsState.isLoadFailure(type),
        NgxOvActionsState.getLoadSuccessMessages(type),
        NgxOvActionsState.getLoadFailureMessages(type),
        NgxOvActionsState.isSaving(type),
        NgxOvActionsState.isSaveSuccess(type),
        NgxOvActionsState.isSaveFailure(type),
        NgxOvActionsState.getSaveSuccessMessages(type),
        NgxOvActionsState.getSaveFailureMessages(type),
        NgxOvActionsState.isDeleting(type),
        NgxOvActionsState.isDeleteSuccess(type),
        NgxOvActionsState.isDeleteFailure(type),
        NgxOvActionsState.getDeleteSuccessMessages(type),
        NgxOvActionsState.getDeleteFailureMessages(type)
      ],
      (
        isLoading: any,
        isLoadSuccess: any,
        isLoadFailure: any,
        loadSuccessMessages,
        loadFailureMessages,
        isSaving: any,
        isSaveSuccess: any,
        isSaveFailure: any,
        saveSuccessMessages,
        saveFailureMessages,
        isDeleting: any,
        isDeleteSuccess: any,
        isDeleteFailure: any,
        deleteSuccessMessages,
        deleteFailureMessages
      ) => {
        return {
          isLoading,
          isLoadSuccess,
          isLoadFailure,
          loadSuccessMessages,
          loadFailureMessages,
          isSaving,
          isSaveSuccess,
          isSaveFailure,
          saveSuccessMessages,
          saveFailureMessages,
          isDeleting,
          isDeleteSuccess,
          isDeleteFailure,
          deleteSuccessMessages,
          deleteFailureMessages
        };
      }
    );

    return res;
  }

  static isLoading(item: string | string[]) {
    const res = createSelector(
      [NgxOvActionsState],
      (state: ActionsStateModel) => {
        return !!deNormalizeArray(state)
          .filter((action) => action.loading)
          .filter((action) =>
            this.getMappedType('LOAD', item).includes(action.name.toUpperCase())
          ).length;
      }
    );
    return res;
  }

  static isLoadSuccess(item: string | string[]) {
    const res = createSelector(
      [NgxOvActionsState],
      (state: ActionsStateModel) => {
        return !!deNormalizeArray(state)
          .filter((action) => action.success)
          .filter((action) =>
            this.getMappedType('LOAD', item).includes(action.name.toUpperCase())
          ).length;
      }
    );
    return res;
  }

  static isLoadFailure(item: string | string[]) {
    const res = createSelector(
      [NgxOvActionsState],
      (state: ActionsStateModel) => {
        return !!deNormalizeArray(state)
          .filter((action) => action.failure)
          .filter((action) =>
            this.getMappedType('LOAD', item).includes(action.name.toUpperCase())
          ).length;
      }
    );
    return res;
  }

  static getLoadSuccessMessages(item: string | string[]) {
    const res = createSelector(
      [NgxOvActionsState],
      (state: ActionsStateModel) => {
        return deNormalizeArray(state)
          .filter((action) => action.success)
          .filter((action) =>
            this.getMappedType('LOAD', item).includes(action.name.toUpperCase())
          );
      }
    );
    return res;
  }

  static getLoadFailureMessages(item: string | string[]) {
    const res = createSelector(
      [NgxOvActionsState],
      (state: ActionsStateModel) => {
        return deNormalizeArray(state)
          .filter((action) => action.failure)
          .filter((action) =>
            this.getMappedType('LOAD', item).includes(action.name.toUpperCase())
          );
      }
    );
    return res;
  }

  static isSaving(item: string | string[]) {
    const res = createSelector(
      [NgxOvActionsState],
      (state: ActionsStateModel) => {
        return !!deNormalizeArray(state)
          .filter((action) => action.loading)
          .filter((action) =>
            this.getMappedType('SAVE', item).includes(action.name.toUpperCase())
          ).length;
      }
    );
    return res;
  }

  static isSaveSuccess(item: string | string[]) {
    const res = createSelector(
      [NgxOvActionsState],
      (state: ActionsStateModel) => {
        return !!deNormalizeArray(state)
          .filter((action) => action.success)
          .filter((action) =>
            this.getMappedType('SAVE', item).includes(action.name.toUpperCase())
          ).length;
      }
    );
    return res;
  }

  static isSaveFailure(item: string | string[]) {
    const res = createSelector(
      [NgxOvActionsState],
      (state: ActionsStateModel) => {
        return !!deNormalizeArray(state)
          .filter((action) => action.failure)
          .filter((action) =>
            this.getMappedType('SAVE', item).includes(action.name.toUpperCase())
          ).length;
      }
    );
    return res;
  }

  static getSaveSuccessMessages(item: string | string[]) {
    const res = createSelector(
      [NgxOvActionsState],
      (state: ActionsStateModel) => {
        return deNormalizeArray(state)
          .filter((action) => action.success)
          .filter((action) =>
            this.getMappedType('SAVE', item).includes(action.name.toUpperCase())
          );
      }
    );
    return res;
  }

  static getSaveFailureMessages(item: string | string[]) {
    const res = createSelector(
      [NgxOvActionsState],
      (state: ActionsStateModel) => {
        return deNormalizeArray(state)
          .filter((action) => action.failure)
          .filter((action) =>
            this.getMappedType('SAVE', item).includes(action.name.toUpperCase())
          );
      }
    );
    return res;
  }

  static isDeleting(item: string | string[]) {
    const res = createSelector(
      [NgxOvActionsState],
      (state: ActionsStateModel) => {
        return !!deNormalizeArray(state)
          .filter((action) => action.loading)
          .filter((action) =>
            this.getMappedType('DELETE', item).includes(
              action.name.toUpperCase()
            )
          ).length;
      }
    );
    return res;
  }

  static isDeleteSuccess(item: string | string[]) {
    const res = createSelector(
      [NgxOvActionsState],
      (state: ActionsStateModel) => {
        return !!deNormalizeArray(state)
          .filter((action) => action.success)
          .filter((action) =>
            this.getMappedType('DELETE', item).includes(
              action.name.toUpperCase()
            )
          ).length;
      }
    );
    return res;
  }

  static isDeleteFailure(item: string | string[]) {
    const res = createSelector(
      [NgxOvActionsState],
      (state: ActionsStateModel) => {
        return !!deNormalizeArray(state)
          .filter((action) => action.failure)
          .filter((action) =>
            this.getMappedType('DELETE', item).includes(
              action.name.toUpperCase()
            )
          ).length;
      }
    );
    return res;
  }

  static getDeleteSuccessMessages(item: string | string[]) {
    const res = createSelector(
      [NgxOvActionsState],
      (state: ActionsStateModel) => {
        return deNormalizeArray(state)
          .filter((action) => action.success)
          .filter((action) =>
            this.getMappedType('DELETE', item).includes(
              action.name.toUpperCase()
            )
          );
      }
    );
    return res;
  }

  static getDeleteFailureMessages(item: string | string[]) {
    const res = createSelector(
      [NgxOvActionsState],
      (state: ActionsStateModel) => {
        return deNormalizeArray(state)
          .filter((action) => action.failure)
          .filter((action) =>
            this.getMappedType('DELETE', item).includes(
              action.name.toUpperCase()
            )
          );
      }
    );
    return res;
  }

  ngxsOnInit() {}

  @Action(ResetErrorMessages)
  resetErrorMessages(ctx: StateContext<ActionsStateModel>) {
    const state = ctx.getState();
    let newStateArray = deNormalizeArray(state);
    newStateArray = newStateArray.map((action) => {
      if (action.failure) {
        return {
          ...action,
          loading: false,
          failure: false,
          success: false,
          errorMessage: null,
          action: null
        };
      } else {
        return { ...action };
      }
    });

    ctx.setState(normalizeArray(newStateArray, 'name'));
  }

  @Action(ResetSuccessMessages)
  resetSuccessMessages(ctx: StateContext<ActionsStateModel>) {
    const state = ctx.getState();

    let newStateArray = deNormalizeArray(state);
    newStateArray = newStateArray.map((action) => {
      if (action.success) {
        return {
          ...action,
          loading: false,
          failure: false,
          success: false,
          successMessage: null,
          action: null
        };
      } else {
        return { ...action };
      }
    });

    ctx.setState(normalizeArray(newStateArray, 'name'));
  }
}
