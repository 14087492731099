<button
  class="c-button"
  [ngClass]="classes"
  [class.c-button--is-not-clickable]="_shouldNotFireClickEvent"
  [class.c-button--primary]="_buttonType === buttonTypeEnum.Primary"
  [class.c-button--secondary]="_buttonType === buttonTypeEnum.Secondary"
  [class.c-button--tertiary]="_buttonType === buttonTypeEnum.Tertiary"
  [class.c-button--text]="_buttonType === buttonTypeEnum.Text"
  [class.c-button--wide]="isWide"
  [class.c-button--narrow]="isNarrow"
  [class.c-button--block]="isBlocked"
  [class.c-button--fab]="floatingActionButtonPosition"
  [class.c-button--fab--bottom-start]="
    floatingActionButtonPosition === 'bottom-start'
  "
  [class.c-button--fab--bottom-end]="
    floatingActionButtonPosition === 'bottom-end'
  "
  [class.c-button--fab--bottom-center]="
    floatingActionButtonPosition === 'bottom-center'
  "
  [class.is-error]="isError"
  [class.is-disabled]="isDisabled"
  [type]="'submit'"
  [attr.aria-disabled]="isDisabled"
  (click.stop)="onButtonClick($event)"
>
  @if (isLoading && iconBefore) {
    <!-- Loading indicator -->
    <span class="c-button__icon c-button__icon--before">
      <i
        class="{{ iconLoading }} fa-spin"
        aria-hidden="true"
        [title]="iconLoadingTitle"
      ></i>
    </span>
    <span class="sr-only">{{ iconLoadingTitle }}</span>
  }

  @if (iconBefore && !isLoading) {
    <!-- Icon before -->
    <span class="c-button__icon c-button__icon--before">
      <i class="{{ iconBefore }}" aria-hidden="true"></i>
    </span>
  }

  <!-- Text -->
  <label [class.sr-only]="iconOnly" class="c-button__text">{{ label }}</label>

  @if (iconAfter && !isLoading) {
    <!-- Icon after -->
    <span class="c-button__icon c-button__icon--after">
      <i class="{{ iconAfter }}" aria-hidden="true"></i>
    </span>
  }

  @if (isLoading && !iconOnly && !iconBefore) {
    <!-- Loading indicator: default -->
    <span class="c-button__icon c-button__icon--after">
      <i
        class="{{ iconLoading }} fa-spin"
        aria-hidden="true"
        [title]="iconLoadingTitle"
      ></i>
      <span class="sr-only">{{ iconLoadingTitle }}</span>
    </span>
  }

  @if (iconOnly && !isLoading) {
    <!-- Icon only -->
    <span class="c-button__icon c-button__icon--only">
      <i class="{{ iconOnly }}" aria-hidden="true"></i>
    </span>
  }

  @if (iconOnly && isLoading) {
    <!-- Loading indicator -->
    <span class="c-button__icon c-button__icon--only">
      <i
        class="{{ iconLoading }} fa-spin"
        aria-hidden="true"
        [title]="iconLoadingTitle"
      ></i>
      <span class="sr-only">{{ iconLoadingTitle }}</span>
    </span>
  }
</button>
