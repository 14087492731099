import { LandOutputRestDto } from '../../../codegen/naricbo-open-api';
import { StoreItem } from '@store/items';
import { loadFailure, loadRequest, loadSuccess } from '@ov/ngx-ov-utils';

export class LoadLandenRequest {
  @loadRequest()
  static readonly type = StoreItem.Landen;
}

export class LoadLandenSuccess {
  @loadSuccess()
  static readonly type = StoreItem.Landen;

  constructor(public landen: LandOutputRestDto[]) {}
}

export class LoadLandenFailure {
  @loadFailure()
  static readonly type = StoreItem.Landen;

  constructor(public errorMessage?: string | any) {}
}
