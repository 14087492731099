import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { PortalSearchFilterDirective } from './portals/portal-search-filter.directive';
import { PortalSearchResultFilterDirective } from './portals/portal-search-result-filter.directive';
import { PortalSearchButtonContainerDirective } from './portals/portal-search-button-container.directive';
import { ConfigurableFocusTrapFactory } from '@angular/cdk/a11y';
import { NgxOvButtonComponent } from '../../components/default-layout-blokken/button/button.component';
import { PortalPanelActionsDirective } from '../../components/data-visualisatie/panel/portals/portal-panel-actions.directive';
import { NgxOvPanelComponent } from '../../components/data-visualisatie/panel/panel.component';
import { PortalModule } from '@angular/cdk/portal';
import { NgxOvControlPanelComponent } from '../../components/data-visualisatie/control-panel/control-panel.component';
import { PortalSearchInfoDirective } from './portals/portal-search-info.directive';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-search',
  templateUrl: './search.component.html',
  standalone: true,
  imports: [
    NgxOvControlPanelComponent,
    PortalModule,
    PortalSearchButtonContainerDirective,
    NgxOvPanelComponent,
    PortalPanelActionsDirective,
    NgxOvButtonComponent,
    PortalSearchInfoDirective
  ]
})
export class NgxOvSearchComponent {
  constructor(private focusTrap: ConfigurableFocusTrapFactory) {}

  // -------------------------------------------------------------------------
  // public variables
  // -------------------------------------------------------------------------

  @ContentChild(PortalSearchFilterDirective, { static: false })
  filterPortal: PortalSearchFilterDirective;
  @ContentChild(PortalSearchResultFilterDirective, { static: false })
  filterResultPortal: PortalSearchResultFilterDirective;
  @ContentChild(PortalSearchButtonContainerDirective, { static: false })
  buttonContainer: PortalSearchButtonContainerDirective;
  @ContentChild(PortalSearchInfoDirective, { static: false })
  infoPortal: PortalSearchInfoDirective;

  private _labelCta = 'Filteren';
  private _labelClear = 'Filters wissen';
  private _labelFilter = 'Filter';
  private _showFooterButtons = true;
  private _titlePanelSearch = 'Filters';
  private _titlePanelResultaten = 'Zoekresultaten';
  private _subTitlePanelResultaten: string;
  private _displayFilterMobile = false;
  private _titlePanelFilterResultaten = 'Bijkomende filters';

  @Input() set labelCta(label: string) {
    this._labelCta = label;
  }

  get labelCta() {
    return this._labelCta;
  }

  @Input() set labelClear(label: string) {
    this._labelClear = label;
  }

  get labelClear() {
    return this._labelClear;
  }

  @Input() set labelFilter(label: string) {
    this._labelFilter = label;
  }

  get labelFilter() {
    return this._labelFilter;
  }

  @Input() set titlePanelResultaten(titlePanelResultaten: string) {
    this._titlePanelResultaten = titlePanelResultaten;
  }

  get titlePanelResultaten() {
    return this._titlePanelResultaten;
  }

  @Input() set titlePanelSearch(titlePanelSearch: string) {
    this._titlePanelSearch = titlePanelSearch;
  }

  get titlePanelSearch() {
    return this._titlePanelSearch;
  }

  @Input() set subTitlePanelResultaten(subTitlePanelResultaten: string) {
    this._subTitlePanelResultaten = subTitlePanelResultaten;
  }

  get subTitlePanelResultaten() {
    return this._subTitlePanelResultaten;
  }

  @Input() set titlePanelFilterResultaten(titlePanelFilterResultaten: string) {
    this._titlePanelFilterResultaten = titlePanelFilterResultaten;
  }

  get titlePanelFilterResultaten() {
    return this._titlePanelFilterResultaten;
  }

  @Input() set displayFilterMobile(displayFilterMobile: boolean) {
    this._displayFilterMobile = displayFilterMobile;
  }

  get displayFilterMobile() {
    return this._displayFilterMobile;
  }

  @Input() set showFooterButtons(showFooterButtons: boolean) {
    this._showFooterButtons = showFooterButtons;
  }

  get showFooterButtons() {
    return this._showFooterButtons;
  }

  // -------------------------------------------------------------------------
  // Output variables
  // -------------------------------------------------------------------------
  @Output() applyFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() clearFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() displayFilterMobileChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  // -------------------------------------------------------------------------
  // Events
  // -------------------------------------------------------------------------
  onShowFilterMobile() {
    this.toggleDisplayFilterMobile(true);
  }

  onClickClearFilter() {
    this.toggleDisplayFilterMobile(true);
    this.clearFilter.emit();
  }

  onClickFilter() {
    this.toggleDisplayFilterMobile(false);
    this.applyFilter.emit();
  }

  onClickCancelFilter() {
    this.toggleDisplayFilterMobile(false);
  }

  private toggleDisplayFilterMobile(display: boolean) {
    this.displayFilterMobile = display;
    this.displayFilterMobileChange.emit(this.displayFilterMobile);
  }
}
