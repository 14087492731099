@if (isVisible) {
  <div
    class="c-flyout-item"
    [ngClass]="{ 'c-flyout-item--large-version': isLargeVersion }"
    (click)="onClick()"
  >
    @if (isLargeVersion && largeVersionContent) {
      <div class="c-flyout-item__content--large-version">
        <ng-template [cdkPortalOutlet]="largeVersionContent"></ng-template>
      </div>
    }
    <ng-content></ng-content>
  </div>
}
