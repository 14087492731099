export const componentTranslationsNl = {
  DATA_VISUALISATIE: {
    CONTROL_PANEL: {
      HEADER: {
        CLOSE: 'Sluiten'
      },
      ITEM: {
        HEADER: {
          CLOSE: 'Sluiten',
          OPEN: 'Open'
        },
        TOOLTIP_ICON: {
          TITLE: 'Click info tooltip'
        }
      }
    }
  },
  CONTENT_BLOKKEN: {
    CARD: {
      ICON: {
        CLOSE: 'Sluit kaart',
        OPEN: 'Open kaart'
      }
    },
    INFO_BLOCK: {
      CLOSED: 'Open informatie blok',
      OPEN: 'Sluit informatie blok'
    },
    PROGRESS_BLOCK: {
      ICON: {
        IN_PROGRESS: 'Bezig',
        COMPLETED: 'Voltooid'
      }
    }
  },
  DEFAULT_LAYOUT_BLOKKEN: {
    BUTTON: {
      LOADING: 'Laden'
    },
    CONTACT_CARD: {
      CONTACT_LABEL: 'Contact',
      TELEPHONE_LABEL: 'Telefoon',
      EMAIL_LABEL: 'E-mail',
      WEBSITE_LABEL: 'Website'
    },
    COOKIEBAR: {
      COOKIES: {
        CONTENT: {
          TITLE: 'gebruikt cookies om jouw surfervaring te verbeteren',
          TEXT: `Sommige cookies zijn noodzakelijk om de website optimaal te laten
                 functioneren. Andere cookies zijn optioneel en worden gebruikt om jouw
                 surfervaring op deze website te verbeteren. Als je op ‘Alle cookies
                 aanvaarden’ klikt, aanvaard je ook de optionele cookies. Wil je liever
                  zelf kiezen welke cookies je aanvaardt? Klik op ‘Cookievoorkeuren
                  beheren’. Meer info lees je in het <a target="_blank" href="https://www.onderwijs.vlaanderen.be/sites/default/files/2021-07/Versie%202.0%20Privacyverklaring%20AHOVOKS.pdf">privacy</a>- en <a target="_blank" href="https://onderwijs.vlaanderen.be/sites/default/files/2021-07/Cookiebeleid%20AHOVOKS.pdf">cookiebeleid</a> van
                  Vlaanderen.be`,
          SUBTITLE: 'Cookievoorkeuren beheren',
          ACCEPT_ALL_BUTTON: 'Alle cookies aanvaarden',
          ACCEPT_MANDATORY_BUTTON: 'Alleen noodzakelijke cookies aanvaarden',
          SAVE_BUTTON: 'Cookievoorkeuren opslaan',
          ICON: {
            CLOSED: 'Cookievoorkeuren sluiten',
            OPEN: 'Cookievoorkeuren open'
          }
        },
        TYPES: {
          MANDATORY: {
            TITLE: 'Noodzakelijke cookies',
            SUBTITLE:
              'Deze cookies zijn vereist om de website goed te laten werken en\n' +
              'zorgen ervoor dat jouw cookievoorkeuren worden bewaard. Zo kun je\n' +
              'geselecteerde publicaties in een winkelmandje bewaren en het bestel-\n' +
              'en betalingsproces succesvol afhandelen. Je kunt ook veilig en\n' +
              'succesvol aanmelden en jouw gegevens bekijken in jouw persoonlijk\n' +
              'Burgerprofiel.'
          },
          ANALYTICAL: {
            TITLE: 'Analytische cookies',
            SUBTITLE:
              'Deze cookies meten het aantal bezoekers op de website en geven een algemeen beeld van jouw bezoek. Alle verzamelde gegevens zijn anoniem.'
          }
        }
      }
    },
    LANGUAGE_SWITCH: {
      HEADER_TEXT: 'Kies een taal'
    }
  },
  DIVERSE: {
    PILL: {
      CLOSE: 'Sluiten'
    },
    PROFILE: {
      IMAGE: 'Profiel foto'
    }
  },
  FORMS: {
    CHECKBOX: {
      READONLY: {
        CHECKED_TEXT: 'Ja',
        NOT_CHECKED_TEXT: 'Nee'
      }
    },
    DATEPICKER_INPUTS: {
      DAY: 'Dag',
      MONTH: 'Maand',
      YEAR: 'Jaar',
      INVALID_DATE: 'Ongeldige datum',
      DATE_TOO_EARLY: 'De ingegeven datum moet na {minDate} komen',
      DATE_TOO_LATE: 'De ingegeven datum moet voor {maxDate} komen',
      INVALID_DATE_DAY: 'Ongeldige dag',
      INVALID_DATE_MONTH: 'Ongeldige maand'
    },
    PAPYRUS_UPLOAD: {
      ERROR_MESSAGE: {
        DOWNLOAD: 'Fout tijdens het downloaden van het bestand',
        UPLOAD: 'Fout tijdens het opladen van het bestand'
      },
      COLUMN_TITLES: {
        FILENAME: 'Bestandsnaam'
      }
    },
    OV_STEPPER: {
      STEP: {
        ICON: {
          CLOSE: 'Sluit stap',
          OPEN: 'Open stap'
        },
        NUMBER: {
          COMPLETED: 'Afgeronde stap'
        }
      }
    },
    SELECT: {
      CLOSE_TEXT: 'Sluiten',
      NOT_FOUND_TEXT: 'Geen items gevonden',
      TYPE_TO_SEARCH_TEXT: 'Begin te typen om te zoeken'
    },
    TOGGLE: {
      READONLY: {
        CHECKED_TEXT: 'Ja',
        NOT_CHECKED_TEXT: 'Nee'
      }
    },
    THREE_WAY_TOGGLE: {
      OFF: 'Uit',
      ON: 'Aan',
      NEUTRAL: 'Neutraal'
    },
    UPLOAD: {
      EMPTY_TABLE_TEXT: 'Er zijn nog geen opgeladen documenten',
      DOWNLOAD_ACTION_NAME: 'document downloaden',
      DELETE_ACTION_NAME: 'document verwijderen',
      EDIT_ACTION_NAME: 'document bewerken',
      UPLOAD_ACTION_NAME: 'Bestanden toevoegen',
      UPLOADED_DOCUMENTS: 'Opgeladen bestanden',
      DRAG_DROP_ACTION_NAME: 'Drag & drop bestanden of',
      DRAG_DROP_SELECT_ACTION_NAME: 'Selecteer bestanden',
      LOADING_TEXT: 'bezig met laden',
      TOO_MANY_FILES: 'Maximum aantal bestanden opgeladen',
      NOT_ALLOW_MULTIPLE: 'Er kan maar één bestand per keer opgeladen worden',
      FILENAME_TOO_LONG:
        'De bestandsnaam mag maximum {maxLengthFileName} karakters lang zijn',
      WRONG_FILETYPES: 'Het bestand moet van type {fileTypes} zijn',
      FILE_TOO_BIG: 'Het bestand mag niet groter dan {maxFileSizeInMB}MB zijn',
      EMPTY_FILE: 'Het bestand is leeg',
      ROW: {
        TOGGLE: {
          CLOSE: 'Sluit upload rij',
          OPEN: 'Open upload rij'
        }
      }
    },
    DOCUMENT_VIEWER: {
      DOWNLOAD_ACTION_NAME: 'Download',
      ROTATE_LEFT_ACTION_NAME: 'Draai links',
      ROTATE_RIGHT_ACTION_NAME: 'Draai rechts'
    }
  },
  FUNDAMENTALS: {
    404: {
      CTA_LABEL: 'Homepagina'
    },
    STORE: {
      LOADING: 'Bezig met laden...',
      ERROR_API_GET: 'Fout bij het laden van de gegevens.',
      ERROR_API_PUT: 'Fout bij het bewaren van de gegevens.',
      ERROR_API_DELETE: 'Fout bij het verwijderen van de gegevens.',
      SUCCESS_API_PUT: 'Gegevens successvol bewaard.',
      SUCCESS_API_DELETE: 'Gegevens successvol verwijderd.'
    }
  },
  MELDINGEN: {
    MODAL: {
      FOOTER: {
        CANCEL_TEXT: 'Annuleer',
        CONFIRM_TEXT: 'Bevestig'
      },
      HEADER: {
        CLOSE: 'Sluit popup'
      }
    }
  },
  NAVIGATION: {
    ARROW_NAVIGATION: {
      NEXT: 'Naar volgende',
      PREVIOUS: 'Naar vorige'
    },
    SIDE_NAVIGATION: {
      ITEM: {
        TOGGLE_ICON: {
          CLOSE: 'Sluit onderliggende navigatie',
          OPEN: 'Open onderliggende navigatie'
        }
      },
      TOGGLE_ICON: {
        CLOSE: 'Klap navigatie in',
        OPEN: 'Klap navigatie open'
      }
    }
  },
  PREVIEW_DEVICE: {
    DESKTOP: 'Toestel: desktop',
    MOBILE: 'Toestel: mobiel',
    TABLET: 'Toestel: tablet'
  },
  ERRORS: {
    FORM: {
      CONTROL: {
        EMAIL: 'Ongeldig e-mailadres',
        REQUIRED: 'Verplicht veld',
        PATTERN: 'Ongeldige waarde',
        MINLENGTH: 'Onvoldoende karakters',
        MAXLENGTH: 'Te veel karakters',
        LATINSCRIPTVALIDATIONFAILED: 'Enkel Latijns schrift toegelaten'
      }
    }
  }
};
