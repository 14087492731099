import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  QueryList
} from '@angular/core';
import { Subject } from 'rxjs';
import { NgxOvFlyoutItemComponent } from './flyout-item.component';
import { NgxOvFlyoutService } from './flyout.service';
import { PortalFlyoutBottomNavigationDirective } from './flyout-bottom-navigation.directive';
import { PortalFlyoutTriggerDirective } from './flyout-trigger.directive';
import { PortalModule } from '@angular/cdk/portal';
import { ClickEqualsEnterDirective } from '../../../directives/click-equals-enter';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-flyout',
  templateUrl: './flyout.component.html',
  providers: [NgxOvFlyoutService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ClickEqualsEnterDirective, PortalModule]
})
export class NgxOvFlyoutComponent implements OnDestroy, AfterContentInit {
  @Input() header: string;
  @Input() headerDesktopHidden = false;
  @Input() secondary = false;
  @Input() trigger: string;
  @Input() triggerLabel: string;
  @Input() triggerLabelMobile: string;
  @Input() position = 'right';
  @Input() minWidth;
  @Input() openMobileFullscreen = false;
  @Input() isLargeVersion = false;
  @Input() maxItems = 4;
  @Input() type: 'light' | 'dark' = 'light';

  @Output() buttonClicked = new EventEmitter();

  @ContentChildren(NgxOvFlyoutItemComponent)
  flyoutComponents: QueryList<NgxOvFlyoutItemComponent>;
  @ContentChild(PortalFlyoutBottomNavigationDirective)
  bottomNavigation: PortalFlyoutBottomNavigationDirective;
  @ContentChild(PortalFlyoutTriggerDirective)
  portalTrigger: PortalFlyoutTriggerDirective;

  public destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private eRef: ElementRef,
    public flyoutService: NgxOvFlyoutService
  ) {}

  ngAfterContentInit() {
    if (this.isLargeVersion && this.flyoutComponents) {
      for (let i = 0; i < this.flyoutComponents.toArray().length; i++) {
        this.flyoutComponents.toArray()[i].isLargeVersion = true;

        // Show 4 items max
        if (this.maxItems && i > this.maxItems - 1) {
          this.flyoutComponents.toArray()[i].isVisible = false;
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  @HostListener('document:click', ['$event'])
  @HostListener('document:touchstart', ['$event'])
  onClickBuitenComponent(event: any) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.flyoutService.close();
    }
  }
}
