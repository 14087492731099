/**
 * Naric
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.3.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import {
  AbstractControlOptions,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn
} from '@angular/forms';

export interface IBeroepInstanceOutputRestDto {
  overkoepelende_instantie?: string;
  url?: string;
}


export class BeroepInstanceOutputRestDto implements IBeroepInstanceOutputRestDto {
  overkoepelende_instantie?: string;
  url?: string;
}

export class BeroepInstanceOutputRestDtoFormHelper {

  static generateFormWithSwaggerValidators(formBuilder: UntypedFormBuilder, controlsConfig: { [key: string]: any; }, options?: AbstractControlOptions | { [key: string]: any; } | null): UntypedFormGroup {
    if (controlsConfig["overkoepelende_instantie"] != undefined && !(controlsConfig["overkoepelende_instantie"] instanceof UntypedFormGroup) && !(controlsConfig["overkoepelende_instantie"] instanceof UntypedFormArray)) {
      controlsConfig["overkoepelende_instantie"][1] = controlsConfig["overkoepelende_instantie"][1].concat(BeroepInstanceOutputRestDtoValidator.overkoepelende_instantie_swagger_validator());
    }
    if (controlsConfig["url"] != undefined && !(controlsConfig["url"] instanceof UntypedFormGroup) && !(controlsConfig["url"] instanceof UntypedFormArray)) {
      controlsConfig["url"][1] = controlsConfig["url"][1].concat(BeroepInstanceOutputRestDtoValidator.url_swagger_validator());
    }
    if (options) {
      return formBuilder.group(controlsConfig, options);
    }
    return formBuilder.group(controlsConfig);
  }
}

export class BeroepInstanceOutputRestDtoValidator {

  /**
   * Active Validators for overkoepelende_instantie:
   */
  static overkoepelende_instantie_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    return validators;
  };

  /**
   * Active Validators for url:
   */
  static url_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    return validators;
  };

}



