import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  QueryList
} from '@angular/core';
import { NgxOvBreadcrumbItemComponent } from './breadcrumb-item.component';
import { LinkService } from '../../../services/link.service';
import { trackItemByIndex } from '../../../helper';

@Component({
  selector: 'ngx-ov-breadcrumb',
  templateUrl: './breadcrumb.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgxOvBreadcrumbItemComponent]
})
export class NgxOvBreadcrumbComponent {
  // -------------------------------------------------------------------------
  // Input variables
  // -------------------------------------------------------------------------
  @ContentChildren(NgxOvBreadcrumbItemComponent)
  items: QueryList<NgxOvBreadcrumbItemComponent>;

  trackByFn = trackItemByIndex;

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  constructor(private linkService: LinkService) {}

  onClick(linkUrl: string) {
    this.linkService.navigate(linkUrl);
  }
}
