import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NgxOvModalService {
  public modals: any[] = [];

  add(modal: any) {
    // add modal to array of active modals
    this.modals.push(modal);
    this.addSubscriptions(modal);
  }

  remove(id: string) {
    const modal = this.modals.find((m: any) => {
      return m.id.toUpperCase() === id.toUpperCase();
    });

    this.removeSubscriptions(modal);

    // remove modal from array of active modals
    this.modals = this.modals.filter(
      (x) => x.id.toUpperCase() !== id.toUpperCase()
    );
  }

  open(id: string, data?: any) {
    // open modal specified by id
    const modal: any = this.modals.filter(
      (x) => x.id.toUpperCase() === id.toUpperCase()
    )[0];

    if (modal) {
      modal.open(data);
    }
  }

  close(id: string) {
    // close modal specified by id
    const modal: any = this.modals.filter(
      (x) => x.id.toUpperCase() === id.toUpperCase()
    )[0];

    if (modal) {
      modal.close();
    }
  }

  private addSubscriptions(modal: any) {
    try {
      modal.cancel.subscribe(() => {
        this.close(modal.id);
      });
    } catch (e) {}

    if (modal.autoCloseOnConfirm) {
      try {
        modal.confirm.subscribe(() => {
          this.close(modal.id);
        });
      } catch (e) {}
    }

    try {
      modal.closePopup.subscribe(() => {
        this.close(modal.id);
      });
    } catch (e) {}
  }

  private removeSubscriptions(modal: any) {
    try {
      if (modal && modal.cancel) {
        modal.cancel.unsubscribe();
      }
      if (modal && modal.confirm) {
        modal.confirm.unsubscribe();
      }
      if (modal && modal.closePopup) {
        modal.closePopup.unsubscribe();
      }
    } catch (e) {}
  }
}
