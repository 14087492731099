import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Error500Component } from './error-500/error-500.component';
import { Error402Component } from './error-402/error-402.component';
import { ErrorComponent } from './error/error.component';

@Component({
    selector: 'app-page-error',
    templateUrl: './page-error.component.html',
    standalone: true
})
export class PageErrorComponent implements OnInit {
  @ViewChild('ErrorPageHostTemplate', {
    static: true,
    read: ViewContainerRef
  })
  ErrorPageHost: ViewContainerRef;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    this.laadError(this.route.snapshot.queryParamMap.get('type'));
  }

  laadError(code: string): void {
    let componentFactory = null;
    switch (code) {
      case '500': {
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(
          Error500Component
        );
        break;
      }
      case '402': {
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(
          Error402Component
        );
        break;
      }
      default: {
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(
          ErrorComponent
        );
        break;
      }
    }

    this.ErrorPageHost.clear();
    this.ErrorPageHost.createComponent(componentFactory);
  }
}
