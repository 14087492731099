import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input
} from '@angular/core';
import { PortalExpansionPanelListEmptyStateDirective } from './expansion-panel-list-empty-state.directive';
import { PortalModule } from '@angular/cdk/portal';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-expansion-panel-list',
  templateUrl: './expansion-panel-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PortalModule]
})
export class NgxOvExpansionPanelListComponent {
  @ContentChild(PortalExpansionPanelListEmptyStateDirective, { static: false })
  emptyStatePortal: PortalExpansionPanelListEmptyStateDirective;

  @Input() isEmpty = false;
}
