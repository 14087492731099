<nav
  class="c-menubar__nav"
  [class.is-vo-theme]="isOvTheme"
  [attr.data-cy]="'menubar'"
>
  <div class="c-menubar__div">
    <ng-template [cdkPortalOutlet]="extraContentPortal"></ng-template>
    <ul
      class="c-menubar__navigation"
      role="tablist"
      [class.is-vo-theme]="isOvTheme"
    >
      @for (item of items; track trackByFn) {
        <li
          class="c-menubar__navigation-item"
          (click)="onSelectitem(item)"
          [routerLink]="item.linkUrl ? item.linkUrl : null"
          [routerLinkActive]="
            item.linkUrl ? 'c-menubar__navigation-item--active' : ''
          "
          [routerLinkActiveOptions]="{ exact: item.linkUrl === '/' }"
          #rla="routerLinkActive"
          [attr.data-cy]="item.linkUrl"
        >
          <div
            class="c-menubar__navigation-link"
            [class.is-vo-theme]="isOvTheme"
          >
            @if (image) {
              <li>
                <span
                  class="c-menubar__navigation-image"
                  [class]="item.icon"
                ></span>
              </li>
            }
            <li>
              {{ item.linkTitle }}
            </li>
          </div>
        </li>
      }
    </ul>
  </div>
</nav>
