export enum NgxOvDataTableSettingsTypes {
  hiddenColumns = 'HIDDENCOLUMNS',
  hiddenFilters = 'HIDDENFILTERS'
}

export enum NgxOvDataTableSortingOrder {
  Ascending = 'ASC',
  Descending = 'DESC'
}

export type PagerPosition = 'top' | 'bottom' | 'top-bottom';

export enum NgxOvDataTableColumnType {
  default = <any>'default',
  yesno = <any>'yesno',
  label = <any>'label',
  numeric = <any>'numeric',
  // user = <any>'user',
  // datetime = <any>'datetime',
  date = <any>'date'
}

export interface INgxOvDataTableColumn {
  name: string;
  tooltip?: string;
  label: string;
  // yesno?: boolean; -> deprecated
  // numeric?: boolean; -> deprecated
  // date?: boolean; -> deprecated
  sortable?: boolean;
  sortCaseSensitive?: boolean;
  sortFormat?: (value: any) => any;
  filterable?: boolean;
  filterableDisabled?: boolean;
  clickable?: boolean;
  format?: (value: any) => any;
  nested?: boolean;
  minWidthInPixels?: number;
  allowLabelWrap?: boolean;
  type?: NgxOvDataTableColumnType;
  filter?: string;
  visible?: boolean;
}

export interface INgxOvDataTableSelectEvent {
  row: any;
  selected: boolean;
}

export interface INgxOvDataTableSelectAllEvent {
  rows: any[];
  selected: boolean;
}

export interface INgxOvDataTableSelectionChangeEvent {
  rows: any[];
}

export interface INgxOvDataTableSortChangeEvent {
  order: NgxOvDataTableSortingOrder;
  caseSensitive: boolean;
  name: string;
}

export const mapVisible = (
  column: INgxOvDataTableColumn,
  savedColumns: INgxOvDataTableColumn[]
) => {
  if (!savedColumns?.length) {
    return column.visible !== false;
  }
  let filteredColumns = savedColumns?.filter(
    (savedColumn) => savedColumn.name === column.name
  );
  return filteredColumns && Array.isArray(filteredColumns)
    ? filteredColumns[0].visible !== false
    : true;
};

export const mapFilterable = (
  column: INgxOvDataTableColumn,
  savedColumns: INgxOvDataTableColumn[]
) => {
  if (column.filterableDisabled) {
    return false;
  }
  if (!savedColumns?.length) {
    return column.filterable !== false;
  }
  let filteredColumns = savedColumns?.filter(
    (savedColumn) => savedColumn.name === column.name
  );
  return filteredColumns && Array.isArray(filteredColumns)
    ? filteredColumns[0].filterable !== false
    : true;
};
