import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxOvFlyoutItemComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/navigatie/flyout/flyout-item.component';
import { NgxOvFlyoutComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/navigatie/flyout/flyout.component';
import { PortalAgendaActionsDirective } from '../../../../projects/ngx-ov-ui/src/lib/components/content-blokken/agenda/portals/portal-panel-actions.directive';
import { NgxOvTooltipComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/meldingen/tooltip/tooltip.component';
import { NgxOvAgendaItemComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/content-blokken/agenda/agenda-item.component';
import { NgxOvAgendaComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/content-blokken/agenda/agenda.component';
import { AsyncPipe } from '@angular/common';

// =============================================================================
// Config
// =============================================================================
@Component({
  selector: 'app-page-agenda',
  templateUrl: './page-agenda.component.html',
  standalone: true,
  imports: [
    NgxOvAgendaComponent,
    NgxOvAgendaItemComponent,
    NgxOvTooltipComponent,
    PortalAgendaActionsDirective,
    NgxOvFlyoutComponent,
    NgxOvFlyoutItemComponent,
    NgxSkeletonLoaderModule,
    AsyncPipe
  ]
})

// =============================================================================
// Definition
// =============================================================================
export class PageAgendaComponent implements OnInit {
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  ngOnInit(): void {
    this.isLoading$.next(true);
    const timerLoading = timer(3000);
    timerLoading.subscribe(() => {
      this.isLoading$.next(false);
    });
  }
}
