import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ClickEqualsEnterDirective } from '../../../../../projects/ngx-ov-ui/src/lib/directives/click-equals-enter';
import { NgxOvStatusMessageComponent } from '../../../../../projects/ngx-ov-ui/src/lib/fundamentals/status-message/status-message.component';
import { trackItemByIndex } from '../../../../../projects/ngx-ov-ui/src/lib/helper';

@Component({
  selector: 'error-500',
  templateUrl: './error-500.component.html',
  standalone: true,
  imports: [NgxOvStatusMessageComponent, ClickEqualsEnterDirective]
})
export class Error500Component {
  public hyperlinks = ['components', 'forms', 'search', 'upload'];

  trackByFn = trackItemByIndex;

  constructor(private router: Router) {}

  goto(link: string) {
    this.router.navigate([link]);
  }
}
