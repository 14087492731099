/**
 * Naric
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.3.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import {
  AbstractControlOptions,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn
} from '@angular/forms';

export interface ITelefoonLandprefixOutputRestDto {
  id?: string;
  prefix?: string;
}


export class TelefoonLandprefixOutputRestDto implements ITelefoonLandprefixOutputRestDto {
  id?: string;
  prefix?: string;
}

export class TelefoonLandprefixOutputRestDtoFormHelper {

  static generateFormWithSwaggerValidators(formBuilder: UntypedFormBuilder, controlsConfig: { [key: string]: any; }, options?: AbstractControlOptions | { [key: string]: any; } | null): UntypedFormGroup {
    if (controlsConfig["id"] != undefined && !(controlsConfig["id"] instanceof UntypedFormGroup) && !(controlsConfig["id"] instanceof UntypedFormArray)) {
      controlsConfig["id"][1] = controlsConfig["id"][1].concat(TelefoonLandprefixOutputRestDtoValidator.id_swagger_validator());
    }
    if (controlsConfig["prefix"] != undefined && !(controlsConfig["prefix"] instanceof UntypedFormGroup) && !(controlsConfig["prefix"] instanceof UntypedFormArray)) {
      controlsConfig["prefix"][1] = controlsConfig["prefix"][1].concat(TelefoonLandprefixOutputRestDtoValidator.prefix_swagger_validator());
    }
    if (options) {
      return formBuilder.group(controlsConfig, options);
    }
    return formBuilder.group(controlsConfig);
  }
}

export class TelefoonLandprefixOutputRestDtoValidator {

  /**
   * Active Validators for id:
   */
  static id_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    return validators;
  };

  /**
   * Active Validators for prefix:
   */
  static prefix_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    return validators;
  };

}



