/**
 * Naric
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.3.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpResponse} from '@angular/common/http';

import {Observable} from 'rxjs';

import {BeroepInstanceOutputRestDto} from '../model/beroepInstanceOutputRestDto';
import {BeroepOutputRestDto} from '../model/beroepOutputRestDto';

import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';


@Injectable()
export class BeroepService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  protected basePath = 'https://localhost:8080';

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * Deze webservice wordt opgeroepen door de aanvraagbegeleiding op het moment dat er beroepen op basis van een beroepscode nodig zijn.
   *
   * @param beroepscode beroepscode
   * @param x_ov_language x-ov-language
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */



  public geefBeroepInstantieAndUrlByBeroepsCodeUsingGET(beroepscode: string, observe?: 'body', reportProgress?: boolean):
    Observable<BeroepInstanceOutputRestDto>;

  public geefBeroepInstantieAndUrlByBeroepsCodeUsingGET(beroepscode: string, observe?: 'response', reportProgress?: boolean):
    Observable<HttpResponse<BeroepInstanceOutputRestDto>>;

  public geefBeroepInstantieAndUrlByBeroepsCodeUsingGET(beroepscode: string, observe?: 'events', reportProgress?: boolean):
    Observable<HttpEvent<BeroepInstanceOutputRestDto>>;

  public geefBeroepInstantieAndUrlByBeroepsCodeUsingGET(beroepscode: string, observe: any = 'body', reportProgress: boolean = false):
    Observable<any> {
    if (beroepscode === null || beroepscode === undefined) {
      throw new Error('Required parameter beroepscode was null or undefined when calling geefBeroepInstantieAndUrlByBeroepsCodeUsingGET.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json;charset=UTF-8'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [];

    return this.httpClient.get<BeroepInstanceOutputRestDto>(`${this.basePath}/open-api/v1/beroep/${encodeURIComponent(String(beroepscode))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Deze webservice wordt opgeroepen door salesforce om alles beroepen te krijgen.
   *
   * @param x_ov_language x-ov-language
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */



  public geefBeroepenUsingGET(observe?: 'body', reportProgress?: boolean):
    Observable<Array<BeroepOutputRestDto>>;

  public geefBeroepenUsingGET(observe?: 'response', reportProgress?: boolean):
    Observable<HttpResponse<Array<BeroepOutputRestDto>>>;

  public geefBeroepenUsingGET(observe?: 'events', reportProgress?: boolean):
    Observable<HttpEvent<Array<BeroepOutputRestDto>>>;

  public geefBeroepenUsingGET(observe: any = 'body', reportProgress: boolean = false):
    Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json;charset=UTF-8'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [];

    return this.httpClient.get<Array<BeroepOutputRestDto>>(`${this.basePath}/open-api/v1/beroep`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (let consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

}
