// =============================================================================
// Imports
// =============================================================================
import { Component, Optional } from '@angular/core';
import { NgxOvModalService } from '../../components/meldingen/modal/modal.service';
import { NgxOvBevestigingService } from './bevestiging.service';
import { NgxOvModalComponent } from '../../components/meldingen/modal/modal.component';
import { NgxOvBevestigingMelding } from './bevestiging-melding.component';
import { MODAL_X_OV_BEVESTIGING } from './bevestiging.constants';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-bevestiging',
  templateUrl: './bevestiging.component.html',
  standalone: true,
  imports: [NgxOvModalComponent, NgxOvBevestigingMelding]
})
export class NgxOvBevestigingComponent {
  public modalId = MODAL_X_OV_BEVESTIGING;
  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  constructor(
    private modalService: NgxOvModalService,
    private bevestigingService: NgxOvBevestigingService,
    @Optional() public modal?: NgxOvModalComponent
  ) {}

  bevestig() {
    this.modalService.close(this.modalId);
    this.bevestigingService.resendRequest();
  }

  annuleer() {
    this.bevestigingService.cancelRequest();
  }
}
