<nav class="c-breadcrumb">
  <div class="c-breadcrumb__list">
    @for (item of items; track trackByFn) {
      <ngx-ov-breadcrumb-item
        [label]="item.label"
        [href]="item.href"
      ></ngx-ov-breadcrumb-item>
    }
  </div>
</nav>
