// =============================================================================
// Imports
// =============================================================================
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgxOvButtonComponent } from '../../components/default-layout-blokken/button/button.component';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-status-message',
  templateUrl: './status-message.component.html',
  standalone: true,
  imports: [NgxOvButtonComponent]
})
export class NgxOvStatusMessageComponent {
  @Input() title;
  @Input() img;
  @Input() ctaLabel = '';

  @Output() ctaClicked: EventEmitter<any> = new EventEmitter<any>();
}
