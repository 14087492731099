import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxOvBevestiging, OntbrekendeBevestiging } from './bevestiging.model';
import { TranslateService } from '@ngx-translate/core';
import { HttpMethod } from '@ngneat/spectator';

@Injectable({
  providedIn: 'root'
})
export class NgxOvBevestigingService {
  private bevestigingen: NgxOvBevestiging[] = [];

  constructor(
    private http: HttpClient,
    @Optional() private translate: TranslateService
  ) {}

  addRequest(req: any, resp: any) {
    this.bevestigingen.push({ request: req, response: resp });
  }

  resendRequest() {
    const bevestiging = this.bevestigingen.pop();
    const requestCodes = bevestiging?.request?.headers?.get(
      'x-ov-confirmed-codes'
    );

    const responseCodes = this.geefCodes(
      bevestiging.response.body.missing_confirmed_codes
    );
    const acceptCodeRequest =
      (requestCodes ? requestCodes + ',' : '') + responseCodes;
    const requestOptions = {
      headers: {
        'x-ov-confirmed-codes': acceptCodeRequest
      }
    };

    switch (bevestiging.request.method) {
      case HttpMethod.PUT: {
        this.http
          .put(
            bevestiging.request.url,
            bevestiging.request.body,
            requestOptions
          )
          .subscribe(() => {});
        break;
      }
      case HttpMethod.POST: {
        this.http
          .post(
            bevestiging.request.url,
            bevestiging.request.body,
            requestOptions
          )
          .subscribe(() => {});
        break;
      }
    }
  }

  cancelRequest() {
    this.bevestigingen.pop();
  }

  geefTeksten(bevestigingen: OntbrekendeBevestiging[]): string {
    let text = '';
    let arrayBevestigingen;
    if (!Array.isArray(bevestigingen)) {
      arrayBevestigingen = [bevestigingen];
    } else {
      arrayBevestigingen = [...bevestigingen];
    }
    arrayBevestigingen.forEach((bevestiging, index) => {
      text += (index ? '<br>' : '') + this.geefTekst(bevestiging);
    });
    return text;
  }

  geefTekst(bevestiging: OntbrekendeBevestiging): string {
    if (this.translate) {
      return this.translate.instant(
        'NGX_OV_UI.CONFIRMATIONS.' + bevestiging?.code,
        bevestiging?.key_value_pairs
      );
    }
    return bevestiging.code;
  }

  geefCodes(bevestigingen: OntbrekendeBevestiging[]): string {
    let code = '';
    let arrayBevestigingen;
    if (!Array.isArray(bevestigingen)) {
      arrayBevestigingen = [bevestigingen];
    } else {
      arrayBevestigingen = [...bevestigingen];
    }
    arrayBevestigingen.forEach((bevestiging, index) => {
      code += (index ? ',' : '') + bevestiging.code;
    });
    return code;
  }
}
