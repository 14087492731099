<div class="container">
  <div class="my-10">
    <h2>Wissel van omgeving</h2>
    @if (_shadow) {
      <p data-cy="text-shadow">
        Applicatie verbonden met <strong>Atos</strong> omgeving via
        <strong>shadow</strong> configuratie.
      </p>
    } @else {
      <p data-cy="text-non-shadow">
        Applicatie verbonden met <strong>VPC</strong> omgeving via
        <strong>regulier</strong> configuratie.
      </p>
    }
    <br />
    <ngx-ov-button
      data-cy="button-wissel-omgeving"
      label="Wissel omgeving"
      (buttonClick)="wisselOmgeving()"
    ></ngx-ov-button>
  </div>
</div>
