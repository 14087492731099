import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Optional,
  Output
} from '@angular/core';
import { NgxOvComponentBaseClass } from '../../ngx-ov-component-base-class';
import { TranslationsService } from '../../../services/translations.service';
import { TranslateService } from '@ngx-translate/core';
import { PortalSearchButtonContainerDirective } from '../../../fundamentals/search/portals/portal-search-button-container.directive';
import { PortalModule } from '@angular/cdk/portal';
import { NgxOvButtonComponent } from '../../default-layout-blokken/button/button.component';
import { ClickEqualsEnterDirective } from '../../../directives/click-equals-enter';

@Component({
  selector: 'ngx-ov-control-panel-mobile',
  templateUrl: './control-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ClickEqualsEnterDirective, NgxOvButtonComponent, PortalModule]
})
export class NgxOvControlPanelComponent extends NgxOvComponentBaseClass {
  // -------------------------------------------------------------------------
  // private variables
  // -------------------------------------------------------------------------
  private _labelCta: string;
  private _labelClear: string;
  private _titlePanelSearch = 'Filter';
  private _showFooterButtons = true;

  @ContentChild(PortalSearchButtonContainerDirective, { static: false })
  buttonContainer: PortalSearchButtonContainerDirective;

  // -------------------------------------------------------------------------
  // public variables
  // -------------------------------------------------------------------------
  public closePanelIconTitle: string;
  // -------------------------------------------------------------------------
  // Input variables
  // -------------------------------------------------------------------------
  @Input() set labelCta(label: string) {
    this._labelCta = label;
  }

  get labelCta() {
    return this._labelCta;
  }

  @Input() set labelClear(label: string) {
    this._labelClear = label;
  }

  get labelClear() {
    return this._labelClear;
  }

  @Input() set titlePanelSearch(titlePanelSearch: string) {
    this._titlePanelSearch = titlePanelSearch;
  }

  get titlePanelSearch() {
    return this._titlePanelSearch;
  }

  @Input() set showFooterButtons(showFooterButtons: boolean) {
    this._showFooterButtons = showFooterButtons;
  }

  get showFooterButtons() {
    return this._showFooterButtons;
  }
  // -------------------------------------------------------------------------
  // Output variables
  // -------------------------------------------------------------------------
  @Output() filterClick: EventEmitter<any> = new EventEmitter();
  @Output() filterCancel: EventEmitter<any> = new EventEmitter();
  @Output() filterClear: EventEmitter<any> = new EventEmitter();

  constructor(
    public translationsService: TranslationsService,
    @Optional() public translateService: TranslateService
  ) {
    super(translationsService, translateService);
  }
  setLabels() {
    this.closePanelIconTitle = this.getTranslation(
      'DATA_VISUALISATIE.CONTROL_PANEL',
      'HEADER.CLOSE'
    );
  }
}
