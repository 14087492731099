import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { catchError, tap } from 'rxjs/operators';
import {
  LoadLandenFailure,
  LoadLandenRequest,
  LoadLandenSuccess
} from './landen.actions';
import { StoreKeys } from '../model';
import {
  LandOutputRestDto,
  LandService
} from '../../../codegen/naricbo-open-api';

export const landenStateDefault = {
  landen: []
};

export interface LandenStateModel {
  landen: LandOutputRestDto[];
}

@State<LandenStateModel>({
  name: StoreKeys.LANDEN,
  defaults: {
    ...landenStateDefault
  }
})
@Injectable()
export class LandenState implements NgxsOnInit {
  constructor(private landService: LandService) {}

  @Selector()
  static geefLanden(state: LandenStateModel) {
    return state.landen;
  }

  ngxsOnInit() {
    // nothing to do here
  }

  @Action(LoadLandenRequest)
  loadLandenRequest(ctx: StateContext<LandenStateModel>) {
    return this.landService.geefActieveLandenUsingGET().pipe(
      tap((landen: LandOutputRestDto[]) => {
        return ctx.dispatch(new LoadLandenSuccess(landen));
      }),
      catchError((err) => {
        return ctx.dispatch(new LoadLandenFailure(err));
      })
    );
  }

  @Action(LoadLandenSuccess)
  loadLandenSuccess(
    ctx: StateContext<LandenStateModel>,
    action: LoadLandenSuccess
  ) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      landen: action.landen
    });
  }
}
