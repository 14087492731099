import { NormalizedData } from '../../utils/normalize';

export interface TechnischeUitzondering {
  identificatie?: string;
  diagnose: string;
  type?: string;
  annotaties?: any[];
}

export interface ApiError {
  uitzonderingen: TechnischeUitzondering[];
}

export interface StoreAction {
  loading?: boolean;
  failure?: boolean;
  success?: boolean;
  errorMessage?: string;
  successMessage?: string;
  name?: string;
  action?: any;
}

export interface ActionsStateModel {
  actions?: NormalizedData<StoreAction>;
}

export interface StoreMessage {
  successMessage?: string;
  errorMessage?: ApiError | string;
  action?: any;
}

export interface StoreItemStatus {
  isLoading: boolean;
  isLoadSuccess: boolean;
  isLoadFailure: boolean;
  loadSuccessMessages: StoreMessage[];
  loadFailureMessages: StoreMessage[];
  isSaving: boolean;
  isSaveSuccess: boolean;
  isSaveFailure: boolean;
  saveSuccessMessages: StoreMessage[];
  saveFailureMessages: StoreMessage[];
  isDeleting: boolean;
  isDeleteSuccess: boolean;
  isDeleteFailure: boolean;
  deleteSuccessMessages: StoreMessage[];
  deleteFailureMessages: StoreMessage[];
}
