import { Injectable, ElementRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NgxOvMainLayoutService {
  contentWrapper: ElementRef;

  init(el: ElementRef) {
    this.contentWrapper = el;
  }

  scrollTop() {
    this.contentWrapper.nativeElement.scrollTop = 0;
  }
}
