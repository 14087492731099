import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import * as uuid from 'uuid';
import {
  NgxOvModalService,
  UploadColumn,
  UploadRecord
} from '../../../../projects/ngx-ov-ui/src/public-api';
import { NgxOvButtonComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/button/button.component';
import { NgxOvInputFieldComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/forms/input-field/input-field.component';
import { NgxOvModalComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/meldingen/modal/modal.component';
import { NgxOvCardComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/content-blokken/card/card.component';
import { NgxOvUploadComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/forms/upload/upload.component';

const remove = (items, index) => {
  return [...items.slice(0, index), ...items.slice(index + 1, items.length)];
};

interface BetrokkeneUploadRecord extends UploadRecord {
  betrokkene?: string;
}

@Component({
  selector: 'app-page-forms',
  templateUrl: './page-upload.component.html',
  providers: [],
  standalone: true,
  imports: [
    NgxOvUploadComponent,
    NgxOvCardComponent,
    NgxOvModalComponent,
    FormsModule,
    ReactiveFormsModule,
    NgxOvInputFieldComponent,
    NgxOvButtonComponent
  ]
})
export class PageUploadComponent implements OnInit {
  nameChangeForm: UntypedFormGroup;
  documentToEdit: BetrokkeneUploadRecord;
  public rows: BetrokkeneUploadRecord[] = [
    {
      id: '1',
      bestandsnaam: 'dummy-file-1.jpg',
      betrokkene: 'Testuser achternaam',
      documentType: 'plain document',
      actions: {
        canDelete: true,
        canDownload: true,
        canEdit: true
      },
      datum: new Date().toLocaleDateString()
    },
    {
      id: '2',
      bestandsnaam:
        'dummyfilemeteenlangenaamzonderspatiesofliggendestreepjesoflagestreepjeswantstreepjeszijnnietleuk.pdf',
      documentType: 'plain document',
      isNotExpandable: true,
      actions: {
        canDelete: true,
        canDownload: false,
        canEdit: true
      }
    }
  ];
  public columns: UploadColumn[] = [
    {
      id: 'bestandsnaam',
      header: 'Bestandsnaam',
      actions: {
        canDownload: true
      }
    },
    {
      id: 'id',
      header: 'Id'
    },
    {
      id: 'documentType',
      header: 'Document Type'
    },
    {
      id: 'datum',
      header: 'Datum'
    }
  ];

  openForm = true;
  type = null;
  file = null;

  constructor(
    private modalService: NgxOvModalService,
    private fb: UntypedFormBuilder
  ) {}
  ngOnInit(): void {
    this.nameChangeForm = this.fb.group({
      documentnaam: ''
    });
  }

  deleteFile(e: any) {
    this.rows = remove(this.rows, e.index);
  }

  downloadFile(e: any) {
    if (e.row.bestand) {
      // eslint-disable-next-line no-console
      console.log('Downloading file met bestand: ', e.row.bestand);
    } else {
      // eslint-disable-next-line no-console
      console.log('Downloading file met id: ', e.row.id);
    }
  }

  onUploadChange(file: File | File[]) {
    let files = [];
    const uploadRecords = [];
    if (!file) {
      return;
    }
    if (!Array.isArray(file)) {
      files.push(file);
    } else {
      files = [...file];
    }
    for (let i = 0, l = files.length; i < l; i++) {
      const myId = uuid.v4();
      const record = {
        id: myId,
        bestandsnaam: files[i].name,
        bestand: file[i],
        betrokkene: 'Aagje Reynders',
        documentType: files[i].type,
        actions: {
          canDelete: true,
          canDownload: true
        }
      };
      uploadRecords.push(record);
    }

    this.rows = [...this.rows, ...uploadRecords];
  }

  onUploadFileError(e: any) {
    // eslint-disable-next-line no-console
    console.log('onUploadFileError', e);
  }

  onClickEdit(e: any) {
    this.documentToEdit = e.row;
    this.nameChangeForm.patchValue({ documentnaam: e.row.bestandsnaam });
    this.modalService.open('edit-modal');
  }

  onConfirmEdit() {
    this.documentToEdit.bestandsnaam =
      this.nameChangeForm.get('documentnaam').value;

    let newRows = this.rows.filter((doc) => doc.id !== this.documentToEdit.id);
    this.rows = [this.documentToEdit, ...newRows];
    this.onCancelEdit();
  }

  onCancelEdit() {
    this.modalService.close('edit-modal');
    this.nameChangeForm.reset();
  }

  onShowExtraInfo() {
    this.modalService.open('extra-info-modal');
  }

  onActivateAdditionalAction() {
    this.modalService.open('additional-action-modal');
  }
}
