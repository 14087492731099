@if (label) {
  <ngx-ov-input-label
    [for]="identifier"
    [text]="label"
    [required]="isRequired"
    [isReadonly]="isReadonly"
  ></ngx-ov-input-label>
}

<div class="c-toggle" [class.c-toggle--large]="isLarge">
  @if (!isReadonly) {
    <input
      type="checkbox"
      class="c-toggle__checkbox"
      id="{{ identifier }}"
      tabindex="0"
      [(ngModel)]="value"
      (change)="valueChange.emit(value)"
      [disabled]="isDisabled"
    />
    <label class="c-toggle__label" [for]="identifier">
      <span class="c-toggle__inner"></span>
      <span
        tabindex="0"
        class="c-toggle__switch"
        [attr.data-cy]="'toggle-to-click'"
      ></span>
    </label>
  } @else {
    <div
      class="u-form-readonly__input-readonly"
      [innerHTML]="value ? toggledReadOnlyTextCopy : notToggledReadOnlyTextCopy"
    ></div>
  }
</div>
