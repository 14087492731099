import { ChangeDetectionStrategy, Component } from '@angular/core';

// =============================================================================
// Component
// =============================================================================
@Component({
    selector: 'ngx-ov-action-list',
    templateUrl: './action-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class NgxOvActionListComponent {}
