export const snackbarTitle = (title?: string) => {
  return function snackbarTitleF(target: any) {
    target.prototype.snackbarTitle = title ? title : undefined;
  };
};

export const snackbarMessage = (message?: string) => {
  return function snackbarMessageF(target: any) {
    target.prototype.snackbarMessage = message ? message : undefined;
  };
};

export const showSnackbar = (show?: boolean) => {
  return function showSnackbarF(target: any) {
    target.prototype.showSnackbar = show ? show : true;
  };
};
