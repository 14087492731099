import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';
import { NgxOvMainLayoutService } from './main.layout.service';
import { PortalFooterDirective } from './portals/portal-footer.directive';
import { PortalHeaderDirective } from './portals/portal-header.directive';
import { PortalLeftNavigationDirective } from './portals/portal-left-navigation.directive';
import { PortalCookiebarDirective } from './portals/portal-cookiebar.directive';
import { PortalModule } from '@angular/cdk/portal';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-main-layout',
  templateUrl: './main-layout.component.html',
  standalone: true,
  imports: [PortalModule]
})
export class MainLayoutComponent implements AfterViewInit {
  @ContentChild(PortalHeaderDirective, { static: false })
  header: PortalHeaderDirective;
  @ContentChild(PortalFooterDirective, { static: false })
  footer: PortalFooterDirective;
  @ContentChild(PortalLeftNavigationDirective, { static: false })
  leftNavigation: PortalLeftNavigationDirective;
  @ContentChild(PortalCookiebarDirective, { static: false })
  cookiebar: PortalCookiebarDirective;

  @ViewChild('containerMainLayout') private containerMainLayout: ElementRef;

  @Input() showMenubar: boolean;

  constructor(private layoutService: NgxOvMainLayoutService) {}

  onScrollTop() {
    this.layoutService.scrollTop();
  }

  ngAfterViewInit() {
    this.layoutService.init(this.containerMainLayout);
  }
}
