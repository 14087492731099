<ngx-ov-login-page
  [bannerImgSrc]="'./assets/images/banner_2.jpeg'"
  [bannerImgSrcMobile]="
    'https://media.istockphoto.com/vectors/vintage-pop-art-yellow-background-banner-vector-illustration-vector-id1061878012?k=6&m=1061878012&s=170667a&w=0&h=xvUHQ5GkIgnB_EUF5Bngzv0vaRmf_stArO4H6MKMQXQ='
  "
  [title]="'PAGES.LANDINGSPAGINA.LOGIN.TITLE' | translate"
  [subtitle]="'PAGES.LANDINGSPAGINA.LOGIN.SUBTITLE' | translate"
  [introTitle]="'PAGES.LANDINGSPAGINA.LOGIN.INTRO.TITLE' | translate"
  [introSubtitle]="'PAGES.LANDINGSPAGINA.LOGIN.INTRO.SUBTITLE' | translate"
  [introCTA]="'PAGES.LANDINGSPAGINA.LOGIN.INTRO.CTA' | translate"
  [introCTALink]="'components/overview'"
  [introImgSrc]="'./assets/images/undraw_notify.png'"
  [contactBranch]="'Agentschap Vlees en Vis'"
  [contactDepartment]="'Afdeling Rundsvlees'"
  [contactTelephone]="'02 111 11 11'"
  [contactEmail]="'info@afdeling.be'"
  [contactUrl]="'www.google.be'"
  (introCTAClicked)="onCtaClicked()"
>
  <ngx-ov-highlight-list>
    @for (item of highlightItems; let i = $index; track trackByFn) {
      <ngx-ov-highlight-item
        [title]="item.title"
        [link]="item.routerLink"
        [icon]="item.icon"
        [info]="item.info"
        [showSeparator]="i < highlightItems.length - 1"
      ></ngx-ov-highlight-item>
    }
  </ngx-ov-highlight-list>

  <div class="my-10">
    <div class="container">
      <h4>Browser Name: {{ browserNameAndVersion.name }}</h4>
      <h4>Browser Version: {{ browserNameAndVersion.version }}</h4>
      <p>Supported: {{ !this.isBrowserNotSupported }}</p>
    </div>

    <div class="container">
      <div class="grid grid-cols-12 gap-large">
        <div
          class="w-full h-32 col-span-6 bg-primary-light rounded-lg shadow-lg"
        >
          1
        </div>
        <div class="w-full h-32 col-span-2 bg-primary-light">2</div>
        <div class="w-full h-32 col-span-2 bg-primary-light">3</div>
        <div class="w-full h-32 col-span-1 bg-primary-light">4</div>
        <div class="w-full h-32 col-span-1 bg-primary-light">5</div>
      </div>
    </div>
  </div>
</ngx-ov-login-page>
