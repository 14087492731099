import { Component, Input } from '@angular/core';
import { NgxOvModalService } from '../../../../../../projects/ngx-ov-ui/src/lib/components/meldingen/modal/modal.service';
import { ProfileService } from '../../services/profile.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxOvButtonComponent } from '../../../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/button/button.component';
import { NgxOvAlertComponent } from '../../../../../../projects/ngx-ov-ui/src/lib/components/meldingen/alert/alert.component';
import { ProfileModalImageComponent } from '../profile-modal-image/profile-modal-image.component';
import { NgxOvModalComponent } from '../../../../../../projects/ngx-ov-ui/src/lib/components/meldingen/modal/modal.component';
import { NgxOvRadioComponent } from '../../../../../../projects/ngx-ov-ui/src/lib/components/forms/radio/radio-item/radio.component';
import { NgxOvRadioGroupComponent } from '../../../../../../projects/ngx-ov-ui/src/lib/components/forms/radio/radio-group/radio-group.component';
import { NgxOvSelectComponent } from '../../../../../../projects/ngx-ov-ui/src/lib/components/forms/select/select.component';
import { NgxOvDatepickerComponent } from '../../../../../../projects/ngx-ov-ui/src/lib/components/forms/datepicker/datepicker.component';
import { NgxOvProfileComponent } from '../../../../../../projects/ngx-ov-ui/src/lib/components/diverse/profile/profile.component';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'profile-identity-form',
  templateUrl: './profile-identity-form.component.html',
  standalone: true,
  imports: [
    FormsModule,
    NgxOvProfileComponent,
    NgxOvDatepickerComponent,
    NgxOvSelectComponent,
    NgxOvRadioGroupComponent,
    NgxOvRadioComponent,
    NgxOvModalComponent,
    ProfileModalImageComponent,
    NgxOvAlertComponent,
    NgxOvButtonComponent,
    NgxSkeletonLoaderModule
  ]
})
export class ProfileIdentityFormComponent {
  @Input() isLoading: boolean;

  public profile = {
    firstname: 'Test',
    name: 'Kandidaat',
    geboorteDatum: null,
    geboorteland: null,
    nationaliteit: null,
    geslacht: null,
    image: null
  };

  public nationaliteiten = [
    {
      code: 'BEL',
      omschrijving: 'België'
    },
    {
      code: 'NL',
      omschrijving: 'Nederland'
    },
    {
      code: 'FR',
      omschrijving: 'Frans'
    },
    {
      code: 'DE',
      omschrijving: 'Duits'
    }
  ];

  public landen = [
    {
      code: 'BEL',
      omschrijving: 'België'
    },
    {
      code: 'NL',
      omschrijving: 'Nederland'
    },
    {
      code: 'FR',
      omschrijving: 'Frankrijk'
    },
    {
      code: 'DE',
      omschrijving: 'Duitsland'
    }
  ];

  public showError = false;

  constructor(
    private modalService: NgxOvModalService,
    private profileService: ProfileService
  ) {}

  opslaan(form: any): void {
    console.log('er is op mij geklikt');
    this.showError = form.form.value.geslacht === 'FOUT';
  }

  openModal(): void {
    this.modalService.open('fotoModal');
  }
  wijzigFoto(): void {
    this.modalService.close('fotoModal');
    if (this.profileService.imageUrl) {
      this.profile.image = this.profileService.imageUrl;
    }
  }
}
