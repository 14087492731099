export const componentTranslationsEN = {
  DATA_VISUALISATIE: {
    CONTROL_PANEL: {
      HEADER: {
        CLOSE: 'Close'
      },
      ITEM: {
        HEADER: {
          CLOSE: 'Close',
          OPEN: 'Open'
        },
        TOOLTIP_ICON: {
          TITLE: 'Click info tooltip'
        }
      }
    }
  },
  CONTENT_BLOKKEN: {
    CARD: {
      ICON: {
        CLOSE: 'Close card',
        OPEN: 'Open card'
      }
    },
    INFO_BLOCK: {
      CLOSED: 'Open information block',
      OPEN: 'Close information block'
    },
    PROGRESS_BLOCK: {
      ICON: {
        IN_PROGRESS: 'In progress',
        COMPLETED: 'Completed'
      }
    }
  },
  DEFAULT_LAYOUT_BLOKKEN: {
    BUTTON: {
      LOADING: 'Loading'
    },
    CONTACT_CARD: {
      CONTACT_LABEL: 'Contact',
      TELEPHONE_LABEL: 'Phone',
      EMAIL_LABEL: 'Email',
      WEBSITE_LABEL: 'Website'
    },
    COOKIEBAR: {
      COOKIES: {
        CONTENT: {
          TITLE: 'uses cookies to improve your surfing experience',
          TEXT: 'Some cookies are necessary to allow the website to function properly. Other cookies are optional and are used to improve your surfing experience on this website. If you click ‘Accept all cookies’ you also accept the optional cookies. Would you rather choose which cookies you accept? Click on ‘Manage cookie preferences’. More info can be found in the <a target="_blank" href=https://www.onderwijs.vlaanderen.be/sites/default/files/2021-07/Versie%202.0%20Privacyverklaring%20AHOVOKS.pdf>privacy</a>- and <a target="_blank" href=https://onderwijs.vlaanderen.be/sites/default/files/2021-07/Cookiebeleid%20AHOVOKS.pdf>cookie policy</a> of Vlaanderen.be',
          SUBTITLE: 'Manage cookie preferences',
          ACCEPT_ALL_BUTTON: 'Accept all cookies',
          ACCEPT_MANDATORY_BUTTON: 'Only accept essential cookies',
          SAVE_BUTTON: 'Save cookie preferences',
          ICON: {
            CLOSED: 'Close cookie preferences',
            OPEN: 'Open cookie preferences'
          }
        },
        TYPES: {
          MANDATORY: {
            TITLE: 'Essential cookies',
            SUBTITLE: `These cookies are required in order to allow the website to work properly and to ensure that your cookie preferences are saved. They enable you, for example, to keep selected publications in a shopping basket and successfully complete the order and payment process. You can also login safely and successfully and view your data in your personal Citizen Profile.`
          },
          ANALYTICAL: {
            TITLE: 'Analytic cookies',
            SUBTITLE:
              'These cookies measure the number of visitors to the website and give a general impression of your visit. All data collected are anonymous.'
          }
        }
      }
    },
    LANGUAGE_SWITCH: {
      HEADER_TEXT: 'Choose a language'
    }
  },
  DIVERSE: {
    PILL: {
      CLOSE: 'Close'
    },
    PROFILE: {
      IMAGE: 'Profile picture'
    }
  },
  FORMS: {
    CHECKBOX: {
      READONLY: {
        CHECKED_TEXT: 'Yes',
        NOT_CHECKED_TEXT: 'No'
      }
    },
    DATEPICKER_INPUTS: {
      DAY: 'Day',
      MONTH: 'Month',
      YEAR: 'Year',
      INVALID_DATE: 'Invalid date',
      DATE_TOO_EARLY: 'The given date must be after {minDate}',
      DATE_TOO_LATE: 'The given date must be before {maxDate}',
      INVALID_DATE_DAY: 'Invalid day',
      INVALID_DATE_MONTH: 'Invalid month'
    },
    PAPYRUS_UPLOAD: {
      ERROR_MESSAGE: {
        DOWNLOAD: 'Error while downloading the file',
        UPLOAD: 'Error while uploading the file'
      },
      COLUMN_TITLES: {
        FILENAME: 'Filename'
      }
    },
    OV_STEPPER: {
      STEP: {
        ICON: {
          CLOSE: 'Close step',
          OPEN: 'Open step'
        },
        NUMBER: {
          COMPLETED: 'Completed step'
        }
      }
    },
    SELECT: {
      CLOSE_TEXT: 'Close',
      NOT_FOUND_TEXT: 'No items found',
      TYPE_TO_SEARCH_TEXT: 'Type to search'
    },
    TOGGLE: {
      READONLY: {
        CHECKED_TEXT: 'Yes',
        NOT_CHECKED_TEXT: 'No'
      }
    },
    THREE_WAY_TOGGLE: {
      OFF: 'Off',
      ON: 'On',
      NEUTRAL: 'Neutral'
    },
    UPLOAD: {
      EMPTY_TABLE_TEXT: 'No documents have been uploaded yet',
      DOWNLOAD_ACTION_NAME: 'download document',
      DELETE_ACTION_NAME: 'delete document',
      EDIT_ACTION_NAME: 'edit document',
      UPLOAD_ACTION_NAME: 'Add files',
      UPLOADED_DOCUMENTS: 'Uploaded documents',
      DRAG_DROP_ACTION_NAME: 'Drag & drop files or',
      DRAG_DROP_SELECT_ACTION_NAME: 'Select files',
      LOADING_TEXT: 'loading',
      TOO_MANY_FILES: 'Maximum number of files uploaded',
      NOT_ALLOW_MULTIPLE: 'Only one file can be uploaded at a time',
      FILENAME_TOO_LONG:
        'The file name must be a maximum of {maxLengthFileName} characters long.',
      WRONG_FILETYPES: 'The file must have type of {fileTypes}',
      FILE_TOO_BIG: 'The file must not be larger than {maxFileSizeInMB}MB',
      EMPTY_FILE: 'The file is empty',
      ROW: {
        TOGGLE: {
          CLOSE: 'Close upload row',
          OPEN: 'Open upload row'
        }
      }
    },
    DOCUMENT_VIEWER: {
      DOWNLOAD_ACTION_NAME: 'Download',
      ROTATE_LEFT_ACTION_NAME: 'Rotate left',
      ROTATE_RIGHT_ACTION_NAME: 'Rotate right'
    }
  },
  FUNDAMENTALS: {
    404: {
      CTA_LABEL: 'Homepage'
    },
    STORE: {
      LOADING: 'Loading...',
      ERROR_API_GET: 'Error loading data.',
      ERROR_API_PUT: 'Error saving data.',
      ERROR_API_DELETE: 'Error deleting data.'
    }
  },
  MELDINGEN: {
    MODAL: {
      FOOTER: {
        CANCEL_TEXT: 'Cancel',
        CONFIRM_TEXT: 'Confirm'
      },
      HEADER: {
        CLOSE: 'Close popup'
      }
    }
  },
  NAVIGATION: {
    ARROW_NAVIGATION: {
      NEXT: 'To next',
      PREVIOUS: 'To previous'
    },
    SIDE_NAVIGATION: {
      ITEM: {
        TOGGLE_ICON: {
          CLOSE: 'Close navigation item',
          OPEN: 'Open navigation item'
        }
      },
      TOGGLE_ICON: {
        CLOSE: 'Open navigation',
        OPEN: 'Close navigation'
      }
    }
  },
  PREVIEW_DEVICE: {
    DESKTOP: 'Device: desktop',
    MOBILE: 'Device: mobile',
    TABLET: 'Device: tablet'
  },
  ERRORS: {
    FORM: {
      CONTROL: {
        EMAIL: 'Invalid email address',
        REQUIRED: 'Required field',
        PATTERN: 'Invalid value',
        MINLENGTH: 'Minimum characters not reached',
        MAXLENGTH: 'Too many characters',
        LATINSCRIPTVALIDATIONFAILED: 'Only Latin alphabet allowed'
      }
    }
  }
};
