import { ApiError, StoreItemStatus, StoreMessage } from './store.model';
import { NgxOvActionPhase, NgxOvActionType } from './actions';
import { getActionTypeFromInstance } from '@ngxs/store';

export const geefEersteErrorDiagnose = (storeMessages: StoreMessage[]) => {
  try {
    return storeMessages[0]?.action?.uitzonderingen[0]?.diagnose
      ? storeMessages[0]?.action.uitzonderingen[0].diagnose
      : null;
  } catch (e) {
    return null;
  }
};

export const geefTechnischeFoutmeldingen = (error: ApiError): string => {
  let foutmelding = '';
  error?.uitzonderingen?.forEach((uitzondering, idx) => {
    if (uitzondering?.diagnose) {
      if (idx > 0) {
        foutmelding = foutmelding + '<br>';
      }
      foutmelding = foutmelding + uitzondering.diagnose;
    }
  });
  return foutmelding;
};

export const bepaalFailureMessages = (
  status: StoreItemStatus,
  storeType: string
): StoreMessage[] => {
  let messages: StoreMessage[];
  if (storeType === 'SAVE') {
    messages = status?.saveFailureMessages;
  }
  if (storeType === 'LOADING') {
    messages = status?.loadFailureMessages;
  }
  if (storeType === 'DELETE') {
    messages = status?.deleteFailureMessages;
  }
  return Array.isArray(messages) ? messages : [];
};

export const bepaalTextFailureMessages = (
  storeMessages: StoreMessage[]
): string => {
  let text = '';
  storeMessages.forEach((storeMessage, idx) => {
    const errorMessage: ApiError | string = storeMessage?.errorMessage;
    if (idx > 0) {
      text = text + '<br>';
    }
    if (typeof errorMessage === 'object' && 'uitzonderingen' in errorMessage) {
      text = text + geefTechnischeFoutmeldingen(errorMessage);
    }
  });
  return text;
};

const geefActieOnderdeel = (action: any, onderdeel: number): string | null => {
  const actionTypeFromInstance = getActionTypeFromInstance(action);

  const actionsArray = actionTypeFromInstance
    .split(']-[')
    .map((res) => res.replace('[', ''))
    .map((res) => res.replace(']', ''));

  if (
    actionsArray &&
    Array.isArray(actionsArray) &&
    actionsArray.length === 3
  ) {
    return actionsArray[onderdeel];
  }

  return null;
};

export const geefActieNaam = (action: any): string | null => {
  return geefActieOnderdeel(action, 1);
};

export const geefActieType = (action: any): NgxOvActionType | null => {
  const actieType = geefActieOnderdeel(action, 0);
  if (
    actieType &&
    Object.values(NgxOvActionType).includes(actieType as NgxOvActionType)
  ) {
    return actieType as NgxOvActionType;
  }
  return null;
};

export const geefActieFase = (action: any): NgxOvActionPhase | null => {
  const actieFase = geefActieOnderdeel(action, 2);
  if (
    actieFase &&
    Object.values(NgxOvActionPhase).includes(actieFase as NgxOvActionPhase)
  ) {
    return actieFase as NgxOvActionPhase;
  }
  return null;
};
