import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import {
  LoadMockFailure,
  LoadMockRequest,
  LoadMockSuccess
} from './mock.actions';
import { StoreKeys } from '../model';
import { HttpEventType } from '@angular/common/http';

export const mockStateDefault = {
  mock: []
};

export interface MockStateModel {
  mock: any | any[];
}

@State<MockStateModel>({
  name: StoreKeys.MOCK,
  defaults: {
    ...mockStateDefault
  }
})
@Injectable()
export class MockState implements NgxsOnInit {
  @Selector()
  static geefMock(state: MockStateModel) {
    return state.mock;
  }

  ngxsOnInit() {
    // nothing to do here
  }

  @Action(LoadMockRequest)
  loadMockRequest(ctx: StateContext<MockStateModel>, action: LoadMockRequest) {
    const apiError: any = {
      error: {
        uitzonderingen: [
          {
            identificatie: '32102',
            diagnose: 'Het maximum aantal bestanden werd overschreden.',
            type: 'FOUT'
          },
          {
            identificatie: '30001',
            diagnose: 'Attribuut ontbreekt: adres.gemeente',
            type: 'FOUT',
            annotaties: [
              {
                naam: 'veld',
                waarde: 'adres.gemeente'
              },
              {
                naam: 'veldwaarde'
              },
              {
                naam: 'validatiecodes',
                waarde:
                  'not_null.adres.gemeente,not_null.gemeente,not_null.string,not_null'
              }
            ]
          },
          {
            identificatie: '30001',
            diagnose: 'Attribuut ontbreekt: adres.huisnummer',
            type: 'FOUT',
            annotaties: [
              {
                naam: 'veld',
                waarde: 'adres.huisnummer'
              },
              {
                naam: 'veldwaarde'
              },
              {
                naam: 'validatiecodes',
                waarde:
                  'not_blank.adres.huisnummer,not_blank.huisnummer,not_blank.string,not_blank'
              }
            ]
          },
          {
            identificatie: '30001',
            diagnose: 'Attribuut ontbreekt: adres.land_code',
            type: 'FOUT',
            annotaties: [
              {
                naam: 'veld',
                waarde: 'adres.land_code'
              },
              {
                naam: 'veldwaarde'
              },
              {
                naam: 'validatiecodes',
                waarde:
                  'not_null.adres.land_code,not_null.land_code,not_null.string,not_null'
              }
            ]
          },
          {
            identificatie: '30001',
            diagnose: 'Attribuut ontbreekt: adres.postcode',
            type: 'FOUT',
            annotaties: [
              {
                naam: 'veld',
                waarde: 'adres.postcode'
              },
              {
                naam: 'veldwaarde'
              },
              {
                naam: 'validatiecodes',
                waarde:
                  'not_null.adres.postcode,not_null.postcode,not_null.string,not_null'
              }
            ]
          },
          {
            identificatie: '30001',
            diagnose: 'Attribuut ontbreekt: adres.straat',
            type: 'FOUT',
            annotaties: [
              {
                naam: 'veld',
                waarde: 'adres.straat'
              },
              {
                naam: 'veldwaarde'
              },
              {
                naam: 'validatiecodes',
                waarde:
                  'not_blank.adres.straat,not_blank.straat,not_blank.string,not_blank'
              }
            ]
          }
        ]
      },
      name: 'HttpErrorResponse',
      ok: false,
      status: 400,
      message: '',
      headers: null,
      statusText: '',
      url: '',
      type: HttpEventType.Response
    };
    if (action.success) {
      return ctx.dispatch(
        new LoadMockSuccess(action.mockData || ['test mock data'])
      );
    } else {
      return ctx.dispatch(new LoadMockFailure(apiError));
    }
  }

  @Action(LoadMockSuccess)
  loadMockSuccess(ctx: StateContext<MockStateModel>, action: LoadMockSuccess) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      mock: action.res
    });
  }
}
