export interface LinkUrl {
  linkTitle?: string;
  linkUrl?: string;
  external?: boolean;
  removeCookies?: boolean;
  separator?: boolean;
  target?: string;
}

export type Status = 'INFO' | 'SUCCESS' | 'WARNING' | 'ERROR';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function trackItemByIndex(index, item) {
  return index;
}
