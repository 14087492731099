import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigurableFocusTrapFactory } from '@angular/cdk/a11y';
import { NgxOvModalService } from './modal.service';
import { PortalModalFooterDirective } from './modal-footer.directive';
import { TranslationsService } from '../../../services/translations.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxOvComponentBaseClass } from '../../ngx-ov-component-base-class';
import { PortalModule } from '@angular/cdk/portal';
import { NgxOvButtonComponent } from '../../default-layout-blokken/button/button.component';
import { NgxOvLinkComponent } from '../../default-layout-blokken/link/link.component';
import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import { ClickEqualsEnterDirective } from '../../../directives/click-equals-enter';

let identifier = 0;

// =============================================================================
// Config
// =============================================================================
@Component({
  selector: 'ngx-ov-modal',
  templateUrl: './modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ClickEqualsEnterDirective,
    NgClass,
    NgStyle,
    NgxOvLinkComponent,
    NgxOvButtonComponent,
    PortalModule,
    AsyncPipe
  ]
})
// =============================================================================
// Definition
// =============================================================================
export class NgxOvModalComponent
  extends NgxOvComponentBaseClass
  implements OnDestroy, OnInit, OnChanges
{
  @ContentChildren(PortalModalFooterDirective, { descendants: true })
  // -------------------------------------------------------------------------
  // public variables
  // -------------------------------------------------------------------------
  footerTemplate: QueryList<PortalModalFooterDirective>;
  iconCloseModalTitle: string;
  // -------------------------------------------------------------------------
  // private variables
  // -------------------------------------------------------------------------
  private _id: string;
  private _isOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private _isSaving: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private _data: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _title: string;
  private _type: 'Passive' | 'Danger' | 'Transactional' | 'Acknowledgement' =
    'Passive';
  private _size: 'small' | 'medium' | 'large' | 'xlarge';
  private _fullScreenMobile = true;
  private _cancelText: string;
  private _confirmText: string;
  private _maxHeight: number;
  private _minHeight: number;
  private _maxWidth: number;
  private _minWidth: number;
  private _confirmIcon: string;
  private _preventClose = false;
  private _allowOverflow = false;
  private _autoCloseOnConfirm = false;
  private _imageAlt: string;
  private _imageSrc = '';

  _styles: Map<string, any>;

  public identifier = `modal-${(identifier += 1)}`;

  // -------------------------------------------------------------------------
  // Input variables
  // -------------------------------------------------------------------------
  @Input() imageMaxHeight = 20;

  @ViewChild('modal', { static: false })
  modal: ElementRef<HTMLElement>;

  // -------------------------------------------------------------------------
  // Input variables: get/set
  // -------------------------------------------------------------------------

  @Input() set id(value: string) {
    this._id = value;
  }

  get id(): string {
    return this._id ? this._id : this.identifier;
  }

  @Input() set isOpen(value: boolean) {
    this._isOpen.next(value);
    if (value) {
      this.trapFocus();
    }
  }

  get isOpen(): boolean {
    return this._isOpen.value;
  }

  get isOpen$(): BehaviorSubject<boolean> {
    return this._isOpen;
  }

  @Input() set isSaving(value: boolean) {
    this._isSaving.next(value);
  }

  get isSaving(): boolean {
    return this._isSaving.getValue();
  }

  get isSaving$(): BehaviorSubject<boolean> {
    return this._isSaving;
  }

  @Input() set title(value: string) {
    if (value) {
      this._title = value;
      if (!this.id) {
        this._id = value.toUpperCase().replace(/[^a-zA-Z0-9]+/g, '');
      }
    }
  }

  get title(): string {
    return this._title;
  }

  @Input() set type(value: string) {
    switch (value.toLowerCase()) {
      case 'danger': {
        this._type = 'Danger';
        break;
      }

      case 'transactional': {
        this._type = 'Transactional';
        break;
      }

      case 'acknowledgement': {
        this._type = 'Acknowledgement';
        break;
      }

      default: {
        this._type = 'Passive';
      }
    }
  }

  get type(): string {
    return this._type;
  }

  @Input() set size(value: string) {
    const v = value.toLowerCase();

    if (v.startsWith('s')) {
      this._size = 'small';
    } else if (v.startsWith('l')) {
      this._size = 'large';
    } else if (v.startsWith('x')) {
      this._size = 'xlarge';
    } else {
      this._size = 'medium';
    }
  }

  get size(): string {
    return this._size;
  }

  @Input() set fullScreenMobile(value: boolean) {
    this._fullScreenMobile = value;
  }

  get fullScreenMobile(): boolean {
    return this._fullScreenMobile;
  }

  @Input() set cancelText(value: string) {
    this._cancelText = value;
  }

  get cancelText(): string {
    return this._cancelText;
  }

  @Input() set confirmText(value: string) {
    this._confirmText = value;
  }

  get confirmText(): string {
    return this._confirmText;
  }

  @Input() set maxWidth(value: number) {
    if (value > 0) {
      this._maxWidth = value;
    }
  }

  get maxWidth(): number {
    return this._maxWidth;
  }

  @Input() set minWidth(value: number) {
    if (value > 0) {
      this._minWidth = value;
    }
  }

  get minWidth(): number {
    return this._minWidth;
  }

  @Input() set maxHeight(value: number) {
    if (value > 0) {
      this._maxHeight = value;
    }
  }

  get maxHeight(): number {
    return this._maxHeight;
  }

  @Input() set minHeight(value: number) {
    if (value > 0) {
      this._minHeight = value;
    }
  }

  get minHeight(): number {
    return this._minHeight;
  }

  @Input() set confirmIcon(icon: string) {
    this._confirmIcon = icon;
  }

  get confirmIcon(): string {
    return this._confirmIcon;
  }

  @Input() set preventClose(value: boolean) {
    this._preventClose = value;
  }

  get preventClose(): boolean {
    return this._preventClose;
  }

  @Input() set allowOverflow(value: boolean) {
    this._allowOverflow = value;
  }

  get allowOverflow(): boolean {
    return this._allowOverflow;
  }

  @Input() set autoCloseOnConfirm(value: boolean) {
    this._autoCloseOnConfirm = value;
  }

  get autoCloseOnConfirm(): boolean {
    return this._autoCloseOnConfirm;
  }

  @Input() set data(value: any) {
    this._data.next(value);
  }

  get data(): any {
    return this._data.getValue();
  }

  get data$(): Observable<any> {
    return this._data;
  }

  // TODO: ook image alt voorzien
  @Input() set imageSrc(imageSrc: string) {
    this._imageSrc = imageSrc;
  }

  get imageSrc(): string {
    return this._imageSrc;
  }

  @Input() set imageAlt(imageAlt: string) {
    this._imageAlt = imageAlt;
  }

  get imageAlt(): string {
    return this._imageAlt;
  }

  // -------------------------------------------------------------------------
  // Output variables: get/set
  // -------------------------------------------------------------------------
  @Output() closePopup: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() confirm: EventEmitter<any> = new EventEmitter();

  // -------------------------------------------------------------------------
  // Copy variables
  // -------------------------------------------------------------------------
  public cancelCopy: string;
  public confirmCopy: string;

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  constructor(
    private focusTrap: ConfigurableFocusTrapFactory,
    private modalService: NgxOvModalService,
    public translationsService: TranslationsService,
    @Optional() public translateService: TranslateService
  ) {
    super(translationsService, translateService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.minHeight ||
      changes.minWidth ||
      changes.maxHeight ||
      changes.maxWidth ||
      changes.allowOverflow
    ) {
      this._styles = this.getStyles();
    }
  }

  // -------------------------------------------------------------------------
  // Lifecycle methods
  // -------------------------------------------------------------------------
  ngOnInit() {
    this.modalService.add(this);
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    this.modalService.remove(this.id);
  }

  private getStyles(): Map<string, any> {
    const styles = new Map<string, any>();

    if (this.minHeight) {
      styles.set('minHeight.rem', this.minHeight);
    }

    if (this.minWidth) {
      styles.set('minWidth.rem', this.minWidth);
    }

    if (this.maxHeight) {
      styles.set('maxHeight.rem', this.maxHeight);
    }

    if (this.maxWidth) {
      styles.set('maxWidth.rem', this.maxWidth);
    }

    if (this.allowOverflow) {
      styles.set('overflow', 'visible');
    }

    return styles;
  }

  trapFocus(): void {
    if (this.modal && this.modal.nativeElement) {
      const focusTrap = this.focusTrap.create(this.modal.nativeElement);
      focusTrap.focusFirstTabbableElementWhenReady();
    }
  }

  // -------------------------------------------------------------------------
  // Events
  // -------------------------------------------------------------------------
  open(data?: any) {
    if (data) {
      this.data = data;
    }
    this._isOpen.next(true);
    this.trapFocus();
  }

  close() {
    this._isOpen.next(false);
  }

  onClosePopup(): void {
    this.closePopup.emit(this.data);
    this.close();
  }

  onCancel(): void {
    this.cancel.emit(this.data);
  }

  onConfirm(): void {
    this.confirm.emit(this.data);
  }

  // -------------------------------------------------------------------------
  // Translation
  // -------------------------------------------------------------------------
  setLabels() {
    const prefix = 'MELDINGEN.MODAL';
    this.cancelCopy =
      this.cancelText ?? this.getTranslation(prefix, 'FOOTER.CANCEL_TEXT');
    this.confirmCopy =
      this.confirmText ?? this.getTranslation(prefix, 'FOOTER.CONFIRM_TEXT');
    this.iconCloseModalTitle = this.getTranslation(prefix, 'HEADER.CLOSE');
  }
}
