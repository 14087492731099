@if (documentType === 'pdf') {
  <iframe
    [title]="title"
    [src]="documentSrc | domSanitizer"
    [height]="height"
    [width]="width"
  ></iframe>
}

@if (documentType === 'image') {
  <div class="flex flex-row gap-small mb-small mt-large">
    @if (allowRotate || rotateImage.observed) {
      <ngx-ov-button
        [label]="rotateImageRightActionNameCopy"
        [isTertiary]="true"
        [iconOnly]="'fa-solid fa-rotate-right'"
        (buttonClick)="onRotateImageRight()"
        [attr.data-cy]="'rotateright-docviewer'"
      ></ngx-ov-button>
      <ngx-ov-button
        [label]="rotateImageLeftActionNameCopy"
        [isTertiary]="true"
        [iconOnly]="'fa-solid fa-rotate-left'"
        (buttonClick)="onRotateImageLeft()"
        [attr.data-cy]="'rotateleft-docviewer'"
      ></ngx-ov-button>
    }
    @if (downloadImage.observed) {
      <ngx-ov-button
        [label]="downloadImageActionNameCopy"
        [isTertiary]="true"
        [iconBefore]="'fas fa-download'"
        (buttonClick)="onDownloadImage()"
        [attr.data-cy]="'download-docviewer'"
      ></ngx-ov-button>
    }
  </div>
  <div
    [class]="'bg-grayscales-gray6 grid place-items-center p-4 overflow-hidden'"
    [ngStyle]="{
      'min-width.px':
        degreesRotated == 90 || degreesRotated == 270
          ? imgContainerHeight
          : imgContainerWidth,
      'min-height.px':
        degreesRotated == 90 || degreesRotated == 270
          ? imgContainerWidth
          : imgContainerHeight
    }"
  >
    <img
      [src]="documentSrc | domSanitizer"
      [alt]="imgAlt"
      [style.transform]="'rotate(' + degreesRotated + 'deg)'"
      [class]="'inline-block relative'"
      [attr.data-cy]="'image-docviewer'"
    />
  </div>
}
