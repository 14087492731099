import { Injectable } from '@angular/core';
import { NgxOvUiConfigServiceOptions } from './models/ngx-ov-ui.config';

@Injectable({ providedIn: 'root' })
export class NgxOvUiConfigService {
  private readonly config: NgxOvUiConfigServiceOptions;
  constructor(moduleConfig: NgxOvUiConfigServiceOptions) {
    this.config = moduleConfig;
  }

  get configOptions() {
    return this.config;
  }

  get cypress() {
    return this.config.cypress;
  }

  get acceptedFileTypes() {
    return this.config.uploadOptions?.acceptedFileTypes;
  }

  get maxLengthFileName() {
    return this.config.uploadOptions?.maxLengthFileName;
  }

  get maxFileSizeInMB() {
    return this.config.uploadOptions?.maxFileSizeInMB;
  }
}
