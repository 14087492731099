<div
  class="c-global-alert {{ classes }}"
  [class.c-global-alert--error]="type === 'ERROR'"
  [class.c-global-alert--warning]="type === 'WARNING'"
  [class.c-global-alert--success]="type === 'SUCCESS'"
>
  @if (showIcon) {
    <div class="c-global-alert__icon">
      <span class="c-icon fas fa-{{ icon }}"></span>
    </div>
  }

  <div class="c-global-alert__message">
    {{ message }}
    <ng-content></ng-content>
  </div>
</div>
