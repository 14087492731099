<ngx-ov-flyout
  [trigger]="'<i class=\'fas fa-globe\' aria-hidden=\'true\'></i>'"
  [triggerLabel]="_triggerLabel"
  [triggerLabelMobile]="_triggerLabelMobile"
  [header]="
    '<i class=\'fas fa-globe\' aria-hidden=\'true\'></i>&nbsp;' + headerCopy
  "
  [secondary]="false"
  [position]="position"
>
  @for (language of languages; track trackByFn) {
    <ngx-ov-flyout-item
      (clicked)="setLanguage(language)"
      [attr.data-cy]="language.code"
    >
      <div class="u-text-bold">{{ language.nameShort }}</div>
      <div>&nbsp;-&nbsp;{{ language.name }}</div>
    </ngx-ov-flyout-item>
  }
</ngx-ov-flyout>
