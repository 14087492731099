import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NgxOvButtonComponent } from '../../default-layout-blokken/button/button.component';

@Component({
  selector: 'ngx-ov-wissel-omgeving',
  templateUrl: './ngx-ov-wissel-omgeving.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgxOvButtonComponent]
})
export class NgxOvWisselOmgevingComponent implements OnInit {
  _shadowSessionKey: string = 'ov-use-shadow';
  _shadow: boolean;

  ngOnInit(): void {
    this._shadow = this.isShadow();
  }

  wisselOmgeving() {
    sessionStorage.setItem(this._shadowSessionKey, String(!this.isShadow()));
    window.location.reload();
  }

  private isShadow(): boolean {
    const value = sessionStorage.getItem(this._shadowSessionKey);
    return value !== null && value === 'true';
  }
}
