import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output
} from '@angular/core';
import { NgxOvNavigationService } from './navigation.service';
import { NavigationItem } from './navigation.model';
import { LinkService } from '../../../services/link.service';
import { NgxOvComponentBaseClass } from '../../ngx-ov-component-base-class';
import { TranslationsService } from '../../../services/translations.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxOvNavigationSubItemComponent } from './navigation-sub-item.component';
import { StopPropagationDirective } from '../../../directives/stop-propagation';
import { NgxOvBadgeComponent } from '../../meldingen/badge/badge.component';
import { ClickEqualsEnterDirective } from '../../../directives/click-equals-enter';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { trackItemByIndex } from '../../../helper';

@Component({
  selector: 'ngx-ov-navigation-item',
  templateUrl: './navigation-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    ClickEqualsEnterDirective,
    NgxOvBadgeComponent,
    StopPropagationDirective,
    NgxOvNavigationSubItemComponent
  ]
})
export class NgxOvNavigationItemComponent extends NgxOvComponentBaseClass {
  // -------------------------------------------------------------------------
  // public variables
  // -------------------------------------------------------------------------
  public toggleIconCloseTitle;
  public toggleIconOpenTitle;
  // -------------------------------------------------------------------------
  // Input variables
  // -------------------------------------------------------------------------
  @Input() linkTitle: string;
  @Input() linkUrl: string;
  @Input() external = false;
  @Input() separator: string;
  @Input() iconClass = 'fas fa-angle-right';
  @Input() badge: string;
  @Input() bottom = false;
  @Input() roles: string | string[];
  @Input() collapsible: boolean;
  @Input() collapsed = true;
  @Input() children: NavigationItem[];
  @Input() mobileQuickLink = false;
  @Input() hideMobile = false;
  @Output() menuClicked = new EventEmitter();

  trackByFn = trackItemByIndex;

  constructor(
    private linkService: LinkService,
    public navigationService: NgxOvNavigationService,
    public translationsService: TranslationsService,
    @Optional() public translateService: TranslateService
  ) {
    super(translationsService, translateService);
  }

  navigate(linkUrl: string) {
    if (this.collapsible && (!linkUrl || this.collapsed)) {
      this.toggle();
    } else {
      this.navigationService.collapseMobile();
    }

    this.menuClicked.emit();

    if (linkUrl) {
      this.linkService.navigate(linkUrl);
    }
  }

  toggle() {
    this.collapsed = !this.collapsed;
  }

  setLabels(): void {
    this.toggleIconCloseTitle = this.getTranslation(
      'NAVIGATION.SIDE_NAVIGATION.ITEM',
      'TOGGLE_ICON.CLOSE'
    );
    this.toggleIconOpenTitle = this.getTranslation(
      'NAVIGATION.SIDE_NAVIGATION.ITEM',
      'TOGGLE_ICON.OPEN'
    );
  }
}
