import { Injectable } from '@angular/core';

export interface NgxOvUiModuleConfig {
  /**
   * Flag used to enable cypress testing on components.
   */
  cypress?: boolean;
  uploadOptions?: NgxOvUiModuleUploadOptions;
}

/**
 * Default options to use for file validation when using UploadComponent.
 * Can be overwritten at component level.
 * When an option is left empty defaults are taken as defined in UploadService.
 */
export interface NgxOvUiModuleUploadOptions {
  /**
   * List of accepted file types e.g. [png, jpg, pdf]
   */
  acceptedFileTypes?: string[];
  /**
   * Max length of a file name.
   */
  maxLengthFileName?: number;
  /**
   * Maximum size of file to be uploaded expressed in MB.
   */
  maxFileSizeInMB?: number;
}

@Injectable({
  providedIn: 'root'
})
export class NgxOvUiServiceUploadOptions implements NgxOvUiModuleUploadOptions {
  public acceptedFileTypes: string[] = [];
  /**
   * Max length of a file name.
   */
  public maxLengthFileName: number = 100;
  /**
   * Maximum size of file to be uploaded expressed in MB.
   */
  public maxFileSizeInMB: number = null;
}

@Injectable({
  providedIn: 'root'
})
export class NgxOvUiConfigServiceOptions implements NgxOvUiModuleConfig {
  public cypress: boolean = false;
  public uploadOptions: NgxOvUiServiceUploadOptions = new NgxOvUiServiceUploadOptions();
}
