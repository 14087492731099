import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as R from 'ramda';
import { trackItemByIndex } from '../../../helper';
import { NgxOvToggleButtonComponent } from './toggle-button.component';

@Component({
  selector: 'ngx-ov-toggle-button-group',
  templateUrl: './toggle-button-group.component.html',
  standalone: true,
  imports: [NgxOvToggleButtonComponent]
})
export class NgxOvToggleButtonGroupComponent {
  private _options: any[];
  private _bindLabel: string;
  private _bindValue: string;
  private _bindIcon: string;
  private _bindCode: string;
  private _value: any;

  trackByFn = trackItemByIndex;

  @Input() set options(options: any[]) {
    this._options = options;
  }

  get options(): any[] {
    return this._options;
  }

  @Input() set bindLabel(bindLabel: string) {
    this._bindLabel = bindLabel;
  }

  get bindLabel(): string {
    return this._bindLabel;
  }

  @Input() set bindValue(bindValue: string) {
    this._bindValue = bindValue;
  }

  get bindValue(): string {
    return this._bindValue;
  }

  @Input() set bindIcon(bindIcon: string) {
    this._bindIcon = bindIcon;
  }

  get bindIcon(): string {
    return this._bindIcon;
  }

  @Input() set value(value: any) {
    this._value = value;
  }

  get value(): any {
    return this._value;
  }

  @Input() set bindCode(bindCode: string) {
    this._bindCode = bindCode;
  }

  get bindCode(): string {
    return this._bindCode;
  }

  // -------------------------------------------------------------------------
  // Output variables
  // -------------------------------------------------------------------------
  @Output() toggleButtonGroupClicked: EventEmitter<any> = new EventEmitter();

  onToggleButtonClicked(index: number) {
    this.toggleButtonGroupClicked.emit(
      this.bindValue ? this.options[index][this.bindValue] : this.options[index]
    );
  }

  isOptionSelected(option: any): boolean {
    if (this.bindValue) {
      return typeof option === 'object' && option !== null
        ? option[this.bindValue] === this.value
        : null;
    }
    return typeof option === 'object' && option !== null
      ? R.equals(option, this.value)
      : option === this.value;
  }
}
