<input
  style="display: none"
  type="radio"
  [id]="id"
  [name]="name"
  [(ngModel)]="value"
/>
@if (label) {
  <ngx-ov-input-label
    [text]="label"
    [required]="isRequired || showRequired"
    [isReadonly]="isReadonly"
    [tooltip]="radioGroupTooltip"
  ></ngx-ov-input-label>
}
@if (!isReadonly) {
  @for (option of options; let i = $index; track trackByFn) {
    <ngx-ov-radio
      [id]="id + (option.id || i)"
      [for]="id"
      [classes]="
        ngControl?.touched && ngControl?.status === 'INVALID'
          ? 'is-error-group'
          : ''
      "
      [value]="bindValue ? getOptionValue(option) : option"
      [label]="bindLabel ? getOptionLabel(option) : option"
      (valueChange)="radioSelected($event)"
      [checked]="isRadioChecked(getOptionValue(option))"
      [showAsBlock]="showAsBlock"
      [extraInfo]="option[bindExtraInfo]"
      [icon]="option[bindIcon]"
      [isDisabled]="bindIsDisabled ? getOptionIsDisabled(option) : false"
    ></ngx-ov-radio>
  }

  @for (radio of radioButtons; let i = $index; track trackByFn) {
    <ngx-ov-radio
      [id]="id + (radio.id || i)"
      [for]="id"
      [classes]="
        ngControl?.touched && ngControl?.status === 'INVALID'
          ? 'is-error-group'
          : ''
      "
      [value]="radio.value"
      [label]="radio.label"
      (valueChange)="radioSelected($event)"
      [checked]="isRadioChecked(radio)"
      [showAsBlock]="showAsBlock"
      [extraInfo]="radio.extraInfo"
      [icon]="radio.icon"
      [isDisabled]="radio.isDisabled"
    >
      @if (radio.contentPortal) {
        <ng-template ngxOvRadioContent>
          <ng-template [cdkPortalOutlet]="radio.contentPortal"></ng-template>
        </ng-template>
      }
      @if (radio.labelPortal) {
        <ng-template ngxOvRadioLabel>
          <ng-template [cdkPortalOutlet]="radio.labelPortal"></ng-template>
        </ng-template>
      }
    </ngx-ov-radio>
  }
} @else {
  @if (!radioButtons.length) {
    <div
      class="u-form-readonly__input-readonly"
      [innerHTML]="selectedLabel"
    ></div>
  }

  @for (radio of radioButtons; let i = $index; track trackByFn) {
    @if (isRadioChecked(radio)) {
      <div class="u-form-readonly__input-readonly">
        {{ radio.label }}
      </div>
    }
  }
}

@if (!isReadonly) {
  <ngx-ov-extra-info [text]="extraInfo"></ngx-ov-extra-info>
}

@if (errors$ | async; as errors) {
  <div class="mt-xsmall">
    @if (ngControl?.touched && ngControl?.status === 'INVALID') {
      <ngx-ov-input-validation [messages]="errors"></ngx-ov-input-validation>
    }
  </div>
}
