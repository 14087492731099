import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild
} from '@angular/core';
import { Observable } from 'rxjs';
import {
  NgxOvUploadComponent,
  UploadColumn,
  UploadRecord
} from '../../components/forms/upload/upload.component';
import { PapyrusUploadService } from './papyrus-upload.service';
import { FilesService } from '../../services/files.service';
import { NgxOvInputValidationComponent } from '../../components/forms/input-validation/input-validation.component';
import { AsyncPipe } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { PapyrusAccessTokenPayloadDownloadRestDto } from './papyrusAccessTokenPayloadDownloadRestDto';
import { NgxOvComponentBaseClass } from '../../components/ngx-ov-component-base-class';
import { TranslationsService } from '../../services/translations.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ngx-ov-papyrus-upload',
  templateUrl: './papyrus-upload.component.html',
  providers: [],
  standalone: true,
  imports: [NgxOvUploadComponent, NgxOvInputValidationComponent, AsyncPipe]
})
export class PapyrusUploadComponent
  extends NgxOvComponentBaseClass
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('uploadComponent') uploadComponent: NgxOvUploadComponent;

  isSaving = false;

  @Input() maxAantalFiles: number;
  @Input() accept: string[];
  @Input() canDownload = true;
  @Input() canDelete = true;
  @Input() canUpload = true;
  @Input() canDragDrop = true;
  @Input() allowedFilesText: string;
  @Input() maxLengthFileName: number;
  @Input() maxFileSizeInMB: number;

  @Input() columns: UploadColumn[];
  @Input() uploadErrorMessage: string;
  @Input() downloadErrorMessage: string;
  @Input() authenticationContext: string;
  @Input() emptyTableText: string;
  @Input() dragDropActionName: string;
  @Input() uploadActionName: string;

  @Output() uploadChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteFile: EventEmitter<any> = new EventEmitter<any>();
  @Output() download: EventEmitter<any> = new EventEmitter<any>();
  @Output() upload: EventEmitter<any> = new EventEmitter<any>();

  public files: UploadRecord[] = [];
  public files$: Observable<UploadRecord[]>;
  public defaultColumns: UploadColumn[];
  // -------------------------------------------------------------------------
  // Copy variables
  // -------------------------------------------------------------------------
  public downloadErrorMessageCopy: string;
  public uploadErrorMessageCopy: string;
  public fileNameColumnCopy: string;

  errorMessage: string;

  constructor(
    private papyrusUploadService: PapyrusUploadService,
    private filesService: FilesService,
    public translationsService: TranslationsService,
    @Optional() public translateService: TranslateService
  ) {
    super(translationsService, translateService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.files$ = this.papyrusUploadService.files$;
  }

  ngAfterViewInit() {
    if (this.upload.observed) {
      this.uploadComponent.uploadFile
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.uploadFile();
        });
    }
  }

  ngOnDestroy() {
    this.papyrusUploadService.initFiles([]);
  }

  onUploadChange(file: File) {
    this.isSaving = true;
    this.errorMessage = null;
    this.papyrusUploadService.uploadFile(file).subscribe({
      next: (res) => {
        this.uploadChange.emit({
          document_key: res.document_key,
          file,
          access_token: res.access_token
        });
      },
      error: () => {
        this.errorMessage = this.uploadErrorMessageCopy;
        this.isSaving = false;
      },
      complete: () => {
        this.isSaving = false;
      }
    });
  }

  onUploadFileError() {
    // nothing to do here
  }

  uploadFile() {
    this.upload.emit();
  }

  downloadFile(e: any) {
    this.errorMessage = null;
    const documentKey = e.row.document_key;

    if (this.download.observed) {
      this.download.emit(documentKey);
    } else {
      let body;
      if (this.authenticationContext) {
        body = new PapyrusAccessTokenPayloadDownloadRestDto();
        body.authentication_context = this.authenticationContext;
      }

      this.papyrusUploadService
        .downloadFile(documentKey, body ? body : null)
        .subscribe({
          next: (res) => {
            const anchor = this.filesService.createClickableLink(
              res.headers,
              res.body
            );
            anchor.click();
            anchor.remove();
          },
          error: () => {
            this.errorMessage = this.downloadErrorMessageCopy;
          }
        });
    }
  }

  onDeleteFile(e: any) {
    this.deleteFile.emit(e);
  }

  setLabels(): void {
    let prefix: string = 'FORMS.PAPYRUS_UPLOAD';
    this.downloadErrorMessageCopy =
      this.downloadErrorMessage ??
      this.getTranslation(prefix, 'ERROR_MESSAGE.DOWNLOAD');
    this.uploadErrorMessageCopy =
      this.uploadErrorMessage ??
      this.getTranslation(prefix, 'ERROR_MESSAGE.UPLOAD');
    this.fileNameColumnCopy = this.getTranslation(
      prefix,
      'COLUMN_TITLES.FILENAME'
    );
    this.defaultColumns = [
      {
        id: 'bestandsnaam',
        header: this.fileNameColumnCopy,
        actions: {
          canDownload: true
        }
      }
    ];
  }
}
