import {
  AbstractControl,
  NgControl,
  NgModel,
  ValidationErrors
} from '@angular/forms';

import { Observable, of, startWith, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

import { AsyncValidatorArray, ValidatorArray } from './validate';

import { ValueAccessorBase } from './value-accessor';
import { TranslateService } from '@ngx-translate/core';
import { Optional } from '@angular/core';
import { TranslationsService } from '../../services/translations.service';

export abstract class FormsBase<T> extends ValueAccessorBase<T> {
  protected abstract model: NgModel;
  public isRequired = false;
  public showRequired = false;
  public errors$: Observable<string>;

  constructor(
    private validators: ValidatorArray,
    private asyncValidators: AsyncValidatorArray,
    public ngControl: NgControl,
    @Optional() public translateService: TranslateService,
    @Optional() public translationsService: TranslationsService
  ) {
    super();
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  init() {
    this.isRequired = this.getIsRequired();
    this.showRequired = this.getShowRequired();
    this.errors$ = this.ngControl?.control?.statusChanges.pipe(
      startWith(this.ngControl.control.status),
      switchMap((e) => {
        const errors = this.ngControl.control.errors
          ? Object.keys(this.ngControl.control.errors).map((i) => {
              return { code: i, error: this.ngControl.control.errors[i] };
            })
          : [];
        const error = errors.length ? errors[0] : null;
        if (error) {
          if (this.translateService) {
            return this.translateService
              .get(
                'NGX_OV_UI.ERRORS.FORM.CONTROL.' + error?.code.toUpperCase(),
                error.error
              )
              .pipe(
                map((translation) => {
                  if (
                    translation ==
                    'NGX_OV_UI.ERRORS.FORM.CONTROL.' + error?.code.toUpperCase()
                  ) {
                    return this.translationsService.getDefaultComponentTranslation(
                      'ERRORS.FORM.CONTROL.' + error?.code.toUpperCase()
                    );
                  } else {
                    return translation;
                  }
                })
              );
          } else {
            return of(
              this.translationsService.getDefaultComponentTranslation(
                'ERRORS.FORM.CONTROL.' + error?.code.toUpperCase()
              )
            );
          }
        } else {
          return of(null);
        }
      })
    );
    try {
      this.ngControl?.control.statusChanges.subscribe(() => {
        this.isRequired = this.getIsRequired();
        this.showRequired = this.getShowRequired();
      });
    } catch (e) {}
  }

  changes() {
    this.isRequired = this.getIsRequired();
    this.showRequired = this.getShowRequired();
  }

  getIsRequired(): boolean {
    if (this.validators) {
      return (
        this.validators.filter((validator) => (validator as any).required)
          .length > 0
      );
    }
    return this.getShowRequired();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value || control.value === '') {
      return { required: true };
    }
    return null;
  }

  invalid(): boolean {
    return this.ngControl.invalid;
  }

  getShowRequired() {
    const validators =
      this.ngControl &&
      this.ngControl.control &&
      this.ngControl.control.validator &&
      this.ngControl.control.validator({} as AbstractControl);
    return validators && validators.required;
  }
  getNgxOvUiTranslation(componentPrefix: string, key: string): any {
    const jsonKey = `NGX_OV_UI.${componentPrefix}.${key}`;
    let jsonText;
    if (this.translateService) {
      jsonText = this.translateService.instant(jsonKey);
      if (jsonText && jsonText !== jsonKey) {
        return jsonText;
      }
    }

    try {
      const translationKey = `${componentPrefix}.${key}`;
      const translation =
        this.translationsService.getDefaultComponentTranslation(translationKey);
      return translation ? translation : 'Missing translation';
    } catch (e) {
      return 'Error translating';
    }
  }
}
