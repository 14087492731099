<form #profielForm="ngForm" novalidate>
  <div class="my-10">
    <h3>Profiel gegevens</h3>
    @if (!isLoading) {
      <ngx-ov-profile
        [name]="'Kandidaat'"
        [firstname]="'Test'"
        [imageToShow]="profile.image"
        (profileEdit)="openModal()"
      >
      </ngx-ov-profile>
    } @else {
      <div style="width: 500px" class="flex">
        <div>
          <ngx-skeleton-loader
            count="1"
            appearance="circle"
            [theme]="{
              height: '108px',
              width: '108px'
            }"
          ></ngx-skeleton-loader>
        </div>
        <div class="w-full">
          <ngx-skeleton-loader
            count="2"
            appearance="line"
          ></ngx-skeleton-loader>
        </div>
      </div>
    }
  </div>
  <div class="my-10 u-form-hide-required">
    <h3>Extra informatie</h3>
    <div class="grid grid-cols-12 gap-medium">
      <div class="col-span-12 desktop:col-span-6">
        <ngx-ov-datepicker
          [(ngModel)]="profile.geboorteDatum"
          [label]="'Geboortedatum'"
          [required]="true"
          [altInput]="'d/m/Y'"
          [allowInput]="true"
          [placeholder]="'31/12/2000'"
          name="geboorteDatum"
        ></ngx-ov-datepicker>
      </div>
      <div class="col-span-12 desktop:col-span-6">
        <ngx-ov-select
          [items]="landen"
          [placeholder]="'Selecteer geboorteland'"
          [label]="'Geboorteland'"
          [bindLabel]="'omschrijving'"
          [(ngModel)]="profile.geboorteland"
          [required]="true"
          name="geboorteland"
        >
        </ngx-ov-select>
      </div>

      <div class="col-span-12 desktop:col-span-6">
        <ngx-ov-radio-group
          [id]="'geslacht-group'"
          [label]="'Geslacht'"
          [(ngModel)]="profile.geslacht"
          [required]="true"
          [extraInfo]="'Let op: kies de correcte optie!'"
          name="geslacht"
        >
          <ngx-ov-radio
            [id]="'geslacht-man'"
            label="Man"
            value="MAN"
          ></ngx-ov-radio>
          <ngx-ov-radio
            [id]="'geslacht-vrouw'"
            label="Vrouw"
            value="VROUW"
          ></ngx-ov-radio>
          <ngx-ov-radio
            [id]="'geslacht-fout'"
            label="Fout"
            value="FOUT"
          ></ngx-ov-radio>
        </ngx-ov-radio-group>
      </div>

      <div class="col-span-12 desktop:col-span-6">
        <ngx-ov-select
          [items]="nationaliteiten"
          [placeholder]="'Selecteer nationaliteit'"
          [label]="'Nationaliteit'"
          [bindLabel]="'omschrijving'"
          [(ngModel)]="profile.nationaliteit"
          [required]="true"
          name="nationaliteit"
        >
        </ngx-ov-select>
      </div>
    </div>
  </div>

  <!-- Modal voor wijzigen foto -->
  <ngx-ov-modal
    [id]="'fotoModal'"
    [type]="'Transactional'"
    [title]="'Wijzig profielfoto'"
    [confirmText]="'Opslaan'"
    [allowOverflow]="true"
    [data]="profile"
    (confirm)="wijzigFoto()"
  >
    <profile-modal-image></profile-modal-image>
  </ngx-ov-modal>
  <div class="my-10">
    @if (showError) {
      <ngx-ov-alert [title]="'Opgelet!'" [type]="'ERROR'">
        <div
          [innerHTML]="'Fout bij het opslaan van de identiteitsgevens.'"
        ></div>
      </ngx-ov-alert>
    }
  </div>
  <div class="my-10">
    <div class="u-position-btn-prim-sec">
      <ngx-ov-button
        [label]="'Identiteitsgegevens opslaan'"
        [isDisabled]="!profielForm.valid"
        (buttonClick)="opslaan(profielForm)"
      ></ngx-ov-button>
    </div>
  </div>
</form>
