import {
  Component,
  ContentChildren,
  Inject,
  Input,
  OnChanges,
  Optional,
  Self,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  FormsModule,
  NG_ASYNC_VALIDATORS,
  NG_VALIDATORS,
  NgControl,
  NgModel
} from '@angular/forms';
import * as R from 'ramda';
import { FormsBase } from '../../forms-base';
import { NgxOvCheckboxComponent } from '../checkbox-item/checkbox.component';
import { trackItemByIndex } from '../../../../helper';
import { NgxOvInputValidationComponent } from '../../input-validation/input-validation.component';
import { PortalModule } from '@angular/cdk/portal';
import { PortalCheckboxContentDirective } from '../portals/portal-checkbox-content.directive';
import { NgxOvCheckboxNestedComponent } from '../checkbox-item/checkbox-nested.component';
import { NgxOvInputLabelComponent } from '../../input-label/input-label.component';
import { AsyncPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { TranslationsService } from '../../../../services/translations.service';

let identifier = 0;

@Component({
  selector: 'ngx-ov-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  standalone: true,
  imports: [
    FormsModule,
    NgxOvInputLabelComponent,
    NgxOvCheckboxNestedComponent,
    PortalCheckboxContentDirective,
    PortalModule,
    NgxOvInputValidationComponent,
    AsyncPipe
  ]
})
export class NgxOvCheckboxGroupComponent
  extends FormsBase<any>
  implements OnChanges
{
  // -------------------------------------------------------------------------
  // Private variables
  // -------------------------------------------------------------------------
  private _name: string;
  private _options: any[];
  private _isReadonly: boolean;
  private _bindId: string[] = ['id'];
  private _bindLabel;
  private _bindIsDisabled = 'isDisabled';
  private _bindValue: string[];
  private _showAsBlock: boolean;
  private _id: string;
  private _code: string;

  trackByFn = trackItemByIndex;
  _sortedValues;
  _checkedOptions;

  // -------------------------------------------------------------------------
  // Input variables
  // -------------------------------------------------------------------------
  get name(): string {
    return this._name;
  }
  @Input() set name(name: string) {
    this._name = name;
  }

  get options(): any[] {
    return this._options;
  }
  @Input() set options(options: any[]) {
    this._options = options;
  }

  get isReadonly(): boolean {
    return this._isReadonly;
  }
  @Input() set isReadonly(value: boolean) {
    this._isReadonly = value;
  }

  @Input() label: string;

  get bindId(): string | string[] {
    return this._bindId;
  }
  @Input() set bindId(bindId: string | string[]) {
    if (!Array.isArray(bindId)) {
      this._bindId = [bindId];
    } else {
      this._bindId = bindId;
    }
  }

  get showAsBlock(): boolean {
    return this._showAsBlock;
  }
  @Input() set showAsBlock(showAsBlock: boolean) {
    this._showAsBlock = showAsBlock;
  }

  get bindLabel(): string {
    return this._bindLabel;
  }
  @Input() set bindLabel(bindLabel: string) {
    this._bindLabel = bindLabel;
  }

  get code(): string {
    return this._code;
  }
  @Input() set code(code: string) {
    this._code = code;
  }

  get bindIsDisabled(): string {
    return this._bindIsDisabled;
  }
  @Input() set bindIsDisabled(bindIsDisabled: string) {
    this._bindIsDisabled = bindIsDisabled || 'isDisabled';
  }

  get bindValue(): string | string[] {
    return this._bindValue;
  }
  @Input() set bindValue(bindValue: string | string[]) {
    if (!Array.isArray(bindValue)) {
      this._bindValue = [bindValue];
    } else {
      this._bindValue = bindValue;
    }
  }

  get id(): string {
    return this._id ? this._id : this.identifier;
  }
  @Input() set id(id: string) {
    this._id = id;
  }

  @Input() bindExtraInfo = 'extraInfo';
  @Input() bindIcon = 'icon';

  // -------------------------------------------------------------------------
  // Other variables
  // -------------------------------------------------------------------------
  @ViewChild(NgModel, { static: true }) model: NgModel;
  @ContentChildren(NgxOvCheckboxComponent) checkboxes: NgxOvCheckboxComponent[];

  public identifier = `checkbox-group-${(identifier += 1)}`;

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
    @Self() @Optional() ngControl: NgControl,
    @Optional() translateService: TranslateService,
    translationsService: TranslationsService
  ) {
    super(
      validators,
      asyncValidators,
      ngControl,
      translateService,
      translationsService
    );
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options || changes.isReadonly) {
      this._sortedValues = this.getSortedValues();
    }
    super.init();
  }

  // -------------------------------------------------------------------------
  // Events
  // -------------------------------------------------------------------------
  checkboxChange(value: any, option: any): void {
    const currentValue: any = this.model.model || [];
    const newValue: any = Array.isArray(currentValue)
      ? [...currentValue]
      : currentValue;
    const optionValue = this.valuesHaveBindId()
      ? this.getOptionValueById(option)
      : this.getOptionValue(option);

    if (this.valuesHaveBindId()) {
      if (this.isChecked(option)) {
        const getValue = R.path(this.bindId);
        newValue.splice(
          currentValue.map((val) => getValue(val)).indexOf(optionValue),
          1
        );
      } else {
        newValue.push(option);
      }
    } else if (currentValue && currentValue.includes(optionValue)) {
      newValue.splice(currentValue.indexOf(optionValue), 1);
    } else if (Array.isArray(currentValue)) {
      newValue.push(optionValue);
    }
    if (this.model) {
      this.model.update.emit(newValue.length ? newValue : null);
    }
  }

  getSortedValues(): any[] {
    const output = [];
    if (!this.options) return output;

    this.options.forEach((option: any) => {
      if (this.value && this.isChecked(option)) {
        output.push(this.getLabelValue(option));
      }
    });

    return output;
  }

  isChecked(option: any): boolean {
    try {
      if (!this.value) {
        return false;
      }

      // If option is an object and it has te bindId => check byId
      if (this.valuesHaveBindId()) {
        const getValue = R.path(this.bindId);
        if (!Array.isArray(this.value)) {
          return false;
        }
        return (
          this.value
            .map((val) => getValue(val))
            .indexOf(this.getOptionValueById(option)) >= 0
        );
      }
      return this.value.indexOf(this.getOptionValue(option)) >= 0;
    } catch (e) {
      return false;
    }
  }

  getOptionValue(option: any) {
    let getValue;
    let optionValue;

    if (this.bindValue) {
      if (!Array.isArray(this.bindValue)) {
        this.bindValue = [this.bindValue];
      }
      getValue = R.path(this.bindValue);
      optionValue = getValue(option);
    } else {
      optionValue = option;
    }
    return optionValue;
  }

  getOptionValueById(option: any) {
    let getValue;

    if (this.bindId) {
      if (!Array.isArray(this.bindId)) {
        this.bindId = [this.bindId];
      }
      getValue = R.path(this.bindId);
      return getValue(option);
    }
    return this.getOptionValue(option);
  }

  valuesHaveBindId(): boolean {
    if (Array.isArray(this.value)) {
      if (this.value.length) {
        const hasBindId = R.has(this.bindId);
        return R.all(hasBindId)(this.value);
      }
      return false;
    }
    return false;
  }

  getLabelValue(option: any) {
    if (this.bindLabel) {
      const getValue = R.path(this.bindLabel.split('.'));
      return getValue(option);
    }

    if (typeof option === 'object') {
      return option.label;
    }

    return option;
  }

  getNgModelValue(value: any, option: any) {
    return (
      value &&
      (typeof value === 'string' || Array.isArray(value)) &&
      value.includes(option[this.bindLabel])
    );
  }
}
