import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';
import { NgxOvModalService } from '../../../../projects/ngx-ov-ui/src/lib/components/meldingen/modal/modal.service';
import { AsyncPipe } from '@angular/common';
import { ProfileContactFormComponent } from './components/profile-contact-form/profile-contact-form.component';
import { ProfileAddressFormComponent } from './components/profile-address-form/profile-address-form.component';
import { ProfileIdentityFormComponent } from './components/profile-identity-form/profile-identity-form.component';
import { NgxOvTabComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/navigatie/tabs/tab.component';
import { NgxOvTabsComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/navigatie/tabs/tabs.component';
import { ProfileService } from './services/profile.service';
import { TabTitleContentDirective } from '../../../../projects/ngx-ov-ui/src/lib/components/navigatie/tabs/tab-title-content.directive';
import { NgxOvLinkComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/link/link.component';
import { NgxOvTabService } from '../../../../projects/ngx-ov-ui/src/lib/components/navigatie/tabs/tabs.service';

@Component({
  selector: 'app-page-profile',
  templateUrl: './page-profile.component.html',
  providers: [NgxOvModalService, ProfileService],
  standalone: true,
  imports: [
    NgxOvTabsComponent,
    NgxOvTabComponent,
    ProfileIdentityFormComponent,
    ProfileAddressFormComponent,
    ProfileContactFormComponent,
    AsyncPipe,
    TabTitleContentDirective,
    NgxOvLinkComponent
  ]
})
export class PageProfileComponent implements OnInit {
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(private tabservice: NgxOvTabService) {}

  ngOnInit(): void {
    this.isLoading$.next(true);
    const timerLoading = timer(3000);
    timerLoading.subscribe(() => {
      this.isLoading$.next(false);
    });
  }

  gaNaarContact() {
    this.tabservice.setActive('CONTACT');
  }
}
