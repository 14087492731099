<div
  class="c-expansion-panel-item"
  [class.c-expansion-panel-item--last]="lastItem"
>
  <div class="c-expansion-panel-item__intro" (click)="onToggleItem()">
    <div class="c-expansion-panel-item__intro__chevron">
      <span>
        @if (isCollapsed) {
          <i class="fas fa-chevron-right" aria-hidden="true"></i>
        } @else {
          <i class="fas fa-chevron-down" aria-hidden="true"></i>
        }
      </span>
    </div>
    <div class="flex flex-col">
      <div class="c-expansion-panel-item__content gap-x-medium">
        <div
          class="c-expansion-panel-item__intro__title"
          [innerHTML]="title"
        ></div>

        @if (rightContent) {
          <div class="c-expansion-panel-item__intro__right-content">
            <ng-template [cdkPortalOutlet]="rightContent"></ng-template>
          </div>
        }
      </div>

      @if (extraTitleContent) {
        <div class="c-expansion-panel-item__subtitle">
          <ng-template [cdkPortalOutlet]="extraTitleContent"></ng-template>
        </div>
      }
    </div>
  </div>

  @if (!isCollapsed) {
    <div>
      <div class="c-expansion-panel-item__detail-container">
        <ng-content></ng-content>

        @if (closeText) {
          <div class="c-expansion-panel-item__close" (click)="onToggleItem()">
            <a>{{ closeText }}</a>
            <i class="fas fa-chevron-up" aria-hidden="true"></i>
          </div>
        }
      </div>

      @if (footer) {
        <div class="c-expansion-panel-item__footer">
          <ng-template [cdkPortalOutlet]="footer"></ng-template>
        </div>
      }
    </div>
  }
</div>
