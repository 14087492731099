import { NgxOvStoreActions } from './actions';

export class ResetErrorMessages {
  static readonly type = NgxOvStoreActions.ResetErrormessages;
}

export class ResetSuccessMessages {
  static readonly type = NgxOvStoreActions.ResetSuccessMessages;
}

