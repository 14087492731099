<div class="c-profile">
  <div class="c-profile__box">
    <div class="c-profile__picture">
      <div class="c-profile__picture__overlay" (click)="profileEdit.emit()">
        <i class="fas fa-plus-circle" aria-hidden="true"></i>
        <div
          class="c-profile__picture__overlay__text"
          [innerHTML]="textUpdatePicture"
        ></div>
      </div>

      @if (!imageToShow) {
        <div class="c-profile__picture__placeholder">
          <i class="fas fa-user-circle fa-7x" aria-hidden="true"></i>
        </div>
      } @else {
        <div class="c-profile__picture__image">
          <img [src]="imageToShow" [alt]="imageAlt" />
        </div>
      }
    </div>

    <div class="c-profile__details">
      <div class="c-profile__detail">
        <div class="c-profile__title" [innerHTML]="textLabelName"></div>
        <div class="c-profile__value">{{ name }}</div>
      </div>

      <div class="c-profile__detail">
        <div class="c-profile__title" [innerHTML]="textLabelFirstname"></div>
        <div class="c-profile__value">{{ firstname }}</div>
      </div>
    </div>
  </div>
  @if (extraInfo) {
    <div class="c-profile__extra-info">
      <ngx-ov-extra-info [text]="extraInfo"></ngx-ov-extra-info>
    </div>
  }
</div>
