import { Component, OnInit } from '@angular/core';
import {
  PAPYRUS_CONFIG,
  PapyrusAccessTokenService,
  PapyrusConfig,
  PapyrusDocumentService,
  PapyrusUploadService
} from '../../../../projects/ngx-ov-ui/src/lib/fundamentals/papyrus-upload';
import { PapyrusWrapperComponent } from '../components/papyrus/papyrus-wrapper.component';
import { UploadColumn } from '../../../../projects/ngx-ov-ui/src/lib/components/forms/upload/upload.component';

/**
 * Configuration of URI's for the API's
 */

const papyrusConfig: PapyrusConfig = {
  urlOvbaseDownload: '/papyrus-stl/papyrus-api/document/v1/document/download',
  urlOvbaseUpload: '/papyrus-stl/papyrus-api/document/v1/document/upload',
  urlAccessTokenForDownload:
    '/stl/ovbase-api/papyrus/document/v1/document/download_token',
  urlAccessTokenForUpload:
    '/stl/ovbase-api/papyrus/document/v1/document/upload_token'
};

@Component({
  selector: 'app-upload-papyrus',
  templateUrl: './page-upload-papyrus.component.html',
  providers: [
    PapyrusUploadService,
    PapyrusDocumentService,
    PapyrusAccessTokenService,
    {
      provide: PAPYRUS_CONFIG,
      useValue: papyrusConfig
    }
  ],
  standalone: true,
  imports: [PapyrusWrapperComponent]
})
export class PageUploadPapyrusComponent implements OnInit {
  public columns: UploadColumn[] = [
    {
      id: 'bestandsnaam',
      header: 'Bestandsnaam',
      actions: {
        canDownload: true
      }
    },
    {
      id: 'id',
      header: 'Id'
    },
    {
      id: 'documentType',
      header: 'Document Type'
    },
    {
      id: 'datum',
      header: 'Datum'
    }
  ];

  constructor() {
    // empty constructor
  }
  ngOnInit(): void {
    // empty on init
  }

  deleteFile(e: any) {}

  downloadFile(e: any) {
    if (e.row.bestand) {
      // eslint-disable-next-line no-console
      console.log('Downloading file met bestand: ', e.row.bestand);
    } else {
      // eslint-disable-next-line no-console
      console.log('Downloading file met id: ', e.row.id);
    }
  }
}
