import { Component } from '@angular/core';
import { NgxOvButtonComponent } from '../../../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/button/button.component';
import { NgxOvSelectComponent } from '../../../../../../projects/ngx-ov-ui/src/lib/components/forms/select/select.component';
import { NgxOvInputFieldComponent } from '../../../../../../projects/ngx-ov-ui/src/lib/components/forms/input-field/input-field.component';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'profile-address-form',
    templateUrl: './profile-address-form.component.html',
    standalone: true,
    imports: [FormsModule, NgxOvInputFieldComponent, NgxOvSelectComponent, NgxOvButtonComponent]
})
export class ProfileAddressFormComponent {
  public landen = [
    {
      code: 'BEL',
      omschrijving: 'België'
    },
    {
      code: 'NL',
      omschrijving: 'Nederland'
    },
    {
      code: 'FR',
      omschrijving: 'Frankrijk'
    },
    {
      code: 'DE',
      omschrijving: 'Duitsland'
    }
  ];

  public adres = {
    straat: null,
    gemeente: null,
    postcode: null,
    land: null
  };
}
