import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Optional,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { NgxOvModalService } from '../../meldingen/modal/modal.service';
import { TranslationsService } from '../../../services/translations.service';
import { TranslateService } from '@ngx-translate/core';
import { UploadService } from '../../../services/upload.service';
import { NgxOvUiConfigService } from '../../../ngx-ov-ui.config.service';
import { NgxOvComponentBaseClass } from '../../ngx-ov-component-base-class';
import { trackItemByIndex } from '../../../helper';
import { PathPipe } from '../../../pipes/path.pipe';
import { NgxOvTooltipComponent } from '../../meldingen/tooltip/tooltip.component';
import { NgxOvRequiredMarkComponent } from '../required-mark/required-mark.component';
import { NgxOvLoaderComponent } from '../../default-layout-blokken/loader/loader.component';
import { ClickEqualsEnterDirective } from '../../../directives/click-equals-enter';
import { NgxOvLinkComponent } from '../../default-layout-blokken/link/link.component';
import { NgStyle, NgTemplateOutlet } from '@angular/common';

// =============================================================================
// Interfaces
// =============================================================================

export interface UploadColumn {
  id: string;
  header: string;
  actions?: {
    canDownload?: boolean;
  };
}

export interface UploadRecord<T = {}> {
  id: string;
  bestandsnaam: string;
  data?: T;
  state?: 'succes' | 'error';
  bestand?: File;
  isOpen?: boolean;
  isNotExpandable?: boolean;
  documentType?: string;
  datum?: string;
  actions?: {
    canDelete?: boolean;
    canDownload?: boolean;
    canEdit?: boolean;
  };
}

let identifier = 0;
const dragOverClass = 'c-upload__dragdrop--dragover';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-upload',
  templateUrl: './upload.component.html',
  providers: [NgxOvModalService, UploadService],
  standalone: true,
  imports: [
    NgTemplateOutlet,
    NgxOvLinkComponent,
    ClickEqualsEnterDirective,
    NgxOvLoaderComponent,
    NgxOvRequiredMarkComponent,
    NgxOvTooltipComponent,
    NgStyle,
    PathPipe
  ]
})
export class NgxOvUploadComponent extends NgxOvComponentBaseClass {
  _showRows = false;
  _styles;
  trackByFn = trackItemByIndex;
  // -------------------------------------------------------------------------
  // Input variables
  // -------------------------------------------------------------------------
  @Input() isOpenByDefault = false;
  @Input() isRequired = false;

  @Input()
  get rows(): Array<UploadRecord> {
    return Array.isArray(this._rows) ? this._rows : [];
  }
  set rows(rows: Array<UploadRecord>) {
    if (Array.isArray(rows)) {
      this._showRows = true;
      this._rows = rows.map((row) => {
        return {
          ...row,
          isOpen: this.isOpenByDefault
        };
      });
    } else {
      this._showRows = false;
      this._rows = [];
    }
  }
  private _rows: Array<UploadRecord> = [];

  @Input()
  get canDragDrop(): boolean {
    // you can only have drag-drop if you can also upload
    return this.canUpload && this._canDragDrop;
  }
  set canDragDrop(canDragDrop: boolean) {
    this._canDragDrop = canDragDrop;
  }
  private _canDragDrop: boolean;

  @Input() get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }
  private _isLoading: boolean;

  get errorMessage(): string {
    return this._errorMessage;
  }

  @Input() set errorMessage(errorMessage: string) {
    this._errorMessage = errorMessage;
  }
  private _errorMessage = '';

  @Input() canUpload = true;
  @Input() canDownload = false;
  @Input() canDelete = false;
  @Input() canEdit = false;

  @Input() title;
  @Input() emptyTableText;
  @Input() downloadActionName;
  @Input() deleteActionName;
  @Input() editActionName;
  @Input() uploadActionName;
  @Input() dragDropActionName;
  @Input() dragDropSelectActionName;
  @Input() loadingText;
  @Input() tooltip: string;
  @Input() additionalActionText;

  @Input()
  get columns(): Array<UploadColumn> {
    return Array.isArray(this._columns) ? this._columns : [];
  }
  set columns(columns: Array<UploadColumn>) {
    this._columns = columns;
    this._styles = this.getStyles();
  }
  private _columns: Array<UploadColumn>;

  /**
   * List of accepted file extensions e.g. ['.pdf', '.png']
   * https://www.w3schools.com/TAGS/att_input_accept.asp
   * When no list is given default application or ngx-ov-ui configuration is used.
   */
  @Input()
  get accept(): string[] {
    const acceptedFileTypes: string[] =
      this._accept ?? this.config.acceptedFileTypes;
    // To remain backwards compatible with list without dots e.g. ['pdf', 'png']
    return acceptedFileTypes?.map((fileExtension: string) => {
      return !fileExtension.indexOf('.') ? fileExtension : `.${fileExtension}`;
    });
  }
  set accept(accept: string[]) {
    this._accept = accept;
  }
  private _accept: string[];

  @Input() allowMultiple = true;

  @Input()
  get maxLengthFileName(): number {
    return this._maxLengthFileName ?? this.config.maxLengthFileName;
  }
  set maxLengthFileName(maxLengthFileName: number) {
    this._maxLengthFileName = maxLengthFileName;
  }
  private _maxLengthFileName: number;

  @Input()
  get maxFileSizeInMB(): number {
    return this._maxFileSizeInMB ?? this.config.maxFileSizeInMB;
  }
  set maxFileSizeInMB(maxFileSizeInMB: number) {
    this._maxFileSizeInMB = maxFileSizeInMB;
  }
  private _maxFileSizeInMB: number;

  @Input() maxNumberFiles: number = null;
  @Input() allowedFilesText: string;
  @Input() tooltipTemplate: TemplateRef<any>;

  @Output() uploadFile: EventEmitter<any> = new EventEmitter<any>();
  @Output() uploadFileError: EventEmitter<string> = new EventEmitter<string>();
  @Output() uploadChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteFile: EventEmitter<any> = new EventEmitter<any>();
  @Output() editFile: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadFile: EventEmitter<any> = new EventEmitter<any>();
  @Output() executeAdditionalAction: EventEmitter<any> =
    new EventEmitter<any>();

  @ViewChild('newFile', { static: false }) fileUpload: ElementRef;
  @ViewChild('dragdropzone', { static: false }) dragdropzone: ElementRef;

  public identifier = `form-upload-${(identifier += 1)}`;

  // -------------------------------------------------------------------------
  // Copy variables
  // -------------------------------------------------------------------------
  public emptyTableTextCopy;
  public uploadedDocumentsCopy;
  public downloadActionNameCopy;
  public deleteActionNameCopy;
  public editActionNameCopy;
  public uploadActionNameCopy;
  public dragDropActionNameCopy;
  public dragDropSelectActionNameCopy;
  public loadingTextCopy;
  public iconRowToggleOpenTitle;
  public iconRowToggleClosedTitle;

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  constructor(
    public config: NgxOvUiConfigService,
    public translationsService: TranslationsService,
    private uploadService: UploadService,
    @Optional() public translateService: TranslateService
  ) {
    super(translationsService, translateService);
  }

  // -------------------------------------------------------------------------
  // Events
  // -------------------------------------------------------------------------
  openUploadFile(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.maxNumberFiles && this.rows.length >= this.maxNumberFiles) {
      // do nothing
    } else {
      if (this.uploadFile.observed) {
        this.uploadFile.emit();
      } else {
        this.fileUpload.nativeElement.click();
      }
    }
  }

  resetUpload() {
    if (
      this.fileUpload &&
      this.fileUpload.nativeElement &&
      this.fileUpload.nativeElement.value
    ) {
      this.fileUpload.nativeElement.value = null;
    }
  }

  onUploadChange(e: any) {
    this.errorMessage = '';
    const target: HTMLInputElement = <HTMLInputElement>e.target;
    this.uploadFiles(target.files);
  }

  onDropFile(event: any) {
    event.preventDefault();
    event.stopPropagation();
    const { classList } = this.dragdropzone.nativeElement;
    classList.remove(dragOverClass);

    this.uploadFiles(event.dataTransfer.files);
  }

  uploadFiles(files: FileList) {
    if (
      this.maxNumberFiles &&
      Array.isArray(this.rows) &&
      files.length + this._rows.length > this.maxNumberFiles
    ) {
      this.handleError('TOO_MANY_FILES');
    } else if (!this.allowMultiple && files.length > 1) {
      this.handleError('NOT_ALLOW_MULTIPLE');
    } else if (files.length === 1) {
      // TODO: refactor to handle file validation
      const file = files[0];
      const errorCode = this.uploadService.validateFile(
        file,
        this.accept,
        this.maxLengthFileName,
        this.maxFileSizeInMB
      );
      if (errorCode === null) {
        this.uploadChange.emit(file);
      } else {
        this.handleError(errorCode);
      }
    } else if (files.length > 0) {
      //TODO: gaan we meerdere bestanden tegelijk opvangen?
      const filesWithErrorCode = Array.from(files).map((file) => {
        return {
          file,
          errorCode: this.uploadService.validateFile(
            file,
            this.accept,
            this.maxLengthFileName,
            this.maxFileSizeInMB
          )
        };
      });
      const validFiles = filesWithErrorCode
        .filter((file) => file.errorCode == null)
        .map(({ file }) => file);
      if (validFiles.length === 1) {
        this.uploadChange.emit(validFiles[0]);
      } else if (validFiles.length > 1) {
        this.uploadChange.emit(validFiles);
      } else {
        this.handleError('NO_VALID_FILE');
      }
    }
    this.resetUpload();
  }

  handleError(translationCode: string) {
    const prefix = 'FORMS.UPLOAD';
    switch (translationCode) {
      case 'FILENAME_TOO_LONG':
        this.errorMessage = this.getTranslation(
          prefix,
          translationCode
        ).replace('{maxLengthFileName}', this.maxLengthFileName);
        break;
      case 'FILE_TOO_BIG':
        this.errorMessage = this.getTranslation(
          prefix,
          translationCode
        ).replace('{maxFileSizeInMB}', this.maxFileSizeInMB);
        break;
      case 'WRONG_FILETYPES':
        this.errorMessage = this.getTranslation(
          prefix,
          translationCode
        ).replace('{fileTypes}', this.accept.join(','));
        break;
      case 'EMPTY_FILE':
        this.errorMessage = this.getTranslation(prefix, translationCode);
        break;
      default:
        this.errorMessage = this.getTranslation(prefix, translationCode);
    }

    this.uploadFileError.emit(translationCode);
  }

  onDeleteFile(row: UploadRecord, index: number) {
    this.deleteFile.emit({ row, index });
  }

  onEditFile(row: UploadRecord, index: number) {
    this.editFile.emit({ row, index });
  }

  onDownloadFileColumn(column: UploadColumn, row: UploadRecord, index: number) {
    if (column && column.actions && column.actions.canDownload) {
      this.onDownloadFile(row, index);
    }
  }

  onDownloadFile(row: UploadRecord, index: number) {
    this.downloadFile.emit({ row, index });
  }

  onClickAdditionalAction() {
    this.executeAdditionalAction.emit();
  }

  toggleRow(row: UploadRecord) {
    if (!row.isNotExpandable) {
      row.isOpen = !row.isOpen;
    }
  }

  private getStyles(): Map<string, any> {
    const styles = new Map<string, any>();
    styles.set('maxWidth.%', (1 / this.columns.length) * 100);
    return styles;
  }

  hasAtLeastOneAction(uploadRecord?: UploadRecord): boolean {
    if (uploadRecord) {
      return (
        (this.canDelete && uploadRecord?.actions?.canDelete) ||
        (this.canDownload && uploadRecord?.actions?.canDownload) ||
        (this.canEdit && uploadRecord?.actions?.canEdit)
      );
    }

    for (const row of this.rows) {
      if (
        (this.canDelete && row?.actions?.canDelete) ||
        (this.canDownload && row?.actions?.canDownload) ||
        (this.canEdit && row?.actions?.canEdit)
      ) {
        return true;
      }
    }

    return false;
  }

  onDragEnter(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    const { classList } = this.dragdropzone.nativeElement;
    classList.add(dragOverClass);
  }

  onDragExit(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    const { classList } = this.dragdropzone.nativeElement;
    classList.remove(dragOverClass);
  }

  // -------------------------------------------------------------------------
  // Translation
  // -------------------------------------------------------------------------
  setLabels() {
    const prefix = 'FORMS.UPLOAD';
    this.emptyTableTextCopy =
      this.emptyTableText ?? this.getTranslation(prefix, 'EMPTY_TABLE_TEXT');
    this.uploadedDocumentsCopy =
      this.uploadedDocumentsCopy ??
      this.getTranslation(prefix, 'UPLOADED_DOCUMENTS');
    this.downloadActionNameCopy =
      this.downloadActionName ??
      this.getTranslation(prefix, 'DOWNLOAD_ACTION_NAME');
    this.deleteActionNameCopy =
      this.deleteActionName ??
      this.getTranslation(prefix, 'DELETE_ACTION_NAME');
    this.editActionNameCopy =
      this.editActionName ?? this.getTranslation(prefix, 'EDIT_ACTION_NAME');
    this.uploadActionNameCopy =
      this.uploadActionName ??
      this.getTranslation(prefix, 'UPLOAD_ACTION_NAME');
    this.dragDropActionNameCopy =
      this.dragDropActionName ??
      this.getTranslation(prefix, 'DRAG_DROP_ACTION_NAME');
    this.dragDropSelectActionNameCopy =
      this.dragDropSelectActionName ??
      this.getTranslation(prefix, 'DRAG_DROP_SELECT_ACTION_NAME');
    this.loadingTextCopy =
      this.loadingText ?? this.getTranslation(prefix, 'LOADING_TEXT');
    this.iconRowToggleClosedTitle = this.getTranslation(
      prefix,
      'ROW.TOGGLE.CLOSE'
    );
    this.iconRowToggleOpenTitle = this.getTranslation(
      prefix,
      'ROW.TOGGLE.OPEN'
    );
  }
}
