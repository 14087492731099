<div class="grid grid-cols-12 gap-medium">
  <div class="col-span-12">
    <p>Duid uw hoogst genoten opleiding aan</p>
  </div>

  <div class="col-span-12">
    <div class="c-toggle-button__col2">
      <ngx-ov-toggle-button-group
        [options]="opleidingOpties"
        [value]="gekozenOpleiding"
        (toggleButtonGroupClicked)="opleidingGekozen.emit($event)"
      ></ngx-ov-toggle-button-group>
    </div>
  </div>
</div>
