import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UpperCasePipe } from '@angular/common';
import { ClickEqualsEnterDirective } from '../../../../directives/click-equals-enter';

// =============================================================================
// Config
// =============================================================================
@Component({
  selector: 'ngx-ov-highlight-item',
  templateUrl: './highlight-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ClickEqualsEnterDirective, UpperCasePipe]
})

// =============================================================================
// Definition
// =============================================================================
export class NgxOvHighlightItemComponent {
  @Input() icon: string;
  @Input() title: string;
  @Input() info: string;
  @Input() link: string;
  @Input() showSeparator: boolean;

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  constructor(private router: Router) {}

  onNavigate() {
    this.router.navigate([this.link]);
  }
}
