import { Component, OnInit, Optional } from '@angular/core';
import { NgxOvModalComponent } from '../../../../../projects/ngx-ov-ui/src/lib/components/meldingen/modal/modal.component';

// =============================================================================
// Config
// =============================================================================
@Component({
  selector: 'ngx-ov-generic',
  templateUrl: './generic.component.html',
  standalone: true
})

// =============================================================================
// Definition
// =============================================================================
// eslint-disable-next-line
export class NgxOvGeneric implements OnInit {
  randomNumber: number;
  tekst: string;

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  constructor(@Optional() public modal?: NgxOvModalComponent) {}

  // -------------------------------------------------------------------------
  // Init
  // -------------------------------------------------------------------------
  ngOnInit(): void {
    this.modal.data$.subscribe((data: any) => {
      if (typeof data === 'number') {
        this.randomNumber = data as number;
      } else {
        this.tekst = data as string;
      }
    });
  }
}
