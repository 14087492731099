<form #contactForm="ngForm" novalidate>
  <div class="my-10">
    <ngx-ov-alert [title]="'Opgelet!'" [type]="'WARNING'">
      <div
        [innerHTML]="
          'Let op: we gebruiken deze contact gegevens om je te contacteren in geval van problemen.<br>' +
          'In dit voorbeeld worden de verplichte velden niet met sterretje aangeduid maar de optionele velden met de label (optioneel)'
        "
      ></div>
    </ngx-ov-alert>
  </div>
  <div class="my-10 u-form-hide-required u-form-show-optional">
    <h3>Contact gegevens</h3>
    <div class="grid grid-cols-12 gap-medium">
      <div class="col-span-12">
        <ngx-ov-input-field
          [label]="'E-mailadres'"
          [(ngModel)]="contact.email"
          [maskExpr]="'A*@A*.SSS'"
          [dropSpecialCharacters]="false"
          [placeholder]="'invullen@emailadres.be'"
          [required]="true"
          [email]="true"
          name="email"
        >
        </ngx-ov-input-field>
      </div>
      <div class="col-span-12">
        <ngx-ov-input-field
          [label]="'GSM-nummer'"
          [(ngModel)]="contact.gsm"
          [required]="true"
          [maskExpr]="'0000 00 00 00'"
          [placeholder]="'4777 11 22 33'"
          [minlength]="10"
          name="gsm"
        >
        </ngx-ov-input-field>
      </div>
      <div class="col-span-12">
        <ngx-ov-input-field
          [label]="'Telefoonnummer'"
          [(ngModel)]="contact.telefoon"
          [maskExpr]="'000 00 00 00'"
          [minlength]="9"
          [placeholder]="'099 11 22 33'"
          name="telefoon"
        >
        </ngx-ov-input-field>
      </div>
    </div>
  </div>
  <div class="my-10">
    <div class="u-position-btn-prim-sec">
      <ngx-ov-button
        [label]="'Adresgegevens opslaan'"
        [isDisabled]="!contactForm.valid"
      ></ngx-ov-button>
    </div>
  </div>
</form>
