/**
 * Naric
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.3.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import {
  AbstractControlOptions,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn
} from '@angular/forms';
import {SwaggerValidators} from '@ov/ngx-ov-utils';

export interface IKnoopRestDto {
  antwoorden: Array<string>;
  scherm: string;
  volgnummer: number;
  voortgangspercentage: number;
  vraag: string;
}


export class KnoopRestDto implements IKnoopRestDto {
  antwoorden: Array<string>;
  scherm: string;
  volgnummer: number;
  voortgangspercentage: number;
  vraag: string;
}

export class KnoopRestDtoFormHelper {

  static generateFormWithSwaggerValidators(formBuilder: UntypedFormBuilder, controlsConfig: { [key: string]: any; }, options?: AbstractControlOptions | { [key: string]: any; } | null): UntypedFormGroup {
    if (controlsConfig["antwoorden"] != undefined && !(controlsConfig["antwoorden"] instanceof UntypedFormGroup) && !(controlsConfig["antwoorden"] instanceof UntypedFormArray)) {
      controlsConfig["antwoorden"][1] = controlsConfig["antwoorden"][1].concat(KnoopRestDtoValidator.antwoorden_swagger_validator());
    }
    if (controlsConfig["scherm"] != undefined && !(controlsConfig["scherm"] instanceof UntypedFormGroup) && !(controlsConfig["scherm"] instanceof UntypedFormArray)) {
      controlsConfig["scherm"][1] = controlsConfig["scherm"][1].concat(KnoopRestDtoValidator.scherm_swagger_validator());
    }
    if (controlsConfig["volgnummer"] != undefined && !(controlsConfig["volgnummer"] instanceof UntypedFormGroup) && !(controlsConfig["volgnummer"] instanceof UntypedFormArray)) {
      controlsConfig["volgnummer"][1] = controlsConfig["volgnummer"][1].concat(KnoopRestDtoValidator.volgnummer_swagger_validator());
    }
    if (controlsConfig["voortgangspercentage"] != undefined && !(controlsConfig["voortgangspercentage"] instanceof UntypedFormGroup) && !(controlsConfig["voortgangspercentage"] instanceof UntypedFormArray)) {
      controlsConfig["voortgangspercentage"][1] = controlsConfig["voortgangspercentage"][1].concat(KnoopRestDtoValidator.voortgangspercentage_swagger_validator());
    }
    if (controlsConfig["vraag"] != undefined && !(controlsConfig["vraag"] instanceof UntypedFormGroup) && !(controlsConfig["vraag"] instanceof UntypedFormArray)) {
      controlsConfig["vraag"][1] = controlsConfig["vraag"][1].concat(KnoopRestDtoValidator.vraag_swagger_validator());
    }
    if (options) {
      return formBuilder.group(controlsConfig, options);
    }
    return formBuilder.group(controlsConfig);
  }
}

export class KnoopRestDtoValidator {

  /**
   * Active Validators for antwoorden:
   * Required validator
   */
  static antwoorden_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    validators.push(SwaggerValidators.required);
    return validators;
  };

  /**
   * Active Validators for scherm:
   * Required validator
   */
  static scherm_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    validators.push(SwaggerValidators.required);
    return validators;
  };

  /**
   * Active Validators for volgnummer:
   * Required validator
   */
  static volgnummer_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    validators.push(SwaggerValidators.required);
    return validators;
  };

  /**
   * Active Validators for voortgangspercentage:
   * Minimum validator: 0
   * Maximum validator: 100
   * Required validator
   * Minimum Length: 0
   * Maximum Length: 100
   */
  static voortgangspercentage_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    validators.push(SwaggerValidators.min(0));
    validators.push(SwaggerValidators.max(100));
    validators.push(SwaggerValidators.required);
    validators.push(SwaggerValidators.minLength(0));
    validators.push(SwaggerValidators.maxLength(100));
    return validators;
  };

  /**
   * Active Validators for vraag:
   * Required validator
   */
  static vraag_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    validators.push(SwaggerValidators.required);
    return validators;
  };

}



