import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { NgxOvModalService } from '../../components/meldingen/modal/modal.service';
import { NgxOvBevestigingService } from './bevestiging.service';
import { MODAL_X_OV_BEVESTIGING } from './bevestiging.constants';

@Injectable()
export class BevestigingInterceptor implements HttpInterceptor {
  constructor(
    private modalService: NgxOvModalService,
    private bevestigingService: NgxOvBevestigingService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event: HttpResponse<any>) => {
        if (event.status == 202) {
          this.bevestigingService.addRequest(request, event);
          this.modalService.open(
            MODAL_X_OV_BEVESTIGING,
            event.body.missing_confirmed_codes
          );
        }
      })
    );
  }
}
