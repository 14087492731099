import { Component, Input } from '@angular/core';
import {
  UntypedFormGroup,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { NgxOvCheckboxComponent } from '../../../../../../projects/ngx-ov-ui/src/lib/components/forms/checkbox/checkbox-item/checkbox.component';
import { NgxOvInputFieldComponent } from '../../../../../../projects/ngx-ov-ui/src/lib/components/forms/input-field/input-field.component';

// =============================================================================
// Config
// =============================================================================
@Component({
  selector: 'app-stepper-form-overzicht',
  templateUrl: './overzicht.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgxOvInputFieldComponent,
    NgxOvCheckboxComponent
  ]
})

// =============================================================================
// Definition
// =============================================================================
export class StepperFormOverzichtComponent {
  @Input() bestelling: UntypedFormGroup;
  gebruikersvoorwaarden = false;

  geefValueFormControl(group: string, control?: string) {
    if (control) {
      const gr = this.bestelling.controls[group] as UntypedFormGroup;
      return gr.controls[control].value;
    }
    return this.bestelling.controls[group].value;
  }

  onChangeGebruikersvoorwaarden(e: any) {
    this.gebruikersvoorwaarden = !this.gebruikersvoorwaarden;
    this.bestelling.controls['gebruiksvoorwaarden'].setValue(
      this.gebruikersvoorwaarden
    );
  }
}
