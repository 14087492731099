import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { ClickEqualsEnterDirective } from '../../../directives/click-equals-enter';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'ngx-ov-menubar-item',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ClickEqualsEnterDirective, NgClass, RouterLink, RouterLinkActive]
})
export class NgxOvMenubarItemComponent {
  @Input() linkTitle = '';
  @Input() linkUrl: string;

  private _icon = '';

  get icon(): string {
    return this._icon;
  }

  @Input() set icon(value: string) {
    this._icon = value;
  }
}
