import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgxOvToggleButtonGroupComponent } from '../../../../../../projects/ngx-ov-ui/src/lib/components/forms/toggle-button/toggle-button-group.component';

@Component({
  selector: 'app-wizard-step-2',
  templateUrl: './wizard-step-2.component.html',
  standalone: true,
  imports: [NgxOvToggleButtonGroupComponent]
})
export class PageWizardStep2Component {
  opleidingOpties: string[] = [
    'Lager secundair',
    'Hoger secundair, dus afgestudeerd in het middelbaar onderwijs',
    'Bachelor',
    'Master'
  ];

  @Input() gekozenOpleiding: any;
  @Output() opleidingGekozen: EventEmitter<any> = new EventEmitter<any>();
}
