import { Inject, Injectable, Optional } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';

import { BehaviorSubject, Observable } from 'rxjs';
import { UploadRecord } from '../../components/forms/upload/upload.component';
import { DocumentResponseRestDto } from '../../models/papyrus';
import { PapyrusDocumentService } from './papyrus-document.service';
import { PapyrusAccessTokenService } from './papyrus-acces-token.service';
import { PapyrusAccessTokenPayloadUploadRestDto } from './papyrusAccessTokenPayloadUploadRestDto';
import { PapyrusConfig, PAPYRUS_CONFIG } from './papyrus.config';
import { PapyrusAccessTokenPayloadDownloadRestDto } from './papyrusAccessTokenPayloadDownloadRestDto';

const originHeader = 'X-Origin-Auth';

export interface PapyrusUploadRecord<T> extends UploadRecord<T> {
  document_key: string;
  access_token?: string;
}

export interface DocumentResponseExtRestDto extends DocumentResponseRestDto {
  access_token: string;
}

@Injectable()
export class PapyrusUploadService<T = {}> {
  private _files: BehaviorSubject<PapyrusUploadRecord<T>[]> =
    new BehaviorSubject([]);

  constructor(
    private papyrusAccessTokenService: PapyrusAccessTokenService,
    private papyrusDocumentService: PapyrusDocumentService,
    @Optional() @Inject(PAPYRUS_CONFIG) private papyrusConfig: PapyrusConfig
  ) {}

  get files(): PapyrusUploadRecord<T>[] {
    return this._files.value;
  }

  get files$(): Observable<PapyrusUploadRecord<T>[]> {
    return this._files;
  }

  initFiles(files: PapyrusUploadRecord<T>[]) {
    this._files.next(files);
  }

  addFiles(files: PapyrusUploadRecord<T> | PapyrusUploadRecord<T>[]) {
    if (Array.isArray(files)) {
      this._files.next([...this._files.value, ...files]);
    } else {
      this._files.next([...this._files.value, files]);
    }
  }

  removeFileByDocumentKey(documentKey: string) {
    this._files.next([
      ...this._files.value.filter((f) => {
        return f.document_key !== documentKey;
      })
    ]);
  }

  uploadFile(
    file: any,
    body?: PapyrusAccessTokenPayloadUploadRestDto
  ): Observable<DocumentResponseExtRestDto> {
    this.papyrusAccessTokenService.defaultHeaders =
      this.papyrusAccessTokenService.defaultHeaders.set(
        originHeader,
        '000.ewoiYXRfaGFzaCI6ICJod1Q3YU0wQ0F0N0I0TUR5anlaZVZRIiwKImF1ZCI6ICJhZjkzMTJiMi04ODU0LTRhNTYtYTE1Mi0xODdjZmVmYWNmZTciLAoiYXpwIjogImFmOTMxMmIyLTg4NTQtNGE1Ni1hMTUyLTE4N2NmZWZhY2ZlNyIsCiJleHAiOiAxNTgyNzI3OTM1LAoiZmFtaWx5X25hbWUiOiAiR2hpanNlbHMiLAoiZ2l2ZW5fbmFtZSI6ICJFdmVsaWVuIiwKImlhdCI6IDE1ODI3MjQzMzUsCiJpc3MiOiAiaHR0cHM6Ly9hdXRoZW50aWNhdGllLXRpLnZsYWFuZGVyZW4uYmUvb3AiLAoia2lkIjogImkzRFdCdmFia01lQUtHYWM3ZXNqMXQ2RmVyamd2RkV6THZqQ1BlOTZjMlEiLAoib3Zfc3R1ZGlldG9lbGFnZW5fcm9sXzNkIjogWwoiU3R1ZGlldG9lbGFnZW5HZWJydWlrZXItUk9MRV9URUNITklTQ0hfQkVIRUVSOk9WTzAwMTk1MSIKXSwKInN1YiI6ICIxYTkzZGRkYzMzNWFhZTljMmE2ODBhMjgzMGIyNzgwMzhmMjY0MTdiIiwKInZvX2lkIjogInRlY2huaXNjaGViZWhlZXItOWI1OS00OGQwLWE5MTAtMzIzZCIKfQ==.000'
      ); // TODO Koen: ^ deze header value laten invullen door de dev interceptor ipv hard-coded
    return this.papyrusAccessTokenService
      .getAccessTokenForUploadUsingPOST(body)
      .pipe(
        switchMap((res) => {
          if (this.papyrusConfig?.use_apigee === false) {
            this.papyrusDocumentService.defaultHeaders =
              this.papyrusDocumentService.defaultHeaders.set(
                originHeader,
                res.access_token
              );
          } else {
            this.papyrusDocumentService.defaultHeaders =
              this.papyrusDocumentService.defaultHeaders.set(
                'Authorization',
                'Bearer ' + res.access_token
              );
          }

          return this.papyrusDocumentService.saveDocumentUsingPOST(file).pipe(
            map((response) => {
              return {
                ...response,
                access_token: res.access_token
              };
            })
          );
        })
      );
  }

  downloadFile(
    documentKey: string,
    body?: PapyrusAccessTokenPayloadDownloadRestDto
  ) {
    this.papyrusAccessTokenService.defaultHeaders =
      this.papyrusAccessTokenService.defaultHeaders.set(
        originHeader,
        '000.ewoiYXRfaGFzaCI6ICJod1Q3YU0wQ0F0N0I0TUR5anlaZVZRIiwKImF1ZCI6ICJhZjkzMTJiMi04ODU0LTRhNTYtYTE1Mi0xODdjZmVmYWNmZTciLAoiYXpwIjogImFmOTMxMmIyLTg4NTQtNGE1Ni1hMTUyLTE4N2NmZWZhY2ZlNyIsCiJleHAiOiAxNTgyNzI3OTM1LAoiZmFtaWx5X25hbWUiOiAiR2hpanNlbHMiLAoiZ2l2ZW5fbmFtZSI6ICJFdmVsaWVuIiwKImlhdCI6IDE1ODI3MjQzMzUsCiJpc3MiOiAiaHR0cHM6Ly9hdXRoZW50aWNhdGllLXRpLnZsYWFuZGVyZW4uYmUvb3AiLAoia2lkIjogImkzRFdCdmFia01lQUtHYWM3ZXNqMXQ2RmVyamd2RkV6THZqQ1BlOTZjMlEiLAoib3Zfc3R1ZGlldG9lbGFnZW5fcm9sXzNkIjogWwoiU3R1ZGlldG9lbGFnZW5HZWJydWlrZXItUk9MRV9URUNITklTQ0hfQkVIRUVSOk9WTzAwMTk1MSIKXSwKInN1YiI6ICIxYTkzZGRkYzMzNWFhZTljMmE2ODBhMjgzMGIyNzgwMzhmMjY0MTdiIiwKInZvX2lkIjogInRlY2huaXNjaGViZWhlZXItOWI1OS00OGQwLWE5MTAtMzIzZCIKfQ==.000'
      );
    return this.papyrusAccessTokenService
      .getAccessTokenForDownloadUsingPOST(documentKey, body)
      .pipe(
        switchMap((res) => {
          if (this.papyrusConfig?.use_apigee === false) {
            this.papyrusDocumentService.defaultHeaders =
              this.papyrusDocumentService.defaultHeaders.set(
                originHeader,
                res.access_token
              );
          } else {
            this.papyrusDocumentService.defaultHeaders =
              this.papyrusDocumentService.defaultHeaders.set(
                'Authorization',
                'Bearer ' + res.access_token
              );
          }
          return this.papyrusDocumentService.getDocumentUsingGET(
            documentKey,
            'response',
            false
          );
        })
      );
  }
}
