import {
  animate,
  state,
  style,
  transition,
  trigger,
  AnimationTriggerMetadata
} from '@angular/animations';

const STEPPER_ANIMATION_TIMING = '225ms cubic-bezier(0.4,0.0,0.2,1)';

export const messageBarAnimations: {
  readonly messagebarOpenTransition: AnimationTriggerMetadata;
  readonly indicatorRotate: AnimationTriggerMetadata;
} = {
  /** Animation that transitions the opening and closing of a step. */
  messagebarOpenTransition: trigger('messagebarOpenTransition', [
    state('collapsed', style({ height: '0px', visibility: 'hidden', padding: '0' })),
    state('expanded', style({ height: '*', visibility: 'visible', padding: '0.8rem 0.8rem' })),
    transition('expanded <=> collapsed', animate(STEPPER_ANIMATION_TIMING))
  ]),

  /** Animation that rotates the open-closed indicator next to a step. */
  indicatorRotate: trigger('indicatorRotate', [
    state('collapsed, void', style({ transform: 'rotate(0deg)' })),
    state('expanded', style({ transform: 'rotate(180deg)' })),
    transition(
      'expanded <=> collapsed, void => collapsed',
      animate(STEPPER_ANIMATION_TIMING)
    )
  ])
};
