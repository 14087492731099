import {
  AfterViewChecked,
  Directive,
  ElementRef,
  Input,
  Renderer2
} from '@angular/core';
import { NgxOvUiService } from './ui.service';

@Directive({
  selector: '[uiIsVisible]',
  standalone: true
})
export class UiIsVisbleDirective implements AfterViewChecked {
  @Input() uiIsVisible: string;

  constructor(
    private uiService: NgxOvUiService,
    private renderer: Renderer2,
    private hostElement: ElementRef
  ) {}

  ngAfterViewChecked() {
    if (!this.uiService.isUiElementHidden$$(this.uiIsVisible)()) {
      this.renderer.addClass(this.hostElement.nativeElement, 'hidden');
    } else {
      this.renderer.removeClass(this.hostElement.nativeElement, 'hidden');
    }
  }
}
