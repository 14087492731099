import { Component, Input } from '@angular/core';
import { NgStyle } from '@angular/common';

@Component({
    selector: 'ngx-ov-progress-bar',
    templateUrl: './progress-bar.component.html',
    standalone: true,
    imports: [NgStyle]
})
export class NgxOvProgressBarComponent {
  private _percentage: number;

  @Input() set percentage(percentage: number) {
    this._percentage = percentage;
  }

  get percentage(): number {
    return this._percentage;
  }
}
