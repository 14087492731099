import { Component } from '@angular/core';
import { ReactiveFormComponent } from './components/reactive-form.component';
import { NgxOvTabComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/navigatie/tabs/tab.component';
import { NgxOvTabsComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/navigatie/tabs/tabs.component';
import { NgxMaskService } from 'ngx-mask';

@Component({
  selector: 'app-page-forms',
  templateUrl: './page-forms.component.html',
  styleUrls: ['./page-forms.component.scss'],
  standalone: true,
  imports: [NgxOvTabsComponent, NgxOvTabComponent, ReactiveFormComponent],
  providers: [NgxMaskService]
})
export class PageFormsComponent {}
