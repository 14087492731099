import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  LoginPageHighlightItem,
  NgxOvLoginPageComponent
} from '../../../../projects/ngx-ov-ui/src/lib/layout/login-page/login-page.component';
import { BrowserSupportService } from '../../../../projects/ngx-ov-ui/src/lib/services/browser-support.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxOvHighlightItemComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/content-blokken/highlight/login-page-highlight-item/highlight-item.component';
import { NgxOvHighlightListComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/content-blokken/highlight/login-page-highlight-list/highlight-list.component';
import { trackItemByIndex } from '../../../../projects/ngx-ov-ui/src/lib/helper';

@Component({
  selector: 'app-page-landingpage',
  templateUrl: './page-landingpage.component.html',
  standalone: true,
  imports: [
    NgxOvLoginPageComponent,
    NgxOvHighlightListComponent,
    NgxOvHighlightItemComponent,
    TranslateModule
  ]
})
export class PageLandingpageComponent implements OnInit {
  highlightItems: LoginPageHighlightItem[] = [
    {
      title: 'Overzicht',
      // eslint-disable-next-line
      info: 'Hier komt wat uitleg',
      routerLink: '/components/overview',
      icon: 'fas fa-graduation-cap'
    },
    {
      title: 'Formulieren',
      info: 'Hier komt wat uitleg met meer tekst om te testen of de uitlijning van de highlight items volledig in orde is.',
      routerLink: '/forms',
      icon: 'fas fa-keyboard'
    },
    {
      title: 'Upload component',
      info: 'Hier komt wat uitleg',
      routerLink: '/upload/default',
      icon: 'fas fa-upload'
    },
    {
      title: 'Search',
      info: 'Hier komt wat uitleg',
      routerLink: '/search',
      icon: 'fas fa-search'
    }
  ];

  browserNameAndVersion: any;
  isBrowserNotSupported: any;

  trackByFn = trackItemByIndex;

  constructor(
    private router: Router,
    private browserSupportService: BrowserSupportService,
    public translationService: TranslateService
  ) {}

  ngOnInit() {
    this.isBrowserNotSupported =
      this.browserSupportService.isBrowserNotSupported();
    this.browserNameAndVersion =
      this.browserSupportService.getBrowserNameAndVersion();
  }

  onCtaClicked() {
    // eslint-disable-next-line no-console
    console.log('CTA clicked!');
    this.router.navigate(['/components/overview']);
  }
}
