import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgxOvContactCardComponent } from '../../components/default-layout-blokken/contact-card/contact-card.component';
import { NgxOvButtonComponent } from '../../components/default-layout-blokken/button/button.component';
import { NgxOvBannerComponent } from '../../components/default-layout-blokken/banner/banner.component';

export interface LoginPageHighlightItem {
  title: string;
  info: string;
  routerLink: string;
  icon?: string;
}

// =============================================================================
// Config
// =============================================================================
@Component({
  selector: 'ngx-ov-login-page',
  templateUrl: './login-page.component.html',
  standalone: true,
  imports: [
    NgxOvBannerComponent,
    NgxOvButtonComponent,
    NgxOvContactCardComponent
  ]
})

// =============================================================================
// Definition
// =============================================================================
export class NgxOvLoginPageComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() bannerImgSrc: string;
  @Input() bannerImgSrcMobile: string;
  @Input() backgroundImagePos: string;
  @Input() backgroundImagePosMobile: string;

  @Input() introTitle: string;
  @Input() introSubtitle: string;
  @Input() introCTA: string;
  @Input() introCTALink: string;
  @Input() introImgSrc: string;

  @Input() contactBranch: string;
  @Input() contactDepartment: string;
  @Input() contactTelephone: string;
  @Input() contactEmail: string;
  @Input() contactWebsiteUrl: string;
  @Input() contactUrl: string;

  @Output() introCTAClicked: EventEmitter<any> = new EventEmitter<any>();
}
