import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CmsService } from 'src/app/shared/cmsService';
import { NgxOvLoaderComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/loader/loader.component';
import { NgxOvNewsTeaserComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/content-blokken/news-teaser/news-teaser.component';
import { NgxOvNewsTeaserListComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/content-blokken/news-teaser/news-teaser-list.component';
import { AsyncPipe } from '@angular/common';
import { trackItemByIndex } from '../../../../projects/ngx-ov-ui/src/lib/helper';

@Component({
  templateUrl: './page-cms.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [
    NgxOvNewsTeaserListComponent,
    NgxOvNewsTeaserComponent,
    NgxOvLoaderComponent,
    AsyncPipe
  ]
})
export class PageCmsComponent implements OnInit {
  articles: Observable<any>;
  trackByFn = trackItemByIndex;

  constructor(private cmsService: CmsService) {}

  ngOnInit() {
    this.cmsService.loadArticles();
    this.articles = this.cmsService.getArticles();
  }
}
