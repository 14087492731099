import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

// =============================================================================
// Component
// =============================================================================
@Component({
    selector: 'ngx-ov-boolean-indicator',
    templateUrl: './boolean-indicator.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class NgxOvBooleanIndicatorComponent {
  /**
   * Represents boolean value to be displayed.
   * @param {boolean}
   */
  @Input() isTrue = false;
}
