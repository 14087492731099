import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChanges
} from '@angular/core';
import { Language } from '../../../models/language';
import { TranslationsService } from '../../../services/translations.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxOvComponentBaseClass } from '../../ngx-ov-component-base-class';
import { trackItemByIndex } from '../../../helper';
import { NgxOvFlyoutItemComponent } from '../../navigatie/flyout/flyout-item.component';
import { NgxOvFlyoutComponent } from '../../navigatie/flyout/flyout.component';
import { DOCUMENT } from '@angular/common';

// =============================================================================
// Config
// =============================================================================
@Component({
  selector: 'ngx-ov-language-switch',
  templateUrl: './language-switch.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgxOvFlyoutComponent, NgxOvFlyoutItemComponent]
})

// =============================================================================
// Definition
// =============================================================================
export class NgxOvLanguageSwitchComponent
  extends NgxOvComponentBaseClass
  implements OnDestroy, OnInit, OnChanges
{
  // -------------------------------------------------------------------------
  // private variables
  // -------------------------------------------------------------------------
  trackByFn = trackItemByIndex;
  private _languages: Language[];
  private _currentLanguageCode: string;
  private _position = 'RIGHT';
  private _header;
  _triggerLabel;
  _triggerLabelMobile;

  // -------------------------------------------------------------------------
  // Input variables: get/set
  // -------------------------------------------------------------------------
  get languages(): Language[] {
    return this._languages;
  }
  @Input() set languages(languages: Language[]) {
    this._languages = languages;
  }

  get currentLanguageCode(): string {
    return this._currentLanguageCode;
  }
  @Input() set currentLanguageCode(currentLanguageCode: string) {
    this._currentLanguageCode = currentLanguageCode;
  }

  get position(): string {
    return this._position;
  }
  @Input() set position(position: string) {
    this._position = position;
  }

  get header(): string {
    return this._header;
  }
  @Input() set header(header: string) {
    this._header = header;
  }

  // -------------------------------------------------------------------------
  // Copy variables
  // -------------------------------------------------------------------------
  public headerCopy;

  // -------------------------------------------------------------------------
  // Output variables
  // -------------------------------------------------------------------------
  @Output() selectLanguage: EventEmitter<Language> = new EventEmitter();

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(
    public translationsService: TranslationsService,
    @Optional() public translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(translationsService, translateService);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentLanguageCode) {
      this._triggerLabel = this.getTriggerLabel();
      this._triggerLabelMobile = this.getTriggerLabelMobile();
      this.document.documentElement.lang = this._currentLanguageCode;
    }
  }

  setLanguage(language: Language) {
    this.selectLanguage.emit(language);
  }

  getCurrentLanguage(): Language {
    const filteredLanguages = this.languages
      ? this.languages.filter(
          (language) => language.code === this._currentLanguageCode
        )
      : [];
    return filteredLanguages && filteredLanguages.length
      ? filteredLanguages[0]
      : this.languages[0];
  }

  getTriggerLabel(): string {
    return `<span>${this.getCurrentLanguage().name}</span>`;
  }
  getTriggerLabelMobile(): string {
    return `<span>${this.getCurrentLanguage().nameShort}</span>`;
  }

  // -------------------------------------------------------------------------
  // Translation
  // -------------------------------------------------------------------------
  setLabels() {
    this.headerCopy =
      this._header ??
      this.getTranslation(
        'DEFAULT_LAYOUT_BLOKKEN.LANGUAGE_SWITCH',
        'HEADER_TEXT'
      );
  }
}
