import { Component, EventEmitter, Output } from '@angular/core';
import { NgxOvButtonComponent } from '../../../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/button/button.component';

@Component({
  selector: 'app-wizard-step-1',
  templateUrl: './wizard-step-1.component.html',
  standalone: true,
  imports: [NgxOvButtonComponent]
})
export class PageWizardStep1Component {
  @Output() startWizard: EventEmitter<any> = new EventEmitter<any>();
}
