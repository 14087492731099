import {
  Component,
  Inject,
  Input,
  OnChanges,
  Optional,
  Self,
  ViewChild
} from '@angular/core';
import {
  FormsModule,
  NG_ASYNC_VALIDATORS,
  NG_VALIDATORS,
  NgControl,
  NgModel
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { FormsBase } from '../forms-base';
import { message } from '../validate';
import { NgxOvInputValidationComponent } from '../input-validation/input-validation.component';
import { AsyncPipe } from '@angular/common';
import { NgxOvInputLabelComponent } from '../input-label/input-label.component';
import { TranslateService } from '@ngx-translate/core';
import { TranslationsService } from '../../../services/translations.service';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-textarea',
  templateUrl: './textarea.html',
  standalone: true,
  imports: [
    NgxOvInputLabelComponent,
    FormsModule,
    NgxOvInputValidationComponent,
    AsyncPipe
  ]
})
export class NgxOvTextareaComponent
  extends FormsBase<string>
  implements OnChanges
{
  private _autocomplete = 'off';

  // -------------------------------------------------------------------------
  // Variables
  // -------------------------------------------------------------------------
  @Input() _classes: string;
  @Input() isDisabled: boolean;
  @Input() placeholder = '';
  @Input() maxChar: number;
  @Input() name = 'textarea-1';
  @Input() label: string;
  @Input() isReadonly: boolean;

  // autocomplete values (https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#values)
  get autocomplete(): string {
    return this._autocomplete;
  }
  @Input() set autocomplete(autocomplete: string) {
    this._autocomplete = autocomplete;
  }

  @ViewChild(NgModel, { static: true }) model: NgModel;

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
    @Self() @Optional() ngControl: NgControl,
    @Optional() translateService: TranslateService,
    translationsService: TranslationsService
  ) {
    super(
      validators,
      asyncValidators,
      ngControl,
      translateService,
      translationsService
    );
  }

  ngOnChanges() {
    super.init();
  }

  get classes(): string {
    return this._classes;
  }
  @Input() set classes(classes: string) {
    this._classes = classes;
  }

  /**
   * Implemented as part of ControlValueAccessor.
   */
  writeValue(value: string) {
    if (typeof value === 'string') {
      this.value = value.trim();
    } else {
      this.value = value;
    }
  }

  onBlur(value: string): void {
    this.ngControl?.control.markAsTouched({ onlySelf: true });
    this.writeValue(value);
  }

  failures(): Observable<Array<string>> {
    if (this.ngControl?.errors?.required) {
      return of(Array(message(null, 'required')));
    }
    return of([]);
  }
}
