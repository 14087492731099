import { ChangeDetectionStrategy, Component } from '@angular/core';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-agenda',
  templateUrl: './agenda.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class NgxOvAgendaComponent {}
