import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NgxOvFooterComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/footer/footer.component';
import { LinkUrl } from '../../../../projects/ngx-ov-ui/src/lib/helper';
import { Language } from '../../../../projects/ngx-ov-ui/src/lib/models/language';
import { GdprService } from '../../../../projects/ngx-ov-ui/src/lib/services/gdpr.service';
import { NavigationItem } from '../../../../projects/ngx-ov-ui/src/lib/components/navigatie/side-navigation/navigation.model';
import { BrowserSupportService } from '../../../../projects/ngx-ov-ui/src/lib/services/browser-support.service';
import { PortalFooterDirective } from '../../../../projects/ngx-ov-ui/src/lib/layout/main-layout/portals/portal-footer.directive';
import { NgxOvNavigationComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/navigatie/side-navigation/navigation.component';
import { PortalLeftNavigationDirective } from '../../../../projects/ngx-ov-ui/src/lib/layout/main-layout/portals/portal-left-navigation.directive';
import { NgxOvHeaderTitleLinkComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/header/header-title-link/header-title-link.component';
import { NgxOvBadgeDirective } from '../../../../projects/ngx-ov-ui/src/lib/directives/badge';
import { PortalFlyoutTriggerDirective } from '../../../../projects/ngx-ov-ui/src/lib/components/navigatie/flyout/flyout-trigger.directive';
import { NgxOvFlyoutItemComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/navigatie/flyout/flyout-item.component';
import { NgxOvFlyoutComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/navigatie/flyout/flyout.component';
import { ClickEqualsEnterDirective } from '../../../../projects/ngx-ov-ui/src/lib/directives/click-equals-enter';
import { NgxOvLoginPageLoginButtonComponent } from '../../../../projects/ngx-ov-ui/src/lib/layout/login-page/login-page-login-button/login-page-login-button.component';
import { NgxOvLanguageSwitchComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/language-switch/language-switch.component';
import { PortalHeaderContentDirective } from '../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/header/portals/portal-header-content.directive';
import { NgxOvHeaderComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/header/header.component';
import { PortalHeaderDirective } from '../../../../projects/ngx-ov-ui/src/lib/layout/main-layout/portals/portal-header.directive';
import { NgxOvGlobalAlertComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/meldingen/global-alert/global-alert.component';
import { AsyncPipe } from '@angular/common';
import { NgxOvCookiebarComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/cookiebar/cookiebar.component';
import { PortalCookiebarDirective } from '../../../../projects/ngx-ov-ui/src/lib/layout/main-layout/portals/portal-cookiebar.directive';
import { MainLayoutComponent } from '../../../../projects/ngx-ov-ui/src/lib/layout/main-layout/main-layout.component';
import { NgxOvMenubarComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/navigatie/menubar/menubar.component';
import { NgxOvMenubarItemComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/navigatie/menubar/menubar-item.component';
import { NgxOvGlobalMigratieAlertComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/migratie/global-migratie-alert/global-migratie-alert.component';
import {
  CustomTranslateService,
  EnvironmentUtils,
  LanguageService,
  NgxOvBuildService
} from '@ov/ngx-ov-utils';
import { NgxOvInternalFooterComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/internal-footer/internal-footer.component';
import { NgxOvBevestigingComponent } from '../../../../projects/ngx-ov-ui/src/lib/fundamentals/bevestiging/bevestiging.component';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
  standalone: true,
  imports: [
    MainLayoutComponent,
    PortalCookiebarDirective,
    NgxOvCookiebarComponent,
    NgxOvGlobalAlertComponent,
    PortalHeaderDirective,
    NgxOvHeaderComponent,
    PortalHeaderContentDirective,
    NgxOvLanguageSwitchComponent,
    NgxOvLoginPageLoginButtonComponent,
    ClickEqualsEnterDirective,
    NgxOvFlyoutComponent,
    NgxOvFlyoutItemComponent,
    PortalFlyoutTriggerDirective,
    NgxOvBadgeDirective,
    NgxOvHeaderTitleLinkComponent,
    PortalLeftNavigationDirective,
    NgxOvNavigationComponent,
    PortalFooterDirective,
    NgxOvFooterComponent,
    NgxOvInternalFooterComponent,
    AsyncPipe,
    NgxOvMenubarComponent,
    NgxOvMenubarItemComponent,
    NgxOvGlobalMigratieAlertComponent,
    PortalCookiebarDirective,
    MainLayoutComponent,
    NgxOvCookiebarComponent,
    NgxOvGlobalAlertComponent,
    NgxOvHeaderComponent,
    NgxOvLanguageSwitchComponent,
    NgxOvLoginPageLoginButtonComponent,
    NgxOvFlyoutComponent,
    NgxOvFlyoutItemComponent,
    NgxOvBadgeDirective,
    NgxOvHeaderTitleLinkComponent,
    NgxOvMenubarItemComponent,
    NgxOvNavigationComponent,
    NgxOvFooterComponent,
    NgxOvMenubarComponent,
    TranslateModule,
    NgxOvBevestigingComponent
  ]
})
export class DefaultLayoutComponent implements OnInit {
  _inIframe = false;
  toonCookiebar$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  shadow = false;

  title = 'Dit is een officiële website van de Vlaamse overheid';
  logoTitle = 'Vlaanderen';
  logoTagline = 'verbeelding werkt.';
  infoPublisher =
    "uitgegeven door <a href='http://www.ahovoks.be/' target='_blank'>AHOVOKS</a>";
  hideLeftNav;

  versions = [];

  browserNameAndVersion: any;
  isBrowserNotSupported: any;

  public cookies = [
    {
      name: 'Analytische',
      code: 'ANALYTICAL',
      active: false,
      disabled: false
    }
  ];

  footerExtraLinks: LinkUrl[] = [
    {
      linkTitle: 'PRIVACYVERKLARING',
      linkUrl:
        'https://www.onderwijs.vlaanderen.be/sites/default/files/atoms/files/Privacyverklaring%20AHOVOKS_0.pdf',
      external: true
    },
    {
      linkTitle: 'COOKIEBELEID',
      linkUrl:
        'https://onderwijs.vlaanderen.be/sites/default/files/atoms/files/Cookiebeleid%20AHOVOKS.pdf',
      external: true
    },
    {
      linkTitle: 'FORMS',
      linkUrl: '/forms',
      external: false
    },
    {
      linkTitle: 'COOKIEVOORKEUREN',
      removeCookies: true
    },
    {
      linkTitle: 'CONTACT',
      linkUrl: 'mailto:ahovoks@vlaanderen.be'
    }
  ];

  menuItems: NavigationItem[] = [
    {
      linkTitle: 'Home',
      iconClass: 'fas fa-home',
      linkUrl: '/landingpage',
      mobileQuickLink: true
    },
    {
      linkTitle: 'Algemeen',
      linkUrl: 'common',
      iconClass: 'fas fa-cogs',
      collapsible: false,
      collapsed: false,
      children: [
        {
          linkTitle: 'Utils',
          linkUrl: '/common/utils'
        },
        {
          linkTitle: 'Weinig content',
          linkUrl: '/common/small'
        },
        {
          linkTitle: 'Spacing',
          linkUrl: '/common/spacing'
        }
      ]
    },
    {
      linkTitle: 'Formulieren',
      iconClass: 'fab fa-wpforms',
      linkUrl: 'forms',
      mobileQuickLink: true,
      badge: '1'
    },
    {
      linkTitle: 'Meldingen',
      iconClass: 'fab fa-wpforms',
      linkUrl: 'meldingen',
      collapsible: true,
      collapsed: true
    },
    {
      linkTitle: 'Content blokken',
      iconClass: 'fa-regular fa-clipboard',
      linkUrl: 'contentblokken',
      collapsible: true,
      collapsed: true
    },
    {
      linkTitle: 'Upload',
      iconClass: 'fas fa-upload',
      linkUrl: 'upload/default',
      collapsible: true,
      collapsed: true,
      children: [
        {
          linkTitle: 'Algemeen',
          linkUrl: 'upload/default'
        },
        {
          linkTitle: 'Papyrus',
          linkUrl: 'upload/papyrus'
        }
      ]
    },
    {
      linkTitle: 'Zoek',
      iconClass: 'fas fa-search',
      linkUrl: 'search',
      mobileQuickLink: true
    },
    {
      linkTitle: 'Datatable',
      iconClass: 'fas fa-table',
      linkUrl: 'datatable/readonly',
      collapsible: true,
      collapsed: true,
      children: [
        {
          linkTitle: 'Readonly',
          linkUrl: 'datatable/readonly'
        },
        {
          linkTitle: 'Editable',
          linkUrl: 'datatable/editable'
        }
      ]
    },
    {
      linkTitle: 'Agenda',
      iconClass: 'fas fa-calendar',
      linkUrl: 'agenda',
      mobileQuickLink: false
    },
    {
      linkTitle: 'Stepper',
      linkUrl: 'stepper/mat-stepper',
      iconClass: 'fas fa-utensils',
      collapsible: true,
      collapsed: true,
      mobileQuickLink: false,
      children: [
        {
          linkTitle: 'Stepper Horizontaal',
          linkUrl: '/stepper/mat-stepper'
        },
        {
          linkTitle: 'Stepper Verticaal',
          linkUrl: '/stepper/ov-stepper-vert'
        }
      ]
    },
    {
      linkTitle: 'Wizard',
      iconClass: 'fas fa-hat-wizard',
      linkUrl: 'wizard',
      mobileQuickLink: false
    },
    {
      linkTitle: 'Playground',
      iconClass: 'fas fa-fighter-jet',
      linkUrl: 'playground',
      bottom: true
    },
    {
      linkTitle: 'Profiel',
      iconClass: 'fas fa-user',
      linkUrl: 'profile',
      bottom: true
    },
    {
      linkTitle: 'Document Viewer',
      iconClass: 'fas fa-file',
      linkUrl: 'document-viewer',
      bottom: false
    },
    {
      linkTitle: 'Hidden mobile',
      iconClass: 'fas fa-eye-slash',
      hideMobile: true
    },
    {
      linkTitle: 'Translate',
      iconClass: 'fa fa-globe',
      linkUrl: '/translate',
      mobileQuickLink: false
    },
    {
      linkTitle: 'Store',
      iconClass: 'fa fa-database',
      linkUrl: '/store',
      mobileQuickLink: false
    },
    {
      linkTitle: 'Bevestiging',
      iconClass: 'fa fa-check-double',
      linkUrl: '/bevestiging',
      mobileQuickLink: false
    },
    {
      linkTitle: 'UI',
      iconClass: 'fa fa-cubes',
      linkUrl: '/ui',
      mobileQuickLink: false
    }
  ];

  loggedIn = false;
  toonCookieBar$: Observable<boolean>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private gdprService: GdprService,
    public translate: TranslateService,
    private browserSupportService: BrowserSupportService,
    public languageService: LanguageService,
    private buildService: NgxOvBuildService
  ) {}

  ngOnInit() {
    this.shadow = EnvironmentUtils.isShadow();
    this.toonCookieBar$ = this.gdprService.toonCookieBar$();
    this.isBrowserNotSupported =
      this.browserSupportService.isBrowserNotSupported();
    this.browserNameAndVersion =
      this.browserSupportService.getBrowserNameAndVersion();
    this.gdprService.initialiseerCookieBar();
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const rt = this.getChild(this.activatedRoute);
        rt.data.subscribe((data) => {
          this.hideLeftNav = data && data.hideLeftNav;
        });
      });

    // eslint-disable-next-line prefer-destructuring
    let buildTime = null;
    try {
      buildTime = this.buildService.buildTime;
    } catch (e) {}

    this.versions = [
      {
        name: 'Frontend',
        version: this.buildService.buildNumber,
        date: buildTime
      }
    ];
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    }
    return activatedRoute;
  }

  onSetLanguage(e: Language) {
    this.languageService.toggleCurrentLanguage(e.code);
  }
  onAcceptCookies(cookie: any) {
    this.gdprService.setCookiePreferences(cookie);
  }

  toggleTranslations() {
    (<CustomTranslateService>this.translate).toggleTranslationCode();
  }

  wisselOmgeving(): void {
    EnvironmentUtils.toggleShadow();
  }
}
