import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Optional
} from '@angular/core';
import { TranslationsService } from '../../../services/translations.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxOvComponentBaseClass } from '../../ngx-ov-component-base-class';
import { ClickEqualsEnterDirective } from '../../../directives/click-equals-enter';

// =============================================================================
// Component
// =============================================================================
// @ts-ignore
@Component({
  selector: 'ngx-ov-info-block',
  templateUrl: './info-block.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ClickEqualsEnterDirective]
})
export class NgxOvInfoBlockComponent
  extends NgxOvComponentBaseClass
  implements OnInit
{
  // -------------------------------------------------------------------------
  // Copy
  // -------------------------------------------------------------------------
  infoBlockOpen;
  infoBlockClosed;
  // -------------------------------------------------------------------------
  // Variables
  // -------------------------------------------------------------------------
  @Input() title: string;
  @Input() icon: string;
  @Input() isCollapsed: boolean;

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  constructor(
    public translationsService: TranslationsService,
    @Optional() public translateService: TranslateService
  ) {
    super(translationsService, translateService);
  }
  // -------------------------------------------------------------------------
  // Events
  // -------------------------------------------------------------------------
  onToggleClicked() {
    this.isCollapsed = !this.isCollapsed;
  }

  setLabels() {
    this.infoBlockClosed = this.getTranslation(
      'CONTENT_BLOKKEN.INFO_BLOCK',
      'CLOSED'
    );
    this.infoBlockOpen = this.getTranslation(
      'CONTENT_BLOKKEN.INFO_BLOCK',
      'OPEN'
    );
  }
}
