import { Directive, OnInit, Optional } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';

import { BaseClass } from '../baseclass';
import { TranslationsService } from '../services/translations.service';

@Directive()
export abstract class NgxOvComponentBaseClass
  extends BaseClass
  implements OnInit
{
  protected constructor(
    public translationsService: TranslationsService,
    @Optional() public translateService: TranslateService
  ) {
    super();
  }

  // -------------------------------------------------------------------------
  // Lifecycle methods
  // -------------------------------------------------------------------------
  ngOnInit(): void {
    this.setLabels();
    if (this.translateService) {
      this.translateService.onLangChange
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.setLabels();
        });
    }
  }

  abstract setLabels(): void;
  getTranslation(componentPrefix: string, key: string): any {
    const jsonKey = `NGX_OV_UI.${componentPrefix}.${key}`;
    let jsonText;
    if (this.translateService) {
      jsonText = this.translateService.instant(jsonKey);
      if (jsonText && jsonText !== jsonKey) {
        return jsonText;
      }
    }

    try {
      const translationKey = `${componentPrefix}.${key}`;
      const translation =
        this.translationsService.getDefaultComponentTranslation(translationKey);
      return translation ? translation : 'Missing translation';
    } catch (e) {
      return 'Error translating';
    }
  }
}
