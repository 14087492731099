import {
  AfterViewInit,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ViewChild
} from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';
import { NgxOvButtonComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/button/button.component';
import { Router } from '@angular/router';

@Component({
  templateUrl: './page-logging.component.html',
  standalone: true,
  imports: [NgxOvButtonComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PageLoggingComponent implements AfterViewInit {
  @ViewChild('survey') survey: any;

  constructor(private router: Router) {}
  onClickLog(): void {
    datadogLogs.logger.log('Message logged from loggin page in demo app');
  }
  onClickTriggerError(): void {
    throw new Error('Error triggered for Datadog logging');
  }

  ngAfterViewInit() {
    if (this.survey) {
      this.survey.nativeElement.addEventListener('buttonClicked', () => {
        this.router.navigate(['/survey']);
      });
    }
  }
}
