/**
 * Naric
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.3.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import {
  AbstractControlOptions,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn
} from '@angular/forms';

export interface IStringRestDto {
  value?: string;
}


export class StringRestDto implements IStringRestDto {
  value?: string;
}

export class StringRestDtoFormHelper {

  static generateFormWithSwaggerValidators(formBuilder: UntypedFormBuilder, controlsConfig: { [key: string]: any; }, options?: AbstractControlOptions | { [key: string]: any; } | null): UntypedFormGroup {
    if (controlsConfig["value"] != undefined && !(controlsConfig["value"] instanceof UntypedFormGroup) && !(controlsConfig["value"] instanceof UntypedFormArray)) {
      controlsConfig["value"][1] = controlsConfig["value"][1].concat(StringRestDtoValidator.value_swagger_validator());
    }
    if (options) {
      return formBuilder.group(controlsConfig, options);
    }
    return formBuilder.group(controlsConfig);
  }
}

export class StringRestDtoValidator {

  /**
   * Active Validators for value:
   */
  static value_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    return validators;
  };

}



