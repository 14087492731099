import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  Optional,
  QueryList
} from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AnimationEvent } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { Directionality } from '@angular/cdk/bidi';

import { NgxOvStepComponent } from './ov-step.component';
import { ovStepperAnimations } from './ov-stepper-animations';
import { TranslationsService } from '../../../services/translations.service';
import { trackItemByIndex } from '../../../helper';
import { ClickEqualsEnterDirective } from '../../../directives/click-equals-enter';
import { NgTemplateOutlet } from '@angular/common';

/** Custom CDK stepper component */
@Component({
  selector: 'ngx-ov-stepper',
  templateUrl: './ov-stepper.component.html',
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  providers: [{ provide: CdkStepper, useExisting: NgxOvStepperComponent }],
  animations: [
    ovStepperAnimations.verticalStepTransition,
    ovStepperAnimations.stepOpenTransition,
    ovStepperAnimations.indicatorRotate
  ],
  standalone: true,
  imports: [ClickEqualsEnterDirective, NgTemplateOutlet]
})
export class NgxOvStepperComponent
  extends CdkStepper
  implements AfterContentInit, OnInit
{
  steps: QueryList<NgxOvStepComponent> = new QueryList<NgxOvStepComponent>();

  trackByFn = trackItemByIndex;

  /** Stream of animation `done` events when the body expands/collapses. */
  readonly _animationDone = new Subject<AnimationEvent>();

  // -------------------------------------------------------------------------
  // Copy variables
  // -------------------------------------------------------------------------
  public closeStepIconTitle;
  public openStepIconTitle;
  public stepNumberCompletedIconTitle;

  constructor(
    public translationsService: TranslationsService,
    @Optional() public translateService: TranslateService,
    _dir: Directionality,
    _changeDetectorRef: ChangeDetectorRef,
    _elementRef: ElementRef<HTMLElement>
  ) {
    super(_dir, _changeDetectorRef, _elementRef);
  }

  // -------------------------------------------------------------------------
  // Lifecycle methods
  // -------------------------------------------------------------------------
  ngAfterContentInit() {
    super.ngAfterContentInit();

    this._animationDone.pipe(
      // This needs a `distinctUntilChanged` in order to avoid emitting the same event twice due
      // to a bug in animations where the `.done` callback gets invoked twice on some browsers.
      // See https://github.com/angular/angular/issues/24084
      distinctUntilChanged(
        (x, y) => x.fromState === y.fromState && x.toState === y.toState
      ),
      takeUntil(this._destroyed)
    );
  }

  ngOnInit(): void {
    this.setLabels();
    if (this.translateService) {
      this.translateService.onLangChange
        .pipe(takeUntil(this._destroyed))
        .subscribe(() => this.setLabels());
    }
  }

  getTranslation(componentPrefix: string, key: string): any {
    try {
      const translationKey = `${componentPrefix}.${key}`;
      const translation =
        this.translationsService.getDefaultComponentTranslation(translationKey);
      return translation ? translation : 'Missing translation';
    } catch (e) {
      return 'Error translating';
    }
  }

  // -------------------------------------------------------------------------
  // Translation
  // -------------------------------------------------------------------------
  setLabels() {
    const prefix = 'FORMS.OV_STEPPER.STEP';
    this.closeStepIconTitle = this.getTranslation(prefix, 'ICON.CLOSE');
    this.openStepIconTitle = this.getTranslation(prefix, 'ICON.OPEN');
    this.stepNumberCompletedIconTitle = this.getTranslation(
      prefix,
      'NUMBER.COMPLETED'
    );
  }
}
