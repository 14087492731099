@if (
  (cardIsClickable && !isExpandable) ||
  (cardIsClickable && isExpandable && expandableOpen)
) {
  <div
    class="c-card c-card--{{ alignment }}"
    [class.c-card--with-link]="hasLinkTitle"
    [class.c-card--secondary]="isSecondary"
    [class.c-card--expandable]="isExpandable"
    [ngStyle]="cardStyle"
  >
    <a class="c-card__title" (click)="onClickCardTitle()">
      <ng-template [ngTemplateOutlet]="titleContent"></ng-template>
    </a>
    <div (click)="onClickCard()">
      <ng-template [ngTemplateOutlet]="cardBody"></ng-template>
    </div>
  </div>
} @else {
  <div
    class="c-card c-card--{{ alignment }}"
    [class.c-card--secondary]="isSecondary"
    [class.c-card--expandable]="isExpandable"
    [ngStyle]="cardStyle"
  >
    <a class="c-card__title" (click)="onClickCardTitle()">
      <ng-template [ngTemplateOutlet]="titleContent"></ng-template>
    </a>
    <ng-template [ngTemplateOutlet]="cardBody"></ng-template>
  </div>
}

<ng-template #titleContent>
  <div class="c-card__title-container">
    <div class="c-card__title-row">
      @if (icon) {
        <i class="c-card__icon-left {{ icon }}" aria-hidden="true"> </i>
      }
      @if (title) {
        <div>
          {{ title }}
        </div>
      }
    </div>
    @if (portalTitle) {
      <div>
        <ng-template [cdkPortalOutlet]="portalTitle"></ng-template>
      </div>
    }
  </div>
  @if (cardIsClickable && isSecondary && showClickIcon && !isExpandable) {
    <i
      aria-hidden="true"
      class="c-card__icon-right fas fa-chevron-circle-right"
    ></i>
  }
  @if (isExpandable) {
    <div>
      @if (expandableOpen) {
        <i
          class="c-card__icon-right fas fa-chevron-circle-up"
          aria-hidden="true"
          [title]="iconCloseLabel"
        ></i>
      } @else {
        <i
          class="c-card__icon-right fas fa-chevron-circle-down"
          aria-hidden="true"
          [title]="iconOpenLabel"
        ></i>
      }
      <!--todo: OAM-1204 check if part of previous if block + check logic (always true?)-->
      @if (expandableOpen) {
        <span class="sr-only">{{
          expandableOpen ? iconCloseLabel : iconOpenLabel
        }}</span>
      }
    </div>
  }
</ng-template>

<ng-template #cardBody>
  @if (!isExpandable || (isExpandable && expandableOpen)) {
    <!-- Content -->
    <div class="c-card__content">
      <ng-content></ng-content>
    </div>
    @if (footer) {
      <!-- Footer -->
      <div>
        <ng-template [cdkPortalOutlet]="footer"></ng-template>
      </div>
    }
  }
</ng-template>
