import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  UploadColumn,
  UploadRecord
} from '../../../../../../projects/ngx-ov-ui/src/lib/components/forms/upload/upload.component';
import { BehaviorSubject } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { NgxOvUploadComponent } from '../../../../../../projects/ngx-ov-ui/src/lib/components/forms/upload/upload.component';

// =============================================================================
// Config
// =============================================================================
@Component({
  selector: 'app-stepper-form-upload-bon',
  templateUrl: './upload-bon.component.html',
  standalone: true,
  imports: [NgxOvUploadComponent, AsyncPipe]
})

// =============================================================================
// Definition
// =============================================================================
export class StepperFormUploadBonComponent {
  @Input() bon: UntypedFormGroup;

  columns: Array<UploadColumn> = [
    {
      id: 'bestandsnaam',
      header: 'Bestand'
    },
    {
      id: 'documentType',
      header: 'Document Type'
    }
  ];
  rows$: BehaviorSubject<Array<UploadRecord>> = new BehaviorSubject<
    Array<UploadRecord>
  >([]);

  onUploadChange(file: File) {
    const record = {
      id: '1',
      bestandsnaam: file.name,
      bestand: file,
      documentType: file.type,
      actions: {
        canDelete: true,
        canDownload: true
      }
    };

    this.bon.controls['bon'].patchValue(record);
    this.rows$.next([record]);
  }
}
