<div class="c-3-way-toggle">
  <ngx-ov-input-label
    [text]="label"
    [required]="isRequired || showRequired"
    [isReadonly]="isReadonly"
  ></ngx-ov-input-label>
  @if (!isReadonly) {
    <div
      class="c-3-way-toggle--container"
      [class.c-3-way-toggle--light]="isLightMode"
    >
      <input
        id="on-{{ identifier }}"
        [(ngModel)]="value"
        [value]="true"
        name="c-3-way-toggle-{{ identifier }}"
        class="c-3-way-toggle__radio-on"
        type="radio"
        (change)="valueChange.emit(value)"
      />
      <label
        for="on-{{ identifier }}"
        class="c-3-way-toggle__label-on"
        [attr.data-cy]="'3-way-toggle-aan'"
        ><i class="fas fa-check" aria-hidden="true" [title]="iconOnTitle"></i>
        <span class="sr-only">{{ iconOnTitle }}</span>
      </label>

      <input
        id="na-{{ identifier }}"
        [(ngModel)]="value"
        [value]="null"
        name="c-3-way-toggle-{{ identifier }}"
        class="c-3-way-toggle__radio-neutral"
        type="radio"
        (change)="valueChange.emit(value)"
      />
      <label class="c-3-way-toggle__label-neutral" for="na-{{ identifier }}"
        ><i
          class="fas fa-circle"
          aria-hidden="true"
          [title]="iconNeutralTitle"
        ></i>
        <span class="sr-only">{{ iconNeutralTitle }}</span></label
      >

      <input
        id="off-{{ identifier }}"
        [(ngModel)]="value"
        [value]="false"
        name="c-3-way-toggle-{{ identifier }}"
        class="c-3-way-toggle__radio-off"
        type="radio"
        (change)="valueChange.emit(value)"
      />
      <label
        for="off-{{ identifier }}"
        class="c-3-way-toggle__label-off"
        [attr.data-cy]="'3-way-toggle-uit'"
        ><i class="fas fa-times" aria-hidden="true" [title]="iconOffTitle"></i>
        <span class="sr-only">{{ iconOffTitle }}</span></label
      >
    </div>
  } @else {
    <div
      class="u-form-readonly__input-readonly"
      [innerHTML]="getTranslationText(value)"
    ></div>
  }
  @if (errors$ | async; as errors) {
    <div class="mt-xsmall">
      @if (ngControl?.touched && ngControl?.status === 'INVALID') {
        <ngx-ov-input-validation [messages]="errors"></ngx-ov-input-validation>
      }
    </div>
  }
</div>
