import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Optional
} from '@angular/core';
import { NgxOvComponentBaseClass } from '../../ngx-ov-component-base-class';
import { TranslationsService } from '../../../services/translations.service';
import { TranslateService } from '@ngx-translate/core';
import { NgClass } from '@angular/common';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-pill',
  templateUrl: './pill.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass]
})
export class NgxOvPillComponent extends NgxOvComponentBaseClass {
  // -------------------------------------------------------------------------
  // public variables
  // -------------------------------------------------------------------------
  iconCloseTitle: string;
  // -------------------------------------------------------------------------
  // Input variables
  // -------------------------------------------------------------------------
  @Input() classes: string;
  @Input() text: string;
  @Input() isFilter: boolean;
  @Input() isWarning: boolean;
  @Input() isError: boolean;
  @Input() isSuccess: boolean;

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  constructor(
    public translationsService: TranslationsService,
    @Optional() public translateService: TranslateService
  ) {
    super(translationsService, translateService);
  }

  setLabels(): void {
    this.iconCloseTitle = this.getTranslation('DIVERSE.PILL', 'CLOSE');
  }
}
