<div
  class="c-navigation-item__wrapper"
  [class.hidden-mobile]="mobileQuickLink || hideMobile"
>
  <li
    class="c-navigation-item"
    (click)="navigate(linkUrl)"
    [routerLink]="linkUrl ? linkUrl : null"
    [routerLinkActive]="linkUrl ? 'c-navigation-item--active' : ''"
    [routerLinkActiveOptions]="{ exact: linkUrl === '/' }"
    #rla="routerLinkActive"
  >
    <div class="c-navigation-item__square hidden-mobile">
      <span class="c-navigation-item__square__icon">
        <i class="{{ iconClass }}" aria-hidden="true" title="{{ linkTitle }}">
          @if (badge) {
            <div class="c-navigation-item__badge">
              <ngx-ov-badge [color]="'error'">{{ badge }}</ngx-ov-badge>
            </div>
          }
        </i>
      </span>
      @if (
        children &&
        children.length &&
        (!collapsed || navigationService._collapsed())
      ) {
        <i
          class="fa-solid fa-arrow-right c-navigation-item__arrow"
          aria-hidden="true"
        ></i>
      }
    </div>
    <div
      class="c-navigation-item__label"
      [class.hidden-desktop]="navigationService._navigationSettings.collapsed"
    >
      <span
        class="c-navigation-item__label__link-label"
        [innerHTML]="linkTitle"
      ></span>
    </div>

    @if (collapsible && children && children.length) {
      <div
        class="c-navigation-item__square c-navigation-item__icon-toggle-item"
      >
        <span class="c-navigation-item__square__icon" (click.stop)="toggle()">
          @if (collapsed) {
            <i
              class="fas fa-chevron-right"
              aria-hidden="true"
              [title]="toggleIconOpenTitle"
            ></i>
          } @else {
            <i
              class="fas fa-chevron-down"
              aria-hidden="true"
              [title]="toggleIconCloseTitle"
            ></i>
          }

          <!--todo: OAM-1204 check if part of previous if block + check logic (always true?)-->
          @if (collapsed) {
            <span class="sr-only">{{
              collapsed ? toggleIconOpenTitle : toggleIconCloseTitle
            }}</span>
          }
        </span>
      </div>
    }
  </li>

  @if (
    children &&
    children.length &&
    (!collapsed || navigationService._collapsed())
  ) {
    <ul
      class="c-navigation-item__submenu"
      [class.c-navigation-item__submenu--active]="rla.isActive"
    >
      @for (child of children; track trackByFn) {
        <ngx-ov-navigation-sub-item
          (menuClicked)="menuClicked.emit()"
          [linkTitle]="child.linkTitle"
          [linkUrl]="child.linkUrl"
        ></ngx-ov-navigation-sub-item>
      }
    </ul>
  }
</div>
