<div class="grid grid-cols-12 gap-medium">
  <div class="col-start-3 col-span-8">
    <p>Deze wizard zal een idee geven van het loon dat u mag verwachten</p>
  </div>
  <div class="col-span-12">
    <div class="u-position-btn-prim-sec">
      <ngx-ov-button
        [attr.data-cy]="'wizard-start'"
        [label]="'Start'"
        (buttonClick)="startWizard.emit()"
      ></ngx-ov-button>
    </div>
  </div>
</div>
