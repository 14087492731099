import {
  computed,
  Injectable,
  Signal,
  signal,
  WritableSignal
} from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NgxOvUiService {
  uiAPI: WritableSignal<string> = signal(null);
  uiIsLoadingError: WritableSignal<boolean> = signal(false);
  uiIsLoading: WritableSignal<boolean> = signal(false);
  uiElementsCheck: WritableSignal<string[]> = signal([]);
  uiElementsAvailable: WritableSignal<string[]> = signal([]);
  uiContext: WritableSignal<any> = signal(null);

  isUiElementHidden$$ = (element: string): Signal<boolean> =>
    computed(() => {
      return this.uiElementsAvailable().includes(element);
    });

  constructor(private http: HttpClient) {}

  init(uiAPI: string) {
    this.uiAPI.set(uiAPI);
  }

  laadUIElementen(context?: any) {
    if (this.uiAPI() && this.uiElementsCheck().length > 0) {
      this.uiIsLoading.set(true);
      this.uiIsLoadingError.set(false);
      if (context) {
        this.setContext(context);
      }

      this.http
        .post(this.uiAPI(), {
          screen_component_codes: this.uiElementsCheck(),
          context: this.uiContext()
        })
        .subscribe({
          next: (res) => {
            this.uiElementsAvailable.set(
              (<any>res).visible_screen_component_codes
            );
            this.uiIsLoading.set(false);
          },
          error: () => {
            this.uiIsLoadingError.set(true);
            this.uiIsLoading.set(false);
          }
        });
    }
  }

  addElement(uiElements: string | string[]) {
    let mappedElements: string[];
    if (!(uiElements instanceof Array)) {
      mappedElements = [uiElements];
    } else {
      mappedElements = uiElements;
    }

    mappedElements.forEach((uiElement) => {
      if (this.uiElementsCheck().indexOf(uiElement) === -1) {
        this.uiElementsCheck.update((uiElementsCheck) => [
          ...uiElementsCheck,
          uiElement
        ]);
      }
    });
  }

  setContext(context: any) {
    this.uiContext.set(context);
  }

  reset() {
    this.uiContext.set(null);
    this.uiElementsCheck.set([]);
    this.uiElementsAvailable.set([]);
  }
}
