import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { LinkUrl, trackItemByIndex } from '../../../helper';
import { Language } from '../../../models/language';
import { LinkService } from '../../../services/link.service';
import { GdprService } from '../../../services/gdpr.service';
import { ClickEqualsEnterDirective } from '../../../directives/click-equals-enter';
import { Version } from './footer.models';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-footer',
  templateUrl: './footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ClickEqualsEnterDirective]
})
export class NgxOvFooterComponent {
  // -------------------------------------------------------------------------
  // Variables
  // -------------------------------------------------------------------------
  trackByFn = trackItemByIndex;
  @Input() classes: string;
  @Input() title: string;
  @Input() logoTitle: string;
  @Input() logoTagline: string;
  @Input() secondLogoSrc: string = null;
  @Input() secondLogoAlt: string = null;
  @Input() infoPublisher: string;
  @Input() extraLinks: LinkUrl[] = [];
  @Input() versions: Version[];
  @Input() languages: Language[];

  @Output() selectLanguage: EventEmitter<Language> = new EventEmitter();

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  constructor(
    private linkService: LinkService,
    private gdprService: GdprService
  ) {}

  onClick(linkUrl: string) {
    this.linkService.navigate(linkUrl);
  }

  onSelectLanguage(language: Language): void {
    this.selectLanguage.emit(language);
  }

  removeCookies() {
    this.gdprService.resetCookies();
  }
}
