import {
  animate,
  state,
  style,
  transition,
  trigger,
  AnimationTriggerMetadata
} from '@angular/animations';

const STEPPER_ANIMATION_TIMING = '225ms cubic-bezier(0.4,0.0,0.2,1)';

export const ovStepperAnimations: {
  readonly verticalStepTransition: AnimationTriggerMetadata;
  readonly stepOpenTransition: AnimationTriggerMetadata;
  readonly indicatorRotate: AnimationTriggerMetadata;
} = {
  /** Animation that transitions the step along the Y axis in a vertical stepper. */
  verticalStepTransition: trigger('verticalStepTransition', [
    state('previous', style({ height: '0px', visibility: 'hidden' })),
    state('next', style({ height: '0px', visibility: 'hidden' })),
    state('current', style({ height: '*', visibility: 'inherit' })),
    transition('* <=> current', animate(STEPPER_ANIMATION_TIMING))
  ]),

  /** Animation that transitions the opening and closing of a step. */
  stepOpenTransition: trigger('stepOpenTransition', [
    state('collapsed', style({ height: '0px', visibility: 'hidden' })),
    state('expanded', style({ height: '*', visibility: 'visible' })),
    transition('expanded <=> collapsed', animate(STEPPER_ANIMATION_TIMING))
  ]),

  /** Animation that rotates the open-closed indicator next to a step. */
  indicatorRotate: trigger('indicatorRotate', [
    state('collapsed, void', style({ transform: 'rotate(0deg)' })),
    state('expanded', style({ transform: 'rotate(180deg)' })),
    transition(
      'expanded <=> collapsed, void => collapsed',
      animate(STEPPER_ANIMATION_TIMING)
    )
  ])
};
