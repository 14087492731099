<div class="container">
  <!--  SECTIOM ALERTS-->
  <div class="my-10">
    <ngx-ov-alert
      [title]="'Opgelet!'"
      [icon]="'exclamation-circle'"
      [type]="'success'"
    >
      <div
        [innerHTML]="
          'Vanaf schooljaar 2019-2020 wordt de schooltoeslag niet meer uitbetaald door de afdeling Studietoelagen. Meer info op <a href=\'http:\/\/www.groeipakket.be\'>www.groeipakket.be</a>'
        "
      ></div>
    </ngx-ov-alert>
  </div>

  <h1>Hoofdtitel pagina h1</h1>

  <h2>Subtitel h2</h2>

  <!--  SECTIOM CARDS-->
  <div class="my-10">
    <h3>De overheid is er voor jou</h3>
    <ngx-ov-card [title]="'Title'">
      Test card zonder cardClicked event of url
    </ngx-ov-card>
    <div class="mb-large"></div>
    <ngx-ov-card [title]="'Title'">
      Test card met cardClicked event zonder url
    </ngx-ov-card>
    <div class="mb-large"></div>
    <ngx-ov-card [title]="'Title'" [url]="'https://www.google.be'">
      Test card met cardClicked event en url
    </ngx-ov-card>
  </div>

  <!--  SECTION TEXT IN GRID-->

  <div class="my-10">
    <div class="grid grid-cols-12 gap-medium">
      <div class="col-span-3">
        <h3>heading 3</h3>
        <p>paragraph</p>
      </div>
      <div class="col-span-3">
        <h3>heading 3</h3>
        <p>paragraph</p>
      </div>
      <div class="col-span-3">
        <h3>heading 3</h3>
        <p>paragraph</p>
      </div>
      <div class="col-span-3">
        <h3>heading 3</h3>
        <p>paragraph</p>
      </div>
    </div>
  </div>
</div>
