import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GdprService {
  private _toonCookiebar: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  private _version;

  initialiseerCookieBar(version?: string) {
    this._version = version || 'v1';
    this._toonCookiebar.next(this.isCookiePreferencesEmpty());
  }

  isCookieAccepted(type: string) {
    const cookieList = localStorage.getItem(`cookieConsent_${this._version}`);
    return cookieList && cookieList.includes(type);
  }

  isCookiePreferencesEmpty() {
    return (
      localStorage.getItem(`cookieConsent_${this._version}`) === null ||
      localStorage.getItem(`cookieConsent_${this._version}`) === undefined
    );
  }

  setCookiePreferences(cookie) {
    localStorage.setItem(`cookieConsent_${this._version}`, cookie);
    this._toonCookiebar.next(this.isCookiePreferencesEmpty());
  }

  toonCookieBar$(): Observable<boolean> {
    return this._toonCookiebar;
  }

  resetCookies() {
    localStorage.removeItem(`cookieConsent_${this._version}`);
    this.initialiseerCookieBar();
  }
}
