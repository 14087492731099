import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { TranslationsService } from '../../../services/translations.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { GdprService } from '../../../services/gdpr.service';
import { NgxOvComponentBaseClass } from '../../ngx-ov-component-base-class';
import { trackItemByIndex } from '../../../helper';
import { FormsModule } from '@angular/forms';
import { NgxOvCheckboxComponent } from '../../forms/checkbox/checkbox-item/checkbox.component';
import { NgxOvButtonComponent } from '../button/button.component';
import { ClickEqualsEnterDirective } from '../../../directives/click-equals-enter';

export interface CookieTypes {
  code: string;
  title?: string;
  subTitle?: string;
  active?: boolean;
  disabled?: boolean;
}

export interface Language {
  code: string;
  name: string;
  active: boolean;
}

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-cookiebar',
  templateUrl: './cookiebar.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [
    ClickEqualsEnterDirective,
    NgxOvButtonComponent,
    NgxOvCheckboxComponent,
    FormsModule
  ]
})
export class NgxOvCookiebarComponent
  extends NgxOvComponentBaseClass
  implements OnInit
{
  // -------------------------------------------------------------------------
  // public variables
  // -------------------------------------------------------------------------
  titleLabel;
  textLabel;
  acceptAllButtonLabel;
  acceptMandatoryButtonLabel;
  subTitleLabel;
  saveButtonLabel;
  iconCookiebarOpenTitle: string;
  iconCookiebarClosedTitle: string;
  trackByFn = trackItemByIndex;
  // -------------------------------------------------------------------------
  // Input variables
  // -------------------------------------------------------------------------
  @Input() toonCookieBar = true;
  @Input() labelAccept = 'Accepteer';
  @Input() nameApplication = 'Vlaanderen.be';
  @Input() currentLanguage: Language;
  @Input() languages: Language[];

  @Input() set cookies(cookie: CookieTypes[]) {
    this._cookies = this._cookies.concat(cookie);
    this.setCookieLabels();

    if (!this.gdprService.isCookiePreferencesEmpty()) {
      this._cookies = this._cookies.map((cookieType) => {
        return {
          ...cookieType,
          active: this.gdprService.isCookieAccepted(cookieType.code)
        };
      });
    }
  }

  get cookies() {
    return this._cookies;
  }

  @Output() acceptCookies: EventEmitter<string[]> = new EventEmitter();
  @Output() setLanguage: EventEmitter<Language> = new EventEmitter();

  private supportedCookies = ['MANDATORY', 'ANALYTICAL'];

  private _cookies: CookieTypes[] = [
    {
      code: 'MANDATORY',
      active: true,
      disabled: true
    }
  ];

  isCollapsed = false;

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  constructor(
    public translationsService: TranslationsService,
    @Optional() public translateService: TranslateService,
    public gdprService: GdprService
  ) {
    super(translationsService, translateService);
  }

  // -------------------------------------------------------------------------
  // Lifecycle methods
  // -------------------------------------------------------------------------
  ngOnInit() {
    this.translationsService.nameApplication = this.nameApplication;
    super.ngOnInit();
    if (this.translateService) {
      this.translateService.onLangChange
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.setCookieLabels();
        });
    }
  }

  setCookieLabels() {
    const prefix = 'DEFAULT_LAYOUT_BLOKKEN.COOKIEBAR.COOKIES.TYPES';
    this._cookies = this._cookies.map((c) => {
      return {
        ...c,
        title: this.getTranslation(prefix, c.code + '.TITLE'),
        subTitle: this.getTranslation(prefix, c.code + '.SUBTITLE')
      };
    });
  }

  setLabels() {
    const prefix = 'DEFAULT_LAYOUT_BLOKKEN.COOKIEBAR.COOKIES.CONTENT';
    this.titleLabel = this.getTranslation(prefix, 'TITLE');
    this.textLabel = this.getTranslation(prefix, 'TEXT');
    this.acceptAllButtonLabel = this.getTranslation(
      prefix,
      'ACCEPT_ALL_BUTTON'
    );
    this.acceptMandatoryButtonLabel = this.getTranslation(
      prefix,
      'ACCEPT_MANDATORY_BUTTON'
    );
    this.subTitleLabel = this.getTranslation(prefix, 'SUBTITLE');
    this.saveButtonLabel = this.getTranslation(prefix, 'SAVE_BUTTON');
    this.iconCookiebarClosedTitle = this.getTranslation(prefix, 'ICON.CLOSED');
    this.iconCookiebarOpenTitle = this.getTranslation(prefix, 'ICON.OPEN');
  }

  onAcceptCookies() {
    const acceptedCookies = [];

    // eslint-disable-next-line sonarjs/no-ignored-return
    this.cookies.map((cookie) => {
      return cookie.active ? acceptedCookies.push(cookie.code) : null;
    });
    this.acceptCookies.emit(acceptedCookies);
  }

  acceptAllCookies() {
    this._cookies = this._cookies.map((cookie) => {
      return {
        ...cookie,
        active: true
      };
    });
    this.setCookieLabels();
    this.acceptCookies.emit(this.supportedCookies);
  }

  acceptOnlyMandatoryCookies() {
    this._cookies = this._cookies.map((cookie) => {
      return {
        ...cookie,
        active: cookie.code === 'MANDATORY'
      };
    });
    this.setCookieLabels();
    this.acceptCookies.emit(['MANDATORY']);
  }

  onSetLanguage(language: Language) {
    // eslint-disable-next-line sonarjs/no-ignored-return
    this.languages.map((lang) => {
      lang.active = false;
      return null;
    });

    language.active = true;

    this.setLanguage.emit(language);
  }
}
