import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '*[click]:not([role]):not([tabindex]',
  standalone: true
})
export class ClickEqualsEnterDirective {
  @HostBinding('attr.role') role = 'button';
  @HostBinding('attr.tabindex') tabindex = '0';
  @HostListener('keydown', ['$event'])
  onKeyDown(e: any) {
    if (e.which === 13 || e.which === 32) {
      e.preventDefault();
      e.target.click();
    }
  }
}
