// =============================================================================
// Imports
// =============================================================================
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { TabTitleContentDirective } from './tab-title-content.directive';
import { NgxOvTabService } from './tabs.service';

let identifier = 0;

// =============================================================================
// Config
// =============================================================================
@Component({
  selector: 'ngx-ov-tab',
  template: `
    <div
      [class.hidden]="!isActive"
      [id]="tabId"
      [attr.data-cy]="'tabpanel-' + dataCy"
      [attr.aria-labelledby]="'tab-' + tabId"
      [attr.tabindex]="0"
      role="tabpanel"
    >
      <ng-content> </ng-content>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: []
})

// =============================================================================
// Definition
// =============================================================================
export class NgxOvTabComponent implements OnInit, OnDestroy {
  // -------------------------------------------------------------------------
  // Private variables
  // -------------------------------------------------------------------------
  private _id: string;

  // -------------------------------------------------------------------------
  // Input variables
  // -------------------------------------------------------------------------
  dataCy: string;
  @Input() tabTitle: string;
  @Input() isActive = false;

  @Input() set tabId(id: string) {
    this._id = id;
  }
  get tabId(): string {
    return this._id ? this._id : this.identifier;
  }

  @Output() tabActive: EventEmitter<string> = new EventEmitter<string>();

  public identifier = `panel-${(identifier += 1)}`;
  @ContentChild(TabTitleContentDirective)
  titleContent: TabTitleContentDirective;

  constructor(
    private elementRef: ElementRef,
    private tabService: NgxOvTabService
  ) {}

  ngOnInit() {
    this.dataCy = this.elementRef.nativeElement.getAttribute('data-cy');
    this.tabService.register(this);
  }

  ngOnDestroy() {
    this.tabService.unregister(this.tabId);
  }

  setActive() {
    this.isActive = true;
    if (this.tabId) {
      this.tabActive.emit(this.tabId);
    }
  }

  setInActive() {
    this.isActive = false;
  }
}
