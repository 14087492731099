import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { CdkPortal } from '@angular/cdk/portal';

@Directive({
    selector: '[ngxOvHeaderPortalContent]ng-template',
    standalone: true
})
export class PortalHeaderContentDirective extends CdkPortal {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('ngxOvHeaderPortalContentPosition') position = 'right';
  // public position: string = 'right';

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(
    templateRef: TemplateRef<any>,
    viewContainerRef: ViewContainerRef
  ) {
    super(templateRef, viewContainerRef);
  }
}
