import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxOv404Component } from '../../../../projects/ngx-ov-ui/src/lib/fundamentals/404/404.component';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    standalone: true,
    imports: [NgxOv404Component]
})
export class PageNotFoundComponent {
  public hyperlinks = ['components', 'forms', 'search', 'upload'];

  constructor(private router: Router) {}

  goto(link: string) {
    this.router.navigate([link]);
  }
}
