import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LinkService } from '../../../services/link.service';
import { ClickEqualsEnterDirective } from '../../../directives/click-equals-enter';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-spotlight',
  templateUrl: './spotlight.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ClickEqualsEnterDirective]
})
export class NgxOvSpotlightComponent {
  // -------------------------------------------------------------------------
  // Input variables
  // -------------------------------------------------------------------------
  @Input() classes: string;
  @Input() title: string;
  @Input() hyperlink: string;
  @Input() text: string;
  @Input() content: boolean;

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  constructor(private linkService: LinkService) {}

  onClick() {
    this.linkService.navigate(this.hyperlink);
  }
}
