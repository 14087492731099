import { Component, OnInit } from '@angular/core';
import {
  Validators,
  UntypedFormBuilder,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import {
  OvStepperNextDirective,
  OvStepperPreviousDirective
} from '../../../../projects/ngx-ov-ui/src/lib/components/forms/ov-stepper/ov-stepper-navigations';
import { NgxOvButtonComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/button/button.component';
import { NgxOvInputFieldComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/forms/input-field/input-field.component';
import { NgxOvStepComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/forms/ov-stepper/ov-step.component';
import { NgxOvStepperComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/forms/ov-stepper/ov-stepper.component';

@Component({
  selector: 'app-page-ov-stepper-vert',
  templateUrl: './page-ov-stepper-vert.component.html',
  standalone: true,
  imports: [
    NgxOvStepperComponent,
    NgxOvStepComponent,
    FormsModule,
    ReactiveFormsModule,
    NgxOvInputFieldComponent,
    NgxOvButtonComponent,
    OvStepperNextDirective,
    OvStepperPreviousDirective
  ]
})
export class PageOvStepperVertComponent implements OnInit {
  firstStepFormGroup;
  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.firstStepFormGroup = this.formBuilder.group({
      gerechtCtrl: ['', Validators.required]
    });
  }
}
