import { Component, Input } from '@angular/core';
import { UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxOvSelectComponent } from '../../../../../../projects/ngx-ov-ui/src/lib/components/forms/select/select.component';

// =============================================================================
// Config
// =============================================================================
@Component({
    selector: 'app-stepper-form-kies-gerecht',
    templateUrl: './kies-gerecht.component.html',
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgxOvSelectComponent]
})

// =============================================================================
// Definition
// =============================================================================
export class StepperFormKiesGerechtComponent {
  @Input() gerecht: UntypedFormGroup;

  gerechten = ['Balletjes in tomatensaus', 'Stoofvlees', 'Vol-au-vent', 'Zalm'];
}
