<div class="c-control-panel">
  <div class="c-control-panel__header">
    <div [innerHTML]="titlePanelSearch"></div>
    <span (click)="filterCancel.emit()">
      <i
        class="c-control-panel__close-icon fas fa-times"
        aria-hidden="true"
        [title]="closePanelIconTitle"
      ></i>
      <span class="sr-only">{{ closePanelIconTitle }}</span>
    </span>
  </div>
  <div class="c-control-panel__filters">
    <ng-content></ng-content>
  </div>
  <div class="c-control-panel__footer">
    @if (showFooterButtons) {
      <div class="mb-4">
        <ngx-ov-button
          [isBlocked]="true"
          (buttonClick)="filterClick.emit()"
          [label]="labelCta"
        ></ngx-ov-button>
      </div>
      <div>
        <ngx-ov-button
          [isBlocked]="true"
          (buttonClick)="filterClear.emit()"
          [isTertiary]="true"
          [label]="labelClear"
        ></ngx-ov-button>
      </div>
    }
    <ng-template [cdkPortalOutlet]="buttonContainer"></ng-template>
  </div>
</div>
