import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PagePlaygroundComponent } from './pages/page-playground/page-playground.component';
import { PageSearchComponent } from './pages/page-search/page-search.component';
import { PageDataTableReadonlyComponent } from './pages/page-data-table-readonly/page-data-table-readonly.component';
import { PageFormsComponent } from './pages/page-forms/page-forms.component';
import { PageLandingpageComponent } from './pages/page-landingpage/page-landingpage.component';
import { PageOverviewComponent } from './pages/page-overview/page-overview.component';
import { PageSmallComponent } from './pages/page-small/page-small.component';
import { PageSpacingComponent } from './pages/page-spacing/page-spacing.component';
import { PageUploadComponent } from './pages/page-upload/page-upload.component';
import { PageUtilsComponent } from './pages/page-utils/page-utils.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PageProfileComponent } from './pages/page-profile/page-profile.component';
import { PageErrorComponent } from './pages/page-error/page-error.component';
import { PageAgendaComponent } from './pages/page-agenda/page-agenda.component';
import { PageStepperFormComponent } from './pages/page-stepper-form/page-stepper-form.component';
import { PageSurveyComponent } from './pages/page-survey/page-survey.component';
import { PageCmsComponent } from './pages/page-cms/page-cms.component';
import { PageWizardComponent } from './pages/page-wizard/page-wizard.component';
import { PageOvStepperVertComponent } from './pages/page-ov-stepper-vert/page-ov-stepper-vert.component';
import { PageLoggingComponent } from './pages/page-logging/page-logging.component';
import { PageDocumentViewerComponent } from './pages/page-document-viewer/page-document-viewer.component';
import { PageDataTableEditableComponent } from './pages/page-data-table-editable/page-data-table-editable.component';
import { PageMeldingenComponent } from './pages/page-meldingen/page-meldingen.component';
import { PageTranslateComponent } from './pages/page-translate/page-translate.component';
import { PageUploadPapyrusComponent } from './pages/page-upload-papyrus/page-upload-papyrus.component';
import { NgxOvWisselOmgevingComponent } from '../../projects/ngx-ov-ui/src/lib/components/migratie/wissel-omgeving/ngx-ov-wissel-omgeving.component';
import { PageStoreComponent } from './pages/page-store/page-store.component';
import { PageContentBlokkenComponent } from './pages/page-content-blokken/page-content-blokken.component';
import { PageBevestigingComponent } from './pages/page-bevestiging/page-bevestiging.component';
import { PageUiComponent } from './pages/page-ui/page-ui.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'landingpage',
    pathMatch: 'full'
  },
  {
    path: 'components',
    redirectTo: 'components/overview',
    pathMatch: 'full'
  },
  {
    path: 'components/overview',
    component: PageOverviewComponent
  },
  {
    path: 'forms',
    component: PageFormsComponent,
    data: {
      hideLeftNav: false
    }
  },
  {
    path: 'profile',
    component: PageProfileComponent
  },
  {
    path: 'landingpage',
    component: PageLandingpageComponent,
    data: {
      hideLeftNav: true
    }
  },
  {
    path: 'logging',
    component: PageLoggingComponent,
    data: {
      hideLeftNav: true
    }
  },
  {
    path: 'upload/default',
    component: PageUploadComponent,
    data: {
      hideLeftNav: false
    }
  },
  {
    path: 'upload/papyrus',
    component: PageUploadPapyrusComponent,
    data: {
      hideLeftNav: false
    }
  },
  {
    path: 'search',
    component: PageSearchComponent,
    data: {
      hideLeftNav: false
    }
  },
  {
    path: 'contentblokken',
    component: PageContentBlokkenComponent,
    data: {
      hideLeftNav: false
    }
  },
  {
    path: 'stepper',
    redirectTo: 'stepper/mat-stepper',
    pathMatch: 'full'
  },
  {
    path: 'stepper/mat-stepper',
    component: PageStepperFormComponent
  },
  {
    path: 'stepper/ov-stepper-vert',
    component: PageOvStepperVertComponent
  },
  {
    path: 'wizard',
    component: PageWizardComponent,
    data: {
      hideLeftNav: false
    }
  },
  {
    path: 'survey',
    component: PageSurveyComponent,
    data: {
      hideLeftNav: false
    }
  },
  {
    path: 'playground',
    component: PagePlaygroundComponent,
    data: {
      hideLeftNav: false
    }
  },
  {
    path: 'agenda',
    component: PageAgendaComponent,
    data: {
      hideLeftNav: false
    }
  },
  {
    path: 'common',
    redirectTo: 'common/utils',
    pathMatch: 'full'
  },
  {
    path: 'common/small',
    component: PageSmallComponent
  },
  {
    path: 'datatable/readonly',
    component: PageDataTableReadonlyComponent
  },
  {
    path: 'datatable/editable',
    component: PageDataTableEditableComponent
  },
  {
    path: 'common/utils',
    component: PageUtilsComponent
  },
  {
    path: 'common/spacing',
    component: PageSpacingComponent
  },
  {
    path: 'error',
    component: PageErrorComponent,
    data: {
      hideLeftNav: true
    }
  },
  {
    path: 'cms',
    component: PageCmsComponent,
    data: {
      hideLeftNav: true
    }
  },
  {
    path: 'document-viewer',
    component: PageDocumentViewerComponent
  },
  {
    path: 'meldingen',
    component: PageMeldingenComponent
  },
  {
    path: 'translate',
    component: PageTranslateComponent
  },
  {
    path: 'wissel-omgeving',
    component: NgxOvWisselOmgevingComponent
  },
  {
    path: 'store',
    component: PageStoreComponent
  },
  {
    path: 'bevestiging',
    component: PageBevestigingComponent,
    data: { uiElement: 'PAGE_BEVESTIGING' }
  },
  {
    path: 'ui',
    component: PageUiComponent,
    data: { uiElement: 'PAGE_UI' }
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: {
      hideLeftNav: true
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot([...routes])],
  exports: []
})
export class AppRoutingModule {}
