import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Signal,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { AsyncPipe, CommonModule } from '@angular/common';
import { NgxOvUiService } from './ui.service';
import { NgxOvLoaderComponent } from '../../components/default-layout-blokken/loader/loader.component';
import { NgxOvAlertComponent } from '../../components/meldingen/alert/alert.component';
import { NgxOvComponentBaseClass } from '../../components/ngx-ov-component-base-class';
import { TranslationsService } from '../../services/translations.service';
import { LoadingHostDirective } from '../store/componenten/loading/store-loading.component';

@Component({
  selector: 'ngx-ov-ui-isloading',
  templateUrl: './ui-loading.component.html',
  providers: [],
  standalone: true,
  imports: [
    CommonModule,
    LoadingHostDirective,
    NgxOvLoaderComponent,
    NgxOvAlertComponent,
    AsyncPipe
  ]
})
export class NgxOvUiIsLoadingComponent
  extends NgxOvComponentBaseClass
  implements OnInit, OnDestroy
{
  @Input() showError = true;
  @Input() loadingMessage: string;
  @Input() errorLoadingMessage: string;
  @Input() alertClasses: string;
  @Input() loaderClasses: string;
  @ViewChild(LoadingHostDirective, { static: true })
  loadingHost: LoadingHostDirective;

  // -------------------------------------------------------------------------
  // Text copy variables
  // -------------------------------------------------------------------------
  public loadingCopy;
  public errorLoadingCopy;

  public destroyed$: Subject<boolean> = new Subject<boolean>();

  uiIsLoading: Signal<boolean>;

  constructor(
    public uiService: NgxOvUiService,
    private viewContainerRef: ViewContainerRef,
    public translationsService: TranslationsService,
    @Optional() public translateService: TranslateService
  ) {
    super(translationsService, translateService);
  }

  ngOnInit() {
    this.uiIsLoading = this.uiService.uiIsLoading;
    this.setLabels();
    if (this.translateService) {
      this.translateService.onLangChange
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.setLabels();
        });
    }
  }

  // -------------------------------------------------------------------------
  // Translation
  // -------------------------------------------------------------------------
  setLabels() {
    const prefix = 'FUNDAMENTALS.STORE';
    this.loadingCopy =
      this.loadingMessage ?? this.getTranslation(prefix, 'LOADING');
    this.errorLoadingCopy =
      this.errorLoadingMessage ?? this.getTranslation(prefix, 'ERROR_API_GET');
  }
}
