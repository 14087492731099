import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges
} from '@angular/core';
import { NgxOvComponentBaseClass } from '../../ngx-ov-component-base-class';
import { TranslationsService } from '../../../services/translations.service';
import { TranslateService } from '@ngx-translate/core';
import { ClickEqualsEnterDirective } from '../../../directives/click-equals-enter';
import { NgClass, NgStyle } from '@angular/common';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-arrow-navigation',
  templateUrl: './arrow-navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgStyle, NgClass, ClickEqualsEnterDirective]
})
export class NgxOvArrowNavigationComponent
  extends NgxOvComponentBaseClass
  implements OnInit, OnChanges
{
  // -------------------------------------------------------------------------
  // Public variables
  // -------------------------------------------------------------------------
  public iconNextTitle;
  public iconPreviousTitle;
  // -------------------------------------------------------------------------
  // Input variables
  // -------------------------------------------------------------------------
  @Input() title: string;
  @Input() items: string[];
  @Input() selectedIndex = 0;
  @Input() titleItemPlaceholder = 'CURRENT_ITEM';
  @Input() previousItemPlaceholder;
  @Input() nextItemPlaceholder;

  @Output() next: EventEmitter<any> = new EventEmitter();
  @Output() previous: EventEmitter<any> = new EventEmitter();

  previousItem: string;
  nextItem: string;

  _isFirstItem: boolean;
  _isLastItem: boolean;

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  constructor(
    public translationsService: TranslationsService,
    @Optional() public translateService: TranslateService
  ) {
    super(translationsService, translateService);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedIndex || changes.items) {
      this._isFirstItem = this.isFirstItem();
      this._isLastItem = this.isLastItem();
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setItems();
  }

  setItems(previousIndex?: number) {
    this._isFirstItem = this.isFirstItem();
    this._isLastItem = this.isLastItem();

    if (!this._isFirstItem) {
      this.previousItem =
        this.previousItemPlaceholder || this.items[this.selectedIndex - 1];
    } else {
      this.previousItem = undefined;
    }

    if (!this._isLastItem) {
      this.nextItem =
        this.nextItemPlaceholder || this.items[this.selectedIndex + 1];
    } else {
      this.nextItem = undefined;
    }

    if (previousIndex >= 0) {
      this.title = this.title.replace(
        this.items[previousIndex],
        this.items[this.selectedIndex]
      );
    } else {
      this.title = this.title.replace(
        this.titleItemPlaceholder,
        this.items[this.selectedIndex]
      );
    }
  }

  private isFirstItem(): boolean {
    return this.selectedIndex === 0;
  }

  private isLastItem(): boolean {
    return this.selectedIndex === this.items.length - 1;
  }

  onClickPrevious(): void {
    if (!this.isFirstItem()) {
      this.selectedIndex -= 1;
      this.setItems(this.selectedIndex + 1);

      this.previous.emit();
    }
    this._isFirstItem = this.isFirstItem();
    this._isLastItem = this.isLastItem();
  }

  onClickNext(): void {
    if (!this.isLastItem()) {
      this.selectedIndex += 1;
      this.setItems(this.selectedIndex - 1);

      this.next.emit();
    }
    this._isLastItem = this.isLastItem();
    this._isFirstItem = this.isFirstItem();
  }

  setLabels(): void {
    this.iconNextTitle = this.getTranslation(
      'NAVIGATION.ARROW_NAVIGATION',
      'NEXT'
    );
    this.iconPreviousTitle = this.getTranslation(
      'NAVIGATION.ARROW_NAVIGATION',
      'PREVIOUS'
    );
  }
}
