@if (toonCookieBar) {
  <div class="c-cookiebar">
    <div class="c-cookiebar__overlay"></div>
    <div class="c-cookiebar__container">
      <div class="container">
        <div class="c-cookiebar__language">
          @for (language of languages; track trackByFn) {
            <div
              [attr.data-cy]="'cookiebar-language-code-' + language.code"
              [class.c-cookiebar__language--active]="language.active"
              (click)="onSetLanguage(language)"
            >
              {{ language.code }}
            </div>
          }
        </div>
        <div class="c-cookiebar__title">
          {{ nameApplication }} {{ titleLabel }}
        </div>
        <div class="c-cookiebar__text" [innerHTML]="textLabel"></div>
        <div class="c-cookiebar__action">
          <ngx-ov-button
            (buttonClick)="acceptAllCookies()"
            [label]="acceptAllButtonLabel"
            [attr.data-cy]="'button-cookiebar-action-accept-all'"
          ></ngx-ov-button>

          <ngx-ov-button
            (buttonClick)="acceptOnlyMandatoryCookies()"
            [isSecondary]="true"
            [label]="acceptMandatoryButtonLabel"
            [attr.data-cy]="'button-cookiebar-action-accept-onlymandatory'"
          ></ngx-ov-button>
        </div>
        <div
          class="c-cookiebar__cookiesvoorkeuren"
          [attr.data-cy]="'link-cookiebar-cookiesvoorkeuren'"
          (click)="isCollapsed = !isCollapsed"
          [attr.aria-expanded]="!isCollapsed"
        >
          @if (isCollapsed) {
            <i
              class="fas fa-chevron-up"
              aria-hidden="true"
              [title]="iconCookiebarClosedTitle"
            ></i>
          } @else {
            <i
              class="fas fa-chevron-down"
              aria-hidden="true"
              [title]="iconCookiebarOpenTitle"
            ></i>
          }

          <span class="sr-only">{{
            isCollapsed ? iconCookiebarClosedTitle : iconCookiebarOpenTitle
          }}</span>
          <span>{{ subTitleLabel }}</span>
        </div>

        @if (isCollapsed) {
          <div class="c-cookiebar__collapsed-content">
            @for (cookie of cookies; let i = $index; track trackByFn) {
              <div class="c-cookiebar__checkboxes">
                <ngx-ov-checkbox
                  [attr.data-cy]="cookie.code"
                  [label]="cookie.title"
                  [isDisabled]="cookie.disabled"
                  [(ngModel)]="cookie.active"
                ></ngx-ov-checkbox>
                <div class="c-cookiebar__extra-info">
                  {{ cookie.subTitle }}
                </div>
              </div>
            }

            <ngx-ov-button
              [attr.data-cy]="'button-cookiebar-save-preferences'"
              (buttonClick)="onAcceptCookies()"
              [label]="saveButtonLabel"
            ></ngx-ov-button>
          </div>
        }
      </div>
    </div>
  </div>
}
