import { Injectable, Optional } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { componentTranslationsNl } from '../models/translations/component.translations.nl';
import { componentTranslationsFr } from '../models/translations/component.translations.fr';
import { componentTranslationsEN } from '../models/translations/component.translations.en';

/**
 * TODO: translations should be loaded from a component translation file
 */

@Injectable({
  providedIn: 'root'
})
export class TranslationsService {
  nameApplication: string;
  private defaultLanguage = 'NL';
  private componentTranslations: { [key: string]: any } = {
    NL: componentTranslationsNl,
    FR: componentTranslationsFr,
    EN: componentTranslationsEN
  };

  constructor(@Optional() private translateService: TranslateService) {}

  get currentLanguage() {
    if (this.translateService) {
      return this.translateService.currentLang;
    } else {
      return 'NL';
    }
  }

  /**
   * Geeft vertaling voor een gegeven component, key en taal.
   * @param key Vertaal sleutel.
   * @param language Taal van vertaling, indien niet aanwezig Nederlands.
   */
  getDefaultComponentTranslation(
    key: string,
    language: string = this.currentLanguage
  ) {
    const keys = key.split('.');
    const translationObject = this.getTranslationObject(language);
    let value: any;

    if (!translationObject) {
      return null;
    }
    value = translationObject[keys[0]];
    if (value) {
      for (let i = 1; i < keys.length; i++) {
        value = value[keys[i]];
      }
    }

    return value ?? null;
  }

  private getTranslationObject(language: string = 'NL'): any {
    return (
      this.componentTranslations[language.toUpperCase()] ??
      this.componentTranslations[this.defaultLanguage]
    );
  }
}
