import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  Input
} from '@angular/core';
import { LinkService } from '../../../services/link.service';
// eslint-disable-next-line import/no-cycle
import { NgxOvNewsTeaserListComponent } from './news-teaser-list.component';
import { ClickEqualsEnterDirective } from '../../../directives/click-equals-enter';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-news-teaser',
  templateUrl: './news-teaser.html',
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [ClickEqualsEnterDirective]
})
export class NgxOvNewsTeaserComponent {
  // -------------------------------------------------------------------------
  // Variables
  // -------------------------------------------------------------------------
  @Input() label: string;
  @Input() title: string;
  @Input() hyperlink: string;
  @Input() image: string;
  @Input() type: string;

  @Input() picturePortrait = false;
  @Input() backgroundSize = '28.15rem auto'; // 28.15rem auto or auto 14.75rem

  @HostBinding('style.width')
  get width() {
    const numberItems =
      this.parent && this.parent.newsItems ? this.parent.newsItems.length : 1;
    const percentage = 100 / numberItems;
    return `${percentage}%`;
  }

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  constructor(
    private linkService: LinkService,
    @Inject(NgxOvNewsTeaserListComponent)
    private parent: NgxOvNewsTeaserListComponent
  ) {}

  onClick() {
    this.linkService.navigate(this.hyperlink);
  }
}
