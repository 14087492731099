import { Component } from '@angular/core';
import { NgxOvButtonComponent } from '../../../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/button/button.component';
import { NgxOvInputFieldComponent } from '../../../../../../projects/ngx-ov-ui/src/lib/components/forms/input-field/input-field.component';
import { NgxOvAlertComponent } from '../../../../../../projects/ngx-ov-ui/src/lib/components/meldingen/alert/alert.component';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'profile-contact-form',
  templateUrl: './profile-contact-form.component.html',
  standalone: true,
  imports: [
    FormsModule,
    NgxOvAlertComponent,
    NgxOvInputFieldComponent,
    NgxOvButtonComponent
  ]
})
export class ProfileContactFormComponent {
  public contact = {
    email: null,
    gsm: null,
    telefoon: null
  };

  patterns = {
    N: { pattern: new RegExp('[N]') },
    0: {
      pattern: new RegExp('[0-9]')
    }
  };
}
