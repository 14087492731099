import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { NgxOvModalComponent } from '../modal/modal.component';
import { ClickEqualsEnterDirective } from '../../../directives/click-equals-enter';
import { NgTemplateOutlet } from '@angular/common';
import { TippyDirective } from '@ngneat/helipopper';

// =============================================================================
// Component
@Component({
  selector: 'ngx-ov-tooltip',
  templateUrl: './tooltip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ClickEqualsEnterDirective,
    NgTemplateOutlet,
    NgxOvModalComponent,
    TippyDirective
  ]
})
export class NgxOvTooltipComponent {
  // -------------------------------------------------------------------------
  // private variables
  // -------------------------------------------------------------------------
  private _icon;
  private _label;
  private _position = 'left';
  // eslint-disable-next-line @angular-eslint/no-output-native
  private _triggerType: 'mouseenter' | 'click' | 'manual' = 'mouseenter';
  private _small = false;
  private _maxWidth;

  isPopupOpen = false;
  isOpen = false;

  // -------------------------------------------------------------------------
  // Input variables
  // -------------------------------------------------------------------------
  @Input() tooltip: string;
  @Input() closeModalText = 'Sluiten';

  @Input() set icon(value: string) {
    this._icon = value;
  }

  get icon(): string {
    if (this.label) {
      return null;
    }
    return this._icon ? this._icon : 'fas fa-info-circle';
  }

  @Input() set label(value: string) {
    this._label = value;
  }

  get label(): string {
    return this._label;
  }

  @Input() set position(value: string) {
    this._position = value;
  }

  get position(): string {
    return this._position.toString().toLowerCase();
  }

  @Input() set triggerType(value: any) {
    this._triggerType = value;
  }

  get triggerType(): any {
    return this._triggerType;
  }

  @Input() set small(value: boolean) {
    this._small = value;
  }

  get small(): boolean {
    return this._small;
  }

  @Input() set maxWidth(value: number) {
    this._maxWidth = value;
  }

  get maxWidth(): number {
    return this._maxWidth;
  }

  @Output()
  tooltipClicked: EventEmitter<any> = new EventEmitter();

  @ViewChild('tooltipContent', { static: false }) tooltipContent: ElementRef;
  @ViewChild('tooltipPlaceholder', { static: false })
  tooltipPlaceholder: ElementRef;

  constructor(private eRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  @HostListener('document:touchstart', ['$event'])
  onClickBuitenComponent(event: any) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  onClickTooltip() {
    if (this.tooltipClicked?.observed) {
      this.tooltipClicked.emit();
    }
  }
}
