// =============================================================================
// Config
// =============================================================================
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgxOvToggleButtonComponent } from '../../../../../../projects/ngx-ov-ui/src/lib/components/forms/toggle-button/toggle-button.component';
import { NgxOvToggleButtonGroupComponent } from '../../../../../../projects/ngx-ov-ui/src/lib/components/forms/toggle-button/toggle-button-group.component';

@Component({
  selector: 'app-stepper-form-kies-levermethode',
  templateUrl: './kies-levermethode.component.html',
  standalone: true,
  imports: [NgxOvToggleButtonGroupComponent, NgxOvToggleButtonComponent]
})

// =============================================================================
// Definition
// =============================================================================
export class StepperFormKiesLevermethodeComponent {
  @Input() levering: UntypedFormGroup;
  @Output() levermethodeGekozen: EventEmitter<any> = new EventEmitter<any>();

  opties = [
    {
      label: 'Afhalen',
      code: 'AFHALEN',
      icon: 'fas fa-people-carry'
    },
    {
      label: 'Leveren',
      code: 'LEVEREN',
      icon: 'fas fa-truck'
    }
  ];

  onToggleButtonClicked(optie: any) {
    this.levermethodeGekozen.emit(optie);
  }
}
