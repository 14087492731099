<div class="c-panel">
  <div class="c-panel__title">
    <div class="c-panel__title-left">
      {{ title }}

      @if (subTitle) {
        <span class="c-panel__sub-title">
          {{ subTitle }}
        </span>
      }
    </div>
    <div class="c-panel__title-right">
      <ng-template [cdkPortalOutlet]="actionsPortal"></ng-template>
    </div>
  </div>

  <div class="c-panel__content">
    <ng-content></ng-content>
  </div>
</div>
