import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { NgxOvComponentBaseClass } from '../../ngx-ov-component-base-class';
import { TranslationsService } from '../../../services/translations.service';
import { TranslateService } from '@ngx-translate/core';
import { StopPropagationDirective } from '../../../directives/stop-propagation';
import { NgClass } from '@angular/common';

export enum FabButtonPosition {
  BottomStart = 'bottom-start',
  BottomCenter = 'bottom-center',
  BottomEnd = 'bottom-end'
}

export enum ButtonTypeEnum {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Text = 'text'
}

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-button',
  templateUrl: './button.html',
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [NgClass, StopPropagationDirective]
})
export class NgxOvButtonComponent
  extends NgxOvComponentBaseClass
  implements OnInit, OnChanges
{
  // -------------------------------------------------------------------------
  // private variables
  // -------------------------------------------------------------------------
  private _label = '';

  private _isSecondary = false;
  private _isTertiary = false;
  private _isText = false;

  private _iconBefore = '';
  private _iconAfter = '';
  private _iconOnly = '';
  private _iconLoading = 'fas fa-spinner'; // fontawesome: free spinner / circle-notch

  private _isBlocked = false;

  private _isWide = false;
  private _isNarrow = false;

  private _floatingActionButtonPosition: FabButtonPosition;

  private _isDisabled = false;
  private _isLoading = false;
  private _isError = false;

  private _stopPropagation = false;
  private _preventMultipleClick = false;
  private _clickTimeout = 1000;

  private _clickTimeOutInternal = null;
  _clicked = false;
  _shouldNotFireClickEvent;
  _buttonType: ButtonTypeEnum = ButtonTypeEnum.Primary;
  buttonTypeEnum = ButtonTypeEnum;
  // -------------------------------------------------------------------------
  // public variables
  // -------------------------------------------------------------------------
  public iconLoadingTitle: string;

  /**
   * Custom CSS classes to add to button.
   * @param {string}
   */
  @Input() classes;

  // -------------------------------------------------------------------------
  // Input variables/Setters
  // -------------------------------------------------------------------------

  @Input() set label(label: string) {
    this._label = label;
  }

  @Input() set isSecondary(isSecondary: boolean) {
    this._isSecondary = isSecondary;
  }

  @Input() set isTertiary(isTertiary: boolean) {
    this._isTertiary = isTertiary;
  }

  @Input() set isText(isText: boolean) {
    this._isText = isText;
  }

  @Input() set iconBefore(iconBefore: string) {
    this._iconBefore = iconBefore;
  }

  @Input() set iconAfter(iconAfter: string) {
    this._iconAfter = iconAfter;
    if (iconAfter) {
      this._iconBefore = '';
    }
  }

  @Input() set iconOnly(iconOnly: string) {
    this._iconOnly = iconOnly;
    if (this.iconOnly) {
      this._iconAfter = '';
      this._iconBefore = '';
    }
  }

  @Input() set iconLoading(iconLoading: string) {
    this._iconLoading =
      iconLoading && iconLoading.length ? iconLoading : 'fas fa-spinner';
  }

  /**
   * Laat de knop de volledige breedte innemen van beschikbare ruimte.
   * Niet toepassen samen met isWide of isNarrow property.
   */
  @Input() set isBlocked(isBlocked: boolean) {
    this._isBlocked = isBlocked;
  }

  /**
   * Voegt meer padding toe aan de knop om meer ruimte rondom tekst te voorzien.
   * Niet toepassen samen met isBlocked of isNarrow property.
   */
  @Input() set isWide(isWide: boolean) {
    this._isWide = isWide;
  }

  /**
   * @deprecated isNarrow verdwijnt in versie 12.4.0. De knop is te klein en moeilijk klikbaar dus voldoet niet gebruiksvriendelijkheid voorwaarden.
   * Voegt minder padding toe aan de knop om minder ruimte rondom tekst te voorzien.
   * Niet toepassen samen met isBlocked of isWide property.
   */
  @Input() set isNarrow(isNarrow: boolean) {
    this._isNarrow = isNarrow;
  }

  @Input() set floatingActionButtonPosition(position: FabButtonPosition) {
    this._floatingActionButtonPosition = position;
  }

  @Input() set isDisabled(isDisabled: boolean) {
    this._isDisabled = isDisabled;
  }

  @Input() set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  @Input() set isError(isError: boolean) {
    this._isError = isError;
  }

  @Input() set stopPropagation(stopPropagation: boolean) {
    this._stopPropagation = stopPropagation;
  }

  @Input() set preventMultipleClick(preventMultipleClick: boolean) {
    this._preventMultipleClick = preventMultipleClick;
  }

  @Input() set clickTimeout(clickTimeout: number) {
    this._clickTimeout = clickTimeout;
  }

  @Output() buttonClick: EventEmitter<Event> = new EventEmitter<Event>();

  // -------------------------------------------------------------------------
  // Getters
  // -------------------------------------------------------------------------
  get label(): string {
    return this._label;
  }

  get isSecondary(): boolean {
    return this._isSecondary;
  }

  get isTertiary(): boolean {
    return this._isTertiary;
  }

  get isText(): boolean {
    return this._isText;
  }

  get iconBefore(): string {
    return this._iconBefore;
  }

  get iconAfter(): string {
    return this._iconAfter;
  }

  get iconOnly(): string {
    return this._iconOnly;
  }

  get iconLoading(): string {
    return this._iconLoading;
  }

  get isBlocked(): boolean {
    return this._isBlocked;
  }

  get isWide(): boolean {
    return this._isWide;
  }

  get isNarrow(): boolean {
    return this._isNarrow;
  }

  get floatingActionButtonPosition(): FabButtonPosition {
    return this._floatingActionButtonPosition;
  }

  get isDisabled(): boolean {
    return this._isDisabled;
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  get isError(): boolean {
    return this._isError;
  }

  get stopPropagation(): boolean {
    return this._stopPropagation;
  }

  get preventMultipleClick(): boolean {
    return this._preventMultipleClick;
  }

  get clickTimeout(): number {
    return this._clickTimeout;
  }

  constructor(
    private renderer: Renderer2,
    private hostElement: ElementRef,
    public translationsService: TranslationsService,
    @Optional() public translateService: TranslateService
  ) {
    super(translationsService, translateService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isDisabled || changes.isLoading || changes._clicked) {
      this._shouldNotFireClickEvent = this.shouldNotFireClickEvent();
    }

    if (changes.isSecondary || changes.isTertiary || changes.isText) {
      this._buttonType = this.determineButtonType();
    }
  }

  ngOnInit() {
    super.ngOnInit();
    if (this._buttonType === ButtonTypeEnum.Primary) {
      this.renderer.addClass(
        this.hostElement.nativeElement,
        'u-position-btn-prim'
      );
      this.renderer.setAttribute(
        this.hostElement.nativeElement,
        'tabindex',
        '-1'
      );
    }
    if (this._buttonType != ButtonTypeEnum.Primary) {
      this.renderer.addClass(
        this.hostElement.nativeElement,
        'u-position-btn-sec'
      );
      this.renderer.setAttribute(
        this.hostElement.nativeElement,
        'tabindex',
        '-1'
      );
    }
    this.hostElement.nativeElement.setAttribute('tabindex', '-1');
  }

  // -------------------------------------------------------------------------
  // Events
  // -------------------------------------------------------------------------
  @HostListener('click') onClick() {
    this.preventDoubleClick();
  }

  @HostListener('keydown', ['$event'])
  public onKeyDown(keyboardEvent: KeyboardEvent): void {
    const blockedKeyCodes = ['Enter', ' '];
    if (this.isDisabled && blockedKeyCodes.includes(keyboardEvent.key)) {
      keyboardEvent.preventDefault();
      keyboardEvent.stopImmediatePropagation();
    }
  }

  onClicked(e: Event) {
    // Stop propagation when variable is true
    if (this.stopPropagation) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  }

  shouldNotFireClickEvent(): boolean {
    return this._isDisabled || this._isLoading || this._clicked;
  }

  preventDoubleClick() {
    this._clicked = true;
    this._clickTimeOutInternal = setTimeout(() => {
      this._clicked = false;
    }, this.clickTimeout);
  }

  onButtonClick(e: Event) {
    if (this.isDisabled || this.isLoading) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      this.buttonClick.emit(e);
    }
  }

  setLabels(): void {
    this.iconLoadingTitle = this.getTranslation(
      'DEFAULT_LAYOUT_BLOKKEN.BUTTON',
      'LOADING'
    );
  }

  determineButtonType = (): ButtonTypeEnum => {
    if (this.isSecondary) {
      return ButtonTypeEnum.Secondary;
    }
    if (this.isTertiary) {
      return ButtonTypeEnum.Tertiary;
    }
    if (this.isText) {
      return ButtonTypeEnum.Text;
    }
    return ButtonTypeEnum.Primary;
  };
}
