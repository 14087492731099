import { Component, Input } from '@angular/core';
import { UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxOvRadioGroupComponent } from '../../../../../../projects/ngx-ov-ui/src/lib/components/forms/radio/radio-group/radio-group.component';

// =============================================================================
// Config
// =============================================================================
@Component({
    selector: 'app-stepper-form-kies-extra',
    templateUrl: './kies-extra.component.html',
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgxOvRadioGroupComponent]
})

// =============================================================================
// Definition
// =============================================================================
export class StepperFormKiesExtraComponent {
  @Input() extra: UntypedFormGroup;

  sauzen = [
    {
      id: 1,
      naam: 'Mayonaise'
    },
    {
      id: 2,
      naam: 'Tomatenketchup'
    },
    {
      id: 3,
      naam: 'Curry ketchup'
    },
    {
      id: 4,
      naam: 'Samoerai'
    },
    {
      id: 5,
      naam: 'Mammoet'
    },
    {
      id: 6,
      naam: 'Joppie'
    },
    {
      id: 7,
      naam: 'Tartaar'
    }
  ];
}
