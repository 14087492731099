import { Pipe, PipeTransform } from '@angular/core';

import * as R from 'ramda';

import { INgxOvDataTableColumn } from '../data-table.utils';

@Pipe({
    name: 'filterDataTable', pure: false,
    standalone: true
})
export class FilterDataTablePipe implements PipeTransform {
  transform(data: any[], filterBy: INgxOvDataTableColumn[]): any[] {
    return data.filter((row) => {
      let found = true;
      filterBy.forEach((filter) => {
        if (!filter.filter || !filter.filter.length) {
        } else {
          const rowValue = R.path(filter.name.split('.'), row);
          if (!rowValue) found = false;
          if (
            typeof rowValue === 'string' &&
            filter.filter &&
            (<string>rowValue)
              .toLowerCase()
              .search(filter.filter.toLowerCase()) === -1
          ) {
            found = false;
          }
          if (
            typeof rowValue === 'number' &&
            filter.filter &&
            (<number>rowValue)
              .toString()
              .search(filter.filter.toLowerCase()) === -1
          ) {
            found = false;
          }
        }
      });
      return found;
    });
  }
}
