<div class="c-arrow-navigation">
  <div
    class="c-arrow-navigation__previous"
    [ngStyle]="{ cursor: _isFirstItem ? 'default' : 'pointer' }"
  >
    <span
      [ngClass]="{ 'c-arrow-navigation__previous--invisible': _isFirstItem }"
      (click)="onClickPrevious()"
      ><i
        class="fas fa-chevron-left"
        aria-hidden="true"
        [title]="iconPreviousTitle"
      ></i>
      <span class="sr-only">{{ iconPreviousTitle }}</span></span
    >
  </div>
  <div class="c-arrow-navigation__title">
    @if (_isFirstItem) {
      <div
        class="c-arrow-navigation__previous-placeholder-spacer"
        [innerHTML]="nextItem"
      ></div>
    } @else {
      <div
        (click)="onClickPrevious()"
        class="c-arrow-navigation__previous-placeholder"
        [innerHTML]="previousItem"
        tabindex="-1"
      ></div>
    }

    <div class="c-arrow-navigation__title-text" [innerHTML]="title"></div>
    @if (_isLastItem) {
      <div
        class="c-arrow-navigation__next-placeholder-spacer"
        [innerHTML]="previousItem"
      ></div>
    } @else {
      <div
        (click)="onClickNext()"
        class="c-arrow-navigation__next-placeholder"
        [innerHTML]="nextItem"
        tabindex="-1"
      ></div>
    }
  </div>
  <div
    class="c-arrow-navigation__next"
    [ngStyle]="{ cursor: _isLastItem ? 'default' : 'pointer' }"
  >
    <span
      [ngClass]="{ 'c-arrow-navigation__next--invisible': _isLastItem }"
      (click)="onClickNext()"
      ><i
        class="fas fa-chevron-right"
        aria-hidden="true"
        [title]="iconNextTitle"
      ></i>
      <span class="sr-only">{{ iconNextTitle }}</span></span
    >
  </div>
</div>
