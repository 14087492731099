import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NgxOvModalService } from '../../../../projects/ngx-ov-ui/src/lib/components/meldingen/modal/modal.service';
import { AsyncPipe, CommonModule } from '@angular/common';
import { NgxOvAlertComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/meldingen/alert/alert.component';
import { NgxOvGeneric } from '../components/generic/generic.component';
import { NgxOvSelectComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/forms/select/select.component';
import { PortalModalFooterDirective } from '../../../../projects/ngx-ov-ui/src/lib/components/meldingen/modal/modal-footer.directive';
import { NgxOvModalComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/meldingen/modal/modal.component';
import { NgxOvButtonComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/button/button.component';
import { ClickEqualsEnterDirective } from '../../../../projects/ngx-ov-ui/src/lib/directives/click-equals-enter';
import { NgxOvLinkComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/link/link.component';
import { v4 as uuidv4 } from 'uuid';

import { TestComponent } from '../../components/test/test.component';
import { NgxOvSnackbarService } from '../../../../projects/ngx-ov-ui/src/lib/components/meldingen/snackbar/snackbar.service';
import { NgxOvInputFieldComponent } from 'projects/ngx-ov-ui/src/public-api';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
  selector: 'app-page-meldingen',
  templateUrl: './page-meldingen.component.html',
  standalone: true,
  imports: [
    NgxOvLinkComponent,
    ClickEqualsEnterDirective,
    NgxOvButtonComponent,
    NgxOvModalComponent,
    PortalModalFooterDirective,
    NgxOvSelectComponent,
    NgxOvGeneric,
    NgxOvAlertComponent,
    NgxOvInputFieldComponent,
    AsyncPipe,
    ReactiveFormsModule,
    CommonModule
  ]
})
export class PageMeldingenComponent implements OnInit {
  isTransactionalPopupOpen = false;
  isAcknowledgementPopupOpen = false;

  isSquarePopupOpen = false;
  isModalSaving$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  form: FormGroup;

  constructor( 
    private fb: FormBuilder,
    private modalService: NgxOvModalService,
    private snackbarService: NgxOvSnackbarService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      voornaam: [undefined, [Validators.required]],
      achternaam: [undefined, [Validators.required]]
    });
  }

  onOpenModal(id: string) {
    if (id === 'passDataDemo') {
      const randomNumber = uuidv4();
      this.modalService.open(id, randomNumber);
    } else if (id === 'passDataDemo2') {
      this.modalService.open(id, 'Data modal 2');
    } else {
      this.modalService.open(id);
    }
  }
  onCloseModal(id: string) {
    this.modalService.close(id);
  }

  onConfirmTransactionalPopUp() {
    this.isModalSaving$.next(true);
    setTimeout(() => {
      this.isModalSaving$.next(false);

      // eslint-disable-next-line no-console
      console.log('Write closing logic here...');
    }, 3000);
  }

  logToConsole(text) {
    // eslint-disable-next-line no-console
    console.log(text);
  }

  showAlertFormModal(){
    this.onCloseModal('modal-form');
    alert(`Naam = ${this.form.controls.voornaam.value} ${this.form.controls.achternaam.value}`);
  }

  getSelectItems(): string[] {
    return ['A', 'B', 'C', 'D', 'E', 'F'];
  }

  openNoConfigSnackbarWithService(): void {
    this.snackbarService.open('Test title', 'this is an info message', 'INFO');
  }

  openNoConfigSnackbarWithServiceAndNoType(): void {
    this.snackbarService.open(
      'Test title',
      'this is an info message without passing type'
    );
  }

  openSuccessSnackbarWithService(): void {
    this.snackbarService.open(
      'Test title',
      'this is a success message',
      'SUCCESS',
      {
        timeShown: 25,
        isSmall: false,
        showProgressBar: true,
        isCloseable: true
      }
    );
  }

  openWarningSnackbarWithService(): void {
    this.snackbarService.open(
      'Test title 2',
      'this is a warning message 2',
      'WARNING',
      {
        isSmall: false,
        showProgressBar: true,
        isCloseable: true
      }
    );
  }

  openBigSnackbarWithService(): void {
    this.snackbarService.open(
      'Test error title 3',
      'this is an error message 3',
      'ERROR',
      {
        timeShown: 10,
        isSmall: false,
        showProgressBar: true
      },
      TestComponent as any
    );
  }

  openSmallSnackbar() {
    this.snackbarService.open(
      'Info via service',
      'Open small Snackbar',
      'INFO',
      {
        isSmall: true
      }
    );
  }
}
