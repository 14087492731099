import { Injectable } from '@angular/core';
import { NgxOvTabComponent } from './tab.component';

@Injectable({
  providedIn: 'root'
})
export class NgxOvTabService {
  public tabs: NgxOvTabComponent[] = [];

  register(tab: NgxOvTabComponent) {
    this.tabs.push(tab);
  }

  unregister(id: string) {
    this.tabs = this.tabs.filter(
      (x) => x.tabId.toUpperCase() !== id.toUpperCase()
    );
  }

  setActive(id: string) {
    for (let tab of this.tabs) {
      if (tab.tabId === id.toUpperCase()) {
        tab.setActive();
      } else {
        tab.setInActive();
      }
    }
  }
}
