import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
export abstract class BaseClass implements OnDestroy {
  public destroyed$: Subject<boolean> = new Subject<boolean>();

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
  }
}
