import { NgxOvActionType } from './actions.types';
import { NgxOvActionPhase } from './actions.phases';

function createAction(
  actionType: NgxOvActionType,
  action: string,
  phase: NgxOvActionPhase
) {
  return '[' + actionType + ']-[' + action.toUpperCase() + ']-[' + phase + ']';
}

const setProperty = (
  actionType: NgxOvActionType,
  actionPhase: NgxOvActionPhase
) => {
  return (target, key): void => {
    let original = target[key];
    Reflect.deleteProperty(target, key);
    Reflect.defineProperty(target, key, {
      value: createAction(actionType, original, actionPhase),
      writable: true
    });
  };
};

export function loadRequest(): PropertyDecorator {
  return setProperty(NgxOvActionType.Load, NgxOvActionPhase.Request);
}

export function loadSuccess(): PropertyDecorator {
  return setProperty(NgxOvActionType.Load, NgxOvActionPhase.Success);
}

export function loadFailure(): PropertyDecorator {
  return setProperty(NgxOvActionType.Load, NgxOvActionPhase.Failure);
}

export function saveRequest(): PropertyDecorator {
  return setProperty(NgxOvActionType.Save, NgxOvActionPhase.Request);
}

export function saveSuccess(): PropertyDecorator {
  return setProperty(NgxOvActionType.Save, NgxOvActionPhase.Success);
}

export function saveFailure(): PropertyDecorator {
  return setProperty(NgxOvActionType.Save, NgxOvActionPhase.Failure);
}

export function deleteRequest(): PropertyDecorator {
  return setProperty(NgxOvActionType.Delete, NgxOvActionPhase.Request);
}

export function deleteSuccess(): PropertyDecorator {
  return setProperty(NgxOvActionType.Delete, NgxOvActionPhase.Success);
}

export function deleteFailure(): PropertyDecorator {
  return setProperty(NgxOvActionType.Delete, NgxOvActionPhase.Failure);
}
