<div class="c-status-message">
  <div class="c-status-message__content">
    @if (title) {
      <div class="c-status-message__title">{{ title }}</div>
    }

    <div class="c-status-message__message">
      <ng-content></ng-content>
    </div>

    @if (ctaLabel) {
      <div class="c-status-message__cta">
        <ngx-ov-button
          [label]="ctaLabel"
          (buttonClick)="ctaClicked.emit()"
        ></ngx-ov-button>
      </div>
    }
  </div>

  @if (img) {
    <div
      class="c-status-message__image"
      [style.background-image]="'url(' + img + ')'"
    ></div>
  }
</div>
