<div [formGroup]="bestelling">
  <p>Je staat op het punt om volgende bestelling te plaatsen:</p>

  <div>
    <h4>Gerecht</h4>
    <p>{{ geefValueFormControl('gerecht', 'naam') }}</p>
  </div>

  <div>
    <h4>Extra's</h4>
    <p>Saus:</p>
    <p>{{ geefValueFormControl('extra', 'saus')?.naam }}</p>
  </div>

  @if (geefValueFormControl('bon', 'bon')?.bestandsnaam; as code) {
    <div>
      <h4>Kortingsbon</h4>
      <p>Code: {{ code }}</p>
    </div>
  }

  <div>
    <h4>Betaalmethode</h4>
    <p>{{ geefValueFormControl('betaalmethode', 'naam') }}</p>
  </div>

  <div>
    <h4>Totaal bedrag</h4>
    <ngx-ov-input-field
      id="totaalBedrag"
      [attr.data-cy]="'input-totaalBedrag'"
      [label]="'Totaal bedrag'"
      [isReadonly]="true"
      [maskExpr]="'separator.2'"
      [suffix]="' EUR'"
      [placeholder]="'0,00 EUR'"
      [formControlName]="'totaalBedrag'"
    >
    </ngx-ov-input-field>
  </div>
  <div>
    <h4>Accepteer gebruikersvoorwaarden</h4>
    <ngx-ov-checkbox
      id="gebruikersvoorwaarden"
      code="gebruikersvoorwaarden"
      [attr.data-cy]="'checkbox-gebruikersvoorwaarden'"
      [isDisabled]="false"
      [formControlName]="'gebruikersvoorwaarden'"
      [label]="
        'Ik accepteer de <a href=\'https:\/\/www.studietoelagen.be/opvraging-gegevens\' target=\'_blank\'>gebruikersvoorwaarden</a>'
      "
      [extraInfo]="
        'Je kan niet verder gaan zonder de gebruiksvoorwaarden goed te keuren'
      "
      (valueChange)="onChangeGebruikersvoorwaarden($event)"
    ></ngx-ov-checkbox>
  </div>
</div>
