import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList
} from '@angular/core';
import { trackItemByIndex } from '../../../helper';
import { NgxOvTabComponent } from './tab.component';
import { ClickEqualsEnterDirective } from '../../../directives/click-equals-enter';
import { PortalModule } from '@angular/cdk/portal';

let identifier = 0;

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-tabs',
  templateUrl: './tabs.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [ClickEqualsEnterDirective, PortalModule]
})
export class NgxOvTabsComponent implements OnInit, AfterContentInit {
  // -------------------------------------------------------------------------
  // Private variables
  // -------------------------------------------------------------------------
  private _id: string;

  // -------------------------------------------------------------------------
  // Input variables
  // -------------------------------------------------------------------------
  selectedTabId: string;
  @Input() selectedTabIndex: string;
  @Input() text: string;
  @Output()
  tabClicked: EventEmitter<any> = new EventEmitter();
  trackByFn = trackItemByIndex;
  showContent = true;

  @Input() set tabsId(id: string) {
    this._id = id;
  }

  get tabsId(): string {
    return this._id ? this._id : this.identifier;
  }

  public identifier = `tabs-${(identifier += 1)}`;

  @ContentChildren(NgxOvTabComponent) tabs: QueryList<NgxOvTabComponent>;

  ngOnInit() {
    if (this.tabClicked.observed) {
      this.showContent = false;
    }
  }

  // -------------------------------------------------------------------------
  // Events
  // -------------------------------------------------------------------------
  onSelectTab(selectedTab: NgxOvTabComponent) {
    // Put all the tabs to false
    // eslint-disable-next-line no-return-assign
    this.tabs.toArray().forEach((tab) => (tab.isActive = false));
    selectedTab.setActive();
    this.tabClicked.emit(selectedTab.tabId || selectedTab.tabTitle);
    this.selectedTabId = selectedTab.tabId;
  }

  ngAfterContentInit() {
    this.setDefaultActiveTab();
  }

  setDefaultActiveTab() {
    const tabsArray = this.tabs.toArray();
    if (
      Array.isArray(tabsArray) &&
      tabsArray.length &&
      !tabsArray.find((tab) => tab.isActive)
    ) {
      const tabIndex = this.selectedTabIndex || 0;
      tabsArray[tabIndex].setActive();
      this.selectedTabId = tabsArray[tabIndex].tabId;
    }
  }
}
