import { StoreItem } from '@store/items';
import {
  saveFailure,
  saveRequest,
  saveSuccess
} from '../../../../../projects/ngx-ov-ui/src/lib/fundamentals/store/actions/actions.utils';
import { ApiError } from '../../../../../projects/ngx-ov-ui/src/lib/fundamentals/store/store.model';
import {
  showSnackbar,
  snackbarTitle
} from '../../../../../projects/ngx-ov-ui/src/lib/fundamentals/store/decorators/snackbar';

export class LoadMockRequest {
  @saveRequest()
  static readonly type = StoreItem.Mock;

  constructor(
    public success: boolean = true,
    public mockData?: any | any[]
  ) {}
}

export class LoadMockSuccess {
  @saveSuccess()
  static readonly type = StoreItem.Mock;

  constructor(public res: any | any[]) {}
}

@showSnackbar()
@snackbarTitle('Fout tijdens het bewaren')
export class LoadMockFailure {
  @saveFailure()
  static readonly type = StoreItem.Mock;

  constructor(public errorMessage?: string | ApiError) {}
}
