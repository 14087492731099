<div [formGroup]="gerecht">
  <!--[OAM-508] required attribute to trigger required validation on two levels -->
  <ngx-ov-select
    id="gerecht"
    [attr.data-cy]="'select-gerecht'"
    [formControlName]="'naam'"
    [label]="'Kies je gerecht'"
    [items]="gerechten"
    [searchable]="true"
    required="true"
  ></ngx-ov-select>
</div>
