export class NgxOvFlyoutService {
  _open = false;

  open() {
    this._open = true;
  }

  close() {
    this._open = false;
  }

  toggle() {
    this._open = !this._open;
  }
}
