<div class="c-pager">
  @if (withRows) {
    <div>
      <!-- rijen -->
      <ngx-ov-pager-page-size
        [pager]="pager"
        (setPageSize)="setPageSize.emit($event)"
        [pageSizes]="pageSizes"
      ></ngx-ov-pager-page-size>
    </div>
  }

  @if (withInfo) {
    <div>
      <!-- welke items -->
      <ngx-ov-pager-info [pager]="pager"></ngx-ov-pager-info>
    </div>
  }

  <div>
    <!-- rijen -->
    <ngx-ov-pager-navigation
      [detailedNavigation]="withDetailedNavigation"
      [pager]="pager"
      (setPage)="setPage.emit($event)"
    ></ngx-ov-pager-navigation>
  </div>
</div>
