<div class="container my-10">
  <ngx-ov-status-message
    [title]="'Oeps, we zijn tijdelijk onbereikbaar'"
    [ctaLabel]="'Naar homepagina'"
    [img]="'/assets/images/undraw_notify.png'"
  >
    Probeer later nog eens opnieuw. Als dit probleem blijft voorvallen
    contacteer ...

    <ul class="fa-ul u-list-overview">
      @for (hyperlink of hyperlinks; track trackByFn) {
        <li>
          <span class="fa-li"
            ><i class="fas fa-chevron-right" aria-hidden="true"></i></span
          ><a (click)="goto(hyperlink)">{{ hyperlink }} </a>
        </li>
      }
      <li></li>
    </ul>
  </ngx-ov-status-message>
</div>
