<div class="container mt-small">
  {{ uiService.uiElementsAvailable() }}
  <ngx-ov-store-isloading
    [uiContext]="{ dossierId: 1 }"
    loaderClasses="m-large"
  >
    <ngx-ov-card [title]="'Demo UI elementen'" [isSecondary]="true">
      <form [formGroup]="form">
        Demo voor het opbouwen van scherm elementen met een UI API.
        <div class="grid grid-cols-1 gap-medium">
          <ngx-ov-select
            [items]="select1Items"
            [bindValue]="'code'"
            [bindLabel]="'omschrijving'"
            (ngModelChange)="changeSelect1($event)"
            formControlName="select1"
          >
          </ngx-ov-select>

          <ngx-ov-select
            [items]="landen$ | async"
            [bindValue]="'landcode'"
            [bindLabel]="'landnaam'"
            (ngModelChange)="changeSelect2($event)"
            formControlName="select2"
          >
          </ngx-ov-select>
        </div>
      </form>

      <ng-template ngxOvCardFooter>
        <div class="invisible"></div>
        <div class="text-right u-position-btn-prim-sec">
          <ngx-ov-button
            [uiIsVisible]="'BTN_ANNULEER'"
            [label]="'Annuleer'"
            [isSecondary]="true"
            (buttonClick)="annuleer()"
          >
          </ngx-ov-button>

          <page-ui-child></page-ui-child>
        </div>
      </ng-template>
    </ngx-ov-card>
  </ngx-ov-store-isloading>
</div>
