<div class="container">
  <div class="my-10">
    <ngx-ov-404
      [ctaLabel]="'Ga naar de startpagina'"
      subtitle="De pagina die u zoekt vinden we niet meer terug"
      [hyperlinks]="hyperlinks"
      [title]="'pagina niet gevonden'"
      (hyperlinkClicked)="goto(hyperlinks[$event])"
      (ctaClicked)="goto('/')"
    ></ngx-ov-404>
  </div>
</div>
