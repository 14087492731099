<div class="container">
  <h1>Geplande examens</h1>
  @if (!(isLoading$ | async)) {
    <ngx-ov-agenda>
      <ngx-ov-agenda-item [title]="'Wiskunde'">
        <p>Agenda item</p>
      </ngx-ov-agenda-item>

      <ngx-ov-agenda-item [title]="'Engels'" [url]="'/agenda'">
        Content agendasdfs sdfsdfs Content agendasdfs sdfsdfsContent agendasdfs
        sdfsdfs
        <ngx-ov-tooltip [position]="'bottom'">
          Test tooltip qsgfsdqfsqdf qsdf1 qsdf2 qsd3 af scherm
        </ngx-ov-tooltip>
      </ngx-ov-agenda-item>

      <ngx-ov-agenda-item [title]="'Nederlands'" [url]="'/agenda'">
        Content agenda
        <ng-template ngxOvAgendaActions>
          <ngx-ov-flyout
            [trigger]="
              '<i class=\'fas fa-ellipsis-v\' aria-hidden=\'true\'></i>'
            "
            [secondary]="false"
            [position]="'left'"
            [header]="'acties'"
          >
            <ngx-ov-flyout-item [link]="''">Link 1</ngx-ov-flyout-item>
            <ngx-ov-flyout-item [link]="''">Link 2</ngx-ov-flyout-item>
          </ngx-ov-flyout>
        </ng-template>
      </ngx-ov-agenda-item>

      <ngx-ov-agenda-item
        [title]="'Frans'"
        [url]="'/agenda'"
        [dateBackgroundColor]="'#123456'"
        [dateColor]="'#FFFFFF'"
      >
        <p>
          Kwaliteitsvol, energiezuinig en betaalbaar wonen in Vlaanderen.
          Infosessie Brussel
        </p>
      </ngx-ov-agenda-item>
    </ngx-ov-agenda>
  } @else {
    <ngx-skeleton-loader
      count="5"
      appearance="line"
      [theme]="{
        height: '80px'
      }"
    ></ngx-skeleton-loader>
  }
</div>
