<label
  class="c-input-label"
  [class.c-input-label--readonly]="isReadonly"
  for="{{ for }}"
>
  <span class="c-input-label__text" [innerHtml]="text"></span>
  @if (required && text?.trim()?.length) {
    <ngx-ov-required-mark
      [attr.data-cy]="'label-required-mark'"
      class="c-input-label__required-mark"
    ></ngx-ov-required-mark>
  }
  @if (!required) {
    <span
      class="c-input-label__marker_optional"
      [attr.data-cy]="'label-optional-mark'"
    ></span>
  }
  @if (tooltip) {
    <ngx-ov-tooltip [tooltip]="tooltip"></ngx-ov-tooltip>
  }
</label>
