import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { NgxOvDocumentViewerComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/diverse/document-viewer/document-viewer.component';
import {
  NgxOvUploadComponent,
  UploadColumn
} from '../../../../projects/ngx-ov-ui/src/lib/components/forms/upload/upload.component';
import { NgxOvInputFieldComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/forms/input-field/input-field.component';

@Component({
  selector: 'app-page-document-viewer',
  templateUrl: './page-document-viewer.component.html',
  standalone: true,
  imports: [
    NgxOvInputFieldComponent,
    NgxOvUploadComponent,
    NgxOvDocumentViewerComponent,
    AsyncPipe
  ]
})
export class PageDocumentViewerComponent {
  public rows = [];
  public columns: UploadColumn[] = [
    {
      id: 'bestandsnaam',
      header: 'Bestandsnaam',
      actions: {
        canDownload: true
      }
    },
    {
      id: 'id',
      header: 'Id'
    }
  ];
  src$: BehaviorSubject<string | Blob> = new BehaviorSubject<string | Blob>(
    null
  );

  onChangeFileURL(e: any) {
    this.src$.next(e.target.value);
  }

  onUploadFile(value: File) {
    this.src$.next(new Blob([value], { type: value.type }));
  }

  onRotateImage() {
    console.log('image rotated');
  }

  onDownloadImage() {
    console.log('image downloaded');
  }
}
