import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NgxOvButtonComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/button/button.component';
import { UiIsVisbleDirective } from '../../../../projects/ngx-ov-ui/src/lib/fundamentals/ui/ui.visible.directive';
import { NgxOvUiService } from '../../../../projects/ngx-ov-ui/src/lib/fundamentals/ui/ui.service';

@Component({
  selector: 'page-ui-child',
  template: `
    <ngx-ov-button
      [uiIsVisible]="'BTN_BEWAAR'"
      [label]="'Bewaar'"
      (buttonClick)="bewaar()"
    ></ngx-ov-button>
  `,
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [NgxOvButtonComponent, UiIsVisbleDirective]
})
export class PageUiChildComponent implements OnInit {
  constructor(private uiService: NgxOvUiService) {}

  ngOnInit() {
    this.uiService.addElement('BTN_BEWAAR');
  }

  bewaar() {
    console.log('Bewaar');
  }
}
