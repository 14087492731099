@if (this.tooltipClicked?.observed) {
  <div class="c-tooltip">
    <span
      class="c-tooltip__placeholder cursor-pointer"
      (click)="onClickTooltip()"
    >
      <ng-container *ngTemplateOutlet="tooltipObject"></ng-container>
    </span>
  </div>
} @else {
  <div class="hidden-mobile c-tooltip">
    <span [tp]="tpl" [tpTrigger]="triggerType" [tpPlacement]="$any(position)">
      <span #tooltipPlaceholder class="c-tooltip__placeholder">
        <ng-container *ngTemplateOutlet="tooltipObject"></ng-container>
      </span>
    </span>
  </div>

  <div class="hidden-desktop c-tooltip">
    @if (icon) {
      <span class="c-tooltip__icon" (click)="isPopupOpen = true">
        <i class="{{ icon }}" aria-hidden="true"></i>
      </span>
    }
    @if (label) {
      <span class="c-tooltip__label" (click)="isPopupOpen = true">
        {{ label }}
      </span>
    }
  </div>
}

<ng-template #tooltipObject>
  @if (!label && icon) {
    <span class="c-tooltip__icon">
      <i class="{{ icon }}" aria-hidden="true"></i>
    </span>
  }
  @if (label) {
    <span class="c-tooltip__label"> {{ label }} </span>
  }
</ng-template>

<ngx-ov-modal
  [type]="'Acknowledgement'"
  [isOpen]="isPopupOpen"
  [confirmText]="closeModalText"
  (closePopup)="isPopupOpen = false"
  (cancel)="isPopupOpen = false"
  (confirm)="isPopupOpen = false"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ngx-ov-modal>

<ng-template #tpl let-hide>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-template>

<ng-template #content>
  <div [class.c-tooltip--small]="small" class="c-tooltip__message">
    <span [innerHTML]="tooltip"></span>
    <ng-content></ng-content>
  </div>
</ng-template>
