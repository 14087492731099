<div
  [class]="'c-message-bar ' + classes"
  [class.is-open]="isOpen()"
  [attr.data-cy]="'messagebar'"
>
  <div class="c-message-bar__title cursor-pointer" (click)="openOrClose()">
    @if (icon) {
      <i [class]="icon"></i>
    }

    <div>{{ title }}</div>
    <ng-template [cdkPortalOutlet]="actionsPortal"></ng-template>
    <div>
      <span
        class="fas fa-chevron-up"
        aria-hidden="true"
        [@indicatorRotate]="isOpen() ? 'expanded' : 'collapsed'"
      ></span>
    </div>
  </div>
  <div
    class="c-message-bar__content"
    [@messagebarOpenTransition]="isOpen() ? 'expanded' : 'collapsed'"
  >
    <ng-content></ng-content>
  </div>
</div>
