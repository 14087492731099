<footer class="c-internal-footer {{ classes }}">
  @if (versions && versions.length) {
    <ul class="c-internal-footer__list">
      @for (version of versions; track trackByFn) {
        <li>
          <div
            class="c-internal-footer__list-item c-internal-footer__list-item--version"
          >
            {{ version.name }}: {{ version.version }} - {{ version.date }}
          </div>
        </li>
      }
    </ul>
  }
</footer>
