<div [formGroup]="extra">
  <ngx-ov-radio-group
    id="extraGroup"
    [attr.data-cy]="'radio-group-extra'"
    [formControlName]="'saus'"
    [options]="sauzen"
    [label]="'Kies je saus'"
    [bindLabel]="'naam'"
  ></ngx-ov-radio-group>
</div>
