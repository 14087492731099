import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MockUserService } from './mock-user.service';
import { Observable } from 'rxjs';
import { AsyncPipe, JsonPipe } from '@angular/common';
import { NgxOvCardComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/content-blokken/card/card.component';
import { PortalCardDirective } from '../../../../projects/ngx-ov-ui/src/lib/components/content-blokken/card/portals/portal-card.directive';
import { UiIsVisbleDirective } from '../../../../projects/ngx-ov-ui/src/lib/fundamentals/ui/ui.visible.directive';
import { NgxOvUiService } from '../../../../projects/ngx-ov-ui/src/lib/fundamentals/ui/ui.service';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup
} from '@angular/forms';
import { NgxOvUiIsLoadingComponent } from '../../../../projects/ngx-ov-ui/src/lib/fundamentals/ui/ui-loading.component';
import { LoadLandenRequest } from '@store/landen/landen.actions';
import { Store } from '@ngxs/store';
import { LandenState } from '@store/landen/landen.state';
import { NgxOvButtonComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/button/button.component';
import { NgxOvSelectComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/forms/select/select.component';
import { StoreIsLoadingComponent } from '../../../../projects/ngx-ov-ui/src/lib/fundamentals/store/componenten/loading/store-loading.component';
import { PageUiChildComponent } from './child.component';

@Component({
  templateUrl: './page-ui.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [
    NgxOvButtonComponent,
    AsyncPipe,
    JsonPipe,
    NgxOvCardComponent,
    PortalCardDirective,
    UiIsVisbleDirective,
    NgxOvSelectComponent,
    NgxOvButtonComponent,
    ReactiveFormsModule,
    NgxOvUiIsLoadingComponent,
    StoreIsLoadingComponent,
    PageUiChildComponent
  ]
})
export class PageUiComponent implements OnInit {
  landen$: Observable<any>;
  select1Items = [{ code: 'CODE_1', omschrijving: 'Conditie 1' }];
  form: UntypedFormGroup;

  users$: Observable<any>;
  constructor(
    private fb: UntypedFormBuilder,
    private mockUserService: MockUserService,
    public uiService: NgxOvUiService,
    private store: Store
  ) {}

  ngOnInit() {
    this.uiService.addElement('BTN_ANNULEER');
    this.laadLanden();

    this.landen$ = this.store.select(LandenState.geefLanden);
    this.form = this.fb.group({
      select1: [null],
      select2: [null]
    });
    console.log('uiElements onInit:', this.uiService.uiElementsCheck());

    this.users$ = this.mockUserService.getUsers();
  }

  annuleer() {
    this.mockUserService.saveUser().subscribe(() => {});
  }

  bewaar() {
    this.mockUserService.saveUser().subscribe(() => {});
  }

  changeSelect1(e: any) {
    this.uiService.laadUIElementen({ dossierId: 2 });
  }

  changeSelect2(e: any) {
    this.uiService.laadUIElementen();
  }

  laadLanden() {
    this.store.dispatch(new LoadLandenRequest());
  }
}
