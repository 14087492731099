<a
  class="c-doormat-acm {{ classes }}"
  (click)="onClick()"
  [class.c-doormat--with-link]="url"
>
  <!-- Content section -->
  <div class="c-doormat-acm__content">
    <div class="c-doormat-acm__arrow-title">
      <i
        class="c-doormat-acm__arrow fas fa-chevron-circle-right"
        aria-hidden="true"
      ></i>
      <div>{{ title }}</div>
    </div>

    <div class="c-doormat-acm__text">
      <ng-content></ng-content>
    </div>
  </div>
  <div class="c-doormat-acm__action">
    <div class="c-doormat-acm__icon">
      <i class="fas fa-users" aria-hidden="true"></i>
    </div>
    @if (linkLabel) {
      <div class="c-doormat-acm__footer">
        <ngx-ov-link
          [text]="linkLabel"
          [linkUrl]="linkUrl"
          [nowrap]="true"
          [iconAfter]="'fas fa-question-circle'"
        ></ngx-ov-link>
      </div>
    }
  </div>
</a>
