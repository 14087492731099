<div class="container" [formGroup]="form">
  <h1>Datepicker</h1>
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <ngx-ov-button [label]="'Bewaren'" (buttonClick)="onSubmit()"></ngx-ov-button>
</div>
