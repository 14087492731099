import * as R from 'ramda';
import { Injectable } from '@angular/core';

export interface IPager {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  totalPages: number;
  startPage: number;
  endPage: number;
  startIndex: number;
  endIndex: number;
  pages: number[];
}

@Injectable()
export class PagerService {
  getPager(totalItems: any, currentPage: any = 1, pageSize: any = 10): IPager {
    // make sure all numbers are actually numbers and not strings
    const totalItemsInt = parseInt(totalItems, 10);
    const currentPageInt = parseInt(currentPage, 10);
    const pageSizeInt = parseInt(pageSize, 10);

    // calculate total pages
    const totalPages = Math.ceil(totalItemsInt / pageSizeInt);

    let startPage: number;
    let endPage: number;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else if (currentPageInt <= 6) {
      // more than 10 total pages so calculate start and end pages
      startPage = 1;
      endPage = 10;
    } else if (currentPageInt + 4 >= totalPages) {
      startPage = totalPages - 9;
      endPage = totalPages;
    } else {
      startPage = currentPageInt - 5;
      endPage = currentPageInt + 4;
    }

    // calculate start and end item indexes
    const startIndex = (currentPageInt - 1) * pageSizeInt;
    const endIndex = Math.min(startIndex + pageSizeInt - 1, totalItemsInt - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = R.range(startPage, endPage + 1);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItemsInt,
      currentPage: currentPageInt,
      pageSize: pageSizeInt,
      totalPages,
      startPage,
      endPage,
      startIndex,
      endIndex,
      pages
    };
  }
}
