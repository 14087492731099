<div class="container">
  <div class="my-10">
    <h2>Upload with rows defined</h2>
    <ngx-ov-upload
      [columns]="columns"
      [rows]="rows"
      [canDelete]="true"
      [canEdit]="true"
      [canDownload]="true"
      [canDragDrop]="false"
      [canUpload]="true"
      [accept]="['pdf', 'xls', '.xlsx', '.png', '.jpg']"
      [allowedFilesText]="
        'bestandstypes zijn: .pdf, .xls, .xlsx, .png, .jpg - maximum 4 bijlages toegestaan'
      "
      [title]="'Documenten Opladen'"
      [isLoading]="false"
      [maxFileSizeInMB]="4"
      [maxNumberFiles]="4"
      [isOpenByDefault]="true"
      [isRequired]="true"
      [tooltip]="'Test tooltip'"
      [additionalActionText]="'Ik heb een benodigd document niet'"
      [maxLengthFileName]="20"
      (deleteFile)="deleteFile($event)"
      (editFile)="onClickEdit($event)"
      (downloadFile)="downloadFile($event)"
      (uploadChange)="onUploadChange($event)"
      (uploadFileError)="onUploadFileError($event)"
      (executeAdditionalAction)="onActivateAdditionalAction()"
    >
    </ngx-ov-upload>
    <div class="mt-10">
      <ngx-ov-card [title]="'Configuratie upload'" [isSecondary]="true">
        <ul>
          <li>maxLengthFileName = 20 karakters</li>
          <li>maxNumberFiles = 4</li>
          <li>maxFileSize = 4MB</li>
          <li>fileTypes = '.pdf', '.xls', '.xlsx', '.png', '.jpg'</li>
        </ul>
      </ngx-ov-card>
    </div>
  </div>

  <div class="my-10">
    <h2>Upload with rows defined custom tooltip</h2>
    <ngx-ov-upload
      [columns]="columns"
      [rows]="rows"
      [canDelete]="true"
      [canEdit]="true"
      [canDownload]="true"
      [canDragDrop]="false"
      [title]="'Documenten Opladen'"
      [isLoading]="false"
      [isOpenByDefault]="true"
      [isRequired]="true"
      [tooltip]="'Test tooltip'"
      [errorMessage]="'This is the errortext'"
      [additionalActionText]="'Ik heb een benodigd document niet'"
      (deleteFile)="deleteFile($event)"
      (editFile)="onClickEdit($event)"
      (downloadFile)="downloadFile($event)"
      (uploadChange)="onUploadChange($event)"
      (uploadFileError)="onUploadFileError($event)"
      (executeAdditionalAction)="onActivateAdditionalAction()"
      [tooltipTemplate]="tooltipTemplate"
    >
    </ngx-ov-upload>

    <ng-template #tooltipTemplate>
      <div class="ml-small">
        <p>Tooltip overschreven door template</p>
      </div>
    </ng-template>

    <div class="mt-10">
      <ngx-ov-card [title]="'Configuratie upload'" [isSecondary]="true">
        Waarden overgenomen uit ngx-ov-ui configuratie!
        <ul>
          <li>maxLengthFileName = 20 from config</li>
          <li>maxNumberFiles = not set</li>
          <li>maxFileSize = 1MB</li>
          <li>bestand types = '.pdf'</li>
        </ul>
      </ngx-ov-card>
    </div>
  </div>

  <div class="my-10">
    <h2>Upload without rows defined</h2>
    <p>
      *deze upload verwerkt de file niet, dus de file zal ook hier niet tonen na
      upload. Wel zal de file bij de bovenstaande uploads bijgekomen zijn
    </p>
    <p></p>
    <p>betere voorbeelden moeten nog uitgewerkt worden</p>

    <ngx-ov-upload
      [canDragDrop]="true"
      [accept]="['.pdf', '.xls', '.xlsx']"
      [allowedFilesText]="
        'bestandstypes zijn: .pdf, .xls, .xlsx - maximum 4 bijlages toegestaan'
      "
      [isLoading]="false"
      [maxNumberFiles]="4"
      [isOpenByDefault]="true"
      (deleteFile)="deleteFile($event)"
      (downloadFile)="downloadFile($event)"
      (uploadChange)="onUploadChange($event)"
      (uploadFileError)="onUploadFileError($event)"
    ></ngx-ov-upload>
  </div>
</div>
<ngx-ov-modal [id]="'edit-modal'" [title]="'Edit Document'">
  <div class="px-4" [formGroup]="nameChangeForm">
    <p>This modal opens when we want to edit our document data</p>
    <ngx-ov-input-field
      [formControlName]="'documentnaam'"
      label="Documentnaam"
      class="mb-4"
    ></ngx-ov-input-field>
    <div class="u-position-btn-prim-sec mt-4">
      <ngx-ov-button
        label="Bevestig"
        (buttonClick)="onConfirmEdit()"
      ></ngx-ov-button>

      <ngx-ov-button
        [isSecondary]="true"
        label="Annuleer"
        (buttonClick)="onCancelEdit()"
      ></ngx-ov-button>
    </div>
  </div>
</ngx-ov-modal>
<ngx-ov-modal [id]="'extra-info-modal'" [title]="'Upload Documents'">
  <p>Here we can show extra info related to the upload.</p>
</ngx-ov-modal>
<ngx-ov-modal [id]="'additional-action-modal'" [title]="'Additional Action'">
  <p>
    Deze modal wordt getoond wanneer de gebruiker klikt op 'Ik heb een benodigd
    document niet'
  </p>
</ngx-ov-modal>
