import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import * as R from 'ramda';

@Injectable()
export class CmsService {
  constructor(private apollo: Apollo) {}
  articles: BehaviorSubject<any> = new BehaviorSubject([]);

  loadArticles() {
    this.apollo
      .watchQuery({
        query: gql`
          {
            articles(filter: { title: { LIKE: "OWD_artikel" } }) {
              items {
                publicationDate
                updatedDate
                title
                language
                tagline
                intro
                showSideNavigation
                link
                instructions
                contactDescription
                externalReferenceIdentifier
                stories
                secondaryStories
              }
            }
          }
        `
      })
      .valueChanges.subscribe((result: any) => {
        this.articles.next(result.data.articles.items);
      });
  }

  getArticles(): Observable<any> {
    return this.articles;
  }

  mergeTranslateWithCmsData(tranlsateData: any, cmsData: any) {
    return R.mergeDeepRight(tranlsateData, cmsData);
  }

  mapCmsDataToJson(cmsData: any[], indexKey: string, indexValue: string) {
    /* TODO: remove spaces for the field which contains the key */

    const mappedData = cmsData.map((data) => {
      const val = data[indexValue];
      const key = data[indexKey];

      const tempObject = {};
      let container = tempObject;
      /* eslint-disable */
      // @ts-ignore
      key.split('.').map((k, j, values) => {
        container = container[k] = j == values.length - 1 ? val : {};
      });

      return tempObject;
    });

    const merged = R.reduce(R.mergeDeepLeft, {}, mappedData);
    return merged;
  }
}
