import { parse, format } from 'date-fns';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NgxOvDatepickerInputsService {
  parseDate(stringToParse: string, dateFormat: string) {
    return parse(stringToParse, dateFormat, new Date());
  }

  formatDate(dateString: string, dateFormat: string, monthYearOnly: boolean) {
    if (dateString) {
      try {
        return monthYearOnly
          ? format(this.parseDate(dateString, dateFormat), 'MM/yyyy')
          : format(this.parseDate(dateString, dateFormat), 'dd/MM/yyyy');
      } catch (err) {
        return '';
      }
    } else {
      return null;
    }
  }
}
