/**
 * Naric
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.3.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import {BevragingRestDto} from './bevragingRestDto';
import {ResultaatInfoRestDto} from './resultaatInfoRestDto';


import {
  AbstractControlOptions,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn
} from '@angular/forms';
import {SwaggerValidators} from '@ov/ngx-ov-utils';

export interface IAanvraagbegeleidingRestDto {
  bevraging: BevragingRestDto;
  creatie_datum: Date;
  id: string;
  resultaat_info?: ResultaatInfoRestDto;
  update_datum: Date;
}


export class AanvraagbegeleidingRestDto implements IAanvraagbegeleidingRestDto {
  bevraging: BevragingRestDto;
  creatie_datum: Date;
  id: string;
  resultaat_info?: ResultaatInfoRestDto;
  update_datum: Date;
}

export class AanvraagbegeleidingRestDtoFormHelper {

  static generateFormWithSwaggerValidators(formBuilder: UntypedFormBuilder, controlsConfig: { [key: string]: any; }, options?: AbstractControlOptions | { [key: string]: any; } | null): UntypedFormGroup {
    if (controlsConfig["bevraging"] != undefined && !(controlsConfig["bevraging"] instanceof UntypedFormGroup) && !(controlsConfig["bevraging"] instanceof UntypedFormArray)) {
      controlsConfig["bevraging"][1] = controlsConfig["bevraging"][1].concat(AanvraagbegeleidingRestDtoValidator.bevraging_swagger_validator());
    }
    if (controlsConfig["creatie_datum"] != undefined && !(controlsConfig["creatie_datum"] instanceof UntypedFormGroup) && !(controlsConfig["creatie_datum"] instanceof UntypedFormArray)) {
      controlsConfig["creatie_datum"][1] = controlsConfig["creatie_datum"][1].concat(AanvraagbegeleidingRestDtoValidator.creatie_datum_swagger_validator());
    }
    if (controlsConfig["id"] != undefined && !(controlsConfig["id"] instanceof UntypedFormGroup) && !(controlsConfig["id"] instanceof UntypedFormArray)) {
      controlsConfig["id"][1] = controlsConfig["id"][1].concat(AanvraagbegeleidingRestDtoValidator.id_swagger_validator());
    }
    if (controlsConfig["resultaat_info"] != undefined && !(controlsConfig["resultaat_info"] instanceof UntypedFormGroup) && !(controlsConfig["resultaat_info"] instanceof UntypedFormArray)) {
      controlsConfig["resultaat_info"][1] = controlsConfig["resultaat_info"][1].concat(AanvraagbegeleidingRestDtoValidator.resultaat_info_swagger_validator());
    }
    if (controlsConfig["update_datum"] != undefined && !(controlsConfig["update_datum"] instanceof UntypedFormGroup) && !(controlsConfig["update_datum"] instanceof UntypedFormArray)) {
      controlsConfig["update_datum"][1] = controlsConfig["update_datum"][1].concat(AanvraagbegeleidingRestDtoValidator.update_datum_swagger_validator());
    }
    if (options) {
      return formBuilder.group(controlsConfig, options);
    }
    return formBuilder.group(controlsConfig);
  }
}

export class AanvraagbegeleidingRestDtoValidator {

  /**
   * Active Validators for bevraging:
   * Required validator
   */
  static bevraging_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    validators.push(SwaggerValidators.required);
    return validators;
  };

  /**
   * Active Validators for creatie_datum:
   * Required validator
   */
  static creatie_datum_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    validators.push(SwaggerValidators.required);
    return validators;
  };

  /**
   * Active Validators for id:
   * Required validator
   */
  static id_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    validators.push(SwaggerValidators.required);
    return validators;
  };

  /**
   * Active Validators for resultaat_info:
   */
  static resultaat_info_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    return validators;
  };

  /**
   * Active Validators for update_datum:
   * Required validator
   */
  static update_datum_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    validators.push(SwaggerValidators.required);
    return validators;
  };

}



