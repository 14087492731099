<ngx-ov-upload
  #uploadComponent
  [columns]="columns || defaultColumns"
  [rows]="files$ | async"
  [isOpenByDefault]="true"
  [accept]="accept"
  [canDownload]="canDownload"
  [canDelete]="canDelete"
  [canUpload]="canUpload"
  [canDragDrop]="canDragDrop"
  [allowedFilesText]="allowedFilesText"
  [emptyTableText]="emptyTableText"
  [dragDropActionName]="dragDropActionName"
  [uploadActionName]="uploadActionName"
  [maxNumberFiles]="50"
  [maxFileSizeInMB]="maxFileSizeInMB"
  [maxLengthFileName]="maxLengthFileName"
  [isLoading]="isSaving"
  [errorMessage]="errorMessage"
  (deleteFile)="onDeleteFile($event)"
  (downloadFile)="downloadFile($event)"
  (uploadChange)="onUploadChange($event)"
  (uploadFileError)="onUploadFileError()"
></ngx-ov-upload>
