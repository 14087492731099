import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { CdkPortal } from '@angular/cdk/portal';

@Directive({
    selector: '[ngxOvAgendaActions]ng-template',
    standalone: true
})
export class PortalAgendaActionsDirective extends CdkPortal {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(
    templateRef: TemplateRef<any>,
    viewContainerRef: ViewContainerRef
  ) {
    super(templateRef, viewContainerRef);
  }
}
