import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { NgxOvActionsState } from './actions.state';

import { ResetErrorMessages, ResetSuccessMessages } from './actions.actions';
import { deNormalizeArray } from '../../../utils/normalize';
import { StoreItemStatus } from '../store.model';

@Injectable({
  providedIn: 'root'
})
export class NgxOvActionsService {
  constructor(private store: Store) {}

  isLoading(item: string | string[]): boolean {
    let mappeditems;
    if (!(item instanceof Array)) {
      mappeditems = [item];
    } else {
      mappeditems = item;
    }
    return this.store.selectSnapshot((state: any) => {
      return !!deNormalizeArray(state)
        .filter((action) => action.loading)
        .filter((action) => mappeditems.includes('LOAD-' + action.name)).length;
    });
  }

  getStatus$(item: string | string[]): Observable<StoreItemStatus> {
    return this.store.select(NgxOvActionsState.getStatus(item));
  }

  isLoading$(item: string | string[]): Observable<boolean> {
    return this.store.select(NgxOvActionsState.isLoading(item));
  }
  isLoadSuccess$(item: string | string[]): Observable<boolean> {
    return this.store.select(NgxOvActionsState.isLoadSuccess(item));
  }

  getSuccessMessages$(item: string | string[]): Observable<any[]> {
    return this.store.select(NgxOvActionsState.getLoadSuccessMessages(item));
  }

  isLoadFailure$(item: string | string[]): Observable<boolean> {
    return this.store.select(NgxOvActionsState.isLoadFailure(item));
  }

  getFailureMessages$(item: string | string[]): Observable<any[]> {
    return this.store.select(NgxOvActionsState.getLoadFailureMessages(item));
  }

  isSaving(item: string | string[]): boolean {
    let mappeditems;
    if (!(item instanceof Array)) {
      mappeditems = [item];
    } else {
      mappeditems = item;
    }
    return this.store.selectSnapshot((state: any) => {
      return !!deNormalizeArray(state)
        .filter((action) => action.loading)
        .filter((action) => mappeditems.includes('SAVE-' + action.name)).length;
    });
  }

  isSaving$(item: string | string[]): Observable<boolean> {
    return this.store.select(NgxOvActionsState.isSaving(item));
  }

  isSaveSuccess$(item: string | string[]): Observable<boolean> {
    return this.store.select(NgxOvActionsState.isSaveSuccess(item));
  }

  getSaveSuccessMessages$(item: string | string[]): Observable<any[]> {
    return this.store.select(NgxOvActionsState.getSaveSuccessMessages(item));
  }

  isSaveFailure$(item: string | string[]): Observable<boolean> {
    return this.store.select(NgxOvActionsState.isSaveFailure(item));
  }

  getSaveFailureMessages$(item: string | string[]): Observable<any[]> {
    return this.store.select(NgxOvActionsState.getSaveFailureMessages(item));
  }

  isDeleting(item: string | string[]): boolean {
    let mappeditems;
    if (!(item instanceof Array)) {
      mappeditems = [item];
    } else {
      mappeditems = item;
    }
    return this.store.selectSnapshot((state: any) => {
      return !!deNormalizeArray(state)
        .filter((action) => action.loading)
        .filter((action) => mappeditems.includes('DELETE-' + action.name))
        .length;
    });
  }

  isDeleting$(item: string | string[]): Observable<boolean> {
    return this.store.select(NgxOvActionsState.isDeleting(item));
  }

  isDeleteSuccess$(item: string | string[]): Observable<boolean> {
    return this.store.select(NgxOvActionsState.isDeleteSuccess(item));
  }

  getDeleteSuccessMessages$(item: string | string[]): Observable<any[]> {
    return this.store.select(NgxOvActionsState.getDeleteSuccessMessages(item));
  }

  isDeleteFailure$(item: string | string[]): Observable<boolean> {
    return this.store.select(NgxOvActionsState.isDeleteFailure(item));
  }

  getDeleteFailureMessages$(item: string | string[]): Observable<any[]> {
    return this.store.select(NgxOvActionsState.getDeleteFailureMessages(item));
  }

  resetErrors() {
    this.store.dispatch(new ResetErrorMessages());
  }

  resetSuccess() {
    this.store.dispatch(new ResetSuccessMessages());
  }
}
