import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input
} from '@angular/core';
import { PortalWizardNavigationLeftContentDirective } from './portals/portal-wizard-navigation-left-content.directive';
import { PortalWizardNavigationRightContentDirective } from './portals/portal-wizard-navigation-right-content.directive';
import { PortalModule } from '@angular/cdk/portal';

@Component({
  selector: 'ngx-ov-wizard-navigation',
  templateUrl: './wizard-navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PortalModule]
})
export class NgxOvWizardNavigationComponent {
  private _title: string;
  get title(): string {
    return this._title;
  }

  @Input() set title(title: string) {
    this._title = title;
  }

  @ContentChild(PortalWizardNavigationLeftContentDirective, { static: false })
  leftContent: PortalWizardNavigationLeftContentDirective;
  @ContentChild(PortalWizardNavigationRightContentDirective, { static: false })
  rightContent: PortalWizardNavigationRightContentDirective;
}
