import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { Router } from '@angular/router';
import { LinkService } from '../../../services/link.service';
import { PortalFlyoutItemContentDirective } from './flyout-item-content.directive';
import { PortalModule } from '@angular/cdk/portal';
import { ClickEqualsEnterDirective } from '../../../directives/click-equals-enter';
import { NgClass } from '@angular/common';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-flyout-item',
  templateUrl: './flyout-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, ClickEqualsEnterDirective, PortalModule]
})
export class NgxOvFlyoutItemComponent {
  // -------------------------------------------------------------------------
  // Input variables
  // -------------------------------------------------------------------------
  @Input() link: string;
  @Input() isLargeVersion = false;
  @Input() isVisible = true;

  // -------------------------------------------------------------------------
  // Output variables
  // -------------------------------------------------------------------------
  @Output() clicked = new EventEmitter<any>();
  @Output() eventEmitter: EventEmitter<any> = new EventEmitter<any>();

  @ContentChild(PortalFlyoutItemContentDirective)
  largeVersionContent: PortalFlyoutItemContentDirective;

  constructor(
    private router: Router,
    private linkService: LinkService
  ) {}

  onClick() {
    this.closeParent();
    if (this.link) {
      this.linkService.navigate(this.link);
    } else {
      this.eventEmitter.emit(true);
    }
  }

  closeParent() {
    this.clicked.emit();
  }
}
