import { Inject, Injectable, Optional } from '@angular/core';
import { NgxsNextPluginFn, NgxsPlugin, Store } from '@ngxs/store';
import {
  NGX_OV_SNACKBAR_PLUGIN_OPTIONS,
  NgxOvSnackbarPluginOptions
} from './snackbar-plugin-options';
import { NgxOvSnackbarService } from '../../../components/meldingen/snackbar/snackbar.service';
import { NgxOvActionPhase, NgxOvActionType } from '../actions';
import { TranslationsService } from '../../../services/translations.service';
import { TranslateService } from '@ngx-translate/core';
import { geefActieFase, geefActieType } from '../store-utils';

@Injectable()
export class NgxOvSnackbarPlugin implements NgxsPlugin {
  private _store: Store;

  constructor(
    @Inject(NGX_OV_SNACKBAR_PLUGIN_OPTIONS)
    private _options: NgxOvSnackbarPluginOptions,
    private _snackbarService: NgxOvSnackbarService,
    private _translationsService: TranslationsService,
    @Optional() public _translateService: TranslateService
  ) {}

  handle(state: any, action: any, next: NgxsNextPluginFn) {
    const actionType = geefActieType(action);
    const actionPhase = geefActieFase(action);

    let title;
    let message;
    let type;

    if (
      actionPhase === NgxOvActionPhase.Success &&
      actionType === NgxOvActionType.Save &&
      (action.snackbarTitle ||
        action.snackbarMessage ||
        action.showSnackbar ||
        (this._options.showDefaultSnackbarOnSaveSuccess &&
          action.showSnackbar !== false))
    ) {
      title = this.getMessage(action.snackbarTitle, 'SUCCESS_API_PUT');
      message = action.snackbarMessage
        ? this.getMessage(action.snackbarMessage)
        : null;
      type = 'SUCCESS';
    }

    if (
      actionPhase === NgxOvActionPhase.Failure &&
      actionType === NgxOvActionType.Save &&
      (action.snackbarTitle ||
        action.snackbarMessage ||
        action.showSnackbar ||
        (this._options.showDefaultSnackbarOnSaveFailure &&
          action.showSnackbar !== false))
    ) {
      title = this.getMessage(action.snackbarTitle, 'ERROR_API_PUT');
      message = action.snackbarMessage
        ? this.getMessage(action.snackbarMessage)
        : null;
      type = 'ERROR';
    }

    if (
      actionPhase === NgxOvActionPhase.Success &&
      actionType === NgxOvActionType.Delete &&
      (action.snackbarTitle ||
        action.snackbarMessage ||
        action.showSnackbar ||
        (this._options.showDefaultSnackbarOnDeleteSuccess &&
          action.showSnackbar !== false))
    ) {
      title = this.getMessage(action.snackbarTitle, 'SUCCESS_API_DELETE');
      message = action.snackbarMessage
        ? this.getMessage(action.snackbarMessage)
        : null;
      type = 'SUCCESS';
    }

    if (
      actionPhase === NgxOvActionPhase.Failure &&
      actionType === NgxOvActionType.Delete &&
      (action.snackbarTitle ||
        action.snackbarMessage ||
        action.showSnackbar ||
        (this._options.showDefaultSnackbarOnDeleteFailure &&
          action.showSnackbar !== false))
    ) {
      title = this.getMessage(action.snackbarTitle, 'ERROR_API_DELETE');
      message = action.snackbarMessage
        ? this.getMessage(action.snackbarMessage)
        : null;
      type = 'ERROR';
    }
    if (!title && action.snackbarTitle) {
      title = action.snackbarTitle;
      if (actionPhase === NgxOvActionPhase.Failure) {
        type = 'ERROR';
      } else {
        type = 'SUCCESS';
      }
    }
    if ((title || message) && type) {
      this._snackbarService.open(title, message, type);
    }

    return next(state, action);
  }

  getMessage(message: string, key?: string) {
    let title;
    if (message) {
      if (this._translateService) {
        let jsonText;
        jsonText = this._translateService.instant(message);
        if (jsonText && jsonText !== message) {
          title = jsonText;
        } else {
          title = message;
        }
      } else {
        title = message;
      }
    } else {
      title = this.getTranslation(
        'FUNDAMENTALS.STORE',
        key ? key : 'SUCCESS_API_PUT'
      );
    }
    return title;
  }

  getTranslation(componentPrefix: string, key: string): any {
    const jsonKey = `NGX_OV_UI.${componentPrefix}.${key}`;
    let jsonText;
    if (this._translateService) {
      jsonText = this._translateService.instant(jsonKey);
      if (jsonText && jsonText !== jsonKey) {
        return jsonText;
      }
    }

    try {
      const translationKey = `${componentPrefix}.${key}`;
      const translation =
        this._translationsService.getDefaultComponentTranslation(
          translationKey
        );
      return translation ? translation : 'Missing translation';
    } catch (e) {
      return 'Error translating';
    }
  }
}
