import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Renderer2
} from '@angular/core';
import { NgxOvDataTableRowComponent } from '../data-table-row/data-table-row.component';

// =============================================================================
// Component
// =============================================================================
@Component({
    /* tslint:disable-next-line */
    selector: 'table[ngx-ov-data-table]',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
    <thead>
      <tr ngx-ov-data-table-row>
        <ng-content select="th[ngx-ov-data-table-column]"></ng-content>
      </tr>
    </thead>
    <ng-content></ng-content>
  `,
    standalone: true,
    imports: [NgxOvDataTableRowComponent]
})
export class NgxOvDataTableTableComponent {
  constructor(private _elementRef: ElementRef, private _renderer: Renderer2) {
    this._renderer.addClass(
      this._elementRef.nativeElement,
      'ngx-ov-data-table'
    );
  }
}
