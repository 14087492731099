<a class="c-doormat {{ classes }}" (click)="goto(url)">
  <!-- Arrow section -->
  <div
    class="c-doormat__arrow-content"
    [class.c-doormat__arrow-content--warning]="type === 'WARNING'"
  >
    <i class="c-doormat__arrow fas fa-chevron-right" aria-hidden="true"></i>
  </div>

  <!-- Content section -->
  <div class="c-doormat__content-container">
    <div class="c-doormat__content">
      <div class="c-doormat__content-right-container">
        <div>
          <div class="c-doormat__title">{{ title }}</div>
          <div class="c-doormat__text">
            <ng-content></ng-content>
          </div>
        </div>
        @if (icon) {
          <div
            [ngStyle]="{ 'font-size.rem': iconSize }"
            [class]="'c-doormat__icon ' + icon"
          ></div>
        }
      </div>
      @if (moreInfoLabel) {
        <div class="c-doormat__more-info-link">
          <a (click)="onClickMoreInfo($event)"
            >{{ moreInfoLabel }}&nbsp;<i
              class="fa-solid fa-question-circle"
              aria-hidden="true"
            ></i>
          </a>
        </div>
      }
    </div>
  </div>
</a>
