import { Component, OnInit } from '@angular/core';
import {
  AbstractControlOptions,
  UntypedFormBuilder,
  UntypedFormGroup,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { NgxOvButtonComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/button/button.component';
import { NgxOvDatepickerComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/forms/datepicker/datepicker.component';

export interface DocumentData {
  document_key: string;
}

/**
 * Provide the services and the configuration
 */
@Component({
  templateUrl: './page-playground.component.html',
  styleUrls: ['./page-playground.component.scss'],
  providers: [],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgxOvDatepickerComponent,
    NgxOvButtonComponent
  ]
})
export class PagePlaygroundComponent implements OnInit {
  form: UntypedFormGroup;
  ngOnInit(): void {
    this.form = this.getForm();
  }

  constructor(private fb: UntypedFormBuilder) {}
  onSubmit() {
    console.log('Begindatum: ');
    console.log(this.form.get('begindatum').value);
  }

  getForm(): UntypedFormGroup {
    return this.generateFormWithSwaggerValidators(this.fb, {
      begindatum: ['', []]
    });
  }

  generateFormWithSwaggerValidators(
    formBuilder: UntypedFormBuilder,
    controlsConfig: { [key: string]: any },
    options?: AbstractControlOptions | { [key: string]: any } | null
  ): UntypedFormGroup {
    if (options) {
      return formBuilder.group(controlsConfig, options);
    }
    return formBuilder.group(controlsConfig);
  }
}
