import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { NgxOvUiConfigService } from '../../../../ngx-ov-ui.config.service';
import {
  PortalRadioContentDirective,
  PortalRadioLabelDirective
} from '../portals/portal-radio-content.directive';
import { NgxOvExtraInfoComponent } from '../../extra-info/extra-info.component';
import { NgxOvTooltipComponent } from '../../../meldingen/tooltip/tooltip.component';
import { PortalModule } from '@angular/cdk/portal';

let identifier = 0;

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-radio',
  templateUrl: './radio.html',
  standalone: true,
  imports: [PortalModule, NgxOvTooltipComponent, NgxOvExtraInfoComponent]
})
export class NgxOvRadioComponent {
  // -------------------------------------------------------------------------
  // private variables
  // -------------------------------------------------------------------------
  private _id: string;
  private _classes: string;
  private _label: string;
  private _value: any;
  private _for: string;
  private _isDisabled: boolean;
  private _isError: boolean;
  private _errorMessage: string;
  private _showAsBlock: boolean;
  private _checked: boolean;
  private _extraInfo: string;
  private _icon: string;

  // -------------------------------------------------------------------------
  // Input variables
  // -------------------------------------------------------------------------
  @Input() set id(id: string) {
    this._id = id;
  }

  @Input() set classes(classes: string) {
    this._classes = classes;
  }

  @Input() set label(label: string) {
    this._label = label;
  }

  get value(): any {
    return this._value;
  }

  @Input() set value(value: any) {
    this._value = value;
  }

  @Input() set for(f: string) {
    this._for = f;
  }

  @Input() set isDisabled(isDisabled: boolean) {
    this._isDisabled = isDisabled;
  }

  @Input() set isError(isError: boolean) {
    this._isError = isError;
  }

  @Input() set errorMessage(errorMessage: string) {
    this._errorMessage = errorMessage;
  }

  @Input() set showAsBlock(showAsBlock: boolean) {
    this._showAsBlock = showAsBlock;
  }

  get checked(): boolean {
    return this._checked;
  }

  @Input() set checked(checked: boolean) {
    this._checked = checked;
  }

  @Input() set extraInfo(extraInfo: string) {
    this._extraInfo = extraInfo;
  }

  @Input() set icon(icon: string) {
    this._icon = icon;
  }

  // -------------------------------------------------------------------------
  // Output variables
  // -------------------------------------------------------------------------
  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  // -------------------------------------------------------------------------
  // Ohter variables
  // -------------------------------------------------------------------------
  public identifier = `form-radio-${(identifier += 1)}`;

  // -------------------------------------------------------------------------
  // Content children
  // -------------------------------------------------------------------------
  @ViewChild('input', { static: false }) radio: ElementRef;
  @ContentChild(PortalRadioContentDirective, { static: false })
  contentPortal: PortalRadioContentDirective;
  @ContentChild(PortalRadioLabelDirective, { static: false })
  labelPortal: PortalRadioLabelDirective;

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  constructor(public config: NgxOvUiConfigService) {}

  // -------------------------------------------------------------------------
  // Events
  // -------------------------------------------------------------------------

  clickRadio(e) {
    e.stopPropagation();

    if (this.isDisabled) {
      return;
    }

    if (this.radio && this.radio.nativeElement) {
      this.valueChange.emit(this.value);
      this.radio.nativeElement.click();
    }
  }

  // -------------------------------------------------------------------------
  // Getters
  // -------------------------------------------------------------------------
  get id(): string {
    return this._id ? this._id : this.identifier;
  }

  get classes(): string {
    return this._classes;
  }

  get label(): string {
    return this._label;
  }

  get for(): string {
    return this._for;
  }

  get isDisabled(): boolean {
    return this._isDisabled;
  }

  get isError(): boolean {
    return this._isError;
  }

  get errorMessage(): string {
    return this._errorMessage;
  }

  get showAsBlock(): boolean {
    return this._showAsBlock;
  }

  get extraInfo(): string {
    return this._extraInfo;
  }

  get icon(): string {
    return this._icon;
  }
}
