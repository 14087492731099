<div class="container grid grid-cols-12">
  <div class="col-span-full desktop:col-start-1 desktop:col-end-10">
    <h1>Jouw profielgegevens</h1>
    <ngx-ov-link
      (click)="gaNaarContact()"
      [attr.data-cy]="'LINK_CONTACT'"
      [iconBefore]="'fas fa-shuffle'"
      [text]="'Ga naar contact tab'"
    ></ngx-ov-link>
    <div class="my-10">
      <div class="container grid grid-cols-12 u-container-width">
        <ngx-ov-tabs
          [attr.data-cy]="'TABS_PROFIEL'"
          class="col-span-full desktop:col-start-1 desktop:col-end-9"
          [selectedTabIndex]="0"
        >
          <ngx-ov-tab
            tabTitle="Identiteit"
            [attr.data-cy]="'TAB_IDENTITEIT'"
            type="test"
          >
            <profile-identity-form
              [isLoading]="isLoading$ | async"
            ></profile-identity-form>
          </ngx-ov-tab>
          <ngx-ov-tab tabTitle="Adres" [attr.data-cy]="'TAB_ADRES'">
            <profile-address-form></profile-address-form>
          </ngx-ov-tab>
          <ngx-ov-tab
            tabId="CONTACT"
            tabTitle="Contact"
            [attr.data-cy]="'TAB_CONTACT'"
          >
            <ng-template ngxOvTabTitleContent>
              Contact
              <i
                class="fas fa-exclamation-circle text-ui-orange2"
                aria-hidden="true"
              ></i>
            </ng-template>
            <profile-contact-form></profile-contact-form>
          </ngx-ov-tab>
        </ngx-ov-tabs>
      </div>
    </div>
  </div>
</div>
