<div class="c-wizard-navigation">
  <ng-template>Linker kant</ng-template>

  @if (leftContent) {
    <div class="c-wizard-navigation__nav-item--left">
      <div class="c-wizard-navigation__nav-item--pointer">
        <ng-template [cdkPortalOutlet]="leftContent"></ng-template>
      </div>
    </div>
  }

  <div class="c-wizard-navigation__title" [innerHTML]="title"></div>

  @if (rightContent) {
    <div class="c-wizard-navigation__nav-item--right">
      <div class="c-wizard-navigation__nav-item--pointer">
        <ng-template [cdkPortalOutlet]="rightContent"></ng-template>
      </div>
    </div>
  }
</div>
