import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { NgxOvNavigationService } from './navigation.service';
import { NavigationItem } from './navigation.model';
import { LinkService } from '../../../services/link.service';
import { ClickEqualsEnterDirective } from '../../../directives/click-equals-enter';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
    selector: 'ngx-ov-navigation-sub-item',
    templateUrl: './navigation-sub-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [RouterLink, RouterLinkActive, ClickEqualsEnterDirective]
})
export class NgxOvNavigationSubItemComponent {
  @Input() linkTitle: string;
  @Input() linkUrl: string;
  @Input() external = false;
  @Input() separator: string;
  @Input() iconClass: string;
  @Input() badge: string;
  @Input() bottom = false;
  @Input() children: NavigationItem[];
  @Output() menuClicked = new EventEmitter();

  constructor(
    private linkService: LinkService,
    public navigationService: NgxOvNavigationService
  ) {}

  navigate(linkUrl: string) {
    this.navigationService.collapseMobile();

    this.menuClicked.emit();

    if (linkUrl) {
      this.linkService.navigate(linkUrl);
    }
  }
}
