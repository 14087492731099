import { Injectable, signal } from '@angular/core';
import { INgxOvDataTableColumn } from '../data-table.utils';

@Injectable({ providedIn: 'root' })
export class NgxOvDataTableSettingsService {
  localstorageDataTableColumns = signal<INgxOvDataTableColumn[]>(null);

  init(datatableId: string) {
    const localstorageDataTableColumns = localStorage.getItem(
      this.getLocalstorageKey(datatableId)
    );
    if (localstorageDataTableColumns !== null) {
      this.localstorageDataTableColumns.set(
        JSON.parse(localstorageDataTableColumns)
      );
    }
  }

  saveToLocalStorage(datatableId: string) {
    localStorage.setItem(
      this.getLocalstorageKey(datatableId),
      JSON.stringify(this.localstorageDataTableColumns())
    );
  }

  getLocalstorageKey(datatableId: string) {
    return 'ngx-ov-datatableColumns-' + datatableId;
  }

  setDataTableSettings(
    identifier: string,
    dataTableColumns: INgxOvDataTableColumn[]
  ) {
    this.localstorageDataTableColumns.set(dataTableColumns);
    this.saveToLocalStorage(identifier);
  }
}
