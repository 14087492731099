import {
  Directive,
  Output,
  EventEmitter,
  Renderer2,
  ElementRef,
  OnDestroy,
  OnInit
} from '@angular/core';

@Directive({
  selector: '[click.stop]',
  standalone: true
})
export class StopPropagationDirective implements OnInit, OnDestroy {
  @Output('click.stop') stopPropEvent = new EventEmitter();
  unsubscribe;

  constructor(
    private renderer: Renderer2,
    private element: ElementRef
  ) {}

  ngOnInit() {
    this.unsubscribe = this.renderer.listen(
      this.element.nativeElement,
      'click',
      (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.stopPropEvent.emit(event);
      }
    );
  }

  ngOnDestroy() {
    this.unsubscribe();
  }
}
