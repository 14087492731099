import {
  Component,
  ContentChild,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {
  FormsModule,
  NG_ASYNC_VALIDATORS,
  NG_VALIDATORS,
  NgControl,
  NgModel
} from '@angular/forms';
import * as R from 'ramda';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { FormsBase } from '../forms-base';
import { TranslationsService } from '../../../services/translations.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { trackItemByIndex } from '../../../helper';
import { ExtendedBindLabelValuePipe } from './pipes/getExtendedBindLabelValue.pipe';
import { HighlightSearchPipe } from '../../../pipes/highlight.pipe';
import { NgxOvInputValidationComponent } from '../input-validation/input-validation.component';
import { NgxOvPillComponent } from '../../diverse/pill/pill.component';
import { ClickEqualsEnterDirective } from '../../../directives/click-equals-enter';
import { NgxOvLinkComponent } from '../../default-layout-blokken/link/link.component';
import { NgxOvInputLabelComponent } from '../input-label/input-label.component';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';

let identifier = 0;

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-select',
  templateUrl: './select.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [ExtendedBindLabelValuePipe],
  standalone: true,
  imports: [
    NgxOvInputLabelComponent,
    NgxOvLinkComponent,
    ClickEqualsEnterDirective,
    NgSelectModule,
    FormsModule,
    NgxOvPillComponent,
    NgTemplateOutlet,
    NgxOvInputValidationComponent,
    ExtendedBindLabelValuePipe,
    HighlightSearchPipe,
    AsyncPipe
  ]
})
export class NgxOvSelectComponent
  extends FormsBase<any[] | any>
  implements OnDestroy, OnInit, OnChanges
{
  public _extendedItems: any[];
  public _extendedBindLabel;
  public _isOpen = false;
  generalTrackByFn = trackItemByIndex;

  @Input() compareFn: (item: any, selected: any) => boolean;

  // custom inputs
  @Input() label: string;
  @Input() classes: string;
  @Input() isDisabled: boolean;
  @Input() isBlocked: boolean;

  // Inputs from ng-select
  @Input() bindLabel: string;

  _bindValue: string;
  get bindValue(): string {
    return this._bindValue ? `value.${this._bindValue}` : 'value';
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('bindValue') set bindValue(value: string) {
    this._bindValue = value;
  }

  @Input() closeOnSelect = true;
  @Input() groupBy: string;
  @Input() isLoading: boolean;
  @Input() multiple: boolean;
  @Input() showCheckbox: boolean;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() searchable: boolean;
  @Input() clearable = true;
  @Input() isReadonly: boolean;
  @Input() searchFn: any;
  @Input() notFoundText;
  @Input() highlightSearch = false;
  @Input() fixedItemsValues: any | any[];
  @Input() fixedItemsBindValue: string;
  @Input() items;
  @Input() closeText;
  @Input() typeToSearchText;
  @Input() typeahead: Subject<string>;
  @Input() trackSelectItemsByFn = null;
  @Input() virtualScroll = false;
  @Input() appendToSelector: string;
  @Input() emptyOnClose: boolean;

  @Output()
  blur: EventEmitter<any> = new EventEmitter();
  @Output()
  change: EventEmitter<any> = new EventEmitter();
  @Output()
  close: EventEmitter<any> = new EventEmitter();
  @Output()
  selectOption: EventEmitter<any> = new EventEmitter();
  @Output()
  clear: EventEmitter<any> = new EventEmitter();
  @Output()
  search: EventEmitter<any> = new EventEmitter();

  @ViewChild(NgModel, { static: true }) model: NgModel;
  @ViewChild('spy', { static: false }) select: NgModel;
  @ViewChild('ngSelect') ngSelect: NgSelectComponent;
  @ViewChild('outlet', { read: ViewContainerRef, static: true })
  outletRef: ViewContainerRef;
  @ViewChild('content', { read: TemplateRef, static: true })
  contentRef: TemplateRef<any>;

  @ContentChild('ngxOvOptionTemplate') optionTemplate: TemplateRef<any>;
  @ContentChild('ngxOvLabelTemplate') labelTemplate: TemplateRef<any>;

  public identifier = `form-select-${(identifier += 1)}`;

  // -------------------------------------------------------------------------
  // Copy variables
  // -------------------------------------------------------------------------
  public notFoundTextCopy;
  public closeTextCopy;
  public typeToSearchTextCopy;

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
    @Self() @Optional() ngControl: NgControl,
    translationsService: TranslationsService,
    @Optional() translateService: TranslateService
  ) {
    super(
      validators,
      asyncValidators,
      ngControl,
      translateService,
      translationsService
    );
  }

  // -------------------------------------------------------------------------
  // Lifecycle methods
  // -------------------------------------------------------------------------
  ngOnInit(): void {
    this.getCopy();
    if (this.translateService) {
      this.translateService.onLangChange
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.getCopy();
        });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    super.init();
    let itemsOrBindLabelChange = false;
    for (const propName in changes) {
      if (propName === 'items' || propName === 'bindLabel') {
        itemsOrBindLabelChange = true;
      }
    }

    if (itemsOrBindLabelChange) {
      this._extendedBindLabel = this.bindLabel
        ? `value.${this.bindLabel}`
        : 'value';

      this.itemsUpdated();
    }
  }

  clearItem(fn: any, item: any) {
    if (!this.isDisabled) {
      fn(item);
    }
  }

  isSelectPristine(): boolean {
    return this.model ? this.model.pristine : true;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onBlur(value: string): void {
    this.closeSelect();
    this.blur.emit(value);
  }

  onClose(e: any): void {
    this.close.emit(e);

    if (this.emptyOnClose) {
      this._extendedItems = [];
    }
  }

  onSelect(e: any): void {
    this.selectOption.emit(e);
  }

  onClear(): void {
    this.clear.emit();
  }

  onSearch(e: any): void {
    this.search.emit(e);
  }

  onChange(): void {
    this.change.emit(this.value);
    if (this.closeOnSelect) {
      this.closeSelect();
    }
  }

  getExtendedItems() {
    const searchOptions = this.getSearchableOptions
      ? this.getSearchableOptions().map((item) => {
          return {
            value: item,
            fixed: false,
            first: false,
            bindLabel: this.bindLabel,
            disabled: item?.disabled
          };
        })
      : [];
    const fixedOptions = this.getFixedOptions()
      ? this.getFixedOptions().map((item) => {
          return {
            value: item,
            fixed: true,
            first: false,
            bindLabel: this.bindLabel,
            disabled: item?.disabled
          };
        })
      : [];
    if (fixedOptions.length) {
      fixedOptions[0].first = true;
    }

    return [...searchOptions, ...fixedOptions];
  }

  getSearchableOptions(): any[] {
    return this.items
      ? this.items.filter((item) => !this.isFixedOption(item))
      : [];
  }

  getFixedOptions(): any[] {
    return this.items
      ? this.items.filter((item) => this.isFixedOption(item))
      : [];
  }

  getFixedOptionValue(item: any): any {
    return this.fixedItemsBindValue
      ? R.path(this.fixedItemsBindValue.split('.'), item)
      : item;
  }

  isFixedOption(item: any): boolean {
    if (item) {
      const fixedOptionValue = this.getFixedOptionValue(item);

      if (Array.isArray(this.fixedItemsValues)) {
        return this.fixedItemsValues.indexOf(fixedOptionValue) > -1;
      }
      return this.fixedItemsValues === fixedOptionValue;
    }
    return true;
  }

  /**
   * Default search function used if no custom search function is provided.
   * Fixed items will always match the search.
   * Non-fixed items text value will be matched by term text.
   * Note: this function should cannot call another function!
   * @param term: term to be searched
   * @param item: item from select
   */
  fixedOptionSearchFn(term: string, item: any): boolean {
    if (item) {
      if (item.fixed) {
        // Always show fixed items
        return true;
      } else {
        // Search text of item value
        let compareValue = item.value
          ? item.bindLabel
            ? item.value[item.bindLabel]
            : item.value
          : item;
        if (
          !(typeof compareValue === 'string' || compareValue instanceof String)
        ) {
          compareValue = String(compareValue);
        }
        return (
          compareValue.toLocaleLowerCase().indexOf(term.toLowerCase()) > -1
        );
      }
    }
    return false;
  }

  getCompareFn(item, selected) {
    if (this.bindValue !== 'value') {
      const props = this.bindValue.split('.');
      const getValue = R.path(props);
      const selectedValue = getValue(item);

      return selected === selectedValue;
    }

    return item.value === selected;
  }

  openSelect() {
    this._isOpen = true;
  }

  closeSelect() {
    this._isOpen = false;
    this.touch();
  }

  getExtendedBindLabelValue(item: any) {
    const props = this._extendedBindLabel.split('.');
    const getValue = R.path(props);
    return getValue(item);
  }

  getItemByValue(value: any) {
    const props = this.bindValue.split('.');
    const getValue = R.path(props);
    return this._extendedItems.find((item) => {
      return value === getValue(item);
    });
  }

  getDataCyCode(item: any) {
    if (item?.value) {
      return item?.value?.code ? item?.value?.code : item?.value;
    }
    return null;
  }

  // -------------------------------------------------------------------------
  // Translation
  // -------------------------------------------------------------------------
  getCopy() {
    this.notFoundTextCopy =
      this.notFoundText ?? this.getNgxOvUiTranslation('NOT_FOUND_TEXT');
    this.closeTextCopy =
      this.closeText ?? this.getNgxOvUiTranslation('CLOSE_TEXT');
    this.typeToSearchTextCopy =
      this.typeToSearchText ??
      this.getNgxOvUiTranslation('TYPE_TO_SEARCH_TEXT');
  }

  getNgxOvUiTranslation(code: string) {
    const translationKey = `FORMS.SELECT.${code.toUpperCase()}`;
    return this.translationsService.getDefaultComponentTranslation(
      translationKey
    );
  }

  /*
    https://mvgond.atlassian.net/browse/OAM-1159
    Bij het asynchroon inladen van de items werkt de comparfn van de ng-select component niet meer.
    Vermits we onze values aanpassen om de fixed waarden te kunnen ondersteunen.
    Enige oplossing die hiervoor gevonden is, is om de ng-select component opnieuw te laten renderen
    wanneer er wijzigingen zijn in de items.
   */
  private itemsUpdated() {
    this._extendedItems = this.getExtendedItems();
    const vorigeWaarde = this.value;
    if (vorigeWaarde !== null) {
      this.ngControl?.control.setValue(null, {
        emitViewToModelChange: false,
        emitModelToViewChange: false
      });
      setTimeout(() => {
        this.ngControl?.control.setValue(vorigeWaarde, {
          emitViewToModelChange: false,
          emitModelToViewChange: false
        });
      }, 0);
    }
  }
}
