import { CdkStepper } from '@angular/cdk/stepper';
import { Directive, HostBinding, HostListener, Input } from '@angular/core';

/** Button that moves to the next step in a stepper workflow. */
@Directive({
    selector: '[ovStepperNext]',
    standalone: true
})
export class OvStepperNextDirective {
  @HostBinding('class.ov-stepper-next')
  @Input()
  type: any;

  @HostListener('click')
  @HostListener('buttonClick')
  myClick() {
    this._stepper.next();
  }

  constructor(public _stepper: CdkStepper) {}
}

/** Button that moves to the previous step in a stepper workflow. */
@Directive({
    selector: '[ovStepperPrevious]',
    standalone: true
})
export class OvStepperPreviousDirective {
  @HostBinding('class.ov-stepper-previous')
  @Input()
  type: any;

  @HostListener('click')
  @HostListener('buttonClick')
  myClick() {
    this._stepper.previous();
  }

  constructor(public _stepper: CdkStepper) {}
}
