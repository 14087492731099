import { Pipe, PipeTransform } from '@angular/core';

import { IPager } from '../pager/services/pager.service';

function isNumeric(value) {
  return /^-?\d+$/.test(value);
}

@Pipe({
    name: 'pageDataTable', pure: false,
    standalone: true
})
export class PageDataTablePipe implements PipeTransform {
  transform(data: any[], pager: IPager): any[] {
    if (pager && isNumeric(pager.startIndex) && isNumeric(pager.endIndex))
      return data.slice(pager.startIndex, pager.endIndex + 1);
    return data;
  }
}
