/**
 * Naric
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.3.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import {
  AbstractControlOptions,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn
} from '@angular/forms';

export interface ILandOutputRestDto {
  auto_erk?: boolean;
  landcode?: string;
  landnaam?: string;
}


export class LandOutputRestDto implements ILandOutputRestDto {
  auto_erk?: boolean;
  landcode?: string;
  landnaam?: string;
}

export class LandOutputRestDtoFormHelper {

  static generateFormWithSwaggerValidators(formBuilder: UntypedFormBuilder, controlsConfig: { [key: string]: any; }, options?: AbstractControlOptions | { [key: string]: any; } | null): UntypedFormGroup {
    if (controlsConfig["auto_erk"] != undefined && !(controlsConfig["auto_erk"] instanceof UntypedFormGroup) && !(controlsConfig["auto_erk"] instanceof UntypedFormArray)) {
      controlsConfig["auto_erk"][1] = controlsConfig["auto_erk"][1].concat(LandOutputRestDtoValidator.auto_erk_swagger_validator());
    }
    if (controlsConfig["landcode"] != undefined && !(controlsConfig["landcode"] instanceof UntypedFormGroup) && !(controlsConfig["landcode"] instanceof UntypedFormArray)) {
      controlsConfig["landcode"][1] = controlsConfig["landcode"][1].concat(LandOutputRestDtoValidator.landcode_swagger_validator());
    }
    if (controlsConfig["landnaam"] != undefined && !(controlsConfig["landnaam"] instanceof UntypedFormGroup) && !(controlsConfig["landnaam"] instanceof UntypedFormArray)) {
      controlsConfig["landnaam"][1] = controlsConfig["landnaam"][1].concat(LandOutputRestDtoValidator.landnaam_swagger_validator());
    }
    if (options) {
      return formBuilder.group(controlsConfig, options);
    }
    return formBuilder.group(controlsConfig);
  }
}

export class LandOutputRestDtoValidator {

  /**
   * Active Validators for auto_erk:
   */
  static auto_erk_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    return validators;
  };

  /**
   * Active Validators for landcode:
   */
  static landcode_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    return validators;
  };

  /**
   * Active Validators for landnaam:
   */
  static landnaam_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    return validators;
  };

}



