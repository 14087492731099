import {
  AbstractControl,
  AsyncValidatorFn,
  Validator,
  ValidatorFn,
  Validators
} from '@angular/forms';

import { of } from 'rxjs';

export interface ValidationResult {
  [validator: string]: string | boolean;
}

export type AsyncValidatorArray = Array<Validator | AsyncValidatorFn>;

export type ValidatorArray = Array<Validator | ValidatorFn>;

const normalizeValidator = (
  validator: Validator | ValidatorFn
): ValidatorFn | AsyncValidatorFn => {
  const func = (validator as Validator).validate.bind(validator);
  if (typeof func === 'function') {
    return (c: AbstractControl) => func(c);
  }
  return validator as ValidatorFn | AsyncValidatorFn;
};

export const composeValidators = (
  validators: ValidatorArray
): AsyncValidatorFn | ValidatorFn | null => {
  if (validators == null || validators.length === 0) {
    return Validators.compose([]);
  }
  return Validators.compose(validators.map(normalizeValidator));
};
/*
export const validate = (
  validators: ValidatorArray,
  asyncValidators: AsyncValidatorArray
) => {
  return (control: AbstractControl) => {
    const synchronousValid = () => {
      const composeValidatorsFn = composeValidators(validators);
      if (composeValidatorsFn == null) {
        return;
      }

      // eslint-disable-next-line consistent-return
      return composeValidatorsFn(control);
    };

    if (asyncValidators) {
      const asyncValidator = composeValidators(asyncValidators);
      if (asyncValidator == null) {
        return of(null);
      }

      const asyncValidatorFn = asyncValidator(control);
      if (asyncValidatorFn == null) {
        return of(null);
      }

      // eslint-disable-next-line array-callback-return,consistent-return
      return asyncValidatorFn.map((v: any) => {
        const secondary = synchronousValid();
        if (secondary || v) {
          // compose async and sync validator results
          return (Object as any).assign({}, secondary, v);
        }
      });
    }

    if (validators) {
      return of(synchronousValid());
    }

    return of(null);
  };
};
*/
export const message = (validator: ValidationResult, key: string): string => {
  switch (key) {
    case 'email':
      return 'Ongeldig e-mailadres.';
    case 'required':
      return 'Verplicht veld.';
    case 'pattern':
      return 'Value does not match required pattern';
    case 'minlength':
      if (validator[key] && (validator[key] as any).requiredLength) {
        return `Minimum van ${
          (validator[key] as any).requiredLength
        } karakters`;
      }
      return 'Minimum N karakters';
    case 'maxlength':
      if (validator[key] && (validator[key] as any).requiredLength) {
        return `Maximum van ${
          (validator[key] as any).requiredLength
        } karakters`;
      }
      return 'Maximum N karakters';
    default:
      break;
  }

  return typeof validator[key] === 'string'
    ? (validator[key] as string)
    : `Validation failed: ${key}`;
};
