import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ContentChildren,
  Input,
  QueryList
} from '@angular/core';
import { trackItemByIndex } from '../../../helper';
import { NgxOvMenubarItemComponent } from './menubar-item.component';
import { PortalMenubarDirective } from './portals/portal-menubar.directive';
import { NgxOvModalService } from '../../meldingen/modal/modal.service';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ClickEqualsEnterDirective } from '../../../directives/click-equals-enter';
import { PortalModule } from '@angular/cdk/portal';

@Component({
  selector: 'ngx-ov-menubar',
  templateUrl: './menubar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ClickEqualsEnterDirective,
    NgxOvMenubarItemComponent,
    PortalModule
  ],
  providers: [NgxOvModalService]
})
export class NgxOvMenubarComponent {
  private _image = false;
  @Input() text: string;
  @Input() isOvTheme = false;

  get image(): boolean {
    return this._image;
  }

  @Input() set image(value: boolean) {
    this._image = value;
  }

  trackByFn = trackItemByIndex;

  @ContentChildren(NgxOvMenubarItemComponent)
  items: QueryList<NgxOvMenubarItemComponent>;

  @ContentChild(PortalMenubarDirective, { static: false })
  extraContentPortal: PortalMenubarDirective;

  constructor(
    private modalService: NgxOvModalService,
    private router: Router
  ) {}

  // -------------------------------------------------------------------------
  // Events
  // -------------------------------------------------------------------------
  onSelectitem(selecteditem: NgxOvMenubarItemComponent) {
    if (selecteditem.linkUrl) {
      this.router.navigate([selecteditem.linkUrl]);
    }
  }

  onOpenModal(id: string) {
    this.modalService.open(id);
  }
}
