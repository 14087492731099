import { ChangeDetectionStrategy, Component } from '@angular/core';

// =============================================================================
// Config
// =============================================================================
@Component({
    selector: 'ngx-ov-highlight-list',
    templateUrl: './highlight-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})

// =============================================================================
// Definition
// =============================================================================
export class NgxOvHighlightListComponent {}
