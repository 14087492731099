@if (isOpen) {
  <div
    @slideInOutTransition
    class="c-snackbar c-snackbar--{{ position }}"
    [class.c-snackbar--error]="type === 'ERROR'"
    [class.c-snackbar--warning]="type === 'WARNING'"
    [class.c-snackbar--success]="type === 'SUCCESS'"
    [class.c-snackbar--small]="isSmall"
    role="alert"
  >
    @if (showProgressBar) {
      <div class="c-snackbar__progressbar">
        <div [style.animation-duration.s]="timeShown"></div>
      </div>
    }

    <div class="c-snackbar__body">
      <div class="c-snackbar__icon">
        <span class="c-icon fas fa-{{ _icon }}" aria-hidden="true"></span>
      </div>
      <div class="c-snackbar__content">
        <div class="c-snackbar__content_toprow">
          @if (title) {
            <div class="c-snackbar__title">{{ title }}</div>
          }
          @if (isCloseable) {
            <span
              class="c-icon__closing fas fa-times"
              (click)="close()"
              role="button"
              aria-label="close"
            ></span>
          }
        </div>
        <p class="c-snackbar__message">
          <ng-content></ng-content>
          @if (message) {
            <span [innerHTML]="message"></span>
          }
        </p>
        @if (footer) {
          <div class="c-snackbar__footer">
            <ng-template [cdkPortalOutlet]="footer"></ng-template>
          </div>
        }
      </div>
    </div>
  </div>
}
