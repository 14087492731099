<ngx-ov-main-layout [showMenubar]="hideLeftNav">
  <ng-template ngxOvLayoutPortalCookiebar>
    <ngx-ov-cookiebar
      [currentLanguage]="languageService.getCurrentLanguage()"
      [languages]="languageService.getLanguages()"
      [cookies]="cookies"
      [nameApplication]="'Vlaanderen.be'"
      [toonCookieBar]="toonCookieBar$ | async"
      (acceptCookies)="onAcceptCookies($event)"
      (setLanguage)="onSetLanguage($event)"
    >
      Akkoord?
    </ngx-ov-cookiebar>
  </ng-template>

  @if (isBrowserNotSupported) {
    <ngx-ov-global-alert
      type="WARNING"
      message="Je browser ({{ browserNameAndVersion.name }} - {{
        browserNameAndVersion.version
      }}) wordt niet meer ondersteund. Update je browser voor de beste ervaring met onze applicatie"
    ></ngx-ov-global-alert>
  }

  <ngx-ov-global-migratie-alert data-cy="global-migratie-alert" />

  <ng-template ngxOvLayoutPortalHeader>
    <ngx-ov-header [classes]="'c-header--ahovoks'">
      <ng-template ngxOvHeaderPortalContent>
        <ngx-ov-language-switch
          [languages]="languageService.getLanguages()"
          [currentLanguageCode]="languageService.getCurrentLanguage()?.code"
          (selectLanguage)="onSetLanguage($event)"
          [header]="'Kies een taal 2'"
          [attr.data-cy]="'language-header'"
        ></ngx-ov-language-switch>

        @if (!loggedIn) {
          <ngx-ov-login-page-login-button
            [label]="'Aanmelden'"
            [icon]="'fas fa-door-open'"
            (click)="loggedIn = true"
          ></ngx-ov-login-page-login-button>
        } @else {
          <ngx-ov-flyout [trigger]="'AA'" [header]="'Menu'">
            <ngx-ov-flyout-item (clicked)="loggedIn = false"
              >Afmelden</ngx-ov-flyout-item
            >
          </ngx-ov-flyout>
        }

        <ngx-ov-flyout
          [trigger]="
            'Hulp nodig <i class=\'fas fa-question-circle\' aria-hidden=\'true\'></i>'
          "
          [secondary]="true"
          [header]="
            '<i class=\'fas fa-question-circle\' aria-hidden=\'true\'></i> Contacteer ons'
          "
          [position]="'left'"
          [isLargeVersion]="true"
        >
          <ng-template ngxOvPortalFlyoutTrigger>
            <div [attr.data-cy]="'flyout-trigger-meldingen'">
              <span [ngxOvBadge]="'5'" class="pr-large"> Meldingen </span>
            </div>
          </ng-template>
          <ngx-ov-flyout-item>
            <div>
              <div>
                <strong style="padding: 0px">Bel ons op het nummer 1700</strong>
              </div>
              <div>
                Elke werkdag tussen 9u en 19u<i
                  class="fas fa-phone ml-small"
                  aria-hidden="true"
                ></i>
              </div>
            </div>
          </ngx-ov-flyout-item>
          <ngx-ov-flyout-item [link]="'/s/contact'">
            <div class="flex">
              <div>
                Stuur ons een mail via het contactformulier
                <i class="fas fa-envelope ml-small" aria-hidden="true"></i>
              </div>
            </div>
          </ngx-ov-flyout-item>
          <ngx-ov-flyout-item (click)="toggleTranslations()">
            <div class="flex">
              <div>Toggle translation code</div>
            </div>
          </ngx-ov-flyout-item>
          <ngx-ov-flyout-item
            [attr.data-cy]="'flyout-item-wissel-omgeving'"
            (click)="wisselOmgeving()"
          >
            <div fxLayout="row">
              <div>Toggle shadow</div>
            </div>
          </ngx-ov-flyout-item>
        </ngx-ov-flyout>
      </ng-template>
      <ng-template
        ngxOvHeaderPortalContent
        ngxOvHeaderPortalContentPosition="left"
      >
        <ngx-ov-header-title-link
          [label]="'Vlaanderen'"
          [link]="'https://www.vlaanderen.be/'"
          [hideMobile]="true"
        >
        </ngx-ov-header-title-link>
        <ngx-ov-header-title-link [label]="'Stijlgids'" [link]="'/'">
        </ngx-ov-header-title-link>
      </ng-template>
    </ngx-ov-header>
    @if (!(toonCookieBar$ | async)) {
      <div [class.hidden-desktop]="!hideLeftNav">
        <ngx-ov-menubar [isOvTheme]="false" [image]="true">
          <ngx-ov-menubar-item
            linkTitle="Home"
            linkUrl="/landingpage"
            icon="fas fa-home"
          ></ngx-ov-menubar-item>
          <ngx-ov-menubar-item
            linkTitle="Logging"
            linkUrl="/logging"
            icon="fas fa-history"
          ></ngx-ov-menubar-item>
          <ngx-ov-menubar-item
            linkTitle="Headless CMS"
            linkUrl="/cms"
            icon="fas fa-circle-user"
          ></ngx-ov-menubar-item>
        </ngx-ov-menubar>
      </div>
    }
  </ng-template>

  <ng-template ngxOvLayoutPortalLeftNavigation>
    <div
      [class.hidden-desktop]="hideLeftNav"
      [class.hidden-mobile]="hideLeftNav"
      style="height: 100%"
    >
      <ngx-ov-navigation
        [title]="'Menu'"
        [menuItems]="menuItems"
        [roles]="['ADMIN', 'LEAD']"
        [collapsible]="true"
        [hideQuicklinkLabels]="false"
      >
      </ngx-ov-navigation>
    </div>
  </ng-template>

  <ng-template ngxOvLayoutPortalFooter>
    @if (!shadow) {
      <ngx-ov-footer
        [title]="title"
        [logoTitle]="logoTitle"
        [logoTagline]="logoTagline"
        [infoPublisher]="infoPublisher"
        [extraLinks]="footerExtraLinks"
        [versions]="versions"
        [languages]="languageService.getLanguages()"
        (selectLanguage)="onSetLanguage($event)"
      ></ngx-ov-footer>
    } @else {
      <div class="pb-20 tablet:pb-0">
        <ngx-ov-internal-footer [versions]="versions"> </ngx-ov-internal-footer>
      </div>
    }
  </ng-template>

  <ng-content></ng-content>
</ngx-ov-main-layout>
<ngx-ov-bevestiging></ngx-ov-bevestiging>
