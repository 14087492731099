import { Directive, Input, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[ngxOvBadge]',
  standalone: true
})
export class NgxOvBadgeDirective {
  @Input() ngxOvBadgeBackgroundColor: string;
  @Input() ngxOvBadgeColor: string;
  @Input('ngxOvBadge')
  set badgeText(badgeText: string) {
    if (this.badgeElement) {
      this.renderer.removeChild(
        this.elementRef.nativeElement,
        this.badgeElement
      );
    }

    this.createBadge(badgeText);
  }

  // TODO: can this be deleted?
  private wrapperElement: HTMLElement;
  private badgeElement: HTMLElement;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private renderer: Renderer2
  ) {}

  createBadge(badgeText: string) {
    if (badgeText?.trim().length) {
      const text = this.renderer.createText(badgeText);

      this.badgeElement = this.renderer.createElement('div');
      this.badgeElement.classList.add('c-badge');

      this.renderer.setStyle(
        this.badgeElement,
        'background-color',
        this.ngxOvBadgeBackgroundColor
          ? this.ngxOvBadgeBackgroundColor
          : '#FD1A20'
      );
      this.renderer.setStyle(
        this.badgeElement,
        'color',
        this.ngxOvBadgeColor ? this.ngxOvBadgeColor : '#FFFFFF'
      );

      this.renderer.appendChild(this.badgeElement, text);

      this.elementRef.nativeElement.appendChild(this.badgeElement);
      this.elementRef.nativeElement.classList.add('c-badge__wrapper');
    }
  }
}
