/**
 * Naric
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.3.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import {
  AbstractControlOptions,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn
} from '@angular/forms';
import {SwaggerValidators} from '@ov/ngx-ov-utils';

export interface IBenodigdeDocumentenSelectiecriteriaRestDto {
  beroep?: string;
  gereglementeerd_beroep?: boolean;
  heeft_aanvullende_opleiding_gevolgd: boolean;
  heeft_adres_in_wallonie: boolean;
  heeft_scriptie_geschreven: boolean;
  heeft_stage_gelopen: boolean;
  heeft_voorgaand_diploma: boolean;
  is_doorverwezen_ho?: boolean;
  land: string;
  naam_op_diploma_zelfde_als_op_id?: boolean;
  onderwijsniveau: string;
  procedure: string;
  vakgebied?: string;
  verdragen: Array<string>;
  vluchteling?: boolean;
  vrijstellingsreden: string;
}


export class BenodigdeDocumentenSelectiecriteriaRestDto implements IBenodigdeDocumentenSelectiecriteriaRestDto {
  beroep?: string;
  gereglementeerd_beroep?: boolean;
  heeft_aanvullende_opleiding_gevolgd: boolean;
  heeft_adres_in_wallonie: boolean;
  heeft_scriptie_geschreven: boolean;
  heeft_stage_gelopen: boolean;
  heeft_voorgaand_diploma: boolean;
  is_doorverwezen_ho?: boolean;
  land: string;
  naam_op_diploma_zelfde_als_op_id?: boolean;
  onderwijsniveau: string;
  procedure: string;
  vakgebied?: string;
  verdragen: Array<string>;
  vluchteling?: boolean;
  vrijstellingsreden: string;
}

export class BenodigdeDocumentenSelectiecriteriaRestDtoFormHelper {

  static generateFormWithSwaggerValidators(formBuilder: UntypedFormBuilder, controlsConfig: { [key: string]: any; }, options?: AbstractControlOptions | { [key: string]: any; } | null): UntypedFormGroup {
    if (controlsConfig["beroep"] != undefined && !(controlsConfig["beroep"] instanceof UntypedFormGroup) && !(controlsConfig["beroep"] instanceof UntypedFormArray)) {
      controlsConfig["beroep"][1] = controlsConfig["beroep"][1].concat(BenodigdeDocumentenSelectiecriteriaRestDtoValidator.beroep_swagger_validator());
    }
    if (controlsConfig["gereglementeerd_beroep"] != undefined && !(controlsConfig["gereglementeerd_beroep"] instanceof UntypedFormGroup) && !(controlsConfig["gereglementeerd_beroep"] instanceof UntypedFormArray)) {
      controlsConfig["gereglementeerd_beroep"][1] = controlsConfig["gereglementeerd_beroep"][1].concat(BenodigdeDocumentenSelectiecriteriaRestDtoValidator.gereglementeerd_beroep_swagger_validator());
    }
    if (controlsConfig["heeft_aanvullende_opleiding_gevolgd"] != undefined && !(controlsConfig["heeft_aanvullende_opleiding_gevolgd"] instanceof UntypedFormGroup) && !(controlsConfig["heeft_aanvullende_opleiding_gevolgd"] instanceof UntypedFormArray)) {
      controlsConfig["heeft_aanvullende_opleiding_gevolgd"][1] = controlsConfig["heeft_aanvullende_opleiding_gevolgd"][1].concat(BenodigdeDocumentenSelectiecriteriaRestDtoValidator.heeft_aanvullende_opleiding_gevolgd_swagger_validator());
    }
    if (controlsConfig["heeft_adres_in_wallonie"] != undefined && !(controlsConfig["heeft_adres_in_wallonie"] instanceof UntypedFormGroup) && !(controlsConfig["heeft_adres_in_wallonie"] instanceof UntypedFormArray)) {
      controlsConfig["heeft_adres_in_wallonie"][1] = controlsConfig["heeft_adres_in_wallonie"][1].concat(BenodigdeDocumentenSelectiecriteriaRestDtoValidator.heeft_adres_in_wallonie_swagger_validator());
    }
    if (controlsConfig["heeft_scriptie_geschreven"] != undefined && !(controlsConfig["heeft_scriptie_geschreven"] instanceof UntypedFormGroup) && !(controlsConfig["heeft_scriptie_geschreven"] instanceof UntypedFormArray)) {
      controlsConfig["heeft_scriptie_geschreven"][1] = controlsConfig["heeft_scriptie_geschreven"][1].concat(BenodigdeDocumentenSelectiecriteriaRestDtoValidator.heeft_scriptie_geschreven_swagger_validator());
    }
    if (controlsConfig["heeft_stage_gelopen"] != undefined && !(controlsConfig["heeft_stage_gelopen"] instanceof UntypedFormGroup) && !(controlsConfig["heeft_stage_gelopen"] instanceof UntypedFormArray)) {
      controlsConfig["heeft_stage_gelopen"][1] = controlsConfig["heeft_stage_gelopen"][1].concat(BenodigdeDocumentenSelectiecriteriaRestDtoValidator.heeft_stage_gelopen_swagger_validator());
    }
    if (controlsConfig["heeft_voorgaand_diploma"] != undefined && !(controlsConfig["heeft_voorgaand_diploma"] instanceof UntypedFormGroup) && !(controlsConfig["heeft_voorgaand_diploma"] instanceof UntypedFormArray)) {
      controlsConfig["heeft_voorgaand_diploma"][1] = controlsConfig["heeft_voorgaand_diploma"][1].concat(BenodigdeDocumentenSelectiecriteriaRestDtoValidator.heeft_voorgaand_diploma_swagger_validator());
    }
    if (controlsConfig["is_doorverwezen_ho"] != undefined && !(controlsConfig["is_doorverwezen_ho"] instanceof UntypedFormGroup) && !(controlsConfig["is_doorverwezen_ho"] instanceof UntypedFormArray)) {
      controlsConfig["is_doorverwezen_ho"][1] = controlsConfig["is_doorverwezen_ho"][1].concat(BenodigdeDocumentenSelectiecriteriaRestDtoValidator.is_doorverwezen_ho_swagger_validator());
    }
    if (controlsConfig["land"] != undefined && !(controlsConfig["land"] instanceof UntypedFormGroup) && !(controlsConfig["land"] instanceof UntypedFormArray)) {
      controlsConfig["land"][1] = controlsConfig["land"][1].concat(BenodigdeDocumentenSelectiecriteriaRestDtoValidator.land_swagger_validator());
    }
    if (controlsConfig["naam_op_diploma_zelfde_als_op_id"] != undefined && !(controlsConfig["naam_op_diploma_zelfde_als_op_id"] instanceof UntypedFormGroup) && !(controlsConfig["naam_op_diploma_zelfde_als_op_id"] instanceof UntypedFormArray)) {
      controlsConfig["naam_op_diploma_zelfde_als_op_id"][1] = controlsConfig["naam_op_diploma_zelfde_als_op_id"][1].concat(BenodigdeDocumentenSelectiecriteriaRestDtoValidator.naam_op_diploma_zelfde_als_op_id_swagger_validator());
    }
    if (controlsConfig["onderwijsniveau"] != undefined && !(controlsConfig["onderwijsniveau"] instanceof UntypedFormGroup) && !(controlsConfig["onderwijsniveau"] instanceof UntypedFormArray)) {
      controlsConfig["onderwijsniveau"][1] = controlsConfig["onderwijsniveau"][1].concat(BenodigdeDocumentenSelectiecriteriaRestDtoValidator.onderwijsniveau_swagger_validator());
    }
    if (controlsConfig["procedure"] != undefined && !(controlsConfig["procedure"] instanceof UntypedFormGroup) && !(controlsConfig["procedure"] instanceof UntypedFormArray)) {
      controlsConfig["procedure"][1] = controlsConfig["procedure"][1].concat(BenodigdeDocumentenSelectiecriteriaRestDtoValidator.procedure_swagger_validator());
    }
    if (controlsConfig["vakgebied"] != undefined && !(controlsConfig["vakgebied"] instanceof UntypedFormGroup) && !(controlsConfig["vakgebied"] instanceof UntypedFormArray)) {
      controlsConfig["vakgebied"][1] = controlsConfig["vakgebied"][1].concat(BenodigdeDocumentenSelectiecriteriaRestDtoValidator.vakgebied_swagger_validator());
    }
    if (controlsConfig["verdragen"] != undefined && !(controlsConfig["verdragen"] instanceof UntypedFormGroup) && !(controlsConfig["verdragen"] instanceof UntypedFormArray)) {
      controlsConfig["verdragen"][1] = controlsConfig["verdragen"][1].concat(BenodigdeDocumentenSelectiecriteriaRestDtoValidator.verdragen_swagger_validator());
    }
    if (controlsConfig["vluchteling"] != undefined && !(controlsConfig["vluchteling"] instanceof UntypedFormGroup) && !(controlsConfig["vluchteling"] instanceof UntypedFormArray)) {
      controlsConfig["vluchteling"][1] = controlsConfig["vluchteling"][1].concat(BenodigdeDocumentenSelectiecriteriaRestDtoValidator.vluchteling_swagger_validator());
    }
    if (controlsConfig["vrijstellingsreden"] != undefined && !(controlsConfig["vrijstellingsreden"] instanceof UntypedFormGroup) && !(controlsConfig["vrijstellingsreden"] instanceof UntypedFormArray)) {
      controlsConfig["vrijstellingsreden"][1] = controlsConfig["vrijstellingsreden"][1].concat(BenodigdeDocumentenSelectiecriteriaRestDtoValidator.vrijstellingsreden_swagger_validator());
    }
    if (options) {
      return formBuilder.group(controlsConfig, options);
    }
    return formBuilder.group(controlsConfig);
  }
}

export class BenodigdeDocumentenSelectiecriteriaRestDtoValidator {

  /**
   * Active Validators for beroep:
   * Minimum Length: 0
   * Maximum Length: 16
   */
  static beroep_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    validators.push(SwaggerValidators.minLength(0));
    validators.push(SwaggerValidators.maxLength(16));
    return validators;
  };

  /**
   * Active Validators for gereglementeerd_beroep:
   */
  static gereglementeerd_beroep_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    return validators;
  };

  /**
   * Active Validators for heeft_aanvullende_opleiding_gevolgd:
   * Required validator
   */
  static heeft_aanvullende_opleiding_gevolgd_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    validators.push(SwaggerValidators.required);
    return validators;
  };

  /**
   * Active Validators for heeft_adres_in_wallonie:
   * Required validator
   */
  static heeft_adres_in_wallonie_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    validators.push(SwaggerValidators.required);
    return validators;
  };

  /**
   * Active Validators for heeft_scriptie_geschreven:
   * Required validator
   */
  static heeft_scriptie_geschreven_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    validators.push(SwaggerValidators.required);
    return validators;
  };

  /**
   * Active Validators for heeft_stage_gelopen:
   * Required validator
   */
  static heeft_stage_gelopen_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    validators.push(SwaggerValidators.required);
    return validators;
  };

  /**
   * Active Validators for heeft_voorgaand_diploma:
   * Required validator
   */
  static heeft_voorgaand_diploma_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    validators.push(SwaggerValidators.required);
    return validators;
  };

  /**
   * Active Validators for is_doorverwezen_ho:
   */
  static is_doorverwezen_ho_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    return validators;
  };

  /**
   * Active Validators for land:
   * Required validator
   * Minimum Length: 0
   * Maximum Length: 100
   */
  static land_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    validators.push(SwaggerValidators.required);
    validators.push(SwaggerValidators.minLength(0));
    validators.push(SwaggerValidators.maxLength(100));
    return validators;
  };

  /**
   * Active Validators for naam_op_diploma_zelfde_als_op_id:
   */
  static naam_op_diploma_zelfde_als_op_id_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    return validators;
  };

  /**
   * Active Validators for onderwijsniveau:
   * Required validator
   * Minimum Length: 0
   * Maximum Length: 20
   */
  static onderwijsniveau_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    validators.push(SwaggerValidators.required);
    validators.push(SwaggerValidators.minLength(0));
    validators.push(SwaggerValidators.maxLength(20));
    return validators;
  };

  /**
   * Active Validators for procedure:
   * Required validator
   * Minimum Length: 0
   * Maximum Length: 5
   */
  static procedure_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    validators.push(SwaggerValidators.required);
    validators.push(SwaggerValidators.minLength(0));
    validators.push(SwaggerValidators.maxLength(5));
    return validators;
  };

  /**
   * Active Validators for vakgebied:
   * Minimum Length: 0
   * Maximum Length: 7
   */
  static vakgebied_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    validators.push(SwaggerValidators.minLength(0));
    validators.push(SwaggerValidators.maxLength(7));
    return validators;
  };

  /**
   * Active Validators for verdragen:
   * Required validator
   */
  static verdragen_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    validators.push(SwaggerValidators.required);
    return validators;
  };

  /**
   * Active Validators for vluchteling:
   */
  static vluchteling_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    return validators;
  };

  /**
   * Active Validators for vrijstellingsreden:
   * Required validator
   * Minimum Length: 0
   * Maximum Length: 10
   */
  static vrijstellingsreden_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    validators.push(SwaggerValidators.required);
    validators.push(SwaggerValidators.minLength(0));
    validators.push(SwaggerValidators.maxLength(10));
    return validators;
  };

}



