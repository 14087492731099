<div class="container grid grid-cols-12 my-10">
  <div class="col-span-full">
    <ngx-ov-stepper [selectedIndex]="0" [linear]="true">
      <ngx-ov-step label="Form validatie" [stepControl]="firstStepFormGroup">
        <form [formGroup]="firstStepFormGroup">
          <p>
            Dit veld moet ingevuld worden, anders kan je niet naar de volgende
            stap
          </p>
          <ngx-ov-input-field
            [attr.data-cy]="'input-field-gerecht'"
            formControlName="gerechtCtrl"
            label="Kies je gerecht"
          ></ngx-ov-input-field>
          <div class="mt-8">
            <ngx-ov-button
              ovStepperNext
              [attr.data-cy]="'button-next-step-one'"
              [label]="'Volgende stap'"
              [isDisabled]="!firstStepFormGroup.valid"
            ></ngx-ov-button>
          </div>
        </form>
      </ngx-ov-step>
      <ngx-ov-step label="Openklikbaar" [canBeExpanded]="true">
        <p>Deze stap is openklikbaar wanneer we al bij de volgende stap zijn</p>
        <button [attr.data-cy]="'button-previous-step-two'" ovStepperPrevious>
          Vorige stap
        </button>
        <button [attr.data-cy]="'button-next-step-two'" ovStepperNext>
          Volgende stap
        </button>
      </ngx-ov-step>
      <ngx-ov-step [isCta]="true" label="CTA" [isLocked]="true"
        ><div>
          <p>
            In deze CTA stap zou actie ondernomen moeten worden door de
            gebruiker
          </p>
          <ngx-ov-button
            [attr.data-cy]="'button-previous-step-three'"
            ovStepperPrevious
            [label]="'terug naar stap 2'"
          ></ngx-ov-button></div
      ></ngx-ov-step>
    </ngx-ov-stepper>
  </div>
</div>
