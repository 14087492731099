import { Component } from '@angular/core';

import { NgxOvInputFieldComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/forms/input-field/input-field.component';
import {
  NgxOvButtonComponent,
  NgxOvCardComponent,
  NgxOvCardGroupComponent,
  NgxOvCheckboxComponent,
  NgxOvPillComponent,
  NgxOvProgressBlockComponent,
  PortalCardDirective,
  PortalCardTitleDirective
} from 'projects/ngx-ov-ui/src/public-api';

@Component({
  selector: 'app-page-content-blokken',
  templateUrl: './page-content-blokken.component.html',
  standalone: true,
  imports: [
    NgxOvCardComponent,
    NgxOvCardGroupComponent,
    NgxOvButtonComponent,
    NgxOvProgressBlockComponent,
    NgxOvPillComponent,
    NgxOvInputFieldComponent,
    NgxOvCheckboxComponent,
    PortalCardDirective,
    PortalCardTitleDirective
  ]
})
export class PageContentBlokkenComponent {
  testClick(message: string) {
    // eslint-disable-next-line no-console
    console.log(message);
    alert(`oncardClicked${message}`);
  }

  onProgressBlockClick(message: string) {
    alert(`onProgressBlockClick${message}`);
  }
}
