import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { NgxOvButtonComponent } from '../button/button.component';
import { NgStyle } from '@angular/common';

export type BannerSize = 'SMALL' | 'MEDIUM' | 'LARGE';
export type BannerVersion = 'DEFAULT' | 'CTA';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-banner',
  templateUrl: './banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgStyle, NgxOvButtonComponent]
})
export class NgxOvBannerComponent implements OnInit {
  private _backgroundImageSrc = './assets/images/defaultBackgroundBanner.png';
  @Input() backgroundImagePos = 'right top';
  private _backgroundImageSrcMobile = '';
  @Input() backgroundImagePosMobile = 'right top';
  private _size: BannerSize;
  private _version: BannerVersion = 'DEFAULT';
  private _screenWidth;

  private _title: string;
  private _boxTitle: string;
  private _intro: string;
  private _buttonLabel: string;
  private _type: 'imageOnly' | 'box' = 'box';

  get screenWidth(): number {
    return this._screenWidth;
  }

  @Input() set type(type: 'imageOnly' | 'box') {
    this._type = type;
  }

  get type(): 'imageOnly' | 'box' {
    return this._type;
  }

  @Input() set backgroundImageSrc(backgroundImageSrc: string) {
    this._backgroundImageSrc = backgroundImageSrc;
  }

  get backgroundImageSrc(): string {
    return this._backgroundImageSrc;
  }

  @Input() set backgroundImageSrcMobile(backgroundImageSrcMobile: string) {
    this._backgroundImageSrcMobile = backgroundImageSrcMobile;
  }

  get backgroundImageSrcMobile(): string {
    return this._backgroundImageSrcMobile || this._backgroundImageSrc;
  }

  @Input() set size(size: BannerSize) {
    this._size = size;
  }

  get size(): BannerSize {
    return this._size;
  }

  @Input() set version(version: BannerVersion) {
    this._version = version;
  }

  get version(): BannerVersion {
    return this._version;
  }

  @Input() set title(title: string) {
    this._title = title;
  }

  get title(): string {
    return this._title;
  }

  @Input() set boxTitle(boxTitle: string) {
    this._boxTitle = boxTitle;
  }

  get boxTitle(): string {
    return this._boxTitle;
  }

  @Input() set intro(intro: string) {
    this._intro = intro;
  }

  get intro(): string {
    return this._intro;
  }

  @Input() set buttonLabel(buttonLabel: string) {
    this._buttonLabel = buttonLabel;
  }

  get buttonLabel(): string {
    return this._buttonLabel;
  }

  @Output() buttonClicked: EventEmitter<Event> = new EventEmitter();

  @HostListener('window:resize')
  onResize() {
    this._screenWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.onResize();
  }
}
