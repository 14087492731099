import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { NgxOvGlobalAlertComponent } from '../../meldingen/global-alert/global-alert.component';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-global-migratie-alert',
  templateUrl: './global-migratie-alert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgxOvGlobalAlertComponent, AsyncPipe]
})
export class NgxOvGlobalMigratieAlertComponent implements OnInit {
  isAtos: boolean;
  ngOnInit(): void {
    this.isAtos = this.isShadow();
  }

  private isShadow(): boolean {
    const value = sessionStorage.getItem('ov-use-shadow');
    return value !== null && value === 'true';
  }
}
