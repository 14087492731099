export enum StoreItem {
  Landen = 'LANDEN',
  Mock = 'MOCK'
}

export enum StorePhase {
  Request = '[REQUEST]',
  Success = '[SUCCESS]',
  Failure = '[FAILURE]',
  Skip = '[SKIP]'
}
