<div class="grid grid-cols-12 gap-medium">
  <div class="col-span-12">
    <p>Duid de toelagen aan die u ontvangt</p>
  </div>

  <div class="col-span-12">
    <div class="c-wizard c-wizard__col3">
      <ngx-ov-checkbox-group
        [options]="toelagenOpties"
        [(ngModel)]="gekozenToelage"
      ></ngx-ov-checkbox-group>
    </div>
  </div>

  <div class="col-span-12">
    <div class="u-position-btn-prim-sec">
      <ngx-ov-button
        [attr.data-cy]="'wizard-volgende'"
        [label]="'Volgende'"
        (buttonClick)="onBevestigToelagen()"
        [isDisabled]="!gekozenToelage"
      ></ngx-ov-button>
    </div>
  </div>
</div>
