import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LinkService } from '../../../../services/link.service';
import { NgxOvLinkComponent } from '../../../default-layout-blokken/link/link.component';
import { ClickEqualsEnterDirective } from '../../../../directives/click-equals-enter';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-doormat-acm',
  templateUrl: './doormat-acm.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ClickEqualsEnterDirective, NgxOvLinkComponent]
})

// =============================================================================
// Definition
// =============================================================================
export class NgxOvDoormatACMComponent {
  // -------------------------------------------------------------------------
  // Input variables
  // -------------------------------------------------------------------------
  @Input() classes: string;
  @Input() title: string;
  @Input() url: string;
  @Input() linkLabel: string;
  @Input() linkUrl: string;

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  constructor(private linkService: LinkService) {}

  // -------------------------------------------------------------------------
  // Events
  // -------------------------------------------------------------------------
  onClick() {
    this.linkService.navigate(this.url);
  }
}
