import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild
} from '@angular/core';
import {
  FormsModule,
  NG_ASYNC_VALIDATORS,
  NG_VALIDATORS,
  NgControl,
  NgModel
} from '@angular/forms';
import { NgxOvUiConfigService } from '../../../../ngx-ov-ui.config.service';
import { FormsBase } from '../../forms-base';
import { PortalCheckboxContentDirective } from '../portals/portal-checkbox-content.directive';
import { TranslationsService } from '../../../../services/translations.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { NgxOvInputLabelComponent } from '../../input-label/input-label.component';
import { PortalModule } from '@angular/cdk/portal';
import { NgxOvExtraInfoComponent } from '../../extra-info/extra-info.component';
import { ClickEqualsEnterDirective } from '../../../../directives/click-equals-enter';
import { NgxOvTooltipComponent } from '../../../meldingen/tooltip/tooltip.component';

let identifier = 0;

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-checkbox',
  templateUrl: './checkbox.html',
  standalone: true,
  imports: [
    FormsModule,
    NgxOvTooltipComponent,
    ClickEqualsEnterDirective,
    NgxOvExtraInfoComponent,
    PortalModule,
    NgxOvInputLabelComponent
  ]
})

// =============================================================================
// Definition
// =============================================================================
export class NgxOvCheckboxComponent
  extends FormsBase<any>
  implements OnDestroy, OnInit
{
  // -------------------------------------------------------------------------
  // Private variables
  // -------------------------------------------------------------------------
  private _id: string;

  // -------------------------------------------------------------------------
  // Input variables
  // -------------------------------------------------------------------------
  @Input() classes: string;

  @Input() set id(id: string) {
    this._id = id;
  }
  get id(): string {
    return this._id ? this._id : this.identifier;
  }

  @Input() label: string;
  @Input() isDisabled: boolean;
  @Input() isError: boolean;
  @Input() errorMessage: string;
  @Input() showAsBlock = false;
  @Input() isReadonly: boolean;
  @Input() extraInfo: string;
  @Input() icon: string;
  @Input() code: string;

  @Input() checkedReadOnlyText;
  @Input() notCheckedReadOnlyText;

  get checked(): boolean {
    return this.value;
  }
  @Input() set checked(checked: boolean) {
    this.value = checked;
  }

  // -------------------------------------------------------------------------
  // Content children
  // -------------------------------------------------------------------------
  @ContentChild(PortalCheckboxContentDirective, { static: false })
  contentPortal: PortalCheckboxContentDirective;

  // -------------------------------------------------------------------------
  // Output variables
  // -------------------------------------------------------------------------
  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  // -------------------------------------------------------------------------
  // Copy variables
  // -------------------------------------------------------------------------
  public checkedReadOnlyTextCopy: string;
  public notCheckedReadOnlyTextCopy: string;

  // -------------------------------------------------------------------------
  // Other variables
  // -------------------------------------------------------------------------
  @ViewChild(NgModel, { static: true }) model: NgModel;
  @ViewChild('input', { static: false }) checkbox: ElementRef;
  public identifier = `form-checkbox-${(identifier += 1)}`;

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
    @Self() @Optional() ngControl: NgControl,
    public config: NgxOvUiConfigService,
    translationsService: TranslationsService,
    @Optional() translateService: TranslateService
  ) {
    super(
      validators,
      asyncValidators,
      ngControl,
      translateService,
      translationsService
    );
  }

  // -------------------------------------------------------------------------
  // Lifecycle methods
  // -------------------------------------------------------------------------
  ngOnInit(): void {
    this.getCopy();
    if (this.translateService) {
      this.translateService.onLangChange
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.getCopy();
        });
    }
  }

  clickCheckbox(e) {
    e.stopPropagation();
    if (e.target && e.target.tagName !== 'A') {
      e.preventDefault();
      if (this.isDisabled || this.isReadonly) {
        return;
      }
      if (this.checkbox && this.checkbox.nativeElement) {
        this.value = !this.value;
        this.onChange(this.value);
        this.valueChange.emit(this.value);
      }
    }
  }

  // -------------------------------------------------------------------------
  // Translation
  // -------------------------------------------------------------------------
  getCopy() {
    this.checkedReadOnlyTextCopy =
      this.checkedReadOnlyText ??
      this.getNgxOvUiTranslation('READONLY.CHECKED_TEXT');
    this.notCheckedReadOnlyTextCopy =
      this.notCheckedReadOnlyText ??
      this.getNgxOvUiTranslation('READONLY.NOT_CHECKED_TEXT');
  }

  getNgxOvUiTranslation(code: string) {
    const translationKey = `FORMS.CHECKBOX.${code.toUpperCase()}`;
    return this.translationsService.getDefaultComponentTranslation(
      translationKey
    );
  }
}
