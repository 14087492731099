<div class="container">
  <div class="my-10" [formGroup]="filters">
    <ngx-ov-search
      (applyFilter)="onApplyFilter()"
      (clearFilter)="onClearFilter()"
      [showFooterButtons]="false"
      [displayFilterMobile]="displayFilterMobile"
      [titlePanelFilterResultaten]="'Extra filters'"
      (displayFilterMobileChange)="displayFilterMobileChange($event)"
    >
      <ng-template ngxOvSearchFilter>
        <ngx-ov-control-panel-item
          [title]="'Zoek traject'"
          [isCollapsed]="false"
        >
          <ngx-ov-input-field
            [placeholder]="'Welk traject zoek je?'"
            [formControlName]="'naam'"
          ></ngx-ov-input-field>
        </ngx-ov-control-panel-item>
        <ngx-ov-control-panel-item title="Empty state" [tooltip]="'tooltop'">
          <ngx-ov-checkbox
            label="Empty state"
            [formControlName]="'empty_state'"
          ></ngx-ov-checkbox>
        </ngx-ov-control-panel-item>
        <ngx-ov-control-panel-item
          title="Show error"
          (tooltipClicked)="openModal()"
        >
          <ngx-ov-checkbox
            label="Show error"
            [formControlName]="'show_error'"
          ></ngx-ov-checkbox>
        </ngx-ov-control-panel-item>
      </ng-template>
      <ng-template ngxOvSearchResultFilter>
        <div class="grid grid-cols-1 tablet:grid-cols-3 gap-medium mx-xsmall">
          <ngx-ov-control-panel-item title="Graad">
            <ngx-ov-select
              [items]="graden"
              [bindLabel]="'omschrijving'"
              [placeholder]="'Selecteer een graad'"
              [searchable]="true"
            ></ngx-ov-select>
          </ngx-ov-control-panel-item>
          <ngx-ov-control-panel-item title="Finaliteit">
            <ngx-ov-select
              [placeholder]="'Selecteer een finaliteit'"
            ></ngx-ov-select>
          </ngx-ov-control-panel-item>
          <ngx-ov-control-panel-item title="Onderwijsvorm">
            <ngx-ov-select
              [placeholder]="'Selecteer een onderwijsvorm'"
            ></ngx-ov-select>
          </ngx-ov-control-panel-item>
        </div>
      </ng-template>
      <ng-template ngxOvSearchButtonContainer>
        <ngx-ov-button
          [label]="'Filter'"
          (buttonClick)="onApplyFilter()"
        ></ngx-ov-button>
      </ng-template>
      <ng-template ngxOvSearchInfo>
        <ngx-ov-card [title]="'Meer info'">
          Vero amet ea. Erat et no sadipscing stet ad hendrerit et praesent
          aliquyam veniam vel gubergren ut sed est.
        </ngx-ov-card>
      </ng-template>
      @if (!(isLoading$ | async)) {
        <ngx-ov-expansion-panel-list
          [isEmpty]="!(showError$ | async) && trajecten.length === 0"
        >
          <ng-template ngxOvPanelListEmptyStatePortal>
            <ng-container *ngTemplateOutlet="geenTrajecten"></ng-container>
          </ng-template>
          @if (!(showError$ | async)) {
            <div class="mx-xsmall">
              @for (traject of trajecten; let last = $last; track trackByFn) {
                <ngx-ov-expansion-panel-item
                  [title]="traject.naam"
                  [lastItem]="last"
                >
                  <ng-template ngxOvPortalTitleContent>
                    {{ traject?.organisatie?.naam }}
                    {{ traject?.soort_traject?.omschrijving }}
                  </ng-template>
                  <ng-template ngxOvPortalRightContent>
                    <div class="flex flex-row gap-small">
                      <div>
                        <i
                          class="fa-solid fa-pen-to-square text-cta-base"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div>
                        <i
                          class="fa-solid fa-trash-can text-cta-base"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div class="notitie-acties">
                        <i
                          class="fa-solid fa-thumbtack text-grayscales-gray4 hover:text-cta-base"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </ng-template>
                  <div>
                    {{ traject?.korte_inhoud }}
                    In deze opleiding assistent douanedeclarant of junior
                    customs consultant leer je douaneformaliteiten afhandelen en
                    administratieve douaneformaliteiten, commerciële
                    activiteiten van het transport of activiteiten voor
                    internationaal goederenverkeer uitvoeren. Deze sector is
                    altijd op zoek naar nieuwe krachten dus je kan na de
                    opleiding meteen aan de slag!
                  </div>
                  @if (traject?.soort_traject?.code === 'OPLEIDING') {
                    <ng-template ngxOvPortalSearchResultFooter>
                      {{ traject?.organisatie?.naam }}
                      {{ traject?.soort_traject?.omschrijving }}
                    </ng-template>
                  }
                </ngx-ov-expansion-panel-item>
              }
            </div>
          } @else {
            <ngx-ov-alert [type]="'error'">
              Er ging iets mis bij het ophalen van de resultaten
            </ngx-ov-alert>
          }
        </ngx-ov-expansion-panel-list>
      } @else {
        <ngx-skeleton-loader
          count="10"
          appearance="line"
          [theme]="{
            height: '53px'
          }"
        ></ngx-skeleton-loader>
      }
    </ngx-ov-search>
  </div>
</div>
<div class="u-sticky-container u-sticky-container-mobile">
  <ngx-ov-button [label]="'Sticky button '"></ngx-ov-button>
</div>
<ng-template #geenTrajecten>
  <ngx-ov-no-data
    title="Geen resultaten gevonden"
    subtitle="Probeer je zoektermen te wijzigen zodat je kunt vinden wat je zoekt"
    linkLabelText="Filters verwijderen"
    (linkClicked)="onClearFilter()"
    class=""
  >
  </ngx-ov-no-data>
</ng-template>
