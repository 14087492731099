import { Component, OnInit } from '@angular/core';
import {
  PapyrusUploadRecord,
  PapyrusUploadService
} from '../../../../../projects/ngx-ov-ui/src/lib/fundamentals/papyrus-upload/papyrus-upload.service';
import { UploadColumn } from '../../../../../projects/ngx-ov-ui/src/public-api';
import { PapyrusUploadComponent } from '../../../../../projects/ngx-ov-ui/src/lib/fundamentals/papyrus-upload';

export interface DocumentData {
  document_key: string;
}

export const columns: UploadColumn[] = [
  {
    id: 'bestandsnaam',
    header: 'Bestandsnaam',
    actions: {
      canDownload: true
    }
  },
  {
    id: 'id',
    header: 'Id'
  },
  {
    id: 'document_key',
    header: 'Papyrus Document Key'
  },
  {
    id: 'documentType',
    header: 'Document Type'
  }
];

/**
 * Provide the services and the configuration
 */
@Component({
  template: `<ngx-ov-papyrus-upload
    [columns]="columns"
    (deleteFile)="onDeleteFile($event)"
    (uploadChange)="onUploadChange($event)"
  >
  </ngx-ov-papyrus-upload>`,
  selector: 'app-papyrus-wrapper',
  providers: [PapyrusUploadService],
  standalone: true,
  imports: [PapyrusUploadComponent]
})
export class PapyrusWrapperComponent implements OnInit {
  public rows: PapyrusUploadRecord<DocumentData>[] = [
    {
      id: '1',
      bestandsnaam: 'upload 1 dummy-file-1.jpg',
      documentType: 'stagebewijs',
      document_key: '46f17035-e9bc-41d5-a2d3-674c5968ba66',
      actions: {
        canDelete: true,
        canDownload: true,
        canEdit: true
      }
    },
    {
      id: '2',
      bestandsnaam:
        'dummyfilemeteenlangenaamzonderspatiesofliggendestreepjesoflagestreepjeswantstreepjeszijnnietleuk.pdf',
      documentType: 'plain document',
      document_key: '46f17035-e9bc-41d5-a2d3-674c5968ba7',
      isNotExpandable: true,
      actions: {
        canDelete: true,
        canDownload: false,
        canEdit: true
      }
    }
  ];

  public columns: UploadColumn[] = columns;

  constructor(
    private papyrusUploadService: PapyrusUploadService<DocumentData>
  ) {}

  ngOnInit() {
    this.papyrusUploadService.initFiles(this.rows);
  }

  onUploadChange(e: any) {
    this.papyrusUploadService.addFiles({
      id: '3',
      bestandsnaam: e.file.name,
      documentType: 'plain document',
      document_key: e.document_key,
      actions: {
        canDelete: true,
        canDownload: false
      }
    });
  }

  onDeleteFile(e: any) {
    this.papyrusUploadService.removeFileByDocumentKey(e.row.document_key);
  }
}
