import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IPager } from '../services/pager.service';

@Component({
    selector: 'ngx-ov-pager-info',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
    <div class="c-pager__info">
      <strong>{{ pager.startIndex + 1 }}-{{ pager.endIndex + 1 }}</strong> van
      {{ pager.totalItems }}
    </div>
  `,
    standalone: true
})
export class NgxOvPagerInfoComponent {
  @Input() pager: IPager;
}
