import { Component, OnInit } from '@angular/core';
import { PageWizardStep4Component } from './components/wizard-step-4/wizard-step-4.component';
import { PageWizardStep3Component } from './components/wizard-step-3/wizard-step-3.component';
import { PageWizardStep2Component } from './components/wizard-step-2/wizard-step-2.component';
import { PageWizardStep1Component } from './components/wizard-step-1/wizard-step-1.component';
import { PortalWizardNavigationRightContentDirective } from '../../../../projects/ngx-ov-ui/src/lib/components/navigatie/wizard-navigation/portals/portal-wizard-navigation-right-content.directive';
import { ClickEqualsEnterDirective } from '../../../../projects/ngx-ov-ui/src/lib/directives/click-equals-enter';
import { NgClass } from '@angular/common';
import { PortalWizardNavigationLeftContentDirective } from '../../../../projects/ngx-ov-ui/src/lib/components/navigatie/wizard-navigation/portals/portal-wizard-navigation-left-content.directive';
import { NgxOvWizardNavigationComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/navigatie/wizard-navigation/wizard-navigation.component';
import { NgxOvProgressBarComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/forms/progress-bar/progress-bar.component';
import { NgxOvLinkComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/link/link.component';

@Component({
  selector: 'app-page-wizard',
  templateUrl: './page-wizard.component.html',
  standalone: true,
  imports: [
    NgxOvProgressBarComponent,
    NgxOvLinkComponent,
    NgxOvWizardNavigationComponent,
    PortalWizardNavigationLeftContentDirective,
    ClickEqualsEnterDirective,
    PortalWizardNavigationRightContentDirective,
    NgClass,
    PageWizardStep1Component,
    PageWizardStep2Component,
    PageWizardStep3Component,
    PageWizardStep4Component
  ]
})
export class PageWizardComponent implements OnInit {
  public totalItems = 4;
  public progress = 0;
  public currentStep = 1;

  public gekozenOpleiding: string;
  public gekozenToelagen: any[];

  ngOnInit(): void {
    this.updateProgress();
  }

  updateProgress() {
    if (this.currentStep === 1) {
      this.progress = 0;
    } else {
      this.progress = ((this.currentStep - 1) / (this.totalItems - 1)) * 100;
    }
  }

  navigate(action: 'forward' | 'backward') {
    if (action === 'forward') {
      this.currentStep += 1;
    } else {
      this.currentStep -= 1;
    }

    this.updateProgress();
  }

  wizardNavigationClicked(action: 'left' | 'right') {
    if (action === 'left') {
      this.navigate('backward');
    } else if (action === 'right') {
      if (this.currentStep !== this.totalItems) {
        this.navigate('forward');
      } else {
        this.currentStep = 1;
        this.gekozenOpleiding = null;
        this.gekozenToelagen = null;

        this.updateProgress();
      }
    }
  }

  onOpleidingGekozen(opleiding: string) {
    if (this.gekozenOpleiding !== opleiding) {
      this.gekozenToelagen = null;
    }

    this.gekozenOpleiding = opleiding;
    this.navigate('forward');
  }

  onToelagenGekozen(toelagen: any[]) {
    this.gekozenToelagen = toelagen;
    this.navigate('forward');
  }
}
