<footer class="c-footer {{ classes }}">
  @if (secondLogoSrc) {
    <div>
      <div class="c-footer__logo-double">
        <div class="c-footer__logo">
          <div class="c-footer__logo-img"></div>
          <div class="c-footer__logo-text">
            <div class="c-footer__logo-title">{{ logoTitle }}</div>
            <span class="c-footer__logo-tagline"> {{ logoTagline }} </span>
          </div>
        </div>
        <div class="c-footer__logo">
          <img
            class="c-footer__logo-second-img"
            [src]="secondLogoSrc"
            [alt]="secondLogoAlt"
          />
        </div>
      </div>
    </div>
  } @else {
    <!-- Logo with text -->
    <div class="c-footer__logo">
      <div class="c-footer__logo-img"></div>
      <div class="c-footer__logo-text">
        <div class="c-footer__logo-title">{{ logoTitle }}</div>
        <span class="c-footer__logo-tagline"> {{ logoTagline }} </span>
      </div>
    </div>
  }

  <!-- Main text footer -->
  <div class="c-footer__site-info">
    <div>
      <p class="c-footer__site-info__title">{{ title }}</p>

      @if (infoPublisher) {
        <p
          class="c-footer__site-info__publisher"
          [innerHTML]="infoPublisher"
        ></p>
      }
    </div>

    <div class="c-footer__links">
      <div class="c-footer__links-basic">
        @if (extraLinks?.length) {
          <!-- Navigation; NOTE: list items are temporary -->
          <ul class="c-footer__list">
            @for (link of extraLinks; track trackByFn) {
              <li>
                <a
                  class="c-footer__list-item"
                  (click)="
                    link.removeCookies ? removeCookies() : onClick(link.linkUrl)
                  "
                  [attr.data-cy]="link.linkTitle"
                >
                  {{ link.linkTitle }}
                </a>
              </li>
            }
          </ul>
        }
      </div>
      <div class="c-footer__links-languages">
        @if (languages?.length > 1) {
          <!-- Language switch links -->
          <ul class="c-footer__list-languages">
            @for (language of languages; track trackByFn) {
              <li>
                <a
                  class="c-footer__list-item c-footer__list-item--link"
                  [class.c-footer__list-item--active]="language.active"
                  (click)="onSelectLanguage(language)"
                >
                  {{ language.nameShort ? language.nameShort : language.name }}
                </a>
              </li>
            }
          </ul>
        }
      </div>
    </div>

    <div class="c-footer__versions">
      @if (versions && versions.length) {
        <ul class="c-footer__list">
          @for (version of versions; track trackByFn) {
            <li>
              <div class="c-footer__list-item c-footer__list-item--version">
                {{ version.name }}: {{ version.version }} - {{ version.date }}
              </div>
            </li>
          }
        </ul>
      }
    </div>
  </div>
</footer>
