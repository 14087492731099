import { Pipe, PipeTransform } from '@angular/core';
import * as R from 'ramda';
@Pipe({
    name: 'path',
    standalone: true
})
export class PathPipe implements PipeTransform {
  transform(value: any, path: any): any {
    return R.path(path.split('.'), value);
  }
}
