import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LinkService } from '../../../../services/link.service';
import { ClickEqualsEnterDirective } from '../../../../directives/click-equals-enter';

@Component({
    selector: 'ngx-ov-header-title-link',
    templateUrl: './header-title-link.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ClickEqualsEnterDirective]
})
export class NgxOvHeaderTitleLinkComponent {
  _label: string;
  _link: string;
  _hideMobile = false;

  get label(): string {
    return this._label;
  }
  @Input() set label(label: string) {
    this._label = label;
  }

  get link(): string {
    return this._link;
  }
  @Input() set link(link: string) {
    this._link = link;
  }

  get hideMobile(): boolean {
    return this._hideMobile;
  }
  @Input() set hideMobile(hideMobile: boolean) {
    this._hideMobile = hideMobile;
  }

  constructor(private linkService: LinkService) {}

  onClick() {
    this.linkService.navigate(this.link);
  }
}
