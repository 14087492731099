import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { isAfter, parse } from 'date-fns';
import { NgxOvButtonComponent } from '../../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/button/button.component';
import { NgxOvTextareaComponent } from '../../../../../projects/ngx-ov-ui/src/lib/components/forms/textarea/textarea.component';
import { NgxOv3WayToggleComponent } from '../../../../../projects/ngx-ov-ui/src/lib/components/forms/3-way-toggle/3-way-toggle.component';
import { NgxOvSelectComponent } from '../../../../../projects/ngx-ov-ui/src/lib/components/forms/select/select.component';
import { NgxOvDatepickerComponent } from '../../../../../projects/ngx-ov-ui/src/lib/components/forms/datepicker/datepicker.component';
import {
  NgxOvDatepickerInputsComponent
} from '../../../../../projects/ngx-ov-ui/src/lib/components/forms/datepicker-inputs/datepicker-inputs.component';
import {
  PortalCheckboxContentDirective
} from '../../../../../projects/ngx-ov-ui/src/lib/components/forms/checkbox/portals/portal-checkbox-content.directive';
import {
  NgxOvCheckboxComponent
} from '../../../../../projects/ngx-ov-ui/src/lib/components/forms/checkbox/checkbox-item/checkbox.component';
import {
  NgxOvCheckboxGroupComponent
} from '../../../../../projects/ngx-ov-ui/src/lib/components/forms/checkbox/checkbox-group/checkbox-group.component';
import {
  PortalRadioContentDirective
} from '../../../../../projects/ngx-ov-ui/src/lib/components/forms/radio/portals/portal-radio-content.directive';
import { NgxOvRadioComponent } from '../../../../../projects/ngx-ov-ui/src/lib/components/forms/radio/radio-item/radio.component';
import {
  NgxOvRadioGroupComponent
} from '../../../../../projects/ngx-ov-ui/src/lib/components/forms/radio/radio-group/radio-group.component';
import { NgxOvInputFieldComponent } from '../../../../../projects/ngx-ov-ui/src/lib/components/forms/input-field/input-field.component';
import { AsyncPipe, JsonPipe, NgClass } from '@angular/common';
import { NgxOvToggleComponent } from '../../../../../projects/ngx-ov-ui/src/lib/components/forms/toggle/toggle.component';
import {
  PortalPanelActionsDirective
} from '../../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/panel/portals/portal-panel-actions.directive';
import { NgxOvPanelComponent } from '../../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/panel/panel.component';
import {
  NgxOvInputReadonlyComponent
} from '../../../../../projects/ngx-ov-ui/src/lib/components/forms/input-readonly/input-readonly.component';
import { DisableEnableDate } from 'angularx-flatpickr/lib/flatpickr-defaults.service';
import { debounceTime, Observable, Subject, switchMap, throwError } from 'rxjs';
import { Store } from '@ngxs/store';
import { LoadLandenRequest } from '@store/landen/landen.actions';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

function validateWeekendDays(c: UntypedFormControl) {
  return !c.value || c.value.length < 2
    ? {
      minLengthWeekend: true
    }
    : null;
}

function validateVerleden(c: UntypedFormControl): ValidationErrors | null {
  return c.value &&
  isAfter(parse(c.value, 'dd-MM-yyyy', new Date()), new Date())
    ? {
      datumMaxVandaag: true
    }
    : null;
}

@Component({
  selector: 'reactive-form',
  templateUrl: './reactive-form.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgxOvPanelComponent,
    PortalPanelActionsDirective,
    NgxOvToggleComponent,
    NgClass,
    NgxOvInputFieldComponent,
    NgxOvRadioGroupComponent,
    NgxOvRadioComponent,
    PortalRadioContentDirective,
    NgxOvCheckboxGroupComponent,
    NgxOvCheckboxComponent,
    PortalCheckboxContentDirective,
    NgxOvDatepickerInputsComponent,
    NgxOvDatepickerComponent,
    NgxOvSelectComponent,
    NgxOv3WayToggleComponent,
    NgxOvTextareaComponent,
    NgxOvButtonComponent,
    JsonPipe,
    AsyncPipe,
    NgxOvInputReadonlyComponent
  ]
})
export class ReactiveFormComponent implements OnInit, AfterViewInit {
  gebruikersvoorwaarden = false;
  form: UntypedFormGroup;
  landTypeaheadSubject: Subject<any> = new Subject<any>();

  maxDate = '2025-01-01';
  minDate = '2015-01-01';

  geslachtenOptions = [
    {
      nested: { code: 'm' },
      omschrijving: 'man',
      disabled: true
    },
    {
      nested: { code: 'v' },
      omschrijving: 'vrouw'
    },
    {
      nested: { code: 'x' },
      omschrijving: 'genderneutraal'
    }
  ];

  multiSelectItems: any[] = [
    {
      name: 'Spaghetti',
      type: 'Hoofdgerecht',
      code: 'SPA'
    },
    {
      name: 'Frietjes',
      type: 'Hoofdgerecht',
      code: 'FRI'
    },
    {
      name: 'Stoemp',
      type: 'Hoofdgerecht',
      code: 'STO'
    },
    {
      name: 'Pudding',
      type: 'Nagerecht',
      code: 'PUD'
    }
  ];

  enableDates: DisableEnableDate[];
  disabledDates: DisableEnableDate[];

  checkboxGroupItems: any[] = [
    {
      id: 1,
      label: 'Maandag',
      shortLabel: 'MA',
      nested: {
        nested_id: 11
      },
      extraInfo: 'extra info voor maandag'
    },
    {
      id: 2,
      label: 'Dinsdag',
      shortLabel: 'DI',
      nested: {
        nested_id: 12
      }
    },
    {
      id: 3,
      label: 'Woensdag',
      shortLabel: 'WO',
      nested: {
        nested_id: 13
      }
    },
    {
      id: 4,
      label: 'Donderdag',
      shortLabel: 'DO',
      nested: {
        nested_id: 14
      }
    },
    {
      id: 5,
      label: 'Vrijdag',
      shortLabel: 'VR',
      nested: {
        nested_id: 15
      }
    },
    {
      id: 6,
      label: 'Zaterdag',
      shortLabel: 'ZA',
      nested: {
        nested_id: 16
      },
      isDisabled: true
    }
  ];
  vrijeDagOptions: any[] = ['vrijdag', 'zaterdag', 'zondag'];
  landen$: Observable<any>;
  landenTypeahead: any[];

  constructor(
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new LoadLandenRequest());
    this.landen$ = this.store.select((state) => {
      return state.landen.landen;
    });
    this.form = this.fb.group({
      isReadonlyForm: [false],
      naam: [
        'V',
        [Validators.required, Validators.minLength(3), Validators.maxLength(10)]
      ],
      voornaam: ['', null],
      email: new UntypedFormControl('', {
        validators: [Validators.required, Validators.email],
        updateOn: 'blur'
      }),
      geslachtWithOptions: ['v', Validators.required],
      geslacht: [{ geslacht: 'man' }, Validators.required],
      haarkleur: ['blond'],
      vrije_dag: [this.vrijeDagOptions[1]],
      geboortedatum: [null, [Validators.required, validateVerleden]],
      geboortedatummonthyear: [null, [Validators.required, validateVerleden]],
      geboortedatumMonthYearObj: ['2017-11-01', Validators.required],
      geboortedatumMonthYearObjEmpty: [null, Validators.required],
      afspraak: [new Date()],
      wekker: [new Date()],
      nationaliteit: [null, Validators.required],
      land: ['SE', Validators.required],
      landTypeahead: ['SE', Validators.required],
      eten_1: [null, Validators.required],
      eten_2: [null],
      postcode: [null],
      gemeente: [null, Validators.required],
      wachtwoord: [null],
      gebruiksvoorwaarden: [null, Validators.requiredTrue],
      nieuwsbrief: [null, Validators.required],
      reclame: [null, Validators.required],
      werkdagen_empty: new UntypedFormControl(null, {
        validators: [Validators.required],
        updateOn: 'change'
      }),
      werkdagen_empty_2: [],
      werkdagen_object_id: [
        [
          {
            id: 3,
            label: 'Woensdag',
            shortLabel: 'WO',
            nested: {
              nested_id: 13
            }
          },
          {
            id: 4,
            label: 'Donderdag',
            shortLabel: 'DO',
            nested: {
              nested_id: 14
            }
          },
          {
            id: 6,
            label: 'Zaterdag',
            shortLabel: 'ZA',
            nested: {
              nested_id: 16
            }
          }
        ]
      ],
      werkdagen: [[this.checkboxGroupItems[1], this.checkboxGroupItems[4]]],
      werkdagen_id: [[2, 3], [Validators.required]],
      werkdagen_nested_id: [[12]],
      weekend_dagen: new UntypedFormControl(null, {
        validators: [validateWeekendDays],
        updateOn: 'change'
      }),
      opmerkingen: [null, [Validators.minLength(3), Validators.maxLength(10)]],
      isDisabled: [false]
    });

    this.form.get('eten_1').patchValue(this.multiSelectItems[1].code);

    this.subscribeItemsOphalen();
  }

  get isReadonlyForm(): boolean {
    return !!this.form.get('isReadonlyForm').value;
  }

  getEnabledDates(): DisableEnableDate[] {
    const d = new Date();
    const d1 = d.setDate(d.getDate()) as unknown as DisableEnableDate;
    const d2 = d.setDate(d.getDate() + 5) as unknown as DisableEnableDate;
    const d3 = d.setDate(d.getDate() + 15) as unknown as DisableEnableDate;
    const d4 = d.setDate(d.getDate() - 30) as unknown as DisableEnableDate;
    return [d1, d2, d3, d4];
  }

  // eslint-disable-next-line sonarjs/no-identical-functions
  getDisabledDates(): DisableEnableDate[] {
    const d = new Date();
    const d1 = d.setDate(d.getDate()) as unknown as DisableEnableDate;
    const d2 = d.setDate(d.getDate() + 5) as unknown as DisableEnableDate;
    const d3 = d.setDate(d.getDate() + 15) as unknown as DisableEnableDate;
    return [d1, d2, d3];
  }

  onChangeGebruikesvoorwaarden(e: any) {
    this.gebruikersvoorwaarden = !this.gebruikersvoorwaarden;
    // eslint-disable-next-line no-console
    console.log('onChangeGebruikesvoorwaarden', e);
  }

  ngAfterViewInit() {
    this.enableDates = this.getEnabledDates();
    this.disabledDates = this.getDisabledDates();
    this.cd.detectChanges();
  }

  onSubmit() {
    this.form.markAllAsTouched();
  }

  onReset() {
    Object.keys(this.form.controls).forEach((key) => {
      const control = this.form.get(key);
      if (control instanceof UntypedFormControl) {
        control.reset('', { onlySelf: true, emitEvent: false });
        control.markAsPristine();
        control.markAsUntouched();
      }
    });
    this.form.updateValueAndValidity();
  }

  patch() {
    this.form.get('geboortedatumMonthYearObj').patchValue('2020-10-10');
  }

  markAll() {
    this.form.markAllAsTouched();
  }

  changeRequiredField() {
    this.form.get('voornaam').addValidators([Validators.required]);
    this.form.get('eten_1').removeValidators([Validators.required]);
    this.form.get('voornaam').updateValueAndValidity();
    this.form.get('eten_1').updateValueAndValidity();
    this.form.updateValueAndValidity({ onlySelf: true });
  }

  private subscribeItemsOphalen() {
    this.landTypeaheadSubject
      .pipe(
        distinctUntilChanged(),
        filter((value) => !!value),
        filter((value) => value.length > 2),
        debounceTime(500),
        switchMap((value) => this.zoekLanden(value))
      )
      .subscribe({
        next: (data) => {
          this.landenTypeahead = data;
        },
        error: (error) => {
          return throwError(() => error);
        }
      });

    this.store.select((state) => {
      return state.landen.landen;
    }).pipe(
      map(data => data.filter(it => {
        return it.landcode.indexOf('SE') !== -1
      }
    )))
      .subscribe((val) => {
        this.landenTypeahead = val;
      });
  }

  private zoekLanden(term: string): Observable<any[]> {
    return this.store.select((state) => {
      return state.landen.landen;
    }).pipe(
      map(data => data.filter(it => it.landnaam.toUpperCase().indexOf(term.toUpperCase()) !== -1))
    );
  }

}
