import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrowserVersionOptions {
  public versions = [
    {
      name: 'Chrome',
      version: '70'
    },
    {
      name: 'Firefox',
      version: '53'
    },
    {
      name: 'Opera',
      version: '52'
    },
    {
      name: 'Safari',
      version: '5'
    },
    {
      name: 'IE',
      version: '11'
    }
  ];
}

@Injectable({
  providedIn: 'root'
})
export class BrowserSupportService {
  constructor(private browserVersionOptions: BrowserVersionOptions) {}

  // sourcecode: https://www.freakyjolly.com/angular-how-to-detect-ie-other-browser-name-tutorial-by-example/
  getBrowserNameAndVersion() {
    const ua = navigator.userAgent;
    let tem;
    let M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];

    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE', version: tem[1] || '' };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bEdg\/(\d+)/);
      if (tem !== null) {
        return { name: 'Edge(Chromium)', version: tem[1] };
      }
      tem = ua.match(/\bOPR\/(\d+)/);
      if (tem !== null) {
        return { name: 'Opera', version: tem[1] };
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    tem = ua.match(/version\/(\d+)/i);
    if (tem !== null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: M[1]
    };
  }

  isBrowserNotSupported(): boolean {
    const browser = this.getBrowserNameAndVersion();

    return (
      this.browserVersionOptions.versions.filter(
        (e) => e.name === browser.name && e.version > browser.version
      ).length > 0
    );
  }
}
