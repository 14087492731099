<ngx-ov-toggle-button-group
  id="toggle_button_group"
  [attr.data-cy]="'toggle-button-group-levermethode'"
  [options]="opties"
  [value]="levering.value?.levering"
  [bindLabel]="'label'"
  [bindValue]="'code'"
  [bindCode]="'code'"
  [bindIcon]="'icon'"
  (toggleButtonGroupClicked)="onToggleButtonClicked($event)"
>
  <ngx-ov-toggle-button
    id="optie_extra"
    code="EXTRA"
    [attr.data-cy]="'toggle-button-optie-extra'"
    [label]="'extra'"
    [value]="'extra'"
    [icon]="'fas fa-people-carry'"
    [selected]="false"
  ></ngx-ov-toggle-button>
</ngx-ov-toggle-button-group>
