import {
  ApplicationRef,
  ComponentRef,
  createComponent,
  ElementRef,
  EnvironmentInjector,
  Injectable,
  Type,
  ViewContainerRef
} from '@angular/core';
import { NgxOvSnackbarComponent, SnackbarOptions } from './snackbar.component';
import { Status } from '../../../helper';
import { NgxOvSnackbarContainerComponent } from './snackbar-container.component';

@Injectable({
  providedIn: 'root'
})
export class NgxOvSnackbarService {
  constructor(
    public appRef: ApplicationRef,
    private injector: EnvironmentInjector
  ) {}

  snackbarArray: any[] = [];
  isSnackbarContainerAdded: boolean;
  private snackbarContainerComponent:
    | ComponentRef<NgxOvSnackbarContainerComponent>
    | undefined;

  open(
    title?: string,
    message?: string,
    type?: Status,
    snackbarOptions?: SnackbarOptions,
    snackbarContent?: Type<ElementRef>
  ) {
    let snackbarContentComponentRef: ComponentRef<ElementRef<any>> | undefined;

    //check if snackbarcontainer exists
    if (!this.isSnackbarContainerAdded) {
      const rootViewContainerRef =
        this.appRef.components[0].injector.get(ViewContainerRef); //fetch root html-element (body)
      this.snackbarContainerComponent = rootViewContainerRef.createComponent(
        NgxOvSnackbarContainerComponent,
        {
          environmentInjector: this.injector
        }
      );

      this.isSnackbarContainerAdded = true;
    }

    if (snackbarContent) {
      snackbarContentComponentRef = createComponent(snackbarContent, {
        environmentInjector: this.injector
      });
    }

    const snackbarComponent =
      this.snackbarContainerComponent.instance.vcRef.createComponent(
        NgxOvSnackbarComponent,
        {
          environmentInjector: this.injector,
          projectableNodes: snackbarContentComponentRef
            ? [[snackbarContentComponentRef.location.nativeElement]]
            : undefined
        }
      );

    //loop over properties AND getters
    const validKeyList = [
      ...Object.keys(snackbarComponent.instance),
      ...Object.keys(
        Object.getOwnPropertyDescriptors(NgxOvSnackbarComponent.prototype)
      )
    ];

    validKeyList.forEach((validKey) => {
      if (
        validKey !== 'constructor' &&
        snackbarOptions &&
        validKey in snackbarOptions
      ) {
        snackbarComponent.setInput(validKey, snackbarOptions[validKey]);
      }
    });

    snackbarComponent.setInput('title', title);
    snackbarComponent.setInput('message', message);
    snackbarComponent.setInput('type', type);

    snackbarContentComponentRef?.changeDetectorRef.detectChanges();
    snackbarComponent?.changeDetectorRef.detectChanges();

    this.snackbarArray.push(snackbarComponent.location.nativeElement);
  }

  close(snackbarToCloseRef: any) {
    const index = this.snackbarArray.indexOf(snackbarToCloseRef);
    this.snackbarArray.splice(index, 1);
    snackbarToCloseRef.remove();

    if (this.snackbarArray.length === 0) {
      this.snackbarContainerComponent?.destroy();
      this.isSnackbarContainerAdded = false;
    }
  }
}
