import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  INgxOvDataTableColumn,
  NgxOvDataTableColumnType
} from '../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/data-table/data-table.utils';
import {
  IPager,
  PagerService
} from '../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/data-table/pager/services/pager.service';
import { CommonModule } from '@angular/common';
import { DefaultLayoutComponent } from './components/default-layout/default-layout.component';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [CommonModule, DefaultLayoutComponent, RouterOutlet],
  providers: [PagerService]
})
export class AppComponent implements OnInit {
  title = 'ui-componenten';

  public _columns: INgxOvDataTableColumn[] = [
    {
      name: 'yesno',
      label: 'Ja/nee',
      sortable: false,
      type: NgxOvDataTableColumnType.yesno
    },
    {
      name: 'naam',
      label: 'naam',
      sortable: true
    },
    {
      name: 'voornaam',
      label: 'voornaam',
      sortable: true
    },
    {
      name: 'age',
      label: 'leeftijd',
      sortable: true,
      type: NgxOvDataTableColumnType.numeric
    }
  ];

  _data = [
    {
      yesno: true,
      naam: 'Aerts',
      voornaam: 'Koen',
      age: 3000
    },
    {
      yesno: false,
      naam: 'De Becker',
      voornaam: 'Elly',
      age: 40
    },
    {
      yesno: true,
      naam: 'Waes',
      voornaam: 'Tom',
      age: 3000
    },
    {
      yesno: false,
      naam: 'Wauter',
      voornaam: 'Koen',
      age: 40
    },
    {
      yesno: true,
      naam: 'De Bruyne',
      voornaam: 'Kevin',
      age: 3000
    },
    {
      yesno: false,
      naam: 'Romelu',
      voornaam: 'Lukaku',
      age: 40
    },
    {
      yesno: true,
      naam: 'Mertens',
      voornaam: 'Dries',
      age: 3000
    },
    {
      yesno: false,
      naam: 'Courtois',
      voornaam: 'Thibeau',
      age: 40
    }
  ];

  _data2 = [
    {
      yesno: true,
      naam: 'Aerts',
      voornaam: 'Koen',
      age: 3000,
      country: 'Belgium'
    },
    {
      yesno: false,
      naam: 'De Becker',
      voornaam: 'Elly',
      age: 40,
      country: 'Belgium'
    },
    {
      yesno: true,
      naam: 'Waes',
      voornaam: 'Tom',
      age: 3000,
      country: 'Belgium'
    },
    {
      yesno: false,
      naam: 'Wauter',
      voornaam: 'Koen',
      age: 40,
      country: 'France'
    },
    {
      yesno: true,
      naam: 'De Bruyne',
      voornaam: 'Kevin',
      age: 3000,
      country: 'Belgium'
    },
    {
      yesno: false,
      naam: 'Romelu',
      voornaam: 'Lukaku',
      age: 40,
      country: 'France'
    },
    {
      yesno: true,
      naam: 'Mertens',
      voornaam: 'Dries',
      age: 3000,
      country: 'Belgium'
    },
    {
      yesno: false,
      naam: 'Courtois',
      voornaam: 'Thibeau',
      age: 40,
      country: 'Belgium'
    }
  ];
  _pager: IPager;

  _input_field_val_empty = '';
  _input_field_val = 'inputval';

  form: UntypedFormGroup;

  _select_val_1: any;
  _select_val_2: any;

  _radio_options = [
    { id: 'radio-1', waarde: 1, label: 'Yes', extra: 'test 1' },
    { id: 'radio-2', waarde: 1, label: 'No', extra: 'test 2' },
    { id: 'radio-3', waarde: 1, label: 'Maybe', extra: 'test 3' }
  ];

  _radio_value;
  _checkbox_value = false;
  _checkbox_value_default_checked = true;

  _checkbox_options = [
    { id: 'check-1', waarde: 'voormiddag', label: 'voormiddag' },
    { id: 'check-2', waarde: 'namiddag', label: 'namiddag' },
    { id: 'check-3', waarde: 'weekend', label: 'weekend' }
  ];

  constructor(
    private pagerService: PagerService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this._pager = this.pagerService.getPager(this._data.length, 1, 4);
    this.form = this.fb.group({
      job_name: ['test'],
      job_description: ['Testdescription'],
      job_enabled: [this._radio_options[1]],
      job_window: [[this._checkbox_options[1]]],
      job_class_name: 'item.job_class_name',
      next_fire_time: ['item.next_fire_time'],
      isChecked: true,
      state: ['item.state'],
      cron: ['item.cron_expression']
    });
    // eslint-disable-next-line prefer-destructuring
    this._select_val_1 = this._data[2];
    this._select_val_2 = [this._data2[3]];
    // eslint-disable-next-line prefer-destructuring
    this._radio_value = this._radio_options[2];
  }

  setPage(e: any) {
    this._pager = this.pagerService.getPager(this._data.length, e, 4);
  }

  filterChange(e: any) {
    this._pager = this.pagerService.getPager(e.length, 1, 4);
  }
}
