import { Component, OnInit, Optional } from '@angular/core';
import { NgxOvModalComponent } from '../../components/meldingen/modal/modal.component';
import { JsonPipe } from '@angular/common';
import { NgxOvBevestigingService } from './bevestiging.service';
import { OntbrekendeBevestiging } from './bevestiging.model';

// =============================================================================
// Config
// =============================================================================
@Component({
  selector: 'ngx-ov-bevestiging-melding',
  template: `<div [innerHTML]="tekst"></div> `,
  standalone: true,
  imports: [JsonPipe]
})

// =============================================================================
// Definition
// =============================================================================
// eslint-disable-next-line
export class NgxOvBevestigingMelding implements OnInit {
  tekst: any;

  // -------------------------------------------------------------------------
  constructor(
    private bevestigingService: NgxOvBevestigingService,
    @Optional() public modal?: NgxOvModalComponent
  ) {}
  // Constructor
  // -------------------------------------------------------------------------

  // -------------------------------------------------------------------------
  // Init
  // -------------------------------------------------------------------------
  ngOnInit(): void {
    this.modal.data$.subscribe((data: OntbrekendeBevestiging[]) => {
      this.tekst = this.bevestigingService.geefTeksten(data) as string;
    });
  }
}
