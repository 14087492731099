import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  forwardRef,
  Input,
  QueryList
} from '@angular/core';
// eslint-disable-next-line import/no-cycle
import { NgxOvNewsTeaserComponent } from './news-teaser.component';
import { trackItemByIndex } from '../../../helper';
import { ClickEqualsEnterDirective } from '../../../directives/click-equals-enter';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-news-teaser-list',
  templateUrl: './news-teaser-list.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ClickEqualsEnterDirective]
})

// =============================================================================
// Definition
// =============================================================================
export class NgxOvNewsTeaserListComponent {
  _currentSlide = 0;
  trackByFn = trackItemByIndex;

  // -------------------------------------------------------------------------
  // Input variables
  // -------------------------------------------------------------------------
  @Input() title: string;

  // -------------------------------------------------------------------------
  // ContentChildren
  // -------------------------------------------------------------------------
  @ContentChildren(forwardRef(() => NgxOvNewsTeaserComponent))
  newsItems: QueryList<NgxOvNewsTeaserComponent>;

  // -------------------------------------------------------------------------
  // Events
  // -------------------------------------------------------------------------
  onSwipeRight() {
    const next = this._currentSlide - 1;
    this._currentSlide = next < 0 ? this.newsItems.length - 1 : next;
  }

  onSwipeLeft() {
    const next = this._currentSlide + 1;
    this._currentSlide =
      next === this.newsItems.length ? this._currentSlide : next;
  }

  setCurrentSlide(slide: number) {
    this._currentSlide = slide === this.newsItems.length ? 0 : slide;
  }
}
