import { Component, Input } from '@angular/core';
import { CdkStep } from '@angular/cdk/stepper';

/** Custom CDK stepper component */
@Component({
    selector: 'ngx-ov-step',
    templateUrl: './ov-step.component.html',
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    providers: [{ provide: CdkStep, useExisting: NgxOvStepComponent }],
    standalone: true
})
export class NgxOvStepComponent extends CdkStep {
  @Input() isCta = false;
  @Input() canBeExpanded = false;
  @Input() isLocked = false;

  @Input() set defaultShowContent(defaultShowContent: boolean) {
    this._defaultShowContent = defaultShowContent;
    if (this.defaultShowContent) {
      this.showContent = true;
    }
  }

  get defaultShowContent() {
    return this._defaultShowContent;
  }

  _defaultShowContent = false;
  showContent = false;

  toggleShowContent() {
    this.showContent = !this.showContent;
  }
}
