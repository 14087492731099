import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

// =============================================================================
// Component
// =============================================================================
@Component({
    selector: 'ngx-ov-badge',
    templateUrl: './badge.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class NgxOvBadgeComponent {
  // -------------------------------------------------------------------------
  // Variables
  // -------------------------------------------------------------------------

  /**
   * @ignore
   */
  private _color = '#000000';
  /**
   * When given on of the following types ERROR, WARNING, SUCCESS or INFO sets both a background and font color.
   * Else sets the font color to the given color.
   * @param {string} [color=#000000] - color value.
   */
  @Input() set color(color: string) {
    switch (color.toUpperCase()) {
      case 'ERROR': {
        this._backgroundColor = '#FD1A20';
        this._color = '#FFFFFF';
        break;
      }
      case 'WARNING': {
        this._backgroundColor = '#F7C648';
        this._color = '#FFFFFF';
        break;
      }
      case 'SUCCESS': {
        this._backgroundColor = '#5EBD59';
        this._color = '#FFFFFF';
        break;
      }
      case 'INFO': {
        this._backgroundColor = '#CBD2DA';
        this._color = '#FFFFFF';
        break;
      }
      default: {
        this._color = color;
        break;
      }
    }
  }
  get color(): string {
    return this._color;
  }

  /**
   * @ignore
   */
  private _backgroundColor = '#F7F9FC';
  /**
   * Sets the background color of the badge.
   * @param backgroundColor
   */
  @Input() set backgroundColor(backgroundColor: string) {
    this._backgroundColor = backgroundColor;
  }
  get backgroundColor(): string {
    return this._backgroundColor;
  }
}
