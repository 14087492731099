import {
  AnimationTriggerMetadata,
  trigger,
  transition,
  style,
  animate,
  group
} from '@angular/animations';

export const ovSnackbarAnimations: {
  readonly slideInOutTransition: AnimationTriggerMetadata;
} = {
  slideInOutTransition: trigger('slideInOutTransition', [
    transition(':enter', [
      style({
        transform: 'translateX(100%)',
        height: '0px',
        opacity: 0
      }),
      group([
        animate('250ms ease-in-out', style({ transform: 'translateX(0%)' })),
        animate(
          '250ms ease-out',
          style({
            height: '*',
            opacity: 1
          })
        )
      ])
    ]),
    transition(':leave', [
      style({
        transform: 'translateX(0)',
        height: '*',
        opacity: 1
      }),
      group([
        animate('250ms ease-in-out', style({ transform: 'translateX(100%)' })),
        animate(
          '250ms ease-in',
          style({
            height: 0,
            opacity: 0
          })
        )
      ])
    ])
  ])
};
