import {
  ChangeDetectorRef,
  Directive,
  Host,
  Input,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';

import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgxOvActionsService } from '../actions/actions.service';
import { NgxOvModalComponent } from '../../../components/meldingen/modal/modal.component';

@Directive({
  selector: '[storeIsSaving]',
  standalone: true
})
export class StoreIsSavingDirective implements OnInit, OnDestroy {
  @Input() storeIsSaving: string | string[];

  public destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private actionsService: NgxOvActionsService,
    private cd: ChangeDetectorRef,
    @Host() @Optional() private modalComonent: NgxOvModalComponent
  ) {}

  ngOnInit() {
    this.actionsService
      .getStatus$(this.storeIsSaving)
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe((res) => {
        if (res) {
          let saving = false;
          if (res.isSaving || res.isDeleting || res.isLoading) {
            saving = true;
          }
          if (this.modalComonent) {
            this.modalComonent.isSaving = saving;
          }
          this.cd.detectChanges();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }
}
