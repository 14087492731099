<article
  class="c-news-teaser"
  [class.c-news-teaser__link]="hyperlink?.length"
  (click)="onClick()"
>
  @if (image) {
    <div
      class="c-news-teaser__image"
      width="250"
      [style.backgroundImage]="'url(' + image + ')'"
      [style.backgroundSize]="
        picturePortrait ? '28.15rem auto' : 'auto 14.75rem'
      "
    >
      @if (type?.toUpperCase() === 'VIDEO') {
        <div class="c-news-teaser__image-control">
          <i class="fas fa-play" aria-hidden="true"></i>
        </div>
      }
    </div>
  }

  <div class="c-news-teaser__content">
    <p class="c-news-teaser__label" [innerHTML]="label"></p>

    <div class="c-news-teaser__title">
      <a> {{ title }} </a>
    </div>
    <p>
      <ng-content></ng-content>
    </p>
  </div>
</article>
