import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  OnDestroy,
  OnInit,
  signal
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalMessageBarActionsDirective } from './portals/portal-message-bar-actions.directive';
import { PortalModule } from '@angular/cdk/portal';
import { messageBarAnimations } from './message-bar-animations';
import { NgxOvMessageBarService } from './message-bar.service';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-message-bar',
  templateUrl: './message-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, PortalMessageBarActionsDirective, PortalModule],
  animations: [
    messageBarAnimations.messagebarOpenTransition,
    messageBarAnimations.indicatorRotate
  ]
})
export class NgxOvMessageBarComponent implements OnDestroy, OnInit {
  private _title: string;
  isOpen = signal(false);

  // -------------------------------------------------------------------------
  // Content children
  // -------------------------------------------------------------------------
  @ContentChild(PortalMessageBarActionsDirective, { static: false })
  actionsPortal: PortalMessageBarActionsDirective;

  // -------------------------------------------------------------------------
  // Getters & Setters
  // -------------------------------------------------------------------------
  @Input() classes;

  @Input() icon;

  get title(): string {
    return this._title;
  }
  @Input() set title(title: string) {
    this._title = title;
  }

  constructor(private messageBarService: NgxOvMessageBarService) {}

  ngOnInit() {
    this.messageBarService.initMessageBar(this);
  }

  ngOnDestroy(): void {
    this.messageBarService.destroyMessageBar();
  }

  open() {
    this.isOpen.set(true);
  }

  close() {
    this.isOpen.set(false);
  }

  openOrClose() {
    this.isOpen() ? this.close() : this.open();
  }
}
