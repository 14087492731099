import { InjectionToken } from '@angular/core';

export interface PapyrusConfig {
  urlAccessTokenForDownload: string;
  urlAccessTokenForUpload: string;
  urlOvbaseDownload: string;
  urlOvbaseUpload: string;
  use_apigee?: boolean;
}

export const PAPYRUS_CONFIG = new InjectionToken<PapyrusConfig>(
  'forRoot() BrowserSupport configuration.'
);
