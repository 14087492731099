import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightSearch',
  standalone: true
})
export class HighlightSearchPipe implements PipeTransform {
  transform(value: any, args: any, highlight: boolean): any {
    if (!highlight) {
      return value;
    }

    if (!args) {
      return value;
    }

    const regex = new RegExp(args, 'gi');
    const match = value.match(regex);

    if (!match) {
      return value;
    }

    return value.replace(
      regex,
      `<span class='highlight-search'>${match[0]}</span>`
    );
  }
}
