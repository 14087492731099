<li
  class="c-navigation-sub-item"
  (click)="navigate(linkUrl)"
  [routerLink]="linkUrl ? linkUrl : null"
  [routerLinkActive]="linkUrl ? 'c-navigation-sub-item--active' : ''"
  [routerLinkActiveOptions]="{ exact: linkUrl === '/' }"
  #rla="routerLinkActive"
>
  <div class="c-navigation-sub-item__icon">
    <span class="c-navigation-sub-item__icon__link">
      <i class="{{ iconClass }}" aria-hidden="true"></i>
    </span>
  </div>
  <div class="c-navigation-sub-item__label">
    <span
      class="c-navigation-sub-item__label__link-label"
      [innerHTML]="linkTitle"
    ></span>
  </div>
</li>
