<div class="container">
  <h1>Document Viewer</h1>

  <p>
    Vul een URL in of laad een bestand op vanaf je lokale systeem (jpg, jpeg,
    png of pdf). De document viewer zal hier vervolgens een preview van tonen.
  </p>

  <ngx-ov-input-field
    [label]="'File URL'"
    (change)="onChangeFileURL($event)"
  ></ngx-ov-input-field>

  <ngx-ov-upload
    [title]="'Upload file'"
    (uploadChange)="onUploadFile($event)"
    [accept]="['pdf', 'jpg', 'jpeg', 'png']"
    [columns]="columns"
    [rows]="rows"
    [attr.data-cy]="'upload-docviewer'"
  ></ngx-ov-upload>

  <div style="height: 100vh; width: 100%">
    <ngx-ov-document-viewer
      [file]="src$ | async"
      width="75%"
      (rotateImage)="onRotateImage()"
      (downloadImage)="onDownloadImage()"
    ></ngx-ov-document-viewer>
  </div>
</div>
