<ngx-ov-progress-bar [percentage]="progress"></ngx-ov-progress-bar>

<div class="my-10">
  <ngx-ov-wizard-navigation [title]="'Werken'">
    <ng-template ngxOvWizardNavigationLeftContent>
      @if (currentStep > 1) {
        <ngx-ov-link
          [iconBefore]="'fas fa-chevron-left'"
          [text]="'return'"
          (click)="wizardNavigationClicked('left')"
        >
        </ngx-ov-link>
      }
    </ng-template>

    <ng-template ngxOvWizardNavigationRightContent>
      <ngx-ov-link
        [iconAfter]="
          currentStep === totalItems ? 'fas fa-redo' : 'fas fa-chevron-right'
        "
        [text]="currentStep === totalItems ? 'redo' : 'volgende'"
        (click)="wizardNavigationClicked('right')"
      >
      </ngx-ov-link>
    </ng-template>
  </ngx-ov-wizard-navigation>
</div>
<div class="container flex-initial justify-items-center">
  <div class="grid grid-cols-12 my-10">
    <div class="col-start-3 col-span-8">
      @if (currentStep === 1) {
        <div>
          <app-wizard-step-1
            (startWizard)="navigate('forward')"
          ></app-wizard-step-1>
        </div>
      }

      @if (currentStep === 2) {
        <div>
          <app-wizard-step-2
            [gekozenOpleiding]="gekozenOpleiding"
            (opleidingGekozen)="onOpleidingGekozen($event)"
          ></app-wizard-step-2>
        </div>
      }

      @if (currentStep === 3) {
        <div>
          <app-wizard-step-3
            [gekozenToelage]="gekozenToelagen"
            (kiesToelagen)="onToelagenGekozen($event)"
          ></app-wizard-step-3>
        </div>
      }

      @if (currentStep === 4) {
        <div>
          <app-wizard-step-4
            [opleiding]="gekozenOpleiding"
            [toelagen]="gekozenToelagen"
          ></app-wizard-step-4>
        </div>
      }
    </div>
  </div>
</div>
