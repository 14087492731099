import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';

import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { NgxOvModalService } from '../../../../projects/ngx-ov-ui/src/lib/components/meldingen/modal/modal.service';
import { NgxOvMainLayoutService } from '../../../../projects/ngx-ov-ui/src/lib/layout/main-layout/main.layout.service';
import {
  FabButtonPosition,
  NgxOvButtonComponent
} from '../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/button/button.component';
import {
  INgxOvDataTableColumn,
  NgxOvDataTableColumnType
} from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/data-table/data-table.utils';
import {
  IPager,
  PagerService
} from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/data-table/pager/services/pager.service';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { BehaviorSubject } from 'rxjs';
import { NgxOvStatusMessageComponent } from '../../../../projects/ngx-ov-ui/src/lib/fundamentals/status-message/status-message.component';
import { NgxOvInfoBlockComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/content-blokken/info-block/info-block.component';
import { NgxOvRadioComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/forms/radio/radio-item/radio.component';
import { NgxOvCheckboxGroupComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/forms/checkbox/checkbox-group/checkbox-group.component';
import { NgxOvRadioGroupComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/forms/radio/radio-group/radio-group.component';
import { NgxOvTextareaComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/forms/textarea/textarea.component';
import { NgxOvToggleComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/forms/toggle/toggle.component';
import { NgxOvSelectComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/forms/select/select.component';
import { NgxOvDatepickerComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/forms/datepicker/datepicker.component';
import { NgxOvCheckboxComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/forms/checkbox/checkbox-item/checkbox.component';
import { NgxOvInputFieldComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/forms/input-field/input-field.component';
import { NgxOvDataTableCellComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/data-table/data-table-cell/data-table-cell.component';
import { NgxOvDataTableColumnComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/data-table/data-table-column/data-table-column.component';
import { NgxOvDataTableRowComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/data-table/data-table-row/data-table-row.component';
import { NgxOvDataTableTableComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/data-table/data-table-table/data-table-table.component';
import { NgxOvInputLabelComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/forms/input-label/input-label.component';
import { NgxOv3WayToggleComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/forms/3-way-toggle/3-way-toggle.component';
import { PortalActionListItemDetailDirective } from '../../../../projects/ngx-ov-ui/src/lib/components/diverse/action-list/portal-action-list-item-detail.directive';
import { PortalActionListItemActionsDirective } from '../../../../projects/ngx-ov-ui/src/lib/components/diverse/action-list/portal-action-list-item-actions.directive';
import { NgxOvActionListItemComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/diverse/action-list/action-list-item.component';
import { NgxOvActionListComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/diverse/action-list/action-list.component';
import { NgxOvAlertComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/meldingen/alert/alert.component';
import { NgxOvArrowNavigationComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/navigatie/arrow-navigation/arrow-navigation.component';
import { NgxOvContactCardComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/contact-card/contact-card.component';
import { PortalRightContentDirective } from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/expansion-panel/expansion-panel-item/portal-right-content.directive';
import { PortalTitleContentDirective } from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/expansion-panel/expansion-panel-item/portal-title-content.directive';
import { NgxOvExpansionPanelItemComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/expansion-panel/expansion-panel-item/expansion-panel-item.component';
import { NgxOvExpansionPanelListComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/expansion-panel/expansion-panel-list.component';
import { NgxOvDoormatComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/content-blokken/doormat/doormat.component';
import { NgxOvDoormatACMComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/content-blokken/doormat/doormat-acm/doormat-acm.component';
import { NgxOvTabComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/navigatie/tabs/tab.component';
import { AsyncPipe, JsonPipe } from '@angular/common';
import { NgxOvTabsComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/navigatie/tabs/tabs.component';
import { NgxOvBadgeComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/meldingen/badge/badge.component';
import { NgxOvGlobalAlertComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/meldingen/global-alert/global-alert.component';
import { NgxOvBadgeDirective } from '../../../../projects/ngx-ov-ui/src/lib/directives/badge';
import { NgxOvFlyoutItemComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/navigatie/flyout/flyout-item.component';
import { NgxOvFlyoutComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/navigatie/flyout/flyout.component';
import { PortalAgendaActionsDirective } from '../../../../projects/ngx-ov-ui/src/lib/components/content-blokken/agenda/portals/portal-panel-actions.directive';
import { NgxOvAgendaItemComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/content-blokken/agenda/agenda-item.component';
import { NgxOvAgendaComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/content-blokken/agenda/agenda.component';
import { NgxOvCardGroupComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/content-blokken/card/card-group.component';
import { NgxOvProgressBlockComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/content-blokken/progress-block/progress-block.component';
import { PortalCardDirective } from '../../../../projects/ngx-ov-ui/src/lib/components/content-blokken/card/portals/portal-card.directive';
import { PortalCardTitleDirective } from '../../../../projects/ngx-ov-ui/src/lib/components/content-blokken/card/portals/portal-card-title.directive';
import { NgxOvCardComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/content-blokken/card/card.component';
import { NgxOvProfileComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/diverse/profile/profile.component';
import { ClickEqualsEnterDirective } from '../../../../projects/ngx-ov-ui/src/lib/directives/click-equals-enter';
import { NgxOvPillComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/diverse/pill/pill.component';
import { NgxOvTooltipComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/meldingen/tooltip/tooltip.component';
import { NgxOvNewsTeaserComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/content-blokken/news-teaser/news-teaser.component';
import { NgxOvNewsTeaserListComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/content-blokken/news-teaser/news-teaser-list.component';
import { MatIconModule } from '@angular/material/icon';
import {
  NgxOvUiModule,
  trackItemByIndex
} from 'projects/ngx-ov-ui/src/public-api';
import { NgxOvMessageBarService } from 'projects/ngx-ov-ui/src/lib/components/data-visualisatie/message-bar/message-bar.service';

@Component({
  selector: 'app-page-overview',
  templateUrl: './page-overview.component.html',
  styleUrls: ['./page-overview.component.scss'],
  providers: [NgxOvModalService],
  standalone: true,
  imports: [
    NgxOvNewsTeaserListComponent,
    NgxOvNewsTeaserComponent,
    MatStepperModule,
    NgxOvTooltipComponent,
    NgxOvButtonComponent,
    NgxOvPillComponent,
    ClickEqualsEnterDirective,
    NgxOvProfileComponent,
    NgxOvCardComponent,
    PortalCardTitleDirective,
    PortalCardDirective,
    NgxOvProgressBlockComponent,
    NgxOvCardGroupComponent,
    FormsModule,
    NgxOvAgendaComponent,
    NgxOvAgendaItemComponent,
    PortalAgendaActionsDirective,
    NgxOvFlyoutComponent,
    NgxOvFlyoutItemComponent,
    NgxOvBadgeDirective,
    NgxOvGlobalAlertComponent,
    NgxOvBadgeComponent,
    NgxOvTabsComponent,
    NgxOvTabComponent,
    NgxOvDoormatACMComponent,
    NgxOvDoormatComponent,
    NgxOvExpansionPanelListComponent,
    NgxOvExpansionPanelItemComponent,
    PortalTitleContentDirective,
    PortalRightContentDirective,
    NgxOvContactCardComponent,
    NgxOvArrowNavigationComponent,
    NgxOvAlertComponent,
    NgxOvActionListComponent,
    NgxOvActionListItemComponent,
    PortalActionListItemActionsDirective,
    PortalActionListItemDetailDirective,
    NgxOv3WayToggleComponent,
    NgxOvInputLabelComponent,
    NgxOvDataTableTableComponent,
    NgxOvDataTableRowComponent,
    NgxOvDataTableColumnComponent,
    NgxOvDataTableCellComponent,
    NgxOvInputFieldComponent,
    NgxOvCheckboxComponent,
    NgxOvDatepickerComponent,
    NgxOvSelectComponent,
    NgxOvToggleComponent,
    ReactiveFormsModule,
    NgxOvTextareaComponent,
    NgxOvRadioGroupComponent,
    NgxOvCheckboxGroupComponent,
    NgxOvRadioComponent,
    NgxOvInfoBlockComponent,
    NgxOvStatusMessageComponent,
    AsyncPipe,
    JsonPipe,
    MatIconModule,
    NgxOvUiModule
  ]
})
export class PageOverviewComponent implements OnInit, AfterViewInit {
  @ViewChild('verticalStepper') stepper: MatStepper;
  title = 'ui-componenten';

  toggleOpenMessagebar = () => {
    this.messageBarService.open();
  };

  showTab$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public arrowNavigationItems: string[] = [
    '2016-2017',
    '2017-2018',
    '2018-2019',
    '2019-2020',
    '2020-2021'
  ];

  public _columns: INgxOvDataTableColumn[] = [
    {
      name: 'yesno',
      label: 'Ja/nee',
      sortable: false,
      type: NgxOvDataTableColumnType.yesno
      // yesno: true -> deprecated
    },
    {
      name: 'naam',
      label: 'naam',
      sortable: true,
      clickable: false
    },
    {
      name: 'voornaam',
      label: 'voornaam',
      sortable: true
    },
    {
      name: 'voornaam',
      label: 'voornaam case sensitive',
      sortable: true,
      sortCaseSensitive: true
    },
    {
      name: 'age',
      label: 'leeftijd',
      sortable: true,
      type: NgxOvDataTableColumnType.numeric
      // numeric: true -> deprecated
    },
    {
      name: 'geboortedatum',
      label: 'geboortedatum',
      type: NgxOvDataTableColumnType.date,
      sortable: true
      // date: true -> deprecated
    }
  ];

  _data = [
    {
      yesno: true,
      naam: 'martinnull',
      voornaam: 'test',
      age: 86
    },
    {
      yesno: true,
      naam: 'Aerts',
      voornaam:
        'Koenljsqfljsdqlkfjlsqjfmsdjfmskdjfmdjsfkmdjsfmjsdkfmjsdkmfjsdmfjsdmfjdsmkfjdskfhdsjlhfjdsghfkjsdgfkjsdgfksgdfkjdlfjhdkjfmksdjfklsdjhflhsdflhdsflhdsfldshfljhdsfljhdsflhdsfldshfjldshfldsjfhdslfds',
      age: 111,
      geboortedatum: '2020-05-10 21:00'
    },
    {
      yesno: false,
      naam: 'De Becker',
      voornaam: 'elly',
      age: 30,
      geboortedatum: '2019-06-21 21:00'
    },
    {
      yesno: true,
      naam: 'Waes',
      voornaam: undefined,
      age: 9000,
      geboortedatum: '2020-02-05 21:00'
    },
    {
      yesno: false,
      naam: 'Wauter',
      voornaam: null,
      age: 60,
      geboortedatum: undefined
    },
    {
      yesno: true,
      naam: 'De Bruyne',
      age: 3000,
      geboortedatum: '2020-06-14 20:45'
    },
    {
      yesno: false,
      naam: 'Romelu',
      voornaam: 'Lukaku',
      age: 40,
      geboortedatum: '2020-06-14 21:05'
    },
    {
      yesno: true,
      naam: 'Mertens',
      voornaam: 'Dries',
      age: 3000,
      geboortedatum: '2020-06-14 21:00'
    },
    {
      yesno: false,
      naam: 'Courtois',
      voornaam: 'Thibeau',
      age: 40,
      geboortedatum: new Date()
    }
  ];

  _data2 = [
    {
      yesno: true,
      naam: 'Aerts',
      voornaam:
        'Koenjsflmkjsqdmfjqsmdfjmsqdjfksdfhlsdhfskjhdskfhsdkfhsdkfhsdkfhsdkfjhsdkfhsdkfhskdhfsdkfhsdkfhsdkhfdsfhsdukfhuzheukhsdfukhsdufhdsfkhdskfuhdskfuhdskfhsdfkuhdsfkuhdsfkudshfkusdhfkusdhfksudhfkuhsdf',
      age: 3000,
      country: 'Belgium'
    },
    {
      yesno: false,
      naam: 'De Becker',
      voornaam: undefined,
      age: 90,
      country: 'Belgium'
    },
    {
      yesno: true,
      naam: 'Waes',
      voornaam: 'Tom',
      age: 111,
      country: 'Belgium'
    },
    {
      yesno: false,
      naam: 'Wauter',
      voornaam: 'Koen',
      age: 40,
      country: 'France'
    },
    {
      yesno: true,
      naam: 'De Bruyne',
      voornaam: 'Kevin',
      age: 3000,
      country: 'Belgium'
    },
    {
      yesno: false,
      naam: 'Romelu',
      voornaam: 'Lukaku',
      age: 40,
      country: 'France'
    },
    {
      yesno: true,
      naam: 'Mertens',
      voornaam: 'Dries',
      age: 3000,
      country: 'Belgium'
    },
    {
      yesno: false,
      naam: 'Courtois',
      voornaam: 'Thibeau',
      age: 40,
      country: 'Belgium'
    }
  ];
  _pager: IPager;

  _input_field_val_empty = '';
  _input_field_val = 'inputval';
  _input_field_max_200 = '0';

  form: UntypedFormGroup;

  _select_val_1: any;
  _select_val_2: any;

  _radio_options = [
    { id: 'radio-1', waarde: 1, label: 'Yes', extra: 'test 1' },
    { id: 'radio-2', waarde: 1, label: 'No', extra: 'test 2' },
    { id: 'radio-3', waarde: 1, label: 'Maybe', extra: 'test 3' }
  ];

  _radio_value;
  _checkbox_value = false;
  _checkbox_value_default_checked = true;
  _selectedDate;
  _selectedDateDefaultNow;

  _toggle_test = false;
  _three_way_toggle_test = true;

  _currentPageSize = 2;

  _checkbox_options = [
    { id: 'check-1', waarde: 'voormiddag', label: 'voormiddag' },
    { id: 'check-2', waarde: 'namiddag', label: 'namiddag' },
    { id: 'check-3', waarde: 'weekend', label: 'weekend' }
  ];

  fabButtonPosition: FabButtonPosition = FabButtonPosition.BottomCenter;

  constructor(
    private pagerService: PagerService,
    private fb: UntypedFormBuilder,
    private renderer: Renderer2,
    private hostElement: ElementRef,
    private modalService: NgxOvModalService,
    private layoutService: NgxOvMainLayoutService,
    private messageBarService: NgxOvMessageBarService
  ) {}

  ngOnInit() {
    this._pager = this.pagerService.getPager(
      this._data.length,
      1,
      this._currentPageSize
    );
    this.form = this.fb.group({
      job_name: ['test'],
      job_description: ['Testdescription'],
      job_enabled: [this._radio_options[1]],
      job_window: [[this._checkbox_options[1]]],
      job_class_name: 'item.job_class_name',
      next_fire_time: ['item.next_fire_time'],
      waarde_max_200: [0, Validators.max(200)],
      isChecked: true,
      state: ['item.state'],
      cron: ['item.cron_expression'],
      toggle: [false],
      three_way_toggle: [null]
    });
    // eslint-disable-next-line prefer-destructuring
    this._select_val_1 = this._data[2];
    this._select_val_2 = [this._data2[3]];
    // eslint-disable-next-line prefer-destructuring
    this._radio_value = this._radio_options[2];

    this.renderer.addClass(this.hostElement.nativeElement, 'custom-theme');

    /* eslint-disable */
    const today = new Date();

    const dd = today.getDate();
    const mm = today.getMonth() + 1;
    const yyyy = today.getFullYear().toString();

    this._selectedDateDefaultNow = dd + '-' + mm + '-' + yyyy;
    /* eslint-enable */
  }

  ngAfterViewInit() {
    this.stepper.selectedIndex = 1;
    this.showTab$.next(true);
  }

  setPage(e: any) {
    this._pager = this.pagerService.getPager(
      this._data.length,
      e,
      this._currentPageSize
    );
  }

  setPageSize(e: any) {
    this._currentPageSize = e;
    this._pager = this.pagerService.getPager(
      this._data.length,
      1,
      this._currentPageSize
    );
  }

  filterChange(e: any) {
    this._pager = this.pagerService.getPager(
      e.length,
      1,
      this._currentPageSize
    );
  }

  triggerSelect(e: any) {
    // eslint-disable-next-line no-console,no-restricted-syntax
    console.info('triggerSelect', e);
  }

  triggerClick(e: any) {
    // eslint-disable-next-line no-console,no-restricted-syntax
    console.info('triggerClick', e);
  }

  // Button
  // eslint-disable-next-line @typescript-eslint/member-ordering
  _buttonLoading = false;

  buttonClicked(e: any) {
    // eslint-disable-next-line no-console,no-restricted-syntax
    console.info('Button clicked', e);
    this._buttonLoading = true;
  }

  clearGeboorteDatum() {
    this._selectedDate = null;
  }

  testDoormatClick() {
    // eslint-disable-next-line no-console
    console.log('doormat click');
  }

  getSearchListItems(): any[] {
    return [
      {
        naam: 'Erik',
        functie: 'Dev',
        omschrijving: '38 jaar'
      },
      {
        naam: 'Mark',
        functie: 'Analist',
        omschrijving: '44 jaar'
      }
    ];
  }

  onClickAction(message: any) {
    // eslint-disable-next-line no-console
    console.log(message);
    // eslint-disable-next-line no-alert
    alert(`onClickAction${message}`);
  }

  onClickActionItem(message: any) {
    // eslint-disable-next-line no-console
    console.log(message);
    // eslint-disable-next-line no-alert
    alert(`onClickActionItem${message}`);
  }

  onTooltipClicked(modalId: string) {
    // eslint-disable-next-line no-console
    console.log('tooltip clicked');
    this.onOpenModal(modalId);
  }

  onDoormatClicked(event: any) {
    if (event === 'button') {
      // eslint-disable-next-line no-console
      console.log('Doormat button clicked, logic executed');
    } else if (event === 'info') {
      // eslint-disable-next-line no-console
      console.log('Doormat info clicked, logic executed');
    } else {
      // eslint-disable-next-line no-console
      console.log('Doormat clicked, logic executed');
    }
  }

  testClick(message: string) {
    // eslint-disable-next-line no-console
    console.log(message);
  }

  navigateHome() {
    // eslint-disable-next-line no-console
    console.log('navigateHome');
  }

  onScrollTop() {
    this.layoutService.scrollTop();
  }

  logToConsole(text) {
    // eslint-disable-next-line no-console
    console.log(text);
  }

  onOpenModal(id: string) {
    this.modalService.open(id);
  }

  onTabClicked() {
    // nothing to do here
  }

  protected readonly trackByFn = trackItemByIndex;
}
