@if (bannerImgSrc || bannerImgSrcMobile) {
  <ngx-ov-banner
    [size]="'MEDIUM'"
    [type]="'imageOnly'"
    [boxTitle]="title"
    [title]="subtitle"
    [backgroundImageSrc]="bannerImgSrc"
    [backgroundImageSrcMobile]="bannerImgSrcMobile"
    [backgroundImagePos]="backgroundImagePos"
    [backgroundImagePosMobile]="backgroundImagePosMobile"
  ></ngx-ov-banner>
}

<div class="l-section">
  <div
    class="c-login-page__intro-container container"
    [class.c-login-page__intro-container__with-image]="
      introImgSrc && introImgSrc.length
    "
  >
    @if (introTitle || introSubtitle || introCTA) {
      <div>
        @if (introTitle) {
          <h2>{{ introTitle }}</h2>
        }

        @if (introSubtitle) {
          <h4 class="c-login-page__intro-subtitle">
            {{ introSubtitle }}
          </h4>
        }

        @if (introCTA) {
          <ngx-ov-button
            [label]="introCTA"
            [iconAfter]="'fas fa-chevron-right'"
            (buttonClick)="introCTAClicked.emit()"
          ></ngx-ov-button>
        }
      </div>
    }

    @if (introImgSrc) {
      <div class="hidden-mobile">
        <img [src]="introImgSrc" alt="login page intro image" />
      </div>
    }
  </div>
</div>

<ng-content></ng-content>

<div class="l-section">
  <div class="container">
    <div class="l-grid l-grid--with-default-spacing">
      <div class="l-grid__col:12/12 l-grid__col:4/12@at:tablet">
        <ngx-ov-contact-card
          [branch]="contactBranch"
          [department]="contactDepartment"
          [telephone]="contactTelephone"
          [email]="contactEmail"
          [url]="contactWebsiteUrl"
          [contactUrl]="contactUrl"
        ></ngx-ov-contact-card>
      </div>
    </div>
  </div>
</div>
