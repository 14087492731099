@if (linkUrl) {
  <a
    (click)="onClick($event)"
    class="c-link {{ classes }}"
    [class.is-disabled]="isDisabled"
    [class.nowrap]="nowrap"
  >
    <ng-container *ngTemplateOutlet="linkContent"></ng-container>
  </a>
} @else {
  <a
    class="c-link {{ classes }}"
    [class.nowrap]="nowrap"
    [class.is-disabled]="isDisabled"
  >
    <ng-container *ngTemplateOutlet="linkContent"></ng-container>
  </a>
}

<ng-template #linkContent>
  @if (iconBefore) {
    <!-- Icon before -->
    <span class="c-link__icon-before">
      <i class="{{ iconBefore }}" aria-hidden="true"></i>
    </span>
  }

  <!-- Text -->
  <span class="c-link__text" [innerHTML]="text"></span>

  @if (iconAfter) {
    <!-- Icon after -->
    <span class="c-link__icon-after">
      <i class="{{ iconAfter }}" aria-hidden="true"></i>
    </span>
  }
</ng-template>
