/* eslint-disable */
export interface IBeroepskwalificatieVersieOutputRestDto {
  beroepskwalificatie_versie_nr?: string;
  titel?: string;
  url?: string;
  vks_niveau?: number;
}
export declare class BeroepskwalificatieVersieOutputRestDto
  implements IBeroepskwalificatieVersieOutputRestDto {
  beroepskwalificatie_versie_nr?: string;
  titel?: string;
  url?: string;
  vks_niveau?: number;
}
export interface ICodeOmschrijvingRestDtostring {
  code: string;
  omschrijving?: string;
}
export declare class CodeOmschrijvingRestDtostring
  implements ICodeOmschrijvingRestDtostring {
  code: string;
  omschrijving?: string;
}
export interface ILesplaatsOutputRestDto {
  bus?: string;
  gemeente?: string;
  naam?: string;
  nr?: string;
  postcode?: string;
  straat?: string;
}
export declare class LesplaatsOutputRestDto implements ILesplaatsOutputRestDto {
  bus?: string;
  gemeente?: string;
  naam?: string;
  nr?: string;
  postcode?: string;
  straat?: string;
}

export interface IOrganisatieOutputRestDto {
  naam?: string;
  soort_organisatie?: CodeOmschrijvingRestDtostring;
  website?: string;
}
export declare class OrganisatieOutputRestDto
  implements IOrganisatieOutputRestDto {
  naam?: string;
  soort_organisatie?: CodeOmschrijvingRestDtostring;
  website?: string;
}

export interface ITrajectOutputRestDto {
  automatische_vrijstelling?: boolean;
  beroepskwalificatie_versies?: Array<BeroepskwalificatieVersieOutputRestDto>;
  id?: string;
  korte_inhoud?: string;
  lesplaatsen?: Array<LesplaatsOutputRestDto>;
  naam?: string;
  organisatie?: OrganisatieOutputRestDto;
  soort_traject?: CodeOmschrijvingRestDtostring;
}
export declare class TrajectOutputRestDto implements ITrajectOutputRestDto {
  automatische_vrijstelling?: boolean;
  beroepskwalificatie_versies?: Array<BeroepskwalificatieVersieOutputRestDto>;
  id?: string;
  korte_inhoud?: string;
  lesplaatsen?: Array<LesplaatsOutputRestDto>;
  naam?: string;
  organisatie?: OrganisatieOutputRestDto;
  soort_traject?: CodeOmschrijvingRestDtostring;
}

export const trajecten: TrajectOutputRestDto[] = [
  {
    id: '1',
    naam: 'Beenhouwer',
    soort_traject: {
      code: 'OPLEIDING',
      omschrijving: 'Opleiding'
    },
    korte_inhoud: 'Lorem ipsum 1',
    organisatie: {
      naam: 'Antwerp Port',
      website: 'portofantwerp.com',
      soort_organisatie: {
        code: 'CVO',
        omschrijving: 'Centrum voor volwassenenonderwijs'
      }
    },
    beroepskwalificatie_versies: [
      {
        beroepskwalificatie_versie_nr: 'BK-0001-1',
        vks_niveau: 1,
        url: 'link',
        titel: 'Beenhouwer'
      },
      {
        beroepskwalificatie_versie_nr: 'BK-0001-2',
        vks_niveau: 1,
        url: 'link',
        titel: 'Beenhouwer'
      }
    ],
    lesplaatsen: [
      {
        naam: 'Antwerp lesplaats',
        straat: 'Amerikalei',
        nr: '134',
        bus: '2',
        postcode: '2000',
        gemeente: 'ANTWERPEN'
      }
    ]
  },
  {
    id: '2',
    naam: 'Architect',
    korte_inhoud: 'Lorem ipsum 2',
    organisatie: {
      naam: 'Antwerp Port',
      website: 'portofantwerp.com',
      soort_organisatie: {
        code: 'CVO',
        omschrijving: 'Centrum voor volwassenenonderwijs'
      }
    },
    beroepskwalificatie_versies: [
      {
        beroepskwalificatie_versie_nr: 'BK-0002-1',
        vks_niveau: 1,
        url: 'arch.com',
        titel: 'Architect'
      }
    ],
    lesplaatsen: [
      {
        naam: 'W Brabant lesplaats',
        straat: 'Rue des Halles',
        nr: '12',
        postcode: '1000',
        gemeente: 'BRUSSEL'
      }
    ]
  },
  {
    id: '3',
    naam: 'Animator',
    soort_traject: {
      code: 'OPLEIDING',
      omschrijving: 'Opleiding'
    },
    korte_inhoud: 'Lorem ipsum 3',
    organisatie: {
      naam: 'Antwerp Port',
      website: 'portofantwerp.com',
      soort_organisatie: {
        code: 'CVO',
        omschrijving: 'Centrum voor volwassenenonderwijs'
      }
    },
    beroepskwalificatie_versies: [
      {
        beroepskwalificatie_versie_nr: 'BK-0002-2',
        vks_niveau: 1,
        url: 'animatoren.be',
        titel: 'Animator'
      }
    ],
    lesplaatsen: [
      {
        naam: 'Esneux',
        straat: 'Rue de la Goffe',
        nr: '26',
        postcode: '4130',
        gemeente: 'ESNEUX'
      }
    ]
  },
  {
    id: '5',
    naam: 'Barman',
    soort_traject: {
      code: 'OPLEIDING',
      omschrijving: 'Opleiding'
    },
    korte_inhoud: 'Lorem ipsum 5',
    organisatie: {
      naam: 'Antwerp Port',
      website: 'portofantwerp.com',
      soort_organisatie: {
        code: 'CVO',
        omschrijving: 'Centrum voor volwassenenonderwijs'
      }
    },
    beroepskwalificatie_versies: [
      {
        beroepskwalificatie_versie_nr: 'BK-0004-1',
        vks_niveau: 1,
        url: 'barmanopleiding.be',
        titel: 'Barman'
      }
    ],
    lesplaatsen: [
      {
        naam: 'Lesplaats 4',
        straat: 'Rue Nouvelle',
        nr: '49',
        postcode: '5060',
        gemeente: 'SAMBREVILLE'
      }
    ]
  },
  {
    id: '6',
    naam: 'Logopedist',
    soort_traject: {
      code: 'OPLEIDING',
      omschrijving: 'Opleiding'
    },
    korte_inhoud: 'Lorem ipsum 6',
    organisatie: {
      naam: 'Antwerp Port',
      website: 'portofantwerp.com',
      soort_organisatie: {
        code: 'CVO',
        omschrijving: 'Centrum voor volwassenenonderwijs'
      }
    },
    beroepskwalificatie_versies: [
      {
        beroepskwalificatie_versie_nr: 'BK-0005-1',
        vks_niveau: 1,
        url: 'audiologen.be',
        titel: 'Audioloog'
      }
    ],
    lesplaatsen: [
      {
        naam: 'Lesplaats 5',
        straat: 'Rue Nouvelle',
        nr: '49',
        postcode: '5060',
        gemeente: 'SAMBREVILLE'
      }
    ]
  },
  {
    id: '7',
    naam: 'Taxi',
    soort_traject: {
      code: 'OPLEIDING',
      omschrijving: 'Opleiding'
    },
    korte_inhoud: 'Lorem ipsum 7',
    organisatie: {
      naam: 'Antwerp Port',
      website: 'portofantwerp.com',
      soort_organisatie: {
        code: 'CVO',
        omschrijving: 'Centrum voor volwassenenonderwijs'
      }
    },
    beroepskwalificatie_versies: [
      {
        beroepskwalificatie_versie_nr: 'BK-0006-1',
        vks_niveau: 1,
        url: 'taxiopleiding.eu',
        titel: 'Autocarchauffeur'
      }
    ],
    lesplaatsen: [
      {
        naam: 'Lesplaats 6',
        straat: 'Rue Nouvelle',
        nr: '49',
        postcode: '5060',
        gemeente: 'SAMBREVILLE'
      }
    ]
  },
  {
    id: '8',
    naam: 'Bouwer-herstéller',
    soort_traject: {
      code: 'OPLEIDING',
      omschrijving: 'Opleiding'
    },
    korte_inhoud: 'Lorem ipsum 8',
    organisatie: {
      naam: 'Antwerp Port',
      website: 'portofantwerp.com',
      soort_organisatie: {
        code: 'CVO',
        omschrijving: 'Centrum voor volwassenenonderwijs'
      }
    },
    beroepskwalificatie_versies: [
      {
        beroepskwalificatie_versie_nr: 'BK-0007-1',
        vks_niveau: 1,
        url: 'wordtboumeester.be',
        titel: 'ingenieur in opleiding'
      }
    ],
    lesplaatsen: [
      {
        naam: 'Lesplaats 7',
        straat: 'Rue Nouvelle',
        nr: '49',
        postcode: '5060',
        gemeente: 'SAMBREVILLE'
      }
    ]
  },
  {
    id: '4',
    naam: 'Kunstenaar',
    soort_traject: {
      code: 'OPLEIDING',
      omschrijving: 'Opleiding'
    },
    automatische_vrijstelling: false,
    korte_inhoud: 'Lorem ipsum 4',
    organisatie: {
      naam: 'MUHKA',
      website: 'muhka.be',
      soort_organisatie: {
        code: 'CVO',
        omschrijving: 'Centrum voor volwassenenonderwijs'
      }
    },
    beroepskwalificatie_versies: [
      {
        beroepskwalificatie_versie_nr: 'BK-0003-1',
        vks_niveau: 1,
        url: 'muhka.be',
        titel: 'Kunstenaar'
      }
    ],
    lesplaatsen: [
      {
        naam: 'Org Vl brabant lesplaats antw',
        straat: 'Rue Nouvelle',
        nr: '49',
        postcode: '5060',
        gemeente: 'SAMBREVILLE'
      }
    ]
  },
  {
    id: '21',
    naam: 'Chocoladebewerker',
    soort_traject: {
      code: 'EVC',
      omschrijving: 'EVC'
    },
    korte_inhoud: 'Lorem ipsum 21',
    organisatie: {
      naam: 'Leonidas',
      website: 'leonidas.be',
      soort_organisatie: {
        code: 'CVO',
        omschrijving: 'Centrum voor volwassenenonderwijs'
      }
    },
    beroepskwalificatie_versies: [
      {
        beroepskwalificatie_versie_nr: 'BK-0009-1',
        vks_niveau: 1,
        url: 'leonidas.be',
        titel: 'Chocoladebewerker'
      }
    ],
    lesplaatsen: [
      {
        naam: 'Lesplaats 9',
        straat: 'Rue Nouvelle',
        nr: '49',
        postcode: '5060',
        gemeente: 'SAMBREVILLE'
      }
    ]
  },
  {
    id: '22',
    naam: 'Conciërge',
    soort_traject: {
      code: 'OPLEIDING',
      omschrijving: 'Opleiding'
    },
    korte_inhoud: 'Lorem ipsum 22',
    organisatie: {
      naam: 'Sint-Gabriël',
      website: 'stgabrielcollege.be',
      soort_organisatie: {
        code: 'SO',
        omschrijving: 'Secundaire school'
      }
    },
    beroepskwalificatie_versies: [
      {
        beroepskwalificatie_versie_nr: 'BK-0010-1',
        vks_niveau: 1,
        url: 'stgabrielcollege.be',
        titel: 'Conciërge'
      }
    ],
    lesplaatsen: [
      {
        naam: 'Lesplaats 10a',
        straat: 'Rue Nouvelle',
        nr: '49',
        postcode: '5060',
        gemeente: 'SAMBREVILLE'
      }
    ]
  },
  {
    id: '23',
    naam: 'Leerkracht',
    soort_traject: {
      code: 'OPLEIDING',
      omschrijving: 'Opleiding'
    },
    korte_inhoud: 'Lorem ipsum 23',
    organisatie: {
      naam: 'Annuntia',
      website: 'annuntia.be',
      soort_organisatie: {
        code: 'SECTOR',
        omschrijving: 'Sector'
      }
    },
    beroepskwalificatie_versies: [
      {
        beroepskwalificatie_versie_nr: 'BK-0010-2',
        vks_niveau: 1,
        url: 'annuntia.be',
        titel: 'Leerkracht'
      }
    ],
    lesplaatsen: [
      {
        naam: 'Lesplaats 10b',
        straat: 'Rue Nouvelle',
        nr: '49',
        postcode: '5060',
        gemeente: 'SAMBREVILLE'
      }
    ]
  },
  {
    id: '24',
    naam: 'Monteur',
    soort_traject: {
      code: 'OPLEIDING',
      omschrijving: 'Opleiding'
    },
    automatische_vrijstelling: true,
    korte_inhoud: 'Lorem ipsum 24',
    organisatie: {
      naam: 'Volvo cars',
      website: 'volvocars.com',
      soort_organisatie: {
        code: 'CVO',
        omschrijving: 'Centrum voor volwassenenonderwijs'
      }
    },
    beroepskwalificatie_versies: [
      {
        beroepskwalificatie_versie_nr: 'BK-0011-1',
        vks_niveau: 1,
        url: 'volvocars.com',
        titel: 'Monteur'
      }
    ],
    lesplaatsen: [
      {
        naam: 'Lesplaats 11',
        straat: 'Rue Nouvelle',
        nr: '49',
        postcode: '5060',
        gemeente: 'SAMBREVILLE'
      }
    ]
  },
  {
    id: '25',
    naam: 'Diamantbewerker',
    soort_traject: {
      code: 'OPLEIDING',
      omschrijving: 'Opleiding'
    },
    automatische_vrijstelling: false,
    korte_inhoud: 'Lorem ipsum 25',
    organisatie: {
      naam: 'DIVA',
      website: 'diva.be',
      soort_organisatie: {
        code: 'CVO',
        omschrijving: 'Centrum voor volwassenenonderwijs'
      }
    },
    beroepskwalificatie_versies: [
      {
        beroepskwalificatie_versie_nr: 'BK-0012-1',
        vks_niveau: 1,
        url: 'diva.be',
        titel: 'Diamantbewerker'
      }
    ],
    lesplaatsen: [
      {
        naam: 'Lesplaats 12',
        straat: 'Rue Nouvelle',
        nr: '49',
        postcode: '5060',
        gemeente: 'SAMBREVILLE'
      }
    ]
  },
  {
    id: '26',
    naam: 'Kinesitherapeut',
    soort_traject: {
      code: 'OPLEIDING',
      omschrijving: 'Opleiding'
    },
    automatische_vrijstelling: true,
    korte_inhoud: 'Lorem ipsum 26',
    organisatie: {
      naam: 'Move to Cure',
      website: 'movetocure.com',
      soort_organisatie: {
        code: 'VDAB',
        omschrijving: 'VDAB'
      }
    },
    beroepskwalificatie_versies: [
      {
        beroepskwalificatie_versie_nr: 'BK-0014-1',
        vks_niveau: 1,
        url: 'movetocure.com',
        titel: 'Kinesitherapeut'
      }
    ],
    lesplaatsen: [
      {
        naam: 'Lesplaats 14',
        straat: 'Rue Nouvelle',
        nr: '49',
        postcode: '5060',
        gemeente: 'SAMBREVILLE'
      }
    ]
  }
];
/* eslint-enable */
