import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  OnChanges,
  Optional,
  QueryList,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';

import { NgxOvNavigationItemComponent } from './navigation-item.component';
import { NgxOvNavigationService } from './navigation.service';
import { NavigationItem } from './navigation.model';
import { NgxOvComponentBaseClass } from '../../ngx-ov-component-base-class';
import { TranslationsService } from '../../../services/translations.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxOvBadgeComponent } from '../../meldingen/badge/badge.component';
import { ClickEqualsEnterDirective } from '../../../directives/click-equals-enter';
import { NgClass } from '@angular/common';
import { trackItemByIndex } from '../../../helper';

/* =============================================================================

INFO

Navigation component met submenu items kan momenteel nog niet desktop collapsible
gebruikt worden.
=============================================================================
 */

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-navigation',
  templateUrl: './navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ClickEqualsEnterDirective,
    NgClass,
    NgxOvNavigationItemComponent,
    RouterLink,
    RouterLinkActive,
    NgxOvBadgeComponent
  ]
})
export class NgxOvNavigationComponent
  extends NgxOvComponentBaseClass
  implements OnChanges, AfterViewInit
{
  _topItems: NavigationItem[];
  _bottomItems: NavigationItem[];

  _quickLinkItems: NavigationItem[];
  _mobileItems: NavigationItem[];

  // -------------------------------------------------------------------------
  // ContentChildren
  // -------------------------------------------------------------------------
  @ContentChildren(NgxOvNavigationItemComponent)
  navigationItems: QueryList<NgxOvNavigationItemComponent>;

  // -------------------------------------------------------------------------
  // ViewChildren
  // -------------------------------------------------------------------------
  @ViewChild('mobileNavigation') mobileNavigation: ElementRef;

  // -------------------------------------------------------------------------
  // Public variables
  // -------------------------------------------------------------------------
  public toggleIconOpenTitle: string;
  public toggleIconCloseTitle: string;
  // -------------------------------------------------------------------------
  // Private variables
  // -------------------------------------------------------------------------
  private _menuItems: NavigationItem[];
  private _roles: {};

  // -------------------------------------------------------------------------
  // Input variables
  // -------------------------------------------------------------------------
  @Input() menuMobileIconText = 'Menu';
  @Input() collapsible = true;
  @Input() title = 'Menu';
  @Input() titleMobile = 'Menu';
  @Input() hideQuicklinkLabels = false;

  get menuItems(): NavigationItem[] {
    return this._menuItems;
  }

  @Input() set menuItems(menuItems: NavigationItem[]) {
    if (this._menuItems !== menuItems) {
      this._menuItems = menuItems.map((item) => {
        return {
          ...item,
          iconClass: item.iconClass ? item.iconClass : 'fas fa-angle-right'
        };
      });
    }
  }

  get roles(): any {
    return this._roles;
  }

  @Input() set roles(roles: any) {
    if (this._roles !== roles) {
      this._roles = this.createRoles(roles);
      this._menuItems = this.navigationService.filterMenuItems(
        this._menuItems,
        this._roles
      );
    }
  }

  get collapsed(): boolean {
    return this.navigationService.isCollapsed();
  }

  @Input() set collapsed(collapsed: boolean) {
    if (
      this.collapsible &&
      this.navigationService.isCollapsed() !== collapsed
    ) {
      this.navigationService.toggleCollapsed();
    }
  }

  createRoles(role: string | string[]): any {
    if (role) {
      const huidigeRollen = {};

      if (Array.isArray(role)) {
        for (const r of role) {
          if (r) {
            huidigeRollen[r.toString().toUpperCase().trim()] = true;
          }
        }
      } else {
        huidigeRollen[role.toString().toUpperCase().trim()] = true;
      }

      return huidigeRollen;
    }
    return null;
  }

  trackByFn = trackItemByIndex;

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------

  constructor(
    public translationsService: TranslationsService,
    @Optional() public translateService: TranslateService,
    private router: Router,
    public navigationService: NgxOvNavigationService,
    private cd: ChangeDetectorRef
  ) {
    super(translationsService, translateService);
  }

  ngAfterViewInit(): void {
    this._topItems = this.getTopItems();
    this._bottomItems = this.getBottomItems();
    this._quickLinkItems = this.getQuicklinkItems();
    this._mobileItems = this.getMobileItems();
    this.cd.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.menuItems || changes.roles) {
      this._topItems = this.getTopItems();
      this._bottomItems = this.getBottomItems();
      this._quickLinkItems = this.getQuicklinkItems();
      this._mobileItems = this.getMobileItems();
    }
  }

  // -------------------------------------------------------------------------
  // Events
  // -------------------------------------------------------------------------
  getMenuTopItems() {
    return this.menuItems
      ? this.menuItems.filter((item: NgxOvNavigationItemComponent) => {
          return (
            !item.bottom &&
            this.navigationService.hasRole(this._roles, item.roles)
          );
        })
      : [];
  }

  getNavigationTopItems() {
    return this.navigationItems
      ? this.navigationItems.filter(
          // eslint-disable-next-line sonarjs/no-identical-functions
          (item: NgxOvNavigationItemComponent) => {
            return (
              !item.bottom &&
              this.navigationService.hasRole(this._roles, item.roles)
            );
          }
        )
      : [];
  }

  getMenuBottomItems() {
    return this.menuItems
      ? this.menuItems.filter((item: NgxOvNavigationItemComponent) => {
          return (
            item.bottom &&
            this.navigationService.hasRole(this._roles, item.roles)
          );
        })
      : [];
  }

  getNavigationBottomItems() {
    return this.navigationItems
      ? this.navigationItems.filter(
          // eslint-disable-next-line sonarjs/no-identical-functions
          (item: NgxOvNavigationItemComponent) => {
            return (
              item.bottom &&
              this.navigationService.hasRole(this._roles, item.roles)
            );
          }
        )
      : [];
  }

  getNavigationQuicklinkItems() {
    return this.navigationItems
      ? this.navigationItems.filter(
          // eslint-disable-next-line sonarjs/no-identical-functions
          (item: NgxOvNavigationItemComponent) => {
            return (
              item.mobileQuickLink &&
              this.navigationService.hasRole(this._roles, item.roles)
            );
          }
        )
      : [];
  }

  getMenuQuicklinkItems() {
    return this._menuItems
      ? this._menuItems.filter(
          // eslint-disable-next-line sonarjs/no-identical-functions
          (item: NgxOvNavigationItemComponent) => {
            return (
              item.mobileQuickLink &&
              this.navigationService.hasRole(this._roles, item.roles)
            );
          }
        )
      : [];
  }

  getNavigationMobileItems() {
    return this.navigationItems
      ? this.navigationItems.filter(
          // eslint-disable-next-line sonarjs/no-identical-functions
          (item: NgxOvNavigationItemComponent) => {
            return (
              !item.mobileQuickLink &&
              !item.hideMobile &&
              this.navigationService.hasRole(this._roles, item.roles)
            );
          }
        )
      : [];
  }

  getMenuMobileItems() {
    return this._menuItems
      ? this._menuItems.filter(
          // eslint-disable-next-line sonarjs/no-identical-functions
          (item: NgxOvNavigationItemComponent) => {
            return (
              !item.mobileQuickLink &&
              !item.hideMobile &&
              this.navigationService.hasRole(this._roles, item.roles)
            );
          }
        )
      : [];
  }

  getTopItems(): NavigationItem[] {
    return [...this.getMenuTopItems(), ...this.getNavigationTopItems()];
  }

  getBottomItems(): NavigationItem[] {
    return [...this.getMenuBottomItems(), ...this.getNavigationBottomItems()];
  }

  getQuicklinkItems(): NavigationItem[] {
    return [
      ...this.getMenuQuicklinkItems(),
      ...this.getNavigationQuicklinkItems()
    ];
  }

  getMobileItems(): NavigationItem[] {
    return [...this.getNavigationMobileItems(), ...this.getMenuMobileItems()];
  }

  toggleNavMobile() {
    /* eslint-disable */
    const clideUpClass = 'c-navigation__collapsable--slide-up';
    const slideDownClass = 'c-navigation__collapsable--slide-down';
    const collapsedClass = 'c-navigation__collapsable--collapsed';

    if (this.navigationService.isCollapsedMobile()) {
      this.mobileNavigation.nativeElement.classList.remove(collapsedClass);
      this.mobileNavigation.nativeElement.classList.add(clideUpClass);
      setTimeout(() => {
        this.mobileNavigation.nativeElement.classList.remove(clideUpClass);
      }, 500);
    } else {
      this.mobileNavigation.nativeElement.classList.add(slideDownClass);
      setTimeout(() => {
        this.mobileNavigation.nativeElement.classList.remove(slideDownClass);
        this.mobileNavigation.nativeElement.classList.add(collapsedClass);
      }, 500);
    }
    /* eslint-enable */

    this.navigationService.toggleCollapsedMobile();
  }

  toggleNavDesktop() {
    if (this.collapsible) {
      this.navigationService.toggleCollapsed();
    }
  }

  navigate(linkUrl: string) {
    this.navigationService.collapseMobile();
    this.mobileNavigation.nativeElement.classList.add(
      'c-navigation__collapsable--collapsed'
    );

    if (linkUrl) {
      this.router.navigate([linkUrl]);
    }
  }

  resetMobileNavigation() {
    this.navigationService.collapseMobile();
    this.mobileNavigation.nativeElement.classList.add(
      'c-navigation__collapsable--collapsed'
    );
  }

  setLabels(): void {
    this.toggleIconCloseTitle = this.getTranslation(
      'NAVIGATION.SIDE_NAVIGATION',
      'TOGGLE_ICON.CLOSE'
    );
    this.toggleIconOpenTitle = this.getTranslation(
      'NAVIGATION.SIDE_NAVIGATION',
      'TOGGLE_ICON.OPEN'
    );
  }
}
