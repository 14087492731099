import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgxOvButtonComponent } from '../../../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/button/button.component';
import { FormsModule } from '@angular/forms';
import { NgxOvCheckboxGroupComponent } from '../../../../../../projects/ngx-ov-ui/src/lib/components/forms/checkbox/checkbox-group/checkbox-group.component';

@Component({
  selector: 'app-wizard-step-3',
  templateUrl: './wizard-step-3.component.html',
  standalone: true,
  imports: [NgxOvCheckboxGroupComponent, FormsModule, NgxOvButtonComponent]
})
export class PageWizardStep3Component {
  toelagenOpties: string[] = [
    'Standplaats',
    'Haard',
    'test van een toelage met veel tekst'
  ];

  @Input() gekozenToelage: string[];
  @Output() kiesToelagen: EventEmitter<any[]> = new EventEmitter();

  onBevestigToelagen() {
    this.kiesToelagen.emit(this.gekozenToelage);
  }
}
