<div class="c-404">
  <div class="c-404__content">
    <div class="c-404__title">{{ title }}</div>
    <div class="c-404__subtitle">{{ subtitle }}</div>
    <div class="c-404__hyperlinks">
      @for (hyperlink of hyperlinks; let i = $index; track trackByFn) {
        <span class="c-404__hyperlink-item">
          <a (click)="hyperlinkClicked.emit(i)">{{ hyperlink }}</a>
        </span>
      }
    </div>
    <div class="c-404__cta">
      <ngx-ov-button
        [label]="ctaLabelCopy"
        (buttonClick)="ctaClicked.emit()"
      ></ngx-ov-button>
    </div>
  </div>
  <div class="c-404__image"></div>
</div>
