import { Component, Input } from '@angular/core';

// =============================================================================
// Config
// =============================================================================
@Component({
    selector: 'ngx-ov-extra-info',
    templateUrl: './extra-info.component.html',
    standalone: true
})

// =============================================================================
// Definition
// =============================================================================
export class NgxOvExtraInfoComponent {
  @Input() text: string;
}
