/**
 * Naric
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.3.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import {
  AbstractControlOptions,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn
} from '@angular/forms';

export interface IVakgebiedOutputRestDto {
  code?: string;
  naam?: string;
}


export class VakgebiedOutputRestDto implements IVakgebiedOutputRestDto {
  code?: string;
  naam?: string;
}

export class VakgebiedOutputRestDtoFormHelper {

  static generateFormWithSwaggerValidators(formBuilder: UntypedFormBuilder, controlsConfig: { [key: string]: any; }, options?: AbstractControlOptions | { [key: string]: any; } | null): UntypedFormGroup {
    if (controlsConfig["code"] != undefined && !(controlsConfig["code"] instanceof UntypedFormGroup) && !(controlsConfig["code"] instanceof UntypedFormArray)) {
      controlsConfig["code"][1] = controlsConfig["code"][1].concat(VakgebiedOutputRestDtoValidator.code_swagger_validator());
    }
    if (controlsConfig["naam"] != undefined && !(controlsConfig["naam"] instanceof UntypedFormGroup) && !(controlsConfig["naam"] instanceof UntypedFormArray)) {
      controlsConfig["naam"][1] = controlsConfig["naam"][1].concat(VakgebiedOutputRestDtoValidator.naam_swagger_validator());
    }
    if (options) {
      return formBuilder.group(controlsConfig, options);
    }
    return formBuilder.group(controlsConfig);
  }
}

export class VakgebiedOutputRestDtoValidator {

  /**
   * Active Validators for code:
   */
  static code_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    return validators;
  };

  /**
   * Active Validators for naam:
   */
  static naam_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    return validators;
  };

}



