<div
  class="c-pill {{ classes }}"
  [ngClass]="{
    'c-pill--error': isError,
    'c-pill--success': isSuccess,
    'c-pill--warning': isWarning
  }"
>
  <span class="c-pill__text"> {{ text }} <ng-content></ng-content> </span>
  @if (isFilter) {
    <a class="c-pill__close">
      <i class="fas fa-times" aria-hidden="true" [title]="iconCloseTitle"></i>
      <span class="sr-only">{{ iconCloseTitle }}</span>
      <div class="c-pill__close-touch"></div>
    </a>
  }
</div>
