// =============================================================================
// Imports
// =============================================================================
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgxOvButtonComponent } from '../../components/default-layout-blokken/button/button.component';
import { ClickEqualsEnterDirective } from '../../directives/click-equals-enter';
import { CommonModule } from '@angular/common';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-no-data',
  templateUrl: './no-data.html',
  standalone: true,
  imports: [ClickEqualsEnterDirective, NgxOvButtonComponent, CommonModule]
})
export class NgxOvNoDataComponent {
  @Input() title;
  @Input() subtitle;
  @Input() linkLabelText;

  @Output() linkClicked: EventEmitter<any> = new EventEmitter<any>();
}
