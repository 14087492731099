import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  input,
  signal
} from '@angular/core';
import { INgxOvDataTableColumn } from '../data-table.utils';
import { JsonPipe, NgForOf } from '@angular/common';
import { NgxOvCheckboxComponent } from '../../../forms/checkbox/checkbox-item/checkbox.component';
import { FormsModule } from '@angular/forms';
import { NgxOvDataTableSettingsService } from './data-table-settings.service';
import { trackItemByIndex } from '../../../../helper';
import { NgxOvFlyoutItemComponent } from '../../../navigatie/flyout/flyout-item.component';
import { NgxOvFlyoutComponent } from '../../../navigatie/flyout/flyout.component';
import { NgxOvLinkComponent } from '../../../default-layout-blokken/link/link.component';

// =============================================================================
// Component
// =============================================================================
@Component({
  /* tslint:disable-next-line */
  selector: 'data-table-settings',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'data-table-settings.component.html',
  imports: [
    JsonPipe,
    NgxOvCheckboxComponent,
    FormsModule,
    NgForOf,
    NgxOvFlyoutItemComponent,
    NgxOvFlyoutComponent,
    NgxOvLinkComponent
  ],
  standalone: true
})
export class NgxOvDataTableSettingsComponent {
  @Input() identifier: string;
  columns = input<INgxOvDataTableColumn[]>([]);

  settingsVisible = signal(false);

  constructor(
    private eRef: ElementRef,
    public dataTableSettingsService: NgxOvDataTableSettingsService
  ) {}

  updateSettings(column: INgxOvDataTableColumn[]) {
    this.dataTableSettingsService.setDataTableSettings(this.identifier, column);
  }

  protected readonly trackByFn = trackItemByIndex;

  toggleSettings() {
    if (this.settingsVisible()) {
      this.settingsVisible.set(false);
    } else {
      this.settingsVisible.set(true);
    }
  }

  @HostListener('document:click', ['$event'])
  @HostListener('document:touchstart', ['$event'])
  onClickBuitenComponent(event: any) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.settingsVisible.set(false);
    }
  }
}
