@if (articles | async; as artikels) {
  @for (article of articles | async; track trackByFn) {
    <div class="grid grid-cols-12 gap-medium">
      <ngx-ov-news-teaser-list [title]="'Nieuws'">
        @for (story of article.stories; track trackByFn) {
          <ngx-ov-news-teaser
            [title]="story.title"
            [label]="'video'"
            [type]="'video'"
            [image]="story.image.image.original"
            [hyperlink]="'forms'"
            [picturePortrait]="true"
          >
            <div [innerHTML]="story.content"></div>
          </ngx-ov-news-teaser>
        }
      </ngx-ov-news-teaser-list>
    </div>
  }

  <div class="my-10">
    Graphql url:
    https://test.contentgateway.informatiekanalen.dev-vlaanderen.be/playground
  </div>
} @else {
  <ngx-ov-loader></ngx-ov-loader>
}
