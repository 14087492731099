import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { IPager } from '../services/pager.service';
import { trackItemByIndex } from '../../../../../helper';
import { NgClass } from '@angular/common';

@Component({
  selector: 'ngx-ov-pager-navigation',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="c-pager__navigation">
      <a
        class="c-pager__nav-previous"
        [ngClass]="{ 'c-pager__nav--disabled': pager.currentPage == 1 }"
        aria-controls="DataTables_Table_2"
        data-dt-idx="0"
        tabindex="0"
        (click)="gotoPrevious()"
      >
        <i class="fas fa-chevron-left"></i>

        @if (detailedNavigation) {
          <span>Vorige</span>
        }
      </a>
      @if (detailedNavigation) {
        <span>
          @for (page of pager.pages; let i = $index; track trackByFn) {
            <a
              class="c-pager__nav-pagenumber"
              [ngClass]="{
                'c-pager__navigation--current': page == pager.currentPage
              }"
              aria-controls="DataTables_Table_2"
              data-dt-idx="1"
              tabindex="0"
              (click)="gotoPage(page)"
            >
              {{ page }}
            </a>
          }
        </span>
      }

      <a
        class="c-pager__nav-next"
        [ngClass]="{
          'c-pager__nav--disabled': pager.currentPage == pager.endPage
        }"
        aria-controls="DataTables_Table_2"
        data-dt-idx="3"
        tabindex="0"
        (click)="gotoNext()"
      >
        @if (detailedNavigation) {
          <span>Volgende</span>
        }

        <i class="fas fa-chevron-right"></i>
      </a>
    </div>
  `,
  styles: [],
  standalone: true,
  imports: [NgClass]
})
export class NgxOvPagerNavigationComponent {
  @Input() pager: IPager;
  @Input() detailedNavigation = false;
  @Output() setPage = new EventEmitter();
  trackByFn = trackItemByIndex;

  gotoPage(index: number) {
    this.setPage.emit(index);
  }

  gotoNext() {
    const nextPage = this.pager.currentPage + 1;
    this.setPage.emit(nextPage);
  }

  gotoPrevious() {
    const previousPage = this.pager.currentPage - 1;
    this.setPage.emit(previousPage);
  }
  gotoFirst() {
    const firstPage = this.pager.startPage;
    this.setPage.emit(firstPage);
  }

  gotoLast() {
    const lastPage = this.pager.endPage;
    this.setPage.emit(lastPage);
  }
}
