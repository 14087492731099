import {Component, ContentChild, Input, OnInit, ViewContainerRef} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {BaseClass} from '../../../baseclass';
import {PortalSnackbarDirective} from './portals/portal-snackbar.directive';
import {Status} from '../../../helper';
import {PortalModule} from '@angular/cdk/portal';
import {ClickEqualsEnterDirective} from '../../../directives/click-equals-enter';
import {ovSnackbarAnimations} from './snackbar-animations';
import {NgxOvSnackbarService} from './snackbar.service';

const iconExclamationCircle = 'exclamation-circle';
const iconCheckCircle = 'check-circle';

let identifier = 0;

// =============================================================================
// Interface
// =============================================================================
export interface SnackbarOptions {
  isCloseable?: boolean;
  isSmall?: boolean;
  showProgressBar?: boolean;
  timeShown?: number;
  position?: string;
}

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-snackbar',
  templateUrl: './snackbar.html',
  standalone: true,
  imports: [ClickEqualsEnterDirective, PortalModule],
  animations: [ovSnackbarAnimations.slideInOutTransition]
})
export class NgxOvSnackbarComponent extends BaseClass implements OnInit {
  // -------------------------------------------------------------------------
  // private variables
  // -------------------------------------------------------------------------
  private _title: string;
  private _message: string;
  private _type: Status;
  private _timeShown: number;
  private _isOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  timeoutId;

  _icon = iconExclamationCircle;

  public identifier = `snackbar-${(identifier += 1)}`;

  // -------------------------------------------------------------------------
  // Input variables/Setters
  // -------------------------------------------------------------------------

  get isOpen(): boolean {
    return this._isOpen$.value;
  }

  @Input() set isOpen(isOpen: boolean) {
    this._isOpen$.next(isOpen);
  }

  @Input() set title(title: string) {
    this._title = title;
  }

  get title(): string {
    return this._title;
  }

  @Input() set message(message: string) {
    this._message = message;
  }

  get message(): string {
    return this._message;
  }

  @Input() set type(type: Status) {
    this._type = <Status>type?.toUpperCase();
    this._icon = this.getIconName();
  }

  get type(): Status {
    return this._type;
  }

  @Input() isCloseable = false;
  @Input() isSmall = false;
  @Input() showProgressBar = false;
  @Input() set timeShown(timeShown: number) {
    this._timeShown = timeShown;
  }

  get timeShown(): number {
    let timeShown: number;

    switch (this.type) {
      case 'SUCCESS': {
        timeShown = 5;
        break;
      }
      case 'WARNING':
      case 'ERROR': {
        timeShown = 10;
        break;
      }
      default: {
        timeShown = 6;
      }
    }

    return this._timeShown ?? timeShown;
  }

  @Input() position = 'top-right';

  @ContentChild(PortalSnackbarDirective, { static: false })
  footer: PortalSnackbarDirective;

  // -------------------------------------------------------------------------∏
  // Constructor
  // -------------------------------------------------------------------------
  constructor(
    private viewRef: ViewContainerRef,
    private snackbarService: NgxOvSnackbarService
  ) {
    super();
  }

  // -------------------------------------------------------------------------
  // Lifecycle methods
  // -------------------------------------------------------------------------

  ngOnInit(): void {
    this.open();
  }

  private open() {
    if (this.timeShown === 0) {
      this.isCloseable = true;
    } else {
      this.timeoutId = setTimeout(() => {
        clearTimeout(this.timeoutId);
        this.close();
      }, this.timeShown * 1000);
    }
  }

  protected close() {
    this._isOpen$.next(false);

    // wacht 1 sec zodat animatie kan uitvoeren, dan verwijderen uit DOM
    setTimeout(() => {
      this.snackbarService.close(this.viewRef.element.nativeElement);
    }, 1000);
  }

  private getIconName() {
    if (!this.type) {
      return iconExclamationCircle;
    }

    switch (this.type) {
      case 'ERROR': {
        return iconExclamationCircle;
      }
      case 'WARNING': {
        return iconExclamationCircle;
      }
      case 'SUCCESS': {
        return iconCheckCircle;
      }
      default: {
        return iconExclamationCircle;
      }
    }
  }
}
