<div
  class="c-toggle-button show-block {{ classes }}"
  [attr.data-cy]="code"
  tabindex="0"
  [class.c-toggle-button--selected]="selected"
  (keyup.enter)="toggleButtonClicked.emit(value)"
  (keyup.space)="toggleButtonClicked.emit(value)"
>
  <div class="c-toggle-button__container">
    <!-- Icon -->
    @if (icon) {
      <div class="c-toggle-button__icon">
        <i class="{{ icon }}" aria-hidden="true"></i>
      </div>
    }
    <!-- Label -->
    <div class="c-toggle-button__label-container">
      <div class="c-toggle-button__label">
        <div>{{ label }}</div>
      </div>
    </div>
    <!-- Checkbox -->
    <div class="c-toggle-button__checkbox">
      <div
        class="c-checkbox c-toggle-button__checkbox"
        [class.c-checkbox--selected]="selected"
      >
        <div class="c-checkbox__container">
          <div class="c-checkbox__toggle">
            <input
              class="c-checkbox__toggle_input"
              type="checkbox"
              id="{{ label }}"
              [name]="label"
              [(ngModel)]="selected"
            />
            <i class="c-checkbox__toggle_box" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
