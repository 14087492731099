<div class="my-10">
  <div class="grid grid-cols-12 gap-medium">
    <div class="col-span-12">
      <form #imageUrlForm="ngForm" novalidate>
        <ngx-ov-select
          [items]="images"
          [placeholder]="'Selecteer foto'"
          [label]="'Foto'"
          [bindLabel]="'description'"
          [bindValue]="'imageUrl'"
          [(ngModel)]="newImageUrl"
          [required]="true"
          (ngModelChange)="wijzigImageUrl()"
          name="foto"
        >
        </ngx-ov-select>
      </form>
    </div>
  </div>
</div>
