import { Component } from '@angular/core';

// =============================================================================
// Config
// =============================================================================
@Component({
    selector: 'ngx-ov-typography',
    templateUrl: './typography.html',
    standalone: true
})

// =============================================================================
// Definition
// =============================================================================
export class NgxOvTypographyComponent {}
