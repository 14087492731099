<div class="c-banner">
  <div
    class="c-banner__bar c-banner__bar-{{ size.toLowerCase() }}"
    [ngStyle]="{
      'background-image':
        'url(' +
        (screenWidth > 850 ? backgroundImageSrc : backgroundImageSrcMobile) +
        ')',
      'background-position':
        screenWidth > 850 ? backgroundImagePos : backgroundImagePosMobile
    }"
    [class.c-banner--default-color]="!backgroundImageSrc"
    [class.c-banner--default-img]="backgroundImageSrc"
  >
    @if (type === 'box') {
      <div
        class="c-banner__content"
        [class.c-banner__content--cta]="version === 'CTA'"
        [class.c-banner__content--default]="version === 'DEFAULT'"
      >
        <div>&nbsp;</div>
        @if (title || boxTitle) {
          <div class="c-banner__title__subtitle">
            @if (title) {
              <div class="c-banner__title" [innerHTML]="title"></div>
            }

            @if (boxTitle) {
              <div class="c-banner__boxtitle" [innerHTML]="boxTitle"></div>
            }
          </div>
        }

        @if (intro) {
          <div class="c-banner__intro" [innerHTML]="intro"></div>
        }

        @if (buttonLabel) {
          <div class="c-banner__button">
            <ngx-ov-button
              (buttonClick)="buttonClicked.emit($event)"
              [label]="buttonLabel"
            ></ngx-ov-button>
          </div>
        }

        <div>&nbsp;</div>
      </div>
    }

    @if (type === 'imageOnly' && title) {
      <div class="c-banner__content--image-only">
        <div class="c-banner__content__title--image-only">
          <h1 [innerHTML]="title"></h1>
        </div>
      </div>
    }
  </div>
</div>
