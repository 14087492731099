import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import {
  BrowserVersionOptions,
  BrowserSupportService
} from './browser-support.service';

export { BrowserSupportService };
export { BrowserVersionOptions };

export interface ModuleOptions {
  versions?: Array<any>;
}

export const FOR_ROOT_OPTIONS_TOKEN = new InjectionToken<ModuleOptions>(
  'forRoot() BrowserSupport configuration.'
);

export function provideBrowserSupportOptions(
  options?: ModuleOptions
): BrowserVersionOptions {
  const browserVersionOptions = new BrowserVersionOptions();

  if (options) {
    browserVersionOptions.versions = options.versions;
  }
  return browserVersionOptions;
}

@NgModule()
export class BrowserSupportModule {
  static forRoot(options?: ModuleOptions): ModuleWithProviders<any> {
    return {
      ngModule: BrowserSupportModule,
      providers: [
        {
          provide: FOR_ROOT_OPTIONS_TOKEN,
          useValue: options
        },
        {
          provide: BrowserVersionOptions,
          useFactory: provideBrowserSupportOptions,
          deps: [FOR_ROOT_OPTIONS_TOKEN]
        }
      ]
    };
  }
}
