import { enableProdMode, importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { provideNgxMask } from 'ngx-mask';
import { provideRouter } from '@angular/router';
import { routes } from './app/app-routing.module';
import { NgxOvUiModuleConfig } from '../projects/ngx-ov-ui/src/lib/models/ngx-ov-ui.config';
import { maskConfig } from './config';
import { NgxOvSnackbarPluginOptions } from '../projects/ngx-ov-ui/src/lib/fundamentals/store/plugins/snackbar-plugin-options';
import { AppModule } from './app/app.module';

if (environment.production) {
  enableProdMode();
}

const ngxOvUIConfiguration: NgxOvUiModuleConfig = {
  cypress: true,
  uploadOptions: {
    acceptedFileTypes: ['.pdf'],
    maxLengthFileName: 20,
    maxFileSizeInMB: 1
  }
};

export function snackbarPluginOptionsFactory(
  options: NgxOvSnackbarPluginOptions
) {
  const defaultSnackbarOptions: NgxOvSnackbarPluginOptions = {
    showDefaultSnackbarOnSaveSuccess: false,
    showDefaultSnackbarOnSaveFailure: true
  };

  return {
    ...defaultSnackbarOptions
  };
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(routes),
    provideNgxMask(maskConfig),
    importProvidersFrom(AppModule)
  ]
}).catch((err) => console.error(err));
