import { Injectable } from '@angular/core';

import * as R from 'ramda';
import {
  INgxOvDataTableColumn,
  NgxOvDataTableColumnType
} from '../data-table.utils';

@Injectable({ providedIn: 'root' })
export class NgxOvDataTableSortService {
  sortData(
    data: any[],
    sortBy: INgxOvDataTableColumn,
    sortOrder: string
  ): any[] {
    return data.sort((a: any, b: any) => {
      return this.sortValues(a, b, sortBy, sortOrder);
    });
  }

  private sortValues(
    valueA: any,
    valueB: any,
    sortBy: INgxOvDataTableColumn,
    sortOrder: string
  ): number {
    const compareValueA = this.getCompareValue(valueA, sortBy);
    const compareValueB = this.getCompareValue(valueB, sortBy);

    return this.compareValues(compareValueA, compareValueB, sortOrder);
  }

  private getCompareValue(value: any, sortBy: INgxOvDataTableColumn): any {
    const valueRetrievedByPath = this.getValueByPath(value, sortBy);

    if (!valueRetrievedByPath) {
      return valueRetrievedByPath;
    }
    return this.convertValue(valueRetrievedByPath, sortBy);
  }

  private convertValue(value: any, sortBy: INgxOvDataTableColumn): any {
    // format sorting
    if (sortBy.sortFormat) {
      return sortBy.sortFormat(value);
    }

    return this.convertValueToType(value, sortBy);
  }

  private compareValues(valueA: any, valueB: any, sortOrder: string): number {
    if (valueA === valueB) {
      return 0;
    }
    if (valueA === undefined || valueA === null) {
      return 1;
    }
    if (valueB === undefined || valueB === null) {
      return -1;
    }
    if (valueA > valueB) {
      return sortOrder.toUpperCase() === 'ASC' ? 1 : -1;
    }
    if (valueA < valueB) {
      return sortOrder.toUpperCase() === 'ASC' ? -1 : 1;
    }
    return 0;
  }

  private getValueByPath(value: any, sortBy: INgxOvDataTableColumn): any {
    return R.path(sortBy.name.split('.'), value);
  }

  private getTextValue(value: any, sortBy: INgxOvDataTableColumn): any {
    const textValue = value || '';

    return sortBy?.sortCaseSensitive ||
      (textValue && typeof textValue !== 'string')
      ? textValue
      : textValue.toUpperCase();
  }

  private convertValueToType(value: any, sortBy: INgxOvDataTableColumn): any {
    switch (sortBy.type) {
      case NgxOvDataTableColumnType.date:
        return new Date(value);
      case NgxOvDataTableColumnType.numeric:
        return +value;
      case NgxOvDataTableColumnType.yesno:
        return !!value;
      default:
        // default string
        return this.getTextValue(value, sortBy);
    }
  }
}
