import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { PortalTitleContentDirective } from './portal-title-content.directive';
import { PortalRightContentDirective } from './portal-right-content.directive';
import { PortalSearchResultFooterDirective } from './portal-footer.directive';
import { PortalModule } from '@angular/cdk/portal';
import { ClickEqualsEnterDirective } from '../../../../directives/click-equals-enter';

@Component({
  selector: 'ngx-ov-expansion-panel-item',
  templateUrl: './expansion-panel-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ClickEqualsEnterDirective, PortalModule]
})
export class NgxOvExpansionPanelItemComponent {
  @ContentChild(PortalTitleContentDirective)
  extraTitleContent: PortalTitleContentDirective;
  @ContentChild(PortalRightContentDirective)
  rightContent: PortalRightContentDirective;
  @ContentChild(PortalSearchResultFooterDirective)
  footer: PortalSearchResultFooterDirective;

  @Input() title: string;
  @Input() isCollapsed = true;
  @Input() isListItem = false;
  @Input() lastItem = false;
  @Input() closeText: string;

  @Output() itemToggled: EventEmitter<any> = new EventEmitter();

  onToggleItem(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.itemToggled.emit(this);
    if (!this.isListItem) {
      this.isCollapsed = !this.isCollapsed;
    }
  }
}
