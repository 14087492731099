import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class LinkService {
  constructor(private router: Router) {}

  navigate(linkUrl: string) {
    if (linkUrl) {
      if (linkUrl.startsWith('https:') || linkUrl.startsWith('http:')) {
        window.open(linkUrl, '_blank', 'noreferrer');
      } else if (linkUrl.startsWith('mailto:') || linkUrl.startsWith('tel:')) {
        window.open(linkUrl);
      } else {
        this.router.navigate([linkUrl]);
      }
    }
  }
}
