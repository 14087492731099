import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxOvUiModuleConfig } from '../../projects/ngx-ov-ui/src/public-api';
import { Configuration } from '@naricbo-open-api/';

const ngxOvUIConfiguration: NgxOvUiModuleConfig = {
  cypress: true,
  uploadOptions: {
    acceptedFileTypes: ['.pdf'],
    maxLengthFileName: 20,
    maxFileSizeInMB: 1
  }
};

export const apiConfigs = [
  {
    id: 'naricbo-open',
    api_url:
      'https://onderwijs-acceptatie.api.vlaanderen.be/naric/app-naricbo/open',
    api_key: 'Fv4QFzlOrkrTu490RfgtO5yBqUYG3heF',
    apikey_in_url: false,
    apikey_in_header: false,
    x_ov_subenvironment: 'uft',
    apigee: {
      use_apigee: true
    }
  },
  {
    id: 'naricbo-medewerker',
    api_url:
      'https://onderwijs-acceptatie.api.vlaanderen.be/naric/app-naricbo/medewerker',
    api_key: 'Fv4QFzlOrkrTu490RfgtO5yBqUYG3heF',
    apikey_in_url: false,
    apikey_in_header: false,
    x_ov_subenvironment: 'uft',
    apigee: {
      use_apigee: true
    }
  }
];

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', `.json`);
}

export function getApiConfig(): Configuration {
  return new Configuration({
    basePath:
      'https://onderwijs-acceptatie.api.vlaanderen.be/naric/app-naricbo/open',
    apiKeys: {}
  });
}
