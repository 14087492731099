import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { PortalActionListItemDetailDirective } from './portal-action-list-item-detail.directive';
import { PortalActionListItemActionsDirective } from './portal-action-list-item-actions.directive';
import { PortalModule } from '@angular/cdk/portal';
import { ClickEqualsEnterDirective } from '../../../directives/click-equals-enter';
import { NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'ngx-ov-action-list-item',
  templateUrl: './action-list-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ClickEqualsEnterDirective, NgTemplateOutlet, PortalModule]
})
export class NgxOvActionListItemComponent implements OnInit {
  @ContentChild(PortalActionListItemDetailDirective)
  detailContent: PortalActionListItemDetailDirective;
  @ContentChild(PortalActionListItemActionsDirective)
  actionsContent: PortalActionListItemActionsDirective;

  // -------------------------------------------------------------------------
  // private variables
  // -------------------------------------------------------------------------
  private _title: string;
  private _subtitle: string;
  itemClickUsed: boolean;

  // -------------------------------------------------------------------------
  // Setters en setters
  // -------------------------------------------------------------------------
  get title(): string {
    return this._title;
  }

  @Input() set title(value: string) {
    this._title = value;
  }

  get subtitle(): string {
    return this._subtitle;
  }

  @Input() set subtitle(value: string) {
    this._subtitle = value;
  }

  @Output() itemClick: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
    this.itemClickUsed = this.itemClick.observed;
  }

  onItemClick(e: Event) {
    this.itemClick.emit(e);
  }
}
