<div class="c-agenda-list-item" [class.c-agenda-list-item--with-link]="url">
  @if (url) {
    <!-- Date section -->
    <a
      class="c-agenda-list-item__date"
      (click)="onClick()"
      [style.background-color]="dateBackgroundColor"
      [style.color]="dateColor"
      tabindex="-1"
    >
      <div class="c-agenda-list-item__date-left">
        <time [attr.datetime]="_formattedDate">
          <div class="c-agenda-list-item__dateformat">
            <div>{{ date | date: 'd' }}</div>
            <div class="hidden-desktop">&nbsp;</div>
            <div>{{ date | date: 'MMM' }}</div>
          </div>
        </time>
      </div>
      @if (actionsPortal) {
        <div class="hidden-desktop">
          <ng-template [cdkPortalOutlet]="actionsPortal"></ng-template>
        </div>
      }
    </a>
  } @else {
    <div
      class="c-agenda-list-item__date"
      [style.background-color]="dateBackgroundColor"
      [style.color]="dateColor"
    >
      <div class="c-agenda-list-item__date-left">
        <time [attr.datetime]="_formattedDate">
          <div class="c-agenda-list-item__dateformat">
            <div>{{ date | date: 'd' }}</div>
            <div class="hidden-desktop">&nbsp;</div>
            <div>{{ date | date: 'MMM' }}</div>
          </div>
        </time>
      </div>
      @if (actionsPortal) {
        <div class="hidden-desktop">
          <ng-template [cdkPortalOutlet]="actionsPortal"></ng-template>
        </div>
      }
    </div>
  }

  <!-- Content section -->
  <div class="c-agenda-list-item__content">
    <div class="c-agenda-list-item__title">
      @if (url) {
        <div (click)="onClick()">
          <div>{{ title }}</div>
        </div>
      } @else {
        <div>{{ title }}</div>
      }
    </div>
    <div class="c-agenda-list-item__text">
      <ng-content></ng-content>
    </div>
  </div>
  @if (actionsPortal) {
    <div class="c-agenda-list-item__content-right hidden-mobile">
      <ng-template [cdkPortalOutlet]="actionsPortal"></ng-template>
    </div>
  }
</div>
