import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  Renderer2
} from '@angular/core';

import {
  INgxOvDataTableSortChangeEvent,
  NgxOvDataTableSortingOrder
} from '../data-table.utils';
import { NgTemplateOutlet } from '@angular/common';

// =============================================================================
// Component
// =============================================================================
@Component({
  /* tslint:disable-next-line */
  selector: 'th[ngx-ov-data-table-column]',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    @if (sortable) {
      <span class="md-caption" (click)="sortable && handleSortBy()">
        <ng-template [ngTemplateOutlet]="headerContent"></ng-template>
      </span>
    } @else {
      <span class="md-caption">
        <ng-template [ngTemplateOutlet]="headerContent"></ng-template>
      </span>
    }
    <ng-template #headerContent>
      <ng-content></ng-content>
    </ng-template>
  `,
  standalone: true,
  imports: [NgTemplateOutlet]
})
export class NgxOvDataTableColumnComponent {
  private _sortOrder: NgxOvDataTableSortingOrder =
    NgxOvDataTableSortingOrder.Ascending;

  /**
   * name?: string
   * Sets unique column [name] for [sortable] events.
   */
  @Input() name = '';

  /**
   * sortable?: boolean
   * Enables sorting events, sort icons and active column states.
   * Defaults to 'false'
   */
  @Input() sortable = false;

  /**
   * sortCaseSensitive?: boolean
   * Determines whether to sort case sensitive or case insensitive
   * Defaults to 'false' (so default sorting is case sensitive)
   */
  @Input() sortCaseSensitive = false;

  /**
   * trim: Number
   * Enables trimming on number of characters. Use value 0 for no trimming.
   * Defaults to '0'
   */
  @Input() trim: Number = 0;

  /**
   * active?: boolean
   * Sets column to active state when 'true'.
   * Defaults to 'false'
   */
  @Input() active = false;

  /**
   * numeric?: boolean
   * Makes column follow the numeric data-table specs and sort icon.
   * Defaults to 'false'
   */
  @Input() numeric = false;

  /**
   * sortOrder?: ['ASC' | 'DESC'] or NgxOvDataTableSortingOrder
   * Sets the sort order of column.
   * Defaults to 'ASC' or NgxOvDataTableSortingOrder.Ascending
   */
  @Input()
  set sortOrder(order: 'ASC' | 'DESC') {
    const sortOrder: string = order ? order.toUpperCase() : 'ASC';
    if (sortOrder !== 'DESC' && sortOrder !== 'ASC') {
      throw new Error('[sortOrder] must be empty, ASC or DESC');
    }

    this._sortOrder =
      sortOrder === 'ASC'
        ? NgxOvDataTableSortingOrder.Ascending
        : NgxOvDataTableSortingOrder.Descending;
  }

  /**
   * sortChange?: function
   * Event emitted when the column headers are clicked. [sortable] needs to be enabled.
   * Emits an [INgxOvDataTableSortChangeEvent] implemented object.
   */
  @Output() sortChange: EventEmitter<INgxOvDataTableSortChangeEvent> =
    new EventEmitter<INgxOvDataTableSortChangeEvent>();

  @HostBinding('class.md-clickable')
  get bindClickable(): boolean {
    return this.sortable;
  }

  @HostBinding('class.md-sortable')
  get bingSortable(): boolean {
    return this.sortable;
  }

  @HostBinding('class.md-active')
  get bindActive(): boolean {
    return this.active;
  }

  @HostBinding('class.md-numeric')
  get bindNumeric(): boolean {
    return this.numeric;
  }

  constructor(
    private _elementRef: ElementRef,
    private _renderer: Renderer2
  ) {
    this._renderer.addClass(
      this._elementRef.nativeElement,
      'ngx-ov-data-table-column'
    );
  }

  handleSortBy(): void {
    this.sortChange.emit({
      name: this.name,
      caseSensitive: this.sortCaseSensitive,
      order: this._sortOrder
    });
  }

  isAscending(): boolean {
    return this._sortOrder === NgxOvDataTableSortingOrder.Ascending;
  }

  isDescending(): boolean {
    return this._sortOrder === NgxOvDataTableSortingOrder.Descending;
  }
}
