import {
  ChangeDetectionStrategy,
  Component,
  computed,
  OnInit,
  signal
} from '@angular/core';
import {
  IPager,
  PagerService
} from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/data-table/pager/services/pager.service';
import {
  INgxOvDataTableColumn,
  NgxOvDataTableColumnType
} from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/data-table/data-table.utils';
import { NgxOvInfoBlockComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/content-blokken/info-block/info-block.component';
import { NgxOvUiPagerComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/data-table/pager/pager.component';
import { NgxOvCheckboxComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/forms/checkbox/checkbox-item/checkbox.component';
import { JsonPipe } from '@angular/common';
import { NgxOvDataTableTemplateDirective } from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/data-table/directives/data-table-template.directive';
import { NgxOvDataTableComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/data-table/data-table.component';
import { ClickEqualsEnterDirective } from '../../../../projects/ngx-ov-ui/src/lib/directives/click-equals-enter';
import { NgxOvLinkComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/link/link.component';
import { PortalPanelActionsDirective } from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/panel/portals/portal-panel-actions.directive';
import { NgxOvPanelComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/panel/panel.component';
import { NgxOvDataTableSettingsComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/data-table/data-table-settings/data-table-settings.component';
import { PortalDataTableCustomFilterDirective } from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/data-table/portals/portal-data-table-custom-filter.directive';
import { NgxOvInputFieldComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/forms/input-field/input-field.component';
import { FormsModule } from '@angular/forms';
import { PortalDataTablePagerDirective } from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/data-table/portals/portal-data-table-pager.directive';

@Component({
  selector: 'app-page-data-table-readoly',
  templateUrl: './page-data-table-readonly.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [
    NgxOvPanelComponent,
    PortalPanelActionsDirective,
    NgxOvLinkComponent,
    ClickEqualsEnterDirective,
    NgxOvDataTableComponent,
    NgxOvDataTableTemplateDirective,
    NgxOvCheckboxComponent,
    NgxOvUiPagerComponent,
    NgxOvInfoBlockComponent,
    NgxOvDataTableSettingsComponent,
    JsonPipe,
    PortalDataTableCustomFilterDirective,
    PortalDataTablePagerDirective,
    NgxOvInputFieldComponent,
    FormsModule
  ]
})
export class PageDataTableReadonlyComponent implements OnInit {
  _pager: IPager;
  _currentPageSize = 10;
  pageSizes: any[] = [
    { value: 2, disabled: false },
    { value: 5, disabled: false },
    { value: 10, disabled: false },
    { value: 100, disabled: false }
  ];
  _selected = [];
  customSearch1 = signal('');
  customSearch2 = signal('');

  public _columns: INgxOvDataTableColumn[] = [
    {
      name: 'yesno',
      label: 'Ja/nee',
      sortable: false,
      type: NgxOvDataTableColumnType.yesno,
      filterable: true,
      filterableDisabled: true
    },
    {
      name: 'user.naam',
      label: 'Naam',
      sortable: true,
      minWidthInPixels: 150,
      clickable: false,
      visible: false
    },
    {
      name: 'voornaam',
      label: 'Voornaam',
      minWidthInPixels: 150,
      sortable: true,
      visible: false,
      filterable: false
    },
    {
      name: 'voornaam-case',
      label: 'Voornaam case sensitive',
      sortable: true,
      sortCaseSensitive: true
    },
    {
      name: 'username',
      label: 'Gebruikersnaam',
      sortable: true,
      filterable: true,
      minWidthInPixels: 300
    },
    {
      name: 'age',
      label: 'Leeftijd',
      sortable: true,
      type: NgxOvDataTableColumnType.numeric,
      filterable: false
    },
    {
      name: 'datums.geboortedatum',
      label: 'Geboortedatum',
      type: NgxOvDataTableColumnType.date,
      sortable: true
    },
    {
      name: 'vollediggevaccineerd',
      label: 'Volledig Gevaccineerd',
      type: NgxOvDataTableColumnType.yesno,
      sortable: true,
      allowLabelWrap: true,
      minWidthInPixels: 90
    },
    {
      name: 'datums.vaccindatum',
      label: 'Vaccinatie',
      type: NgxOvDataTableColumnType.date,
      filterable: true,
      sortable: true,
      sortFormat: (v) => this.formatDate(v)
    }
  ];

  dataCustomFiltered = computed(() => {
    return this._data.filter(
      (row) =>
        row?.naam?.toLowerCase().includes(this.customSearch1().toLowerCase()) ||
        row?.voornaam
          ?.toLowerCase()
          .includes(this.customSearch1().toLowerCase())
    );
  });

  _data = [
    {
      id: 1,
      yesno: null,
      naam: 'martinnull',
      voornaam: 'test',
      username: 'gebruikersnaam1',
      age: 86,
      user: {
        naam: 'naammartinnull',
        voornaam: 'test'
      },
      vollediggevaccineerd: true
    },
    {
      id: 2,
      yesno: true,
      voornaam:
        'Koenljsqfljsdqlkfjlsqjfmsdjfmskdjfmdjsfkmdjsfmjsdkfmjsdkmfjsdmfjsdmfjdsmkfjdskfhdsjlhfjdsghfkjsdgfkjsdgfksgdfkjdlfjhdkjfmksdjfklsdjhflhsdflhdsflhdsfldshfljhdsfljhdsflhdsfldshfjldshfldsjfhdslfds',
      username: 'gebruikersnaam2',
      age: 111,
      geboortedatum: '2020-05-15 21:00',
      vaccindatum: '23-07-2020 21:00',
      selected: true,
      user: {
        voornaam: 'test'
      },
      datums: {
        geboortedatum: '2021-05-10 21:00',
        vaccindatum: '23-05-2025 21:00'
      },
      vollediggevaccineerd: true
    },
    {
      id: 3,
      yesno: false,
      naam: 'De Becker',
      voornaam: 'elly',
      username: 'gebruikersnaam3',
      age: 30,
      geboortedatum: '2019-06-21 21:00',
      vaccindatum: '10-05-2021 21:00',
      user: {
        naam: 'martinnull',
        voornaam: 'test'
      },
      datums: {},
      vollediggevaccineerd: false
    },
    {
      id: 4,
      yesno: true,
      naam: 'De Becker',
      voornaam: undefined,
      username: 'gebruikersnaam4',
      age: 9000,
      geboortedatum: '2020-02-05 21:00',
      vaccindatum: '10-03-2020 21:00',
      selected: true,
      user: {
        naam: 'Waes',
        voornaam: ''
      },
      datums: {
        geboortedatum: '2020-05-11 21:00',
        vaccindatum: '02-05-2020 21:00'
      },
      vollediggevaccineerd: true
    },
    {
      id: 5,
      yesno: false,
      naam: 'Wauter',
      voornaam: null,
      username: 'gebruikersnaam5',
      age: 60,
      geboortedatum: undefined,
      vaccindatum: '10-05-2019 21:00',
      user: {
        naam: 'Wauter',
        voornaam: ''
      },
      datums: {
        geboortedatum: '1978-05-10 21:00',
        vaccindatum: '03-05-2020 21:00'
      },
      vollediggevaccineerd: false
    },
    {
      id: 6,
      yesno: true,
      naam: 'De Bruyne',
      username: 'gebruikersnaam6',
      age: 3000,
      geboortedatum: '2020-06-14 20:45',
      vaccindatum: '10-05-2020 21:00',
      user: {
        naam: 'Bruyne',
        voornaam: 'test'
      },
      datums: {
        geboortedatum: '1999-05-10 21:00',
        vaccindatum: '23-05-2020 21:00'
      },
      vollediggevaccineerd: true
    },
    {
      id: 7,
      yesno: false,
      naam: 'Romelu',
      voornaam: 'Lukaku',
      username: 'gebruikersnaam7',
      age: 40,
      geboortedatum: '2020-06-14 21:05',
      vaccindatum: '01-05-2020 20:00',
      user: {
        naam: 'Romelu',
        voornaam: 'Lukaku'
      },
      datums: {
        geboortedatum: '2000-05-10 21:00',
        vaccindatum: '23-05-2020 21:00'
      }
    },
    {
      id: 8,
      yesno: true,
      naam: 'Mertens',
      voornaam: 'Dries',
      username:
        'extralangegebruikersnaam88888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888',
      age: 3000,
      geboortedatum: '2001-06-14 21:00',
      vaccindatum: '12-05-2020 21:00',
      user: {
        naam: 'Mertens',
        voornaam: 'Dries'
      },
      datums: {
        geboortedatum: '2020-05-10 21:00',
        vaccindatum: '12-05-2020 21:00'
      },
      vollediggevaccineerd: null
    },
    {
      id: 9,
      yesno: false,
      naam: 'Courtois',
      voornaam: 'Thibeau',
      age: 40,
      geboortedatum: new Date(),
      vaccindatum: '10-06-2020 21:00',
      user: {
        naam: 'Courtois',
        voornaam: 'Thibeau'
      },
      datums: {
        geboortedatum: '2020-05-10 21:00',
        vaccindatum: '10-06-2020 21:00'
      },
      vollediggevaccineerd: false
    }
  ];

  constructor(private pagerService: PagerService) {}

  ngOnInit(): void {
    this._pager = this.pagerService.getPager(
      this._data.length,
      1,
      this._currentPageSize
    );
    this._selected = this._data.filter((row) => row.selected);
  }

  filterChange(e: any) {
    this._pager = this.pagerService.getPager(
      e.length,
      1,
      this._currentPageSize
    );
    console.log('filterChange', e);
  }

  onServersideFilterChange(e: any) {
    console.log('onServersideFilterChange', e);
  }

  setPage(e: any) {
    this._pager = this.pagerService.getPager(
      this._data.length,
      e,
      this._currentPageSize
    );
  }

  setPageSize(e: any) {
    this._currentPageSize = e;
    this._pager = this.pagerService.getPager(
      this._data.length,
      1,
      this._currentPageSize
    );
  }

  dataChange(e: any): void {
    console.log(e);
  }

  onDownload() {
    // eslint-disable-next-line no-console
    console.log('download');
  }

  onClearFilters() {
    this.customSearch1.set('');
    this.customSearch2.set('');
  }

  formatDate(v: any) {
    return v ? v.substr(6, 4) + v.substr(3, 2) + v.substr(0, 2) : '';
  }
}
