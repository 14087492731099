<div class="container mt-small">
  <ngx-ov-store-isloading loaderClasses="m-large">
    <ngx-ov-card [title]="'Demo bevestigingen'" [isSecondary]="true">
      Demo met de vraag voor bevestiging na het uitvoeren van een actie via een
      API.
      <ng-template ngxOvCardFooter>
        <div class="text-right">
          <ngx-ov-button
            [label]="'Bewaar'"
            (buttonClick)="bewaar()"
          ></ngx-ov-button>
        </div>
      </ng-template>
    </ngx-ov-card>
  </ngx-ov-store-isloading>
</div>
