import { LOCATION_INITIALIZED } from '@angular/common';
import { Injector } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';
import {
  EnvironmentService,
  LanguageService,
  NgxOvEnvironmentSettings
} from '@ov/ngx-ov-utils';
import { NgxOvUiService } from '../../../projects/ngx-ov-ui/src/lib/fundamentals/ui/ui.service';

export function appInitializerFactory(
  uiService: NgxOvUiService,
  languageService: LanguageService,
  environmentService: EnvironmentService<NgxOvEnvironmentSettings>,
  injector: Injector
) {
  datadogLogs.init({
    clientToken: 'pubf15fd38acac3810f1a61c8950f19f12a',
    site: 'datadoghq.com',
    service: 'angular-demo-app',
    forwardErrorsToLogs: true,
    sampleRate: 100
  });

  return () =>
    new Promise<any>((resolve: any) => {
      uiService.init('http://localhost:3000/api/ui');
      // init environmentService
      environmentService.init();

      // Init LanguageService
      const locationInitialized = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null)
      );
      locationInitialized.then(() => {
        languageService.init().then(() => {
          resolve(null);
        });
      });
    });
}
