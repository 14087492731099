// =============================================================================
// Imports
// =============================================================================
import {
  Component,
  EventEmitter,
  Input,
  Optional,
  Output
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxOvComponentBaseClass } from '../../components/ngx-ov-component-base-class';
import { TranslationsService } from '../../services/translations.service';
import { NgxOvButtonComponent } from '../../components/default-layout-blokken/button/button.component';
import { ClickEqualsEnterDirective } from '../../directives/click-equals-enter';
import { trackItemByIndex } from '../../helper';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-404',
  templateUrl: './404.html',
  standalone: true,
  imports: [ClickEqualsEnterDirective, NgxOvButtonComponent]
})
export class NgxOv404Component extends NgxOvComponentBaseClass {
  // -------------------------------------------------------------------------
  // Copy variables
  // -------------------------------------------------------------------------
  public ctaLabelCopy;
  // -------------------------------------------------------------------------
  // Input variables
  // -------------------------------------------------------------------------
  @Input() title;
  @Input() subtitle;
  @Input() hyperlinks = [];
  @Input() ctaLabel;

  @Output() hyperlinkClicked: EventEmitter<number> = new EventEmitter<number>();
  @Output() ctaClicked: EventEmitter<any> = new EventEmitter<any>();

  trackByFn = trackItemByIndex;

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  constructor(
    public translationsService: TranslationsService,
    @Optional() public translateService: TranslateService
  ) {
    super(translationsService, translateService);
  }

  // -------------------------------------------------------------------------
  // Translation
  // -------------------------------------------------------------------------
  setLabels(): void {
    this.ctaLabelCopy =
      this.ctaLabel ?? this.getTranslation('FUNDAMENTALS.404', 'CTA_LABEL');
  }
}
