@if (title()) {
  <ngx-ov-panel [title]="title()" [subTitle]="subTitle()">
    <ng-template ngxOvPanelActions>
      <div class="flex flex-row">
        <div><ng-template [cdkPortalOutlet]="actionsPortal"></ng-template></div>
        @if (actionsPortal && showSetting) {
          <div class="c-data-table-settings__action_separator"></div>
        }
        @if (_filterable) {
          <div>
            <ngx-ov-link
              [text]="'Verwijder filters'"
              [iconAfter]="'fas fa-filter-circle-xmark'"
              (click)="verwijderFilters()"
              [attr.data-cy]="'clear-data-table-filters'"
            ></ngx-ov-link>
          </div>
        }
        @if (_filterable && showSetting) {
          <div class="c-data-table-settings__action_separator"></div>
        }
        @if (showSetting) {
          <div>
            <data-table-settings
              [identifier]="identifier"
              [columns]="_columns()"
            ></data-table-settings>
          </div>
        }
      </div>
    </ng-template>

    <ng-container [ngTemplateOutlet]="datable"></ng-container>
  </ngx-ov-panel>
} @else {
  <ng-container [ngTemplateOutlet]="datable"></ng-container>
}

<ng-template #datable>
  @if (additionalFilterColumns(); as additionalFilterColumns) {
    @if (additionalFilterColumns.length || customFilterPortal.length) {
      <div
        class="grid grid-cols-12 gap-small bg-grayscales-gray6 p-large compact-layout"
      >
        @for (
          customFilter of customFilterPortal;
          let index = $index;
          track index
        ) {
          <div class="col-span-6">
            <ng-template [cdkPortalOutlet]="customFilter"></ng-template>
          </div>
        }
        @for (column of additionalFilterColumns; track column.name) {
          <div class="col-span-6">
            <ngx-ov-input-field
              [label]="column.label"
              type="text"
              class="c-data-table__filter-field"
              [style.width]="'100%'"
              [(ngModel)]="column.filter"
              (mouseup)="handleFilter($event, column)"
              (keyup)="handleFilter($event, column)"
            ></ngx-ov-input-field>
          </div>
        }
        @if (serversideFiltering) {
          <div class="col-span-12 u-position-btn-prim-sec">
            <ngx-ov-button
              [label]="'Verwijder filters'"
              [isSecondary]="true"
              (buttonClick)="verwijderFilters()"
            ></ngx-ov-button>
            <ngx-ov-button
              [label]="'Zoeken'"
              (buttonClick)="onServersideFilter()"
            ></ngx-ov-button>
          </div>
        }
      </div>
    }
  }
  @if (pagerPosition() === 'top' || pagerPosition() === 'top-bottom') {
    <ng-template [cdkPortalOutlet]="pagerPortal"></ng-template>
  }

  <div class="c-data-table" title>
    <table
      class="c-data-table__container"
      ngx-ov-data-table
      [class.md-selectable]="_selectable"
    >
      @if (caption) {
        <caption>
          {{
            caption
          }}
        </caption>
      }

      <!-- https://mvgond.atlassian.net/browse/OAM-805 -->
      @if (_isHeaderVisible) {
        <thead class="c-data-table__header">
          <tr class="c-data-table__row" ngx-ov-data-table-row>
            @if (_selectable) {
              <th
                class="c-data-table__cell c-data-table__cell__select"
                scope="col"
                [style.width.px]="widthSelectColumn"
                ngx-ov-data-table-column
              >
                <div
                  class="c-checkbox"
                  (click)="
                    selectAllVisible(!areAllVisibleSelected());
                    $event.stopPropagation()
                  "
                >
                  <input
                    class="c-checkbox__toggle"
                    type="checkbox"
                    (click)="
                      selectAllVisible(!areAllVisibleSelected());
                      $event.stopPropagation()
                    "
                  />
                  <div class="c-checkbox__label">
                    <i class="c-checkbox__box" aria-hidden="true"></i>
                  </div>
                </div>
              </th>
            }
            @for (
              column of datatableColumns() | filterVisibleColumns;
              track trackByFn
            ) {
              <th
                class="c-data-table__cell"
                scope="col"
                ngx-ov-data-table-column
                [name]="column.name"
                [numeric]="column.type == ngxOvDataTableColumnType.numeric"
                [ngClass]="{
                  sorting:
                    _sortable &&
                    !(column === _sortBy) &&
                    !(column.sortable === false),
                  sorting_asc:
                    _sortable && column === _sortBy && _sortOrder == 'ASC',
                  sorting_desc:
                    _sortable && column === _sortBy && _sortOrder == 'DESC',
                  break_word: true
                }"
                (click)="handleSort(column)"
                [active]="_sortable && column === _sortBy"
                [sortable]="_sortable"
                (mouseleave)="column.tooltip && tooltipRefresh()"
                (mouseenter)="column.tooltip && tooltipRefresh()"
                [sortOrder]="_sortOrder"
                [style.width.px]="
                  column?.minWidthInPixels ? column.minWidthInPixels : null
                "
              >
                <span [class.whitespace-nowrap]="!column.allowLabelWrap">{{
                  column.label
                }}</span>
              </th>
            }
          </tr>
          @if (_filterable) {
            <tr class="c-data-table__row" ngx-ov-data-table-row>
              @if (_selectable) {
                <th
                  class="c-data-table__cell"
                  scope="col"
                  ngx-ov-data-table-column
                  [style.width.px]="widthSelectColumn"
                >
                  &nbsp;&nbsp;
                </th>
              }
              @for (
                column of datatableColumns() | filterVisibleColumns;
                track trackByFn
              ) {
                <th
                  class="c-data-table__cell compact-layout"
                  scope="col"
                  ngx-ov-data-table-column
                  [name]="column.name"
                  [numeric]="column.type == ngxOvDataTableColumnType.numeric"
                  [style.width.px]="column.minWidthInPixels"
                >
                  @if (!(column.filterable == false)) {
                    <ngx-ov-input-field
                      label=""
                      type="text"
                      [attr.data-cy]="'search-input-field-' + column.name"
                      [style.width]="'100%'"
                      [(ngModel)]="column.filter"
                      (mouseup)="handleFilter($event, column)"
                      (keyup)="handleFilter($event, column)"
                    ></ngx-ov-input-field>
                  }
                </th>
              }
            </tr>
          }
        </thead>
      }

      <tbody class="c-data-table__body">
        @for (
          row of _data
            | filterDataTable: (serversideFiltering ? [] : _filters)
            | sortDataTable: _sortBy : _sortOrder
            | pageDataTable: pagerSignal();
          let index = $index;
          track trackByFn
        ) {
          <tr
            class="c-data-table__row"
            ngx-ov-data-table-row
            [class.md-selected]="_selectable && isRowSelected(row)"
            (click.stop)="
              select(
                row,
                !isRowSelected(row),
                _selectable,
                _clickable,
                _detail,
                false,
                $event
              )
            "
          >
            @if (_selectable) {
              <td
                class="c-data-table__cell c-data-table__cell__select"
                [style.width.px]="widthSelectColumn"
                [style.min-width.px]="widthSelectColumn"
                ngx-ov-data-table-cell
                (click.stop)="
                  select(
                    row,
                    !isRowSelected(row),
                    _selectable,
                    _clickable,
                    _detail,
                    true,
                    $event
                  )
                "
              >
                <div
                  class="c-checkbox"
                  (click)="
                    select(
                      row,
                      !isRowSelected(row),
                      _selectable,
                      _clickable,
                      _detail,
                      true,
                      $event
                    )
                  "
                >
                  <input
                    class="c-checkbox__toggle"
                    type="checkbox"
                    id=""
                    [checked]="isRowSelected(row)"
                  />
                  <div class="c-checkbox__label">
                    <i class="c-checkbox__box" aria-hidden="true"></i>
                  </div>
                </div>
              </td>
            }
            @for (
              column of datatableColumns() | filterVisibleColumns;
              track trackByFn
            ) {
              <td
                class="c-data-table__cell"
                valign="top"
                ngx-ov-data-table-cell
                [ngClass]="{
                  'cursor-pointer': _clickable && column.clickable,
                  break_word: true
                }"
                [numeric]="column.type == ngxOvDataTableColumnType.numeric"
                (click)="openRow(row, _clickable, column.clickable)"
                [style.min-width.px]="
                  column?.minWidthInPixels ? column.minWidthInPixels : 50
                "
              >
                <!-- NOT specific template defined -->
                @if (
                  !getTemplateRef(column.name) &&
                  !getExtraTemplateRef(column.name)
                ) {
                  @switch (column.type) {
                    @case (ngxOvDataTableColumnType.label) {
                      <span>
                        {{ column | cellValue: row }}
                      </span>
                    }
                    @case (ngxOvDataTableColumnType.yesno) {
                      <span class="c-data-table__icon">
                        @if (
                          (column | cellValue: row) === true ||
                          (column | cellValue: row) === false
                        ) {
                          <ngx-ov-boolean-indicator
                            [isTrue]="!!(column | cellValue: row)"
                          ></ngx-ov-boolean-indicator>
                        }
                      </span>
                    }
                    @default {
                      <span>
                        {{
                          column.format
                            ? column.format(column | cellValue: row)
                            : (column | cellValue: row)
                        }}
                      </span>
                    }
                  }
                }

                <!-- specific template defined -->
                @if (getTemplateRef(column.name)) {
                  <ng-template
                    [ngTemplateOutlet]="getTemplateRef(column.name)"
                    [ngTemplateOutletContext]="{
                      $implicit: row,
                      value: column | cellValue: row,
                      row: row,
                      column: column.name
                    }"
                  ></ng-template>
                }
                @if (getExtraTemplateRef(column.name)) {
                  <ng-template
                    [ngTemplateOutlet]="getExtraTemplateRef(column.name)"
                    [ngTemplateOutletContext]="{
                      value: column | cellValue: row,
                      row: row,
                      column: column.name
                    }"
                  ></ng-template>
                }
              </td>
            }
          </tr>
          @if (_detail) {
            <tr
              class="c-data-table__detail_row"
              [hidden]="!isDetailVisible(row)"
            >
              @if (getTemplateRef('detail')) {
                <td colspan="100%" class="c-data-table__detail_cell">
                  <ng-template
                    [ngTemplateOutlet]="getTemplateRef('detail')"
                    [ngTemplateOutletContext]="{ $implicit: row }"
                  ></ng-template>
                </td>
              }
            </tr>
          }
        }
      </tbody>
    </table>
  </div>

  @if (data?.length == 0) {
    <div class="md-padding" layout="row" layout-align="center center">
      <h3>{{ emptyTableText }}</h3>
    </div>
  }
  @if (pagerPosition() === 'bottom' || pagerPosition() === 'top-bottom') {
    <ng-template [cdkPortalOutlet]="pagerPortal"></ng-template>
  }
</ng-template>
