<div class="c-datepicker-inputs mb-4">
  <input [(ngModel)]="value" (ngModelChange)="registerModelChange()" hidden />
  @if (label) {
    <ngx-ov-input-label
      [text]="label"
      [required]="isRequired"
      [for]="identifier"
      [isReadonly]="isReadonly"
    >
    </ngx-ov-input-label>
  }
  @if (!isReadonly) {
    <div
      id="formFields-{{ id }}"
      class="flex flex-row"
      [formGroup]="datePickerForm"
    >
      <div
        id="dayFormFields-{{ id }}"
        class="flex flex-col mr-8"
        [class.hidden]="monthYearOnly"
      >
        <input
          id="day-{{ id }}"
          class="c-datepicker-inputs__input w-24"
          mask="d0"
          placeholder="dd"
          (ngModelChange)="registerChange()"
          (blur)="onBlur(value)"
          (keyup)="gotoNext($event, 'DAY')"
          [formControlName]="'day'"
          [attr.data-cy]="'day'"
          #inputDay
        />
        <ngx-ov-extra-info
          class="c-input-field__extra-info"
          [text]="_dayText"
        ></ngx-ov-extra-info>
      </div>
      <div class="flex flex-col">
        <input
          id="month-{{ id }}"
          class="c-datepicker-inputs__input w-24"
          mask="M0"
          placeholder="mm"
          (ngModelChange)="registerChange()"
          (blur)="onBlur(value)"
          (keyup)="gotoNext($event, 'MONTH')"
          [formControlName]="'month'"
          [attr.data-cy]="'month'"
          #inputMonth
        />
        <ngx-ov-extra-info
          class="c-input-field__extra-info"
          [text]="_monthText"
        ></ngx-ov-extra-info>
      </div>
      <div class="flex flex-col ml-8">
        <input
          id="year-{{ id }}"
          [formControlName]="'year'"
          class="c-datepicker-inputs__input w-32"
          mask="0000"
          placeholder="yyyy"
          (ngModelChange)="registerChange()"
          (blur)="onBlur(value)"
          (keyup)="gotoNext($event, 'YEAR')"
          [attr.data-cy]="'year'"
          #inputYear
        />
        <ngx-ov-extra-info
          class="c-input-field__extra-info"
          [text]="_yearText"
        ></ngx-ov-extra-info>
      </div>
    </div>
  } @else {
    @if (readOnlyText) {
      <div
        id="readonly"
        class="u-form-readonly__input-readonly"
        data-cy="readonly-value"
        [innerHTML]="readOnlyText"
      ></div>
    }
  }
  @if (internalValidationErrors) {
    <ngx-ov-input-validation
      [messages]="internalValidationErrors"
    ></ngx-ov-input-validation>
  }
  @if (errors$ | async; as errors) {
    <div class="mt-xsmall">
      @if (
        ngControl?.touched &&
        ngControl?.status === 'INVALID' &&
        internalValidationErrors == ''
      ) {
        <ngx-ov-input-validation [messages]="errors"></ngx-ov-input-validation>
      }
    </div>
  }
</div>
