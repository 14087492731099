import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Input,
  QueryList
} from '@angular/core';
import { PortalHeaderContentDirective } from './portals/portal-header-content.directive';
import { LinkService } from '../../../services/link.service';
import { PortalModule } from '@angular/cdk/portal';
import { ClickEqualsEnterDirective } from '../../../directives/click-equals-enter';
import { trackItemByIndex } from '../../../helper';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-header',
  templateUrl: './header.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ClickEqualsEnterDirective, PortalModule]
})
export class NgxOvHeaderComponent {
  // -------------------------------------------------------------------------
  // Variables
  // -------------------------------------------------------------------------
  @Input() classes: string;
  @Input() iconAfter: string;
  @Input() linkLogo: string;

  // -------------------------------------------------------------------------
  // Content children
  // -------------------------------------------------------------------------
  @ContentChildren(PortalHeaderContentDirective)
  headerContent: QueryList<PortalHeaderContentDirective>;

  // -------------------------------------------------------------------------
  // Helper function
  // -------------------------------------------------------------------------
  trackByFn = trackItemByIndex;

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  constructor(private linkService: LinkService) {}

  // -------------------------------------------------------------------------
  // Events
  // -------------------------------------------------------------------------
  getContentChildren(position?: string) {
    return this.headerContent.filter((item: PortalHeaderContentDirective) => {
      return item.position === (position || 'right');
    });
  }

  onClickLogo() {
    this.linkService.navigate(this.linkLogo);
  }
}
