import { Component } from '@angular/core';

// =============================================================================
// Config
// =============================================================================
@Component({
    selector: 'app-page-survey',
    templateUrl: './page-survey.component.html',
    standalone: true
})

// =============================================================================
// Definition
// =============================================================================
export class PageSurveyComponent {}
