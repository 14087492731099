import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Renderer2
} from '@angular/core';

// =============================================================================
// Component
// =============================================================================
@Component({
    /* tslint:disable-next-line */
    selector: 'tr[ngx-ov-data-table-row]',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: '<ng-content></ng-content>',
    standalone: true
})
export class NgxOvDataTableRowComponent {
  constructor(private _elementRef: ElementRef, private _renderer: Renderer2) {
    this._renderer.addClass(
      this._elementRef.nativeElement,
      'ngx-ov-data-table-row'
    );
  }
}
