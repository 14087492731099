import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { LinkService } from '../../../services/link.service';
import { NgxOvComponentBaseClass } from '../../ngx-ov-component-base-class';
import { TranslationsService } from '../../../services/translations.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxOvProgressBarComponent } from '../../forms/progress-bar/progress-bar.component';
import { ClickEqualsEnterDirective } from '../../../directives/click-equals-enter';

/**
 * Progress block shows a progress bar with a header.
 * When hovering over the progress block a pointer should be shown
 * in case an action on click is available unless block is disabled.
 */
@Component({
  selector: 'ngx-ov-progress-block',
  templateUrl: './progress-block.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgxOvProgressBarComponent, ClickEqualsEnterDirective]
})
export class NgxOvProgressBlockComponent
  extends NgxOvComponentBaseClass
  implements OnInit
{
  // -------------------------------------------------------------------------
  // private variables
  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------
  // public variables
  // -------------------------------------------------------------------------
  public iconInProgressTitle: string;
  public iconCompletedTitle: string;
  /**
   * Block has an action when not disabled
   * and either redirect to url or click event is defined.
   */
  public hasAction: boolean;
  // -------------------------------------------------------------------------
  // Input variables
  // -------------------------------------------------------------------------
  @Input() title: string;
  @Input() url: string;

  @Input() isCompleted = false;
  @Input() isDisabled = false;

  @Input() percentage: number;

  // -------------------------------------------------------------------------
  // Output variables
  // -------------------------------------------------------------------------
  @Output()
  progressClicked: EventEmitter<any> = new EventEmitter();
  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  constructor(
    private linkService: LinkService,
    public translationsService: TranslationsService,
    @Optional() public translateService: TranslateService
  ) {
    super(translationsService, translateService);
  }
  ngOnInit(): void {
    super.ngOnInit();
    // assuming url or click event is never changed after init
    this.hasAction =
      !this.isDisabled &&
      (this.progressClicked?.observed || (this.url != null && this.url !== ''));
  }
  onClickBar() {
    if (!this.isDisabled) {
      if (this.progressClicked.observed) {
        this.progressClicked.emit();
      } else if (this.url) {
        this.linkService.navigate(this.url);
      }
    }
  }
  setLabels() {
    this.iconCompletedTitle = this.getTranslation(
      'CONTENT_BLOKKEN.PROGRESS_BLOCK',
      'ICON.COMPLETED'
    );
    this.iconInProgressTitle = this.getTranslation(
      'CONTENT_BLOKKEN.PROGRESS_BLOCK',
      'ICON.IN_PROGRESS'
    );
  }
}
