import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NgxOvMessageBarService {
  public messageBar: any = null;

  initMessageBar(newMessageBar: any) {
    if (!this.messageBar) {
      this.messageBar = newMessageBar;
    }
  }

  destroyMessageBar() {
    this.messageBar = null;
  }

  open() {
    if (this.messageBar) {
      this.messageBar.open();
    }
  }

  close() {
    if (this.messageBar) {
      this.messageBar.close();
    }
  }
}
