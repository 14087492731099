/**
 * Naric
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.3.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import {
  AbstractControlOptions,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn
} from '@angular/forms';
import {SwaggerValidators} from '@ov/ngx-ov-utils';

export interface ICodeOmschrijvingRestDtoOfstring {
  code: string;
  omschrijving?: string;
}


export class CodeOmschrijvingRestDtoOfstring implements ICodeOmschrijvingRestDtoOfstring {
  code: string;
  omschrijving?: string;
}

export class CodeOmschrijvingRestDtoOfstringFormHelper {

  static generateFormWithSwaggerValidators(formBuilder: UntypedFormBuilder, controlsConfig: { [key: string]: any; }, options?: AbstractControlOptions | { [key: string]: any; } | null): UntypedFormGroup {
    if (controlsConfig["code"] != undefined && !(controlsConfig["code"] instanceof UntypedFormGroup) && !(controlsConfig["code"] instanceof UntypedFormArray)) {
      controlsConfig["code"][1] = controlsConfig["code"][1].concat(CodeOmschrijvingRestDtoOfstringValidator.code_swagger_validator());
    }
    if (controlsConfig["omschrijving"] != undefined && !(controlsConfig["omschrijving"] instanceof UntypedFormGroup) && !(controlsConfig["omschrijving"] instanceof UntypedFormArray)) {
      controlsConfig["omschrijving"][1] = controlsConfig["omschrijving"][1].concat(CodeOmschrijvingRestDtoOfstringValidator.omschrijving_swagger_validator());
    }
    if (options) {
      return formBuilder.group(controlsConfig, options);
    }
    return formBuilder.group(controlsConfig);
  }
}

export class CodeOmschrijvingRestDtoOfstringValidator {

  /**
   * Active Validators for code:
   * Required validator
   */
  static code_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    validators.push(SwaggerValidators.required);
    return validators;
  };

  /**
   * Active Validators for omschrijving:
   */
  static omschrijving_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    return validators;
  };

}



