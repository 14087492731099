import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Directive,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { NgxOvAlertComponent } from '../../../../components/meldingen/alert/alert.component';
import { NgxOvActionsService } from '../../actions/actions.service';
import {
  bepaalFailureMessages,
  bepaalTextFailureMessages
} from '../../store-utils';
import { NgxOvComponentBaseClass } from '../../../../components/ngx-ov-component-base-class';
import { TranslationsService } from '../../../../services/translations.service';

@Directive({
  selector: '[alertHost]',
  standalone: true
})
export class AlertHostDirective {
  constructor(public viewContainerRef: ViewContainerRef) {}
}

@Component({
  selector: 'ngx-ov-store-show-alert',
  templateUrl: './store-alert.component.html',
  standalone: true,
  imports: [AlertHostDirective]
})
export class NgxOvStoreAlertComponent
  extends NgxOvComponentBaseClass
  implements OnInit, OnDestroy
{
  @Input() key: string | string[];
  @Input() storeType: 'LOADING' | 'SAVE' | 'DELETE' = 'SAVE';
  @Input() message: string;
  @Input() classes: string;
  @ViewChild(AlertHostDirective, { static: true })
  alertHost: AlertHostDirective;

  private errorLoadingCopy;
  private errorSavingCopy;
  private errorDeletingCopy;
  private existingAlerts: Array<ComponentRef<NgxOvAlertComponent>> = [];

  constructor(
    private viewContainerRef: ViewContainerRef,
    private actionsService: NgxOvActionsService,
    private cd: ChangeDetectorRef,
    public translationsService: TranslationsService,
    @Optional() public translateService: TranslateService
  ) {
    super(translationsService, translateService);
  }

  ngOnInit() {
    this.setLabels();
    if (this.translateService) {
      this.translateService.onLangChange
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.setLabels();
        });
    }
    this.actionsService
      .getStatus$(this.key)
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe((res) => {
        this.alertHost.viewContainerRef.clear();

        if (res) {
          const failureMessages = bepaalFailureMessages(res, this.storeType);
          this.existingAlerts.forEach((alertRef) => alertRef?.destroy());

          if (failureMessages.length) {
            const compRef =
              this.alertHost.viewContainerRef.createComponent(
                NgxOvAlertComponent
              );

            const text = bepaalTextFailureMessages(failureMessages);

            compRef.instance.type = 'ERROR';
            compRef.instance.title = this.bepaalTitle();
            compRef.instance.message = text;
            if (this.classes) {
              compRef.instance.classes = this.classes;
            }
            this.existingAlerts.push(compRef);
            this.cd.detectChanges();
          }
        }
      });
  }

  bepaalTitle() {
    let title = '';

    if (this.storeType === 'SAVE') {
      title = this.errorSavingCopy;
    }
    if (this.storeType === 'LOADING') {
      title = this.errorLoadingCopy;
    }
    if (this.storeType === 'DELETE') {
      title = this.errorDeletingCopy;
    }

    return title;
  }

  // -------------------------------------------------------------------------
  // Translation
  // -------------------------------------------------------------------------
  setLabels() {
    const prefix = 'FUNDAMENTALS.STORE';
    this.errorLoadingCopy =
      this.errorLoadingCopy ?? this.getTranslation(prefix, 'ERROR_API_GET');
    this.errorSavingCopy =
      this.errorSavingCopy ?? this.getTranslation(prefix, 'ERROR_API_PUT');
    this.errorDeletingCopy =
      this.errorDeletingCopy ?? this.getTranslation(prefix, 'ERROR_API_DELETE');
  }
}
