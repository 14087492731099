<div
  class="modal"
  [class.hidden]="!(isOpen$ | async)"
  role="dialog"
  aria-modal="true"
  [attr.data-cy]="id"
  #modal
>
  <div
    class="modal__overlay"
    (click)="!preventClose ? onClosePopup() : null"
  ></div>
  <div
    [ngClass]="[
      size ? 'modal__block-' + size : '',
      !fullScreenMobile ? 'modal__block--not-fullscreen' : ''
    ]"
    [ngStyle]="_styles"
    class="modal__block"
  >
    @if (imageSrc) {
      <!-- header with title and close button -->
      <div class="modal__header">
        <div class="modal__title"></div>
        @if (!preventClose) {
          <div class="modal__close">
            <i
              (click)="onClosePopup()"
              class="fas fa-times cursor-pointer"
              aria-hidden="true"
              [title]="iconCloseModalTitle"
            ></i>
            <span class="sr-only">{{ iconCloseModalTitle }}</span>
          </div>
        }
      </div>

      <!-- image -->
      <div class="modal__image">
        <img
          [attr.alt]="imageAlt"
          [attr.src]="imageSrc"
          [ngStyle]="{
            'max-height': imageMaxHeight + 'rem'
          }"
        />
      </div>
    }

    <!-- header with title and close button -->
    <div class="modal__header">
      <div class="modal__title">
        @if (type === 'Danger') {
          <i
            class="fas fa-exclamation-circle modal__title-icon"
            aria-hidden="true"
          ></i>
        }
        <div>{{ title }}</div>
      </div>
      @if (!preventClose && !imageSrc) {
        <div class="modal__close">
          <i
            class="fas fa-times cursor-pointer"
            (click)="onClosePopup()"
            aria-hidden="true"
            [title]="iconCloseModalTitle"
          ></i>
          <span class="sr-only">{{ iconCloseModalTitle }}</span>
        </div>
      }
    </div>

    <!-- content -->
    <div
      [class.modal__content--allow-overflow]="allowOverflow"
      class="modal__content"
    >
      <ng-content></ng-content>
    </div>

    @if (!footerTemplate?.length && type !== 'Passive') {
      <!-- footer -->
      <div class="modal__footer">
        @if (type !== 'Acknowledgement') {
          <ngx-ov-link
            [attr.data-cy]="id + '-link-cancel'"
            [text]="cancelCopy"
            (click)="onCancel()"
          ></ngx-ov-link>
        }

        <ngx-ov-button
          [attr.data-cy]="id + '-button-confirm'"
          [label]="confirmCopy"
          (buttonClick)="onConfirm()"
          [isError]="type === 'Danger'"
          [iconAfter]="confirmIcon"
          [isLoading]="isSaving$ | async"
        >
        </ngx-ov-button>
      </div>
    }
    @if (footerTemplate?.length) {
      <div class="modal__footer">
        <ng-template [cdkPortalOutlet]="footerTemplate.first"></ng-template>
      </div>
    }
  </div>
</div>
