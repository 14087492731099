import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NgxOvButtonComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/button/button.component';
import { MockUserService } from './mock-user.service';
import { Observable } from 'rxjs';
import { AsyncPipe, JsonPipe } from '@angular/common';
import { NgxOvCardComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/content-blokken/card/card.component';
import { PortalCardDirective } from '../../../../projects/ngx-ov-ui/src/lib/components/content-blokken/card/portals/portal-card.directive';
import { StoreIsLoadingComponent } from '../../../../projects/ngx-ov-ui/src/lib/fundamentals/store/componenten/loading/store-loading.component';

@Component({
  templateUrl: './page-bevestiging.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [
    NgxOvButtonComponent,
    AsyncPipe,
    JsonPipe,
    NgxOvCardComponent,
    PortalCardDirective,
    StoreIsLoadingComponent
  ]
})
export class PageBevestigingComponent implements OnInit {
  users$: Observable<any>;
  constructor(private mockUserService: MockUserService) {}

  ngOnInit() {
    this.users$ = this.mockUserService.getUsers();
  }

  bewaar() {
    this.mockUserService.saveUser().subscribe(() => {});
  }
}
