import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

const iconExclamationCircle = 'exclamation-circle';
const iconCheckCircle = 'check-circle';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-alert',
  templateUrl: './alert.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: []
})
export class NgxOvAlertComponent {
  // -------------------------------------------------------------------------
  // private variables
  // -------------------------------------------------------------------------
  private _title: string;
  private _message: string;
  private _type: string;
  private _classes: string;
  private _icon = iconExclamationCircle;

  // -------------------------------------------------------------------------
  // Input variables/Setters
  // -------------------------------------------------------------------------
  @Input() set title(title: string) {
    this._title = title;
  }

  @Input() set message(message: string) {
    this._message = message;
  }

  @Input() set type(type: string) {
    this._type = type.toUpperCase();
  }

  @Input() set classes(classes: string) {
    this._classes = classes;
  }

  @Input() set icon(icon: string) {
    if (icon) {
      this._icon = icon;
    } else {
      switch (this.type) {
        case 'ERROR': {
          this._icon = iconExclamationCircle;
          break;
        }

        case 'WARNING': {
          this._icon = iconExclamationCircle;
          break;
        }

        case 'SUCCESS': {
          this._icon = iconCheckCircle;
          break;
        }

        default: {
          this._icon = iconExclamationCircle;
        }
      }
    }
  }

  // -------------------------------------------------------------------------
  // Getters
  // -------------------------------------------------------------------------
  get title(): string {
    return this._title;
  }

  get message(): string {
    return this._message;
  }

  get type(): string {
    return this._type;
  }

  get classes(): string {
    return this._classes;
  }

  get icon(): string {
    return this._icon;
  }
}
