<div class="container my-10" [formGroup]="tableForm">
  <ngx-ov-data-table
    [columns]="_columns"
    [data]="toetsenLijst"
    [detail]="false"
    [sortable]="true"
    [sortOrder]="'DESC'"
    [filterable]="true"
    formArrayName="toetsen"
  >
    <ng-template let-row ngxOvDataTableTemplate="vak.omschrijving">
      <div [formGroupName]="row.id">
        {{ row | json }}
        {{ row.id }}
        <ngx-ov-select
          [bindLabel]="'omschrijving'"
          [bindValue]="'code'"
          [closeOnSelect]="true"
          [items]="vakken"
          [searchable]="true"
          [formControlName]="'vak'"
        ></ngx-ov-select>
      </div>
    </ng-template>
  </ngx-ov-data-table>
  {{ toetsenLijst | json }}
</div>
