import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { LinkService } from '../../../services/link.service';
import { NgStyle } from '@angular/common';
import { ClickEqualsEnterDirective } from '../../../directives/click-equals-enter';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-doormat',
  templateUrl: './doormat.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ClickEqualsEnterDirective, NgStyle]
})
export class NgxOvDoormatComponent {
  // -------------------------------------------------------------------------
  // private variables
  // -------------------------------------------------------------------------
  private _type: string;

  // -------------------------------------------------------------------------
  // Input variables
  // -------------------------------------------------------------------------
  @Input() classes: string;
  @Input() title: string;
  @Input() url: string;
  @Input() icon: string;
  @Input() iconSize = 6;
  @Input() moreInfoLabel: string;

  @Input() set type(type: string) {
    this._type = type.toUpperCase();
  }

  // -------------------------------------------------------------------------
  // Output variables
  // -------------------------------------------------------------------------
  @Output()
  doormatClicked: EventEmitter<any> = new EventEmitter();
  @Output() moreInfoClicked: EventEmitter<any> = new EventEmitter();

  // -------------------------------------------------------------------------
  // Getters
  // -------------------------------------------------------------------------
  get type(): string {
    return this._type;
  }

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  constructor(private linkService: LinkService) {}

  goto(url?: string) {
    if (url) {
      this.linkService.navigate(url);
    } else {
      this.doormatClicked.emit();
    }
  }

  onClickMoreInfo(e: Event): void {
    this.moreInfoClicked.emit();
    e.stopPropagation();
  }
}
