<div
  class="c-main-layout"
  [class.c-main-layout--with-header]="header"
  [class.c-main-layout--with-left-navigation]="leftNavigation"
  [class.c-main-layout--with-footer]="footer"
>
  @if (cookiebar) {
    <div>
      <ng-template [cdkPortalOutlet]="cookiebar"></ng-template>
    </div>
  }
  @if (header) {
    <div class="c-main-layout__header">
      <ng-template [cdkPortalOutlet]="header"></ng-template>
    </div>
  }

  <!-- flex row -->
  <div
    class="c-main-layout__wrapper-horizontal"
    [class.c-main-layout__wrapper-horizontal-with]="!showMenubar"
    [class.c-main-layout__wrapper-horizontal-without]="showMenubar"
  >
    @if (leftNavigation) {
      <div class="c-main-layout__left-navigation" stickybits stickyOffset="43">
        <ng-template [cdkPortalOutlet]="leftNavigation"></ng-template>
      </div>
    }

    <!-- https://stackoverflow.com/questions/6146768/detect-when-scrollable-div-is-focused-in-firefox -> Waarom een scrollable div focusable is in firefox en we dit moeten afzetten met tabindex   -->
    <div
      class="c-main-layout__wrapper-content"
      tabindex="-1"
      #containerMainLayout
    >
      <div class="c-main-layout__wrapper-content__content">
        <ng-content></ng-content>
      </div>
      @if (footer) {
        <div class="c-main-layout__wrapper-content__footer">
          <ng-template [cdkPortalOutlet]="footer"></ng-template>
        </div>
      }
    </div>
  </div>
</div>
<div class="overlay"></div>
