import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  Renderer2
} from '@angular/core';

// =============================================================================
// Component
// =============================================================================
@Component({
    /* tslint:disable-next-line */
    selector: 'td[ngx-ov-data-table-cell]',
    template: '<ng-content></ng-content>',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class NgxOvDataTableCellComponent {
  /**
   * numeric?: boolean
   * Makes cell follow the numeric data-table specs.
   * Defaults to 'false'
   */
  @Input() numeric = false;

  @HostBinding('class.md-numeric')
  get bindNumeric(): boolean {
    return this.numeric;
  }

  constructor(private _elementRef: ElementRef, private _renderer: Renderer2) {
    this._renderer.addClass(
      this._elementRef.nativeElement,
      'ngx-ov-data-table-cell'
    );
  }
}
