import { EventEmitter, Injectable, signal } from '@angular/core';
import * as R from 'ramda';
import { NavigationItem } from './navigation.model';

export interface INavigationSettings {
  collapsed: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class NgxOvNavigationService {
  _collapsedMobile = true;
  _collapsed = signal(false);

  collapsedChanged = new EventEmitter<boolean>();

  _navigationSettings: INavigationSettings = {
    collapsed: false
  };

  getNavigationSettings(): INavigationSettings {
    return this._navigationSettings;
  }

  isCollapsed(): boolean {
    return this.getNavigationSettings().collapsed;
  }

  isCollapsedMobile(): boolean {
    return this._collapsedMobile;
  }

  toggleCollapsed() {
    this._navigationSettings.collapsed = !this._navigationSettings.collapsed;
    if (this._collapsed()) {
      this._collapsed.set(false);
    } else {
      this._collapsed.set(true);
    }
    this.collapsedChanged.next(this._navigationSettings.collapsed);
  }

  toggleCollapsedMobile() {
    this._collapsedMobile = !this._collapsedMobile;
  }

  collapseMobile() {
    this._collapsedMobile = true;
  }

  filterMenuItems(menuItems: NavigationItem[], roles: any) {
    const mappedItems =
      menuItems && Array.isArray(menuItems)
        ? menuItems.map((item) => {
            const filteredChildren = item.children
              ? item.children.filter((subItem) =>
                  this.hasRole(roles, subItem.roles)
                )
              : item.children;
            return {
              ...item,
              children: filteredChildren
            };
          })
        : [];

    return mappedItems
      .filter((menuItem) => this.hasRole(roles, menuItem.roles))
      .filter((menuItem) => {
        if (!menuItem.linkUrl && Array.isArray(menuItem.children)) {
          return menuItem.children.length;
        }
        return true;
      });
  }

  hasRole(ownedRoles: any, needRoles: string | string[]): boolean {
    let role = needRoles;
    let res = false;
    if (!role) {
      return true;
    }
    if (role instanceof Array) {
      role = role.map((r) => (r ? r.toUpperCase().trim() : ''));
      const testLoading = (x) => ownedRoles[x];
      // Gebruiker heeft minstens 1 van de rollen:
      res = R.any(testLoading)(role);
    } else {
      role = role ? role.toUpperCase().trim() : '';
      res = !!ownedRoles[role.toString()];
    }
    return res;
  }
}
