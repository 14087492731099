<div class="c-control-panel-item">
  <div
    class="c-control-panel-item__header"
    [class.c-control-panel-item__header--hidden]="isTitleHiddenDesktop"
    (click)="isCollapsed = !isCollapsed"
    [tabindex]="-1"
  >
    <div>
      <span [tabindex]="-1">{{ title }}</span>
      @if (tooltip) {
        <ngx-ov-tooltip
          [tooltip]="tooltip"
          [position]="'right'"
          [tabindex]="-1"
        ></ngx-ov-tooltip>
      }
      @if (tooltipClicked.observed) {
        <span class="c-tooltip__icon" (click)="onTooltipClick()" tabindex="-1">
          <i
            class="fas fa-info-circle"
            aria-hidden="true"
            [title]="tooltipIconTitle"
          ></i>
          <span class="sr-only">{{ tooltipIconTitle }}</span>
        </span>
      }
    </div>
    <div class="c-control-panel-item__collapsable tablet:invisible">
      @if (!isCollapsed) {
        <i
          class="fas fa-chevron-down"
          aria-hidden="true"
          [title]="headerCloseIconTitle"
        ></i>
      } @else {
        <i
          class="fas fa-chevron-up"
          aria-hidden="true"
          [title]="headerOpenIconTitle"
        ></i>
      }
      <!--todo: OAM-1204 check if part of previous if block + check logic (always true?)-->
      @if (isCollapsed) {
        <span class="sr-only">{{
          !isCollapsed ? headerCloseIconTitle : headerOpenIconTitle
        }}</span>
      }
    </div>
  </div>
  <div
    [class.c-control-panel-item__content--hidden]="isCollapsed"
    class="c-control-panel-item__content"
  >
    <ng-content></ng-content>
  </div>
</div>
