@for (optie of options; let i = $index; track trackByFn) {
  <ngx-ov-toggle-button
    [id]="'toggle_' + i"
    [label]="bindLabel ? optie[bindLabel] : optie"
    [value]="optie"
    [icon]="bindIcon ? optie[bindIcon] : null"
    [code]="bindCode ? optie[bindCode] : null"
    [selected]="isOptionSelected(optie)"
    (toggleButtonClicked)="onToggleButtonClicked(i)"
  ></ngx-ov-toggle-button>
}
<ng-content></ng-content>
