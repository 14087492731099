import { LandenStateModel } from '@store/landen/landen.state';
import { MockStateModel } from './mock/mock.state';

export enum StoreKeys {
  MOCK = 'mock',
  LANDEN = 'landen'
}

export interface StateModel {
  [StoreKeys.MOCK]: MockStateModel;
  [StoreKeys.LANDEN]: LandenStateModel;
}
