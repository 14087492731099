@if (!uiIsLoading() && !uiService.uiIsLoadingError()) {
  <div>
    <ng-content></ng-content>
  </div>
} @else if (showError) {
  <ngx-ov-alert
    [title]="errorLoadingCopy"
    [type]="'error'"
    [classes]="alertClasses"
  >
  </ngx-ov-alert>
}

<div [class.hidden]="!uiIsLoading()" class="{{ loaderClasses }}">
  <ngx-ov-loader [text]="loadingCopy"></ngx-ov-loader>
</div>
