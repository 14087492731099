<div class="container my-10">
  <h1 class="text-primary-dark">mr(margin-right) demo</h1>
  <div>
    <ngx-ov-button [label]="'demo'" class="mr-xsmall"></ngx-ov-button>
    <ngx-ov-button [label]="'demo'" class="mr-small"></ngx-ov-button>
    <ngx-ov-button [label]="'demo'" class="mr-medium"></ngx-ov-button>
    <ngx-ov-button [label]="'demo'" class="mr-large"></ngx-ov-button>
    <ngx-ov-button [label]="'demo'" class="mr-xlarge"></ngx-ov-button>
    <ngx-ov-button [label]="'demo'" class="mr-xlarge"></ngx-ov-button>
  </div>

  <div class="my-10">
    <h1>mb(margin-bottom) demo</h1>
    <div>
      <div style="display: flex; flex-direction: column">
        <ngx-ov-button [label]="'demo'" class="mb-xsmall"></ngx-ov-button>
        <ngx-ov-button [label]="'demo'" class="mb-small"></ngx-ov-button>
        <ngx-ov-button [label]="'demo'" class="mb-medium"></ngx-ov-button>
        <ngx-ov-button [label]="'demo'" class="mb-large"></ngx-ov-button>
        <ngx-ov-button [label]="'demo'" class="mb-xlarge"></ngx-ov-button>
        <ngx-ov-button [label]="'demo'"></ngx-ov-button>
      </div>
    </div>
  </div>

  <div class="my-10">
    <h1>grid gap-</h1>
    <div>
      <div>default</div>
      <div class="grid grid-cols-12 gap-medium">
        <ng-container *ngTemplateOutlet="gridBoxes"></ng-container>
      </div>
      <div>xsmall</div>
      <div class="grid grid-cols-12 gap-xsmall">
        <ng-container *ngTemplateOutlet="gridBoxes"></ng-container>
      </div>
      <div>small</div>
      <div class="grid grid-cols-12 gap-small">
        <ng-container *ngTemplateOutlet="gridBoxes"></ng-container>
      </div>
      <div>medium</div>
      <div class="grid grid-cols-12 gap-medium">
        <ng-container *ngTemplateOutlet="gridBoxes"></ng-container>
      </div>
      <div>large</div>
      <div class="grid grid-cols-12 gap-large">
        <ng-container *ngTemplateOutlet="gridBoxes"></ng-container>
      </div>
      <div>xlarge</div>
      <div class="grid grid-cols-12 gap-xlarge">
        <ng-container *ngTemplateOutlet="gridBoxes"></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #gridBoxes>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
  <div class="col-span-1 coloredContainer">&nbsp;</div>
</ng-template>
