<div class="container">
  <h1>Test store</h1>
  Testcases:
  <div class="p-large">
    <ul>
      <li>page loader</li>
      <li>button loading</li>
      <li>alert component</li>
      <li>loader select component</li>
      <li>loading state upload component</li>
      <li>reset state errors</li>
    </ul>
  </div>
  <div class="u-position-btn-prim-sec my-small">
    <ngx-ov-button
      [label]="'Laad landen'"
      (buttonClick)="laadLanden()"
      [storeIsLoading]="'LANDEN'"
      [attr.data-cy]="'button-laad-landen'"
    ></ngx-ov-button>
    <ngx-ov-button
      [label]="'Failure'"
      (buttonClick)="mockFailure()"
      [storeIsLoading]="'MOCK'"
      [attr.data-cy]="'button-bewaar-mock'"
    ></ngx-ov-button>
    <ngx-ov-button
      [label]="'Reset errors'"
      (buttonClick)="resetErrors()"
      [attr.data-cy]="'button-reset-errors'"
    ></ngx-ov-button>
  </div>
  <div class="my-10"></div>
  <ngx-ov-info-block
    [title]="'ngx-ov-store-isloading'"
    [isCollapsed]="true"
    [icon]="'fas fa-book'"
    [attr.data-cy]="'info-store-loading'"
  >
    <div class="p-large">
      <ngx-ov-store-isloading [key]="'LANDEN'" [alertClasses]="'my-small'">
        Dit wijzigt naar loading component bij laden van landen.
      </ngx-ov-store-isloading>
    </div>
  </ngx-ov-info-block>

  <div class="my-10"></div>
  <ngx-ov-info-block
    [title]="'Upload component'"
    [isCollapsed]="true"
    [icon]="'fas fa-book'"
    [attr.data-cy]="'info-upload'"
  >
    Spinner wordt getoond bij het laden van de landen.
    <ngx-ov-upload
      [columns]="columns"
      [rows]="rows"
      [title]="'Documenten Opladen'"
      [additionalActionText]="'Ik heb een benodigd document niet'"
      [storeIsLoading]="'LANDEN'"
      [attr.data-cy]="'upload'"
    >
    </ngx-ov-upload>
  </ngx-ov-info-block>
  <div class="my-10"></div>
  <ngx-ov-info-block
    [title]="'Select component'"
    [isCollapsed]="true"
    [icon]="'fas fa-book'"
    [attr.data-cy]="'info-select'"
  >
    Spinner wordt getoond bij het laden van de landen.
    <ngx-ov-select
      [label]="'Selecteer je geboorteland?'"
      [items]="foodItems"
      [storeIsLoading]="'LANDEN'"
      [attr.data-cy]="'select'"
    ></ngx-ov-select>
  </ngx-ov-info-block>
  <div class="my-10"></div>
  <ngx-ov-info-block
    [title]="'Alert demo / reset'"
    [isCollapsed]="true"
    [icon]="'fas fa-book'"
    [attr.data-cy]="'info-alert'"
  >
    Hier komt de alert bij klikken op de failure, de alert wordt verwijderd bij
    reset van de errors.
    <ngx-ov-store-show-alert
      [key]="'MOCK'"
      [classes]="'my-small'"
    ></ngx-ov-store-show-alert>
  </ngx-ov-info-block>
  <div class="my-10"></div>
  <ngx-ov-info-block
    [title]="'Status landen'"
    [isCollapsed]="true"
    [icon]="'fas fa-book'"
    [attr.data-cy]="'info-status-landen'"
  >
    <div class="p-large">
      {{ statusLanden$ | async | json }}
    </div>
  </ngx-ov-info-block>

  <div class="my-10"></div>
  <ngx-ov-info-block
    [title]="'Status mock'"
    [isCollapsed]="true"
    [icon]="'fas fa-book'"
  >
    <div class="p-large">
      {{ statusMock$ | async | json }}
    </div>
  </ngx-ov-info-block>
</div>
