import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LinkService } from '../../../services/link.service';
import { ClickEqualsEnterDirective } from '../../../directives/click-equals-enter';
import { NgTemplateOutlet } from '@angular/common';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-link',
  templateUrl: './link.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ClickEqualsEnterDirective, NgTemplateOutlet]
})
export class NgxOvLinkComponent {
  private _isDisabled = false;

  // -------------------------------------------------------------------------
  // Input variables
  // -------------------------------------------------------------------------
  @Input() classes: string;
  /**
   * Link text to display.
   * @param {string}
   */
  @Input() text;
  @Input() linkUrl: string;
  @Input() iconBefore: string;
  @Input() iconAfter: string;

  @Input() set isDisabled(isDisabled: boolean) {
    this._isDisabled = isDisabled;
  }
  get isDisabled(): boolean {
    return this._isDisabled;
  }
  /**
   * Don\'t wrap text to a new line but show a single line only.
   * @param {boolean}
   */
  @Input() nowrap = false;

  constructor(private linkService: LinkService) {}

  onClick(e: Event) {
    if (!this.isDisabled) {
      this.linkService.navigate(this.linkUrl);
    } else {
      e.stopPropagation();
      e.preventDefault();
    }
  }
}
