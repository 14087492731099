import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-loader',
  templateUrl: './loader.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: []
})
export class NgxOvLoaderComponent {
  // -------------------------------------------------------------------------
  // Input variables
  // -------------------------------------------------------------------------
  @Input() text: string;
  /**
   * Display both text and icon on one line.
   * @param {boolean}
   */
  @Input() oneLine = false;
}
