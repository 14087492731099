<div
  class="c-radio {{ classes }}"
  [class.is-disabled]="isDisabled"
  [class.is-error]="isError"
  [class.show-block]="showAsBlock"
  [class.c-radio--selected]="checked"
>
  <div
    class="c-radio__container"
    [attr.data-cy]="id"
    (click)="clickRadio($event)"
  >
    <!-- Radio button -->
    <input
      class="c-radio__toggle"
      type="radio"
      id="{{ id }}"
      [name]="for"
      [disabled]="isDisabled"
      [value]="value"
      [checked]="checked"
      #input
    />
    <i class="c-radio__toggle_box" aria-hidden="true"></i>
    @if (icon) {
      <div class="c-radio__icon">
        <i class="{{ icon }}" aria-hidden="true"></i>
      </div>
    }

    <!-- Label -->
    <div class="c-radio__label-container">
      <div class="c-radio__label">
        <label for="{{ id }}">{{ label }}</label>
        @if (labelPortal) {
          <div>
            &nbsp;<ng-template [cdkPortalOutlet]="labelPortal"></ng-template>
          </div>
        }
        @if (extraInfo) {
          &nbsp;
          <ngx-ov-tooltip class="hidden-mobile">{{ extraInfo }}</ngx-ov-tooltip>
        }
      </div>

      <!-- Extra Information -->
      @if (extraInfo) {
        <div class="hidden-desktop">
          <ngx-ov-extra-info [text]="extraInfo"></ngx-ov-extra-info>
        </div>
      }
    </div>
  </div>

  <!-- Error message -->
  @if (isError && errorMessage) {
    <span class="c-radio__error-message u-text-bold">{{ errorMessage }}</span>
  }
  @if (checked && contentPortal) {
    <div class="c-radio__content">
      <ng-template [cdkPortalOutlet]="contentPortal"></ng-template>
    </div>
  }
</div>
