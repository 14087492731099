import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LinkService } from '../../../services/link.service';
import { ClickEqualsEnterDirective } from '../../../directives/click-equals-enter';

// =============================================================================
// Component
// =============================================================================
@Component({
    selector: 'ngx-ov-breadcrumb-item',
    template: `<div class="c-breadcrumb__item">
    <div (click)="onClick(href)" class="c-breadcrumb__link">
      {{ label }}
    </div>
  </div>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ClickEqualsEnterDirective]
})
export class NgxOvBreadcrumbItemComponent {
  // -------------------------------------------------------------------------
  // Input variables
  // -------------------------------------------------------------------------
  @Input() label: string;
  @Input() href: string;

  constructor(private linkService: LinkService) {}

  onClick(linkUrl: string) {
    this.linkService.navigate(linkUrl);
  }
}
