import { Component } from '@angular/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Store } from '@ngxs/store';
import { LoadLandenRequest } from '@store/landen/landen.actions';

import { NgxOvUiModule } from '../../../../projects/ngx-ov-ui/src/lib/ngx-ov-ui.module';

import { Observable } from 'rxjs';
import { StoreItemStatus } from '../../../../projects/ngx-ov-ui/src/lib/fundamentals/store/store.model';
import { CommonModule } from '@angular/common';
import { NgxOvActionsService } from '../../../../projects/ngx-ov-ui/src/lib/fundamentals/store/actions/actions.service';
import { LoadMockRequest } from '@store/mock/mock.actions';
import { NgxOvStoreModule } from '../../../../projects/ngx-ov-ui/src/lib/fundamentals/store/store.module';
import {
  NgxOvUploadComponent,
  UploadColumn
} from '../../../../projects/ngx-ov-ui/src/lib/components/forms/upload/upload.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxOvInfoBlockComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/content-blokken/info-block/info-block.component';
import { NgxOvSelectComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/forms/select/select.component';
import { NgxOvButtonComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/button/button.component';

@Component({
  selector: 'app-page-store',
  templateUrl: './page-store.component.html',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxOvStoreModule,
    NgxOvInfoBlockComponent,
    NgxOvSelectComponent,
    NgxOvButtonComponent,
    NgxOvUploadComponent
  ],
  standalone: true
})
export class PageStoreComponent {
  statusLanden$: Observable<StoreItemStatus>;
  statusMock$: Observable<StoreItemStatus>;
  foodItems: any[] = ['fries', 'pizza', 'donuts'];
  public rows: any[] = [
    {
      id: '1',
      bestandsnaam: 'dummy-file-1.jpg',
      betrokkene: 'Testuser achternaam',
      documentType: 'plain document',
      actions: {
        canDelete: true,
        canDownload: true,
        canEdit: true
      },
      datum: new Date().toLocaleDateString()
    },
    {
      id: '2',
      bestandsnaam:
        'dummyfilemeteenlangenaamzonderspatiesofliggendestreepjesoflagestreepjeswantstreepjeszijnnietleuk.pdf',
      documentType: 'plain document',
      isNotExpandable: true,
      actions: {
        canDelete: true,
        canDownload: false,
        canEdit: true
      }
    }
  ];
  public columns: UploadColumn[] = [
    {
      id: 'bestandsnaam',
      header: 'Bestandsnaam',
      actions: {
        canDownload: true
      }
    },
    {
      id: 'id',
      header: 'Id'
    },
    {
      id: 'documentType',
      header: 'Document Type'
    },
    {
      id: 'datum',
      header: 'Datum'
    }
  ];

  constructor(
    private store: Store,
    private actionsService: NgxOvActionsService
  ) {}

  laadLanden() {
    this.store.dispatch(new LoadLandenRequest());
    this.statusLanden$ = this.actionsService.getStatus$('LANDEN');
    this.statusMock$ = this.actionsService.getStatus$('MOCK');
  }

  mockFailure() {
    this.store.dispatch(new LoadMockRequest(false, { testdata: 'test' }));
  }

  resetErrors() {
    this.actionsService.resetErrors();
  }
}
