/**
 * Naric
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.3.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import {BenodigdeDocumentRestDto} from './benodigdeDocumentRestDto';
import {BenodigdeDocumentenSelectiecriteriaRestDto} from './benodigdeDocumentenSelectiecriteriaRestDto';


import {
  AbstractControlOptions,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn
} from '@angular/forms';

export interface IResultaatInfoRestDto {
  benodigde_documenten?: Array<BenodigdeDocumentRestDto>;
  generieke_waarden?: { [key: string]: string; };
  kost?: number;
  looptijd?: number;
  selectie_criteria?: BenodigdeDocumentenSelectiecriteriaRestDto;
}


export class ResultaatInfoRestDto implements IResultaatInfoRestDto {
  benodigde_documenten?: Array<BenodigdeDocumentRestDto>;
  generieke_waarden?: { [key: string]: string; };
  kost?: number;
  looptijd?: number;
  selectie_criteria?: BenodigdeDocumentenSelectiecriteriaRestDto;
}

export class ResultaatInfoRestDtoFormHelper {

  static generateFormWithSwaggerValidators(formBuilder: UntypedFormBuilder, controlsConfig: { [key: string]: any; }, options?: AbstractControlOptions | { [key: string]: any; } | null): UntypedFormGroup {
    if (controlsConfig["benodigde_documenten"] != undefined && !(controlsConfig["benodigde_documenten"] instanceof UntypedFormGroup) && !(controlsConfig["benodigde_documenten"] instanceof UntypedFormArray)) {
      controlsConfig["benodigde_documenten"][1] = controlsConfig["benodigde_documenten"][1].concat(ResultaatInfoRestDtoValidator.benodigde_documenten_swagger_validator());
    }
    if (controlsConfig["generieke_waarden"] != undefined && !(controlsConfig["generieke_waarden"] instanceof UntypedFormGroup) && !(controlsConfig["generieke_waarden"] instanceof UntypedFormArray)) {
      controlsConfig["generieke_waarden"][1] = controlsConfig["generieke_waarden"][1].concat(ResultaatInfoRestDtoValidator.generieke_waarden_swagger_validator());
    }
    if (controlsConfig["kost"] != undefined && !(controlsConfig["kost"] instanceof UntypedFormGroup) && !(controlsConfig["kost"] instanceof UntypedFormArray)) {
      controlsConfig["kost"][1] = controlsConfig["kost"][1].concat(ResultaatInfoRestDtoValidator.kost_swagger_validator());
    }
    if (controlsConfig["looptijd"] != undefined && !(controlsConfig["looptijd"] instanceof UntypedFormGroup) && !(controlsConfig["looptijd"] instanceof UntypedFormArray)) {
      controlsConfig["looptijd"][1] = controlsConfig["looptijd"][1].concat(ResultaatInfoRestDtoValidator.looptijd_swagger_validator());
    }
    if (controlsConfig["selectie_criteria"] != undefined && !(controlsConfig["selectie_criteria"] instanceof UntypedFormGroup) && !(controlsConfig["selectie_criteria"] instanceof UntypedFormArray)) {
      controlsConfig["selectie_criteria"][1] = controlsConfig["selectie_criteria"][1].concat(ResultaatInfoRestDtoValidator.selectie_criteria_swagger_validator());
    }
    if (options) {
      return formBuilder.group(controlsConfig, options);
    }
    return formBuilder.group(controlsConfig);
  }
}

export class ResultaatInfoRestDtoValidator {

  /**
   * Active Validators for benodigde_documenten:
   */
  static benodigde_documenten_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    return validators;
  };

  /**
   * Active Validators for generieke_waarden:
   */
  static generieke_waarden_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    return validators;
  };

  /**
   * Active Validators for kost:
   */
  static kost_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    return validators;
  };

  /**
   * Active Validators for looptijd:
   */
  static looptijd_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    return validators;
  };

  /**
   * Active Validators for selectie_criteria:
   */
  static selectie_criteria_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    return validators;
  };

}



