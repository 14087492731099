<div
  class="c-news-teaser-list"
  (swipeleft)="onSwipeLeft()"
  (swiperight)="onSwipeRight()"
>
  <h2>{{ title }}</h2>
  <div class="c-news-teaser-list__wrapper">
    <div
      class="c-news-teaser-list__scroller"
      [style.width]="newsItems.length + '00%'"
      [style.marginLeft]="'-' + _currentSlide + '00%'"
    >
      <ng-content></ng-content>
    </div>
  </div>

  @if (newsItems && newsItems.length > 1) {
    <div class="c-news-teaser-list__controls">
      <div class="c-news-teaser-list__dots">
        @for (newsItem of newsItems; let i = $index; track trackByFn) {
          <span
            class="c-news-teaser-list__dot"
            [class.c-news-teaser-list__dot-active]="_currentSlide === i"
            (click)="setCurrentSlide(i)"
          >
          </span>
        }
      </div>
    </div>
  }
</div>
