import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core';
import { NgxOvUiConfigService } from '../../../ngx-ov-ui.config.service';
import { FormsModule } from '@angular/forms';

// =============================================================================
// Config
// =============================================================================
@Component({
  selector: 'ngx-ov-toggle-button',
  templateUrl: './toggle-button.component.html',
  standalone: true,
  imports: [FormsModule]
})

// =============================================================================
// Definition
// =============================================================================
export class NgxOvToggleButtonComponent {
  // -------------------------------------------------------------------------
  // private variables
  // -------------------------------------------------------------------------
  private _classes: string;
  private _label: string;
  private _value: any;
  private _selected: boolean;
  private _icon: string;
  private _code: string;

  // -------------------------------------------------------------------------
  // Input variables
  // -------------------------------------------------------------------------

  @Input() set classes(classes: string) {
    this._classes = classes;
  }

  @Input() set label(label: string) {
    this._label = label;
  }

  get value(): any {
    return this._value;
  }

  @Input() set value(value: any) {
    this._value = value;
  }

  get selected(): boolean {
    return this._selected;
  }

  @Input() set selected(selected: boolean) {
    this._selected = selected;
  }

  @Input() set icon(icon: string) {
    this._icon = icon;
  }

  @Input() set code(code: string) {
    this._code = code;
  }

  // -------------------------------------------------------------------------
  // Output variables
  // -------------------------------------------------------------------------
  @Output() toggleButtonClicked: EventEmitter<any> = new EventEmitter();

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  constructor(public config: NgxOvUiConfigService) {}

  // -------------------------------------------------------------------------
  // Events
  // -------------------------------------------------------------------------
  @HostListener('click', ['$event'])
  onClick(e) {
    e.stopPropagation();

    this.toggleButtonClicked.emit(this.value);
  }

  // -------------------------------------------------------------------------
  // Getters
  // -------------------------------------------------------------------------
  get classes(): string {
    return this._classes;
  }

  get label(): string {
    return this._label;
  }

  get icon(): string {
    return this._icon;
  }

  get code(): string {
    return this._code;
  }
}
