import { Component, Input, OnInit } from '@angular/core';
import { NgxOvInputLabelComponent } from '../../forms/input-label/input-label.component';
import { NgTemplateOutlet } from '@angular/common';
import { IConfig, NgxMaskPipe } from 'ngx-mask';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-input-readonly',
  templateUrl: './input-readonly.html',
  standalone: true,
  imports: [NgxOvInputLabelComponent, NgxMaskPipe, NgTemplateOutlet],
  providers: []
})
export class NgxOvInputReadonlyComponent implements OnInit {
  @Input() label: string;
  @Input() value: string | boolean;
  @Input() type: 'TEXT' | 'NUMBER' | 'BOOLEAN' = 'TEXT';
  @Input() readonlyEmptyText: string = '-';
  @Input() maskExpr: string;
  // TODO: upgrade
  @Input() thousandSeparator: Partial<IConfig> = { thousandSeparator: ' ' };
  @Input() prefix: string;
  @Input() suffix: string;

  displayValue;

  ngOnInit() {
    this.displayValue = this.value;
  }
}
