import { Pipe, PipeTransform } from '@angular/core';
import * as R from 'ramda';

//TODO: testen schrijven
// https://mvgond.atlassian.net/browse/OAM-1007
@Pipe({
    name: 'extendedBindLabelValue',
    standalone: true
})
export class ExtendedBindLabelValuePipe implements PipeTransform {
  transform(item: any, extendedBindLabel: string): string | boolean {
    const props = extendedBindLabel.split('.');
    const getValue = R.path(props);
    return getValue(item);
  }
}
