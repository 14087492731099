<ngx-ov-modal
  [id]="modalId"
  [title]="'Wilt u verder gaan?'"
  type="transactional"
  (confirm)="bevestig()"
  (closePopup)="annuleer()"
  (cancel)="annuleer()"
  size="medium"
>
  <ngx-ov-bevestiging-melding></ngx-ov-bevestiging-melding>
</ngx-ov-modal>
