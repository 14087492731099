import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import * as R from 'ramda';
import { IPager } from '../services/pager.service';
import { trackItemByIndex } from '../../../../../helper';
import { FormsModule } from '@angular/forms';

export interface PageSizeOption {
  value: number;
  disabled?: boolean;
}

@Component({
  selector: 'ngx-ov-pager-page-size',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="flex c-pager__pagesize">
      <div>Rijen:</div>
      &nbsp;
      <select
        #pagesize
        (change)="changePageSize(pagesize.value)"
        [ngModel]="_currentPageSize"
      >
        @for (size of pageSizes; track trackByFn) {
          <option [value]="size.value">
            {{ size.value }}
          </option>
        }
      </select>
    </div>
  `,
  standalone: true,
  imports: [FormsModule]
})
export class NgxOvPagerPageSizeComponent implements OnInit, OnChanges {
  _currentPageSize;
  @Input() pager: IPager;
  @Input() pageSizes: PageSizeOption[] = [
    { value: 5, disabled: false },
    { value: 10, disabled: false },
    { value: 50, disabled: false },
    { value: 100, disabled: false }
  ];
  @Output() setPageSize = new EventEmitter();

  _pageSizes: any[];
  trackByFn = trackItemByIndex;

  ngOnInit() {
    this.initPageSize();
  }

  ngOnChanges() {
    const largestindex = R.findIndex(
      (size) => size.value >= this.pager.totalItems
    )(this.pageSizes);
    this._pageSizes = this.pageSizes.map((size, index) => {
      const newsize = size;
      if (index > largestindex) {
        newsize.disabled = true;
      } else {
        newsize.disabled = false;
      }
      return newsize;
    });
  }

  changePageSize(size: any) {
    this.setPageSize.emit(size);
  }

  initPageSize() {
    const filtered = this.pageSizes.filter(
      (x: PageSizeOption) => x.value >= this.pager.pageSize
    );
    if (Array.isArray(filtered) && filtered.length) {
      this._currentPageSize = filtered[0].value;
    }
  }
}
