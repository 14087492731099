import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxOvCardComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/content-blokken/card/card.component';
import { NgxOvAlertComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/meldingen/alert/alert.component';

@Component({
    selector: 'app-spacing-landingpage',
    templateUrl: './page-spacing.component.html',
    standalone: true,
    imports: [NgxOvAlertComponent, NgxOvCardComponent]
})
export class PageSpacingComponent {
  constructor(private router: Router) {}
}
