<div class="grid grid-cols-12 gap-medium">
  <div class="col-span-12">
    <p>Uw hoogste opleiding is: {{ opleiding }}</p>
  </div>

  <div class="col-span-12">
    @if (toelagen && toelagen.length) {
      <p>U selecteerde volgende toelagen:</p>
      <ul [attr.data-cy]="'wizard-toelagen'">
        @for (toelage of toelagen; track trackByFn) {
          <li>{{ toelage }}</li>
        }
      </ul>
    } @else {
      <p>U hebt geen toelagen geselecteerd</p>
    }
  </div>

  <div class="col-span-12">
    <p>U zal 2000€/maand verdienen</p>
  </div>
</div>
