import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

// =============================================================================
// Component
// =============================================================================
@Component({
    selector: 'ngx-ov-form-message',
    templateUrl: './form-message.html',
    standalone: true
})

// =============================================================================
// Definition
// =============================================================================
export class NgxOvFormMessageComponent implements OnChanges {
  _singleMessage;
  // -------------------------------------------------------------------------
  // Variables
  // -------------------------------------------------------------------------
  @Input() classes: string;
  @Input() messages: Array<string> | string;
  @Input() isAnnotation: boolean;
  @Input() isError: boolean;
  @Input() isSuccess: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.messages) {
      this._singleMessage = this.createSingleMessage();
    }
  }

  // -------------------------------------------------------------------------
  // Events
  // -------------------------------------------------------------------------

  createSingleMessage() {
    if (this.messages instanceof Array && this.messages.length > 0) {
      return this.messages.slice(0, 1);
    }

    return this.messages;
  }
}
