import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })

/**
 * Configuratie kan uit for root applicatie, component of default komen
 */
export class UploadService {
  /**NgxOvUiServiceConfig
   * Based on extension (e.g. .png) check if file is of correct file type.
   * @param file The file to be checked.
   * @param acceptedFileTypes List of accepted files e.g. [png, jpg, pdf]
   * @returns True if file of a valid type.
   */
  public isCorrectFileType(file: File, acceptedFileTypes?: string[]): boolean {
    const extension = this.getFileExtension(file.name);

    if (extension === '') {
      return false;
    }

    if (!acceptedFileTypes || acceptedFileTypes.length == 0) {
      return true;
    }

    let typesUpper = acceptedFileTypes.map((t: string) => t.toUpperCase());

    return typesUpper && typesUpper.includes(extension.toUpperCase());
  }

  /**
   * Validate file a file against a set of requirements,
   * @param file File to be validated
   * @param acceptedFileTypes accepted file types
   * @param maxLengthFileName maximum file name length
   * @param maxFileSizeInMB maximum file size
   * @returns true if file has valid extension, size and filename length.
   */
  validateFile(
    file: File,
    acceptedFileTypes?: string[],
    maxLengthFileName?: number,
    maxFileSizeInMB?: number
  ): string {
    if (file.name.length > maxLengthFileName) {
      return 'FILENAME_TOO_LONG';
    }
    if (!this.isCorrectFileType(file, acceptedFileTypes)) {
      return 'WRONG_FILETYPES';
    }
    if (!file.size) {
      return 'EMPTY_FILE';
    }
    if (!this.checkFileSizeOk(file.size, maxFileSizeInMB)) {
      return 'FILE_TOO_BIG';
    }
    return null;
  }

  checkFileSizeOk(fileSizeInBytes: number, maxFileSizeInMBValue?: number) {
    if (!maxFileSizeInMBValue) {
      return true;
    }
    const fileSizeInMegaBytes = fileSizeInBytes / 1000000;
    return fileSizeInMegaBytes <= maxFileSizeInMBValue;
  }

  getFileExtension(fileName: string): string {
    if (
      fileName.length === 0 ||
      fileName.lastIndexOf('.') == -1 ||
      fileName.endsWith('.')
    ) {
      return '';
    }
    return '.' + fileName.split('.').pop();
  }
}
