import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-page-translate',
  templateUrl: './page-translate.component.html',
  imports: [TranslateModule],
  standalone: true
})
export class PageTranslateComponent {}
