import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output
} from '@angular/core';
import { NgxOvComponentBaseClass } from '../../ngx-ov-component-base-class';
import { TranslationsService } from '../../../services/translations.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxOvExtraInfoComponent } from '../../forms/extra-info/extra-info.component';
import { ClickEqualsEnterDirective } from '../../../directives/click-equals-enter';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-profile',
  templateUrl: './profile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ClickEqualsEnterDirective, NgxOvExtraInfoComponent]
})
export class NgxOvProfileComponent extends NgxOvComponentBaseClass {
  // -------------------------------------------------------------------------
  // public variables
  // -------------------------------------------------------------------------
  public imageAlt: string;
  // -------------------------------------------------------------------------
  // private variables
  // -------------------------------------------------------------------------
  private _name: string;
  private _firstname: string;
  private _imageToShow;

  // -------------------------------------------------------------------------
  // Output variables
  // -------------------------------------------------------------------------
  @Output() profileEdit = new EventEmitter();

  // -------------------------------------------------------------------------
  // Getters & Setters
  // -------------------------------------------------------------------------
  @Input() extraInfo = '';
  @Input() textUpdatePicture = 'Profielfoto wijzigen';
  @Input() textLabelName = 'Naam';
  @Input() textLabelFirstname = 'Voornaam';

  get name(): string {
    return this._name;
  }
  @Input() set name(name: string) {
    this._name = name;
  }

  get firstname(): string {
    return this._firstname;
  }
  @Input() set firstname(firstname: string) {
    this._firstname = firstname;
  }

  get imageToShow(): string {
    return this._imageToShow;
  }
  @Input() set imageToShow(imageToShow: string) {
    this._imageToShow = imageToShow;
  }
  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  constructor(
    public translationsService: TranslationsService,
    @Optional() public translateService: TranslateService
  ) {
    super(translationsService, translateService);
  }
  setLabels(): void {
    this.imageAlt = this.getTranslation('DIVERSE.PROFILE', 'IMAGE');
  }
}
