<div class="c-input-validation">
  <p
    class="c-input-validation__error-message u-text-bold {{ classes }}"
    [class.is-annotation]="isAnnotation"
    [class.is-error]="isError"
    [class.is-success]="isSuccess"
  >
    {{ _singleMessage }}
  </p>
</div>
