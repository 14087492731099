import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild
} from '@angular/core';
import {
  FormsModule,
  NG_ASYNC_VALIDATORS,
  NG_VALIDATORS,
  NgControl,
  NgModel
} from '@angular/forms';
import { FormsBase } from '../forms-base';
import { TranslationsService } from '../../../services/translations.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { NgxOvInputLabelComponent } from '../input-label/input-label.component';

let identifier = 0;

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-toggle',
  templateUrl: './toggle.html',
  standalone: true,
  imports: [NgxOvInputLabelComponent, FormsModule]
})
export class NgxOvToggleComponent
  extends FormsBase<boolean>
  implements OnDestroy, OnInit, OnChanges
{
  // -------------------------------------------------------------------------
  // Variables
  // -------------------------------------------------------------------------
  @Input() isLarge = false;
  @Input() isDisabled = false;
  @Input() label: string;
  @Input() isReadonly = false;

  @Input() toggledReadOnlyText;
  @Input() notToggledReadOnlyText;

  @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  // -------------------------------------------------------------------------
  // Copy variables
  // -------------------------------------------------------------------------
  public toggledReadOnlyTextCopy: string;
  public notToggledReadOnlyTextCopy: string;

  @ViewChild(NgModel, { static: true }) model: NgModel;
  public identifier = `form-toggle-${(identifier += 1)}`;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
    @Self() @Optional() ngControl: NgControl,
    translationsService: TranslationsService,
    @Optional() translateService: TranslateService
  ) {
    super(
      validators,
      asyncValidators,
      ngControl,
      translateService,
      translationsService
    );
  }

  // -------------------------------------------------------------------------
  // Lifecycle methods
  // -------------------------------------------------------------------------
  ngOnInit() {
    this.value = this.isDisabled ? false : this.value;
    this.getCopy();
    if (this.translateService) {
      this.translateService.onLangChange
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.getCopy();
        });
    }
  }

  ngOnChanges() {
    super.init();
  }

  // -------------------------------------------------------------------------
  // Translation
  // -------------------------------------------------------------------------
  getCopy() {
    this.toggledReadOnlyTextCopy =
      this.toggledReadOnlyText ??
      this.getNgxOvUiTranslation('READONLY.CHECKED_TEXT');
    this.notToggledReadOnlyTextCopy =
      this.notToggledReadOnlyText ??
      this.getNgxOvUiTranslation('READONLY.NOT_CHECKED_TEXT');
  }

  getNgxOvUiTranslation(code: string) {
    const translationKey = `FORMS.TOGGLE.${code.toUpperCase()}`;
    return this.translationsService.getDefaultComponentTranslation(
      translationKey
    );
  }
}
