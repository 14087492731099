export interface NormalizedData<T> {
  [uuid: string]: T;
}

export interface NgxOvStoreError {
  type: string;
  errorCode: string;
}

// eslint-disable-next-line
export function normalizeArray<T>(array: Array<T>, indexKey: keyof T) {
  const normalizedObject: any = {};
  for (let element of array) {
    const key = element[indexKey];
    if (!key) {
      return null;
    }
    normalizedObject[key] = element;
  }
  return normalizedObject as { [key: string]: T };
}

// eslint-disable-next-line
export function deNormalizeArray<T>(object: Object) {
  const deNormalizedArray: any = [];

  for (const item in object) {
    if (item) {
      deNormalizedArray.push(object[item]);
    }
  }
  return deNormalizedArray;
}
