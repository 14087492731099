import { CommonModule } from '@angular/common';
import {
  importProvidersFrom,
  ModuleWithProviders,
  NgModule
} from '@angular/core';
import { StoreIsLoadingComponent } from './componenten/loading/store-loading.component';
import { NgxOvStoreAlertComponent } from './componenten/alert/store-alert.component';

import { NgxOvButtonComponent } from '../../components/default-layout-blokken/button/button.component';
import { StoreIsLoadingDirective } from './directives/store.loading.directive';
import { StoreIsSavingDirective } from './directives/store.saving.directive';
// eslint-disable-next-line import/no-extraneous-dependencies
import { NGXS_PLUGINS, NgxsModule } from '@ngxs/store';
import { actionsPlugin, NgxOvActionsState } from './actions';

@NgModule({
  imports: [
    CommonModule,
    NgxOvButtonComponent,
    StoreIsLoadingComponent,
    NgxOvStoreAlertComponent,
    StoreIsLoadingDirective,
    StoreIsSavingDirective
  ],
  exports: [
    StoreIsLoadingComponent,
    NgxOvStoreAlertComponent,
    StoreIsLoadingDirective,
    StoreIsSavingDirective
  ]
})
export class NgxOvStoreModule {
  static forRoot(): ModuleWithProviders<NgxOvStoreModule> {
    return {
      ngModule: NgxOvStoreModule,
      providers: [
        importProvidersFrom(NgxsModule.forFeature([NgxOvActionsState])),
        {
          provide: NGXS_PLUGINS,
          useValue: actionsPlugin,
          multi: true
        }
      ]
    };
  }
}
