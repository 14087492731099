@if (type === 'TEXT') {
  <div>
    @if (label) {
      <ngx-ov-input-label [text]="label"></ngx-ov-input-label>
    }
    <div
      class="u-form-readonly__input-readonly c-input-field__input-readonly"
      data-cy="readonly-value"
    >
      @if (value) {
        @if (maskExpr) {
          <ng-container
            *ngTemplateOutlet="valueWithMaskPrefixSuffix"
          ></ng-container>
        } @else {
          <ng-container
            *ngTemplateOutlet="valueWithPrefixSuffix"
          ></ng-container>
        }
      } @else {
        <ng-container *ngTemplateOutlet="noValue"></ng-container>
      }
    </div>
  </div>
}

@if (type === 'NUMBER') {
  <div>
    @if (label) {
      <ngx-ov-input-label [text]="label"></ngx-ov-input-label>
    }
    <div
      class="u-form-readonly__input-readonly c-input-field__input-readonly"
      data-cy="readonly-value"
    >
      @if (value !== null) {
        @if (maskExpr) {
          <ng-container
            *ngTemplateOutlet="valueWithMaskPrefixSuffix"
          ></ng-container>
        } @else {
          <ng-container
            *ngTemplateOutlet="valueWithPrefixSuffix"
          ></ng-container>
        }
      } @else {
        <ng-container *ngTemplateOutlet="noValue"></ng-container>
      }
    </div>
  </div>
}

@if (type === 'BOOLEAN') {
  <ng-container>
    <div class="c-checkbox__icon-readonly">
      @if (value) {
        <i
          data-testid="iconCheck"
          class="fa fa-check c-checkbox__icon-check-readonly"
          title="Checked"
          aria-hidden="true"
        ></i>
        <span class="sr-only">checked</span>
      }

      @if (!value) {
        <i
          data-testid="iconXmark"
          class="fa fa-xmark c-checkbox__icon-xmark-readonly"
          title="Not Checked"
          aria-hidden="true"
        ></i>
        <span class="sr-only">not checked</span>
      }
      <ngx-ov-input-label
        [text]="label"
        [isReadonly]="true"
      ></ngx-ov-input-label>
    </div>
  </ng-container>
}

<ng-template #noValue> {{ readonlyEmptyText }}</ng-template>

<ng-template #valueWithMaskPrefixSuffix let-context>
  {{ prefix }}{{ prefix ? ' ' : ''
  }}{{ displayValue | mask: maskExpr : thousandSeparator }}{{ suffix ? ' ' : ''
  }}{{ suffix }}
</ng-template>
<ng-template #valueWithPrefixSuffix>
  {{ prefix }}{{ prefix ? ' ' : '' }}{{ value }}{{ suffix ? ' ' : ''
  }}{{ suffix }}
</ng-template>
