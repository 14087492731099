<div class="c-tabs">
  <!-- Navigation -->
  <ul class="c-tabs__navigation" role="tablist" [attr.aria-labelledby]="tabsId">
    @for (tab of tabs; let i = $index; track trackByFn) {
      <li
        class="c-tabs__navigation-item"
        role="tab"
        [attr.aria-selected]="tab.isActive"
        [id]="'tab-' + tab.tabId"
        [attr.aria-controls]="tab.tabId"
        [attr.tabindex]="i < 1 ? null : -1"
      >
        <div
          (click)="onSelectTab(tab)"
          class="c-tabs__navigation-link"
          [class.is-active]="tab.isActive"
          [attr.data-cy]="'tab-' + tab.dataCy"
        >
          @if (!tab.titleContent) {
            <ng-container>
              {{ tab.tabTitle }}
            </ng-container>
          } @else {
            <ng-container>
              <ng-template [cdkPortalOutlet]="tab.titleContent"></ng-template>
            </ng-container>
          }
        </div>
      </li>
    }
  </ul>

  @if (showContent) {
    <!-- Content -->
    <div class="c-tabs__content" role="tabpanel">
      <ng-content></ng-content>
    </div>
  }
</div>
