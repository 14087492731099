<div class="container grid grid-cols-12 my-10">
  <div class="col-span-full">
    @if (!bestellingGeplaatst) {
      <mat-stepper #stepper [linear]="true">
        <mat-step
          [label]="'Gerecht'"
          [completed]="geefFormGroup('gerecht').valid"
          [editable]="true"
        >
          <app-stepper-form-kies-gerecht
            [gerecht]="geefFormGroup('gerecht')"
          ></app-stepper-form-kies-gerecht>
        </mat-step>

        <mat-step
          [label]="'Extra'"
          [completed]="stepper.selectedIndex >= 1"
          [editable]="true"
        >
          <app-stepper-form-kies-extra
            [extra]="geefFormGroup('extra')"
          ></app-stepper-form-kies-extra>
        </mat-step>

        <mat-step
          [label]="'Levering'"
          [completed]="stepper.selectedIndex >= 2"
          [editable]="true"
        >
          <app-stepper-form-kies-levermethode
            [levering]="geefFormGroup('levering')"
            (levermethodeGekozen)="kiesLeverMethode($event)"
          ></app-stepper-form-kies-levermethode>
        </mat-step>

        <mat-step
          [label]="'Bon'"
          [completed]="geefFormGroup('bon').valid && stepper.selectedIndex >= 3"
          [editable]="true"
        >
          <app-stepper-form-upload-bon
            [bon]="geefFormGroup('bon')"
          ></app-stepper-form-upload-bon>
        </mat-step>

        <mat-step
          [label]="'Betaling'"
          [completed]="
            geefFormGroup('betaalmethode').valid && stepper.selectedIndex >= 4
          "
          [editable]="true"
        >
          <app-stepper-form-kies-betaalmethode
            [betaalmethode]="geefFormGroup('betaalmethode')"
          ></app-stepper-form-kies-betaalmethode>
        </mat-step>

        <mat-step [label]="'Bevestiging'">
          <app-stepper-form-overzicht
            [bestelling]="bestelling"
          ></app-stepper-form-overzicht>
        </mat-step>
      </mat-stepper>

      <div class="u-position-btn-prim-sec" style="padding: 0 24px 24px 24px">
        @if (stepper.selectedIndex !== 0) {
          <ngx-ov-button
            [attr.data-cy]="'knop-vorige'"
            [label]="'Vorige'"
            [isSecondary]="true"
            (buttonClick)="onNavigeerStepper(-1)"
          ></ngx-ov-button>
        }

        <ngx-ov-button
          [attr.data-cy]="'knop-volgende'"
          [label]="
            stepper.selectedIndex !== stepper.steps?.length - 1
              ? 'Volgende'
              : 'Plaats bestelling'
          "
          [isDisabled]="isButtonVolgendeDisabled(stepper.selectedIndex)"
          (buttonClick)="
            onNavigeerStepper(
              1,
              stepper.selectedIndex === stepper.steps?.length - 1
            )
          "
        ></ngx-ov-button>
      </div>

      <div>
        {{ bestelling.getRawValue() | json }}
      </div>
    } @else {
      <div class="mb-medium">
        <ngx-ov-alert [title]="'Bestelling geplaatst'" [type]="'success'">
          <p>
            Uw bestelling werd geplaatst. De referentie van deze bestelling is
            A001.
          </p>
        </ngx-ov-alert>
      </div>

      <div class="u-position-btn-prim-sec">
        <ngx-ov-button
          [attr.data-cy]="'knop-nieuwe-bestelling'"
          [label]="'Plaats nieuwe bestelling'"
          (buttonClick)="onResetFormEnStepper()"
        ></ngx-ov-button>
      </div>
    }
  </div>
</div>
