export const componentTranslationsFr = {
  DATA_VISUALISATIE: {
    CONTROL_PANEL: {
      HEADER: {
        CLOSE: 'Fermer'
      },
      ITEM: {
        HEADER: {
          CLOSE: 'Fermer',
          OPEN: 'Ouvrir'
        },
        TOOLTIP_ICON: {
          TITLE: "Cliquez sur l'info-bulle d'informations"
        }
      }
    }
  },
  CONTENT_BLOKKEN: {
    CARD: {
      ICON: {
        CLOSE: 'Fermer la carte',
        OPEN: 'Ouvrir la carte'
      }
    },
    INFO_BLOCK: {
      CLOSED: "Ouvrir le bloc d'informations",
      OPEN: "Fermer le bloc d'informations"
    },
    PROGRESS_BLOCK: {
      ICON: {
        IN_PROGRESS: 'En cours',
        COMPLETED: 'Complété'
      }
    }
  },
  DEFAULT_LAYOUT_BLOKKEN: {
    BUTTON: {
      LOADING: 'Chargement'
    },
    CONTACT_CARD: {
      CONTACT_LABEL: 'Contact',
      TELEPHONE_LABEL: 'Téléphone',
      EMAIL_LABEL: 'E-mail',
      WEBSITE_LABEL: 'Site internet'
    },
    COOKIEBAR: {
      COOKIES: {
        CONTENT: {
          TITLE:
            'utilise des cookies pour améliorer votre expérience de navigation',
          TEXT: `Certains cookies sont nécessaires au fonctionnement optimal du site web. 
                'D’autres cookies sont facultatifs et sont utilisés pour améliorer votre expérience de navigation sur ce' 
                site web. En cliquant sur « Accepter tous les cookies », vous acceptez également les cookies facultatifs. 
                Si vous préférez choisir vous-même les cookies que vous acceptez, cliquez sur « Gérer mes préférences en 
                matière de cookies ». Vous trouverez d’autres informations dans 
                la <a target="_blank" href=https://www.onderwijs.vlaanderen.be/sites/default/files/2021-07/Versie%202.0%20Privacyverklaring%20AHOVOKS.pdf>déclaration 
                de confidentialité</a>- et la <a target="_blank" href=https://onderwijs.vlaanderen.be/sites/default/files/2021-07/Cookiebeleid%20AHOVOKS.pdf>politique en 
                matière de cookies</a> de Vlaanderen.be.`,
          SUBTITLE: 'Gérer mes préférences en matière de cookies,',
          ACCEPT_ALL_BUTTON: 'Accepter tous les cookies',
          ACCEPT_MANDATORY_BUTTON:
            'Accepter uniquement les cookies indispensables',
          SAVE_BUTTON: 'Enregistrer mes préférences en matière de cookies',
          ICON: {
            CLOSED: 'Fermer mes préférences en matière de cookies',
            OPEN: 'Ouvrir mes préférences en matière de cookies'
          }
        },
        TYPES: {
          MANDATORY: {
            TITLE: 'Cookies indispensables',
            SUBTITLE: `Ces cookies sont nécessaires au bon fonctionnement du site web et enregistrent vos préférences en matière de cookies. Vous pouvez ainsi conserver les publications sélectionnées dans un panier et mener à bonne fin le processus de commande et de paiement. Vous pouvez également vous connecter en toute sécurité et examiner vos données dans votre profil Citoyen personnel.`
          },
          ANALYTICAL: {
            TITLE: 'Cookies analytiques',
            SUBTITLE:
              'Ces cookies mesurent le nombre de visiteurs sur le site web et donnent une vue d’ensemble de votre visite. Toutes les données collectées sont anonymes.'
          }
        }
      }
    },
    LANGUAGE_SWITCH: {
      HEADER_TEXT: 'Choisissez une langue'
    }
  },
  DIVERSE: {
    PILL: {
      CLOSE: 'Fermer'
    },
    PROFILE: {
      IMAGE: 'Photo de profil'
    }
  },
  FORMS: {
    CHECKBOX: {
      READONLY: {
        CHECKED_TEXT: 'Oui',
        NOT_CHECKED_TEXT: 'Non'
      }
    },
    DATEPICKER_INPUTS: {
      DAY: 'Jour',
      MONTH: 'Mois',
      YEAR: 'An',
      INVALID_DATE: 'Date incorrecte',
      DATE_TOO_EARLY: 'La date saisie doit venir après {minDate}',
      DATE_TOO_LATE: 'La date saisie doit être avant {maxDate}',
      INVALID_DATE_DAY: 'Jour incorrect',
      INVALID_DATE_MONTH: 'Mois incorrect'
    },
    OV_STEPPER: {
      STEP: {
        ICON: {
          CLOSE: 'Fermer étape',
          OPEN: 'Ouvrir étape'
        },
        NUMBER: {
          COMPLETED: 'Étape terminée'
        }
      }
    },
    SELECT: {
      CLOSE_TEXT: 'Fermer',
      NOT_FOUND_TEXT: 'Aucun élément trouvé',
      TYPE_TO_SEARCH_TEXT: 'Tapez pour rechercher'
    },
    TOGGLE: {
      READONLY: {
        CHECKED_TEXT: 'Oui',
        NOT_CHECKED_TEXT: 'Non'
      }
    },
    THREE_WAY_TOGGLE: {
      OFF: 'Désactivé',
      ON: 'Activé',
      NEUTRAL: 'Neutre'
    },
    UPLOAD: {
      EMPTY_TABLE_TEXT: "Aucun document n'a encore été téléchargé",
      DOWNLOAD_ACTION_NAME: 'télécharger le document',
      DELETE_ACTION_NAME: 'supprimer le document',
      EDIT_ACTION_NAME: 'modifier le document',
      UPLOAD_ACTION_NAME: 'Ajout de fichiers',
      UPLOADED_DOCUMENTS: 'Documents téléchargé',
      DRAG_DROP_ACTION_NAME: 'Faites glisser et déposez des fichiers ou',
      DRAG_DROP_SELECT_ACTION_NAME: 'Sélectionnez des fichiers',
      LOADING_TEXT: 'chargement en cours',
      TOO_MANY_FILES: 'Nombre maximal de fichiers téléchargés',
      NOT_ALLOW_MULTIPLE: 'Un seul fichier peut être téléchargé à la fois.',
      FILENAME_TOO_LONG:
        'Le nom du fichier doit comporter un maximum de {maxLengthFileName} caractères.',
      WRONG_FILETYPES: 'Le fichier doit être de type {fileTypes}',
      FILE_TOO_BIG:
        'Le fichier ne doit pas être plus grand que {maxFileSizeInMB}MB',
      EMPTY_FILE: 'Le fichier est vide',
      ROW: {
        TOGGLE: {
          CLOSE: 'Fermer la ligne de téléchargement',
          OPEN: 'Ouvrir la ligne de téléchargement'
        }
      }
    },
    DOCUMENT_VIEWER: {
      DOWNLOAD_ACTION_NAME: 'Télécharger',
      ROTATE_LEFT_ACTION_NAME: 'Tourner à gauche',
      ROTATE_RIGHT_ACTION_NAME: 'Tourner à droite'
    }
  },
  FUNDAMENTALS: {
    404: {
      CTA_LABEL: "Page d'accueil"
    },
    STORE: {
      LOADING: 'Chargement...',
      ERROR_API_GET: 'Erreur lors du chargement des données.',
      ERROR_API_PUT: "Erreur lors de l'enregistrement des données.",
      ERROR_API_DELETE: 'Erreur lors de la suppression des données.'
    }
  },
  MELDINGEN: {
    MODAL: {
      FOOTER: {
        CANCEL_TEXT: 'Annuler',
        CONFIRM_TEXT: 'Confirmer'
      },
      HEADER: {
        CLOSE: 'Fermer la fenêtre contextuelle'
      }
    }
  },
  NAVIGATION: {
    ARROW_NAVIGATION: {
      NEXT: 'Au suivant',
      PREVIOUS: 'Au précédent'
    },
    SIDE_NAVIGATION: {
      ITEM: {
        TOGGLE_ICON: {
          CLOSE: "Fermer l'élément de navigation",
          OPEN: "Ouvrir l'élément de navigation"
        }
      },
      TOGGLE_ICON: {
        CLOSE: 'Ouvrur la navigation',
        OPEN: 'Fermer la navigation'
      }
    }
  },
  PREVIEW_DEVICE: {
    DESKTOP: 'Appareil : ordinateur de bureau',
    MOBILE: 'Appareil : tablette',
    TABLET: 'Appareil : mobile'
  },
  ERRORS: {
    FORM: {
      CONTROL: {
        EMAIL: 'Adresse e-mail invalide',
        REQUIRED: 'Champs requis',
        PATTERN: 'Forme de modèle invalide',
        MINLENGTH: 'Trop peu de caractères',
        MAXLENGTH: 'Trop de caractères',
        LATINSCRIPTVALIDATIONFAILED: 'Seulement caractères latins est autorisée'
      }
    }
  }
};
