import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { IPager } from './services/pager.service';
import { NgxOvPagerNavigationComponent } from './components/pager-navigation.component';
import { NgxOvPagerInfoComponent } from './components/pager-pageinfo.component';
import { NgxOvPagerPageSizeComponent } from './components/pager-pagesize.component';

@Component({
  selector: 'ngx-ov-ui-pager',
  templateUrl: './pager.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgxOvPagerPageSizeComponent,
    NgxOvPagerInfoComponent,
    NgxOvPagerNavigationComponent
  ]
})
export class NgxOvUiPagerComponent {
  @Input() pager: IPager;
  @Input() pageSizes: any[] = [
    { value: 5, disabled: false },
    { value: 10, disabled: false },
    { value: 50, disabled: false },
    { value: 100, disabled: false }
  ];

  @Input() withRows = false;
  @Input() withInfo = false;
  @Input() withDetailedNavigation = false;

  @Output() setPage: EventEmitter<number> = new EventEmitter();
  @Output() setPageSize: EventEmitter<number> = new EventEmitter();
}
