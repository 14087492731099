import {
  AfterViewInit,
  Component,
  Directive,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { CommonModule } from '@angular/common';
import { NgxOvActionsService } from '../../actions/actions.service';
import { NgxOvAlertComponent } from '../../../../components/meldingen/alert/alert.component';
import { NgxOvLoaderComponent } from '../../../../components/default-layout-blokken/loader/loader.component';
import { StoreItemStatus } from '../../store.model';
import { NgxOvComponentBaseClass } from '../../../../components/ngx-ov-component-base-class';
import { TranslationsService } from '../../../../services/translations.service';
import { NgxOvUiService } from '../../../ui/ui.service';
import { ActivatedRoute } from '@angular/router';

@Directive({
  selector: '[loadingHost]',
  standalone: true
})
export class LoadingHostDirective {
  constructor(public viewContainerRef: ViewContainerRef) {}
}

@Component({
  selector: 'ngx-ov-store-isloading',
  templateUrl: './store-loading.component.html',
  providers: [NgxOvActionsService],
  standalone: true,
  imports: [
    CommonModule,
    LoadingHostDirective,
    NgxOvLoaderComponent,
    NgxOvAlertComponent
  ]
})
export class StoreIsLoadingComponent
  extends NgxOvComponentBaseClass
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() key: string | string[];
  @Input() showError = true;
  @Input() loadingMessage: string;
  @Input() errorLoadingMessage: string;
  @Input() alertClasses: string;
  @Input() loaderClasses: string;
  @Input() uiContext: any;
  @ViewChild(LoadingHostDirective, { static: true })
  loadingHost: LoadingHostDirective;

  status$: Observable<StoreItemStatus>;

  // -------------------------------------------------------------------------
  // Text copy variables
  // -------------------------------------------------------------------------
  public loadingCopy;
  public errorLoadingCopy;

  public destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private actionsService: NgxOvActionsService,
    private viewContainerRef: ViewContainerRef,
    public translationsService: TranslationsService,
    private route: ActivatedRoute,
    @Optional() public translateService: TranslateService,
    @Optional() public uiService: NgxOvUiService
  ) {
    super(translationsService, translateService);
  }

  ngOnInit() {
    if (this.uiService && this.uiContext) {
      this.uiService.setContext(this.uiContext);
    }
    this.status$ = this.actionsService.getStatus$(this.key);
    this.setLabels();
    if (this.translateService) {
      this.translateService.onLangChange
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.setLabels();
        });
    }
  }

  // -------------------------------------------------------------------------
  // Translation
  // -------------------------------------------------------------------------
  setLabels() {
    const prefix = 'FUNDAMENTALS.STORE';
    this.loadingCopy =
      this.loadingMessage ?? this.getTranslation(prefix, 'LOADING');
    this.errorLoadingCopy =
      this.errorLoadingMessage ?? this.getTranslation(prefix, 'ERROR_API_GET');
  }

  ngAfterViewInit() {
    if (this.uiService) {
      this.uiService.laadUIElementen();
    }
  }
}
