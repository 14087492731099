<div class="c-no-data">
  <div class="c-no-data__image"></div>
  <div class="c-no-data__content">
    <div class="c-no-data__title">{{ title }}</div>
    <div class="c-no-data__subtitle">{{ subtitle }}</div>

    @if (!!linkLabelText) {
      <div class="c-no-data__cta">
        <ngx-ov-button
          [label]="linkLabelText"
          [isText]="true"
          (buttonClick)="linkClicked.emit()"
        ></ngx-ov-button>
      </div>
    }
  </div>
</div>
