import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup
} from '@angular/forms';
import { BehaviorSubject, timer } from 'rxjs';
import { trajecten, TrajectOutputRestDto } from 'src/app/shared/cresco-data';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxOvButtonComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/button/button.component';
import { NgxOvAlertComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/meldingen/alert/alert.component';
import { PortalSearchResultFooterDirective } from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/expansion-panel/expansion-panel-item/portal-footer.directive';
import { PortalRightContentDirective } from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/expansion-panel/expansion-panel-item/portal-right-content.directive';
import { PortalTitleContentDirective } from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/expansion-panel/expansion-panel-item/portal-title-content.directive';
import { NgxOvExpansionPanelItemComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/expansion-panel/expansion-panel-item/expansion-panel-item.component';
import { PortalExpansionPanelListEmptyStateDirective } from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/expansion-panel/expansion-panel-list-empty-state.directive';
import { NgxOvExpansionPanelListComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/expansion-panel/expansion-panel-list.component';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ClickEqualsEnterDirective } from '../../../../projects/ngx-ov-ui/src/lib/directives/click-equals-enter';
import { NgxOvLinkComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/link/link.component';
import { PortalSearchButtonContainerDirective } from '../../../../projects/ngx-ov-ui/src/lib/fundamentals/search/portals/portal-search-button-container.directive';
import { PortalSearchResultFilterDirective } from '../../../../projects/ngx-ov-ui/src/lib/fundamentals/search/portals/portal-search-result-filter.directive';
import { NgxOvCheckboxComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/forms/checkbox/checkbox-item/checkbox.component';
import { NgxOvInputFieldComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/forms/input-field/input-field.component';
import { NgxOvControlPanelItemComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/control-panel/control-panel-item/control-panel-item.component';
import { PortalSearchFilterDirective } from '../../../../projects/ngx-ov-ui/src/lib/fundamentals/search/portals/portal-search-filter.directive';
import { NgxOvSearchComponent } from '../../../../projects/ngx-ov-ui/src/lib/fundamentals/search/search.component';
import { NgxOvSelectComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/forms/select/select.component';
import { trackItemByIndex } from '../../../../projects/ngx-ov-ui/src/lib/helper';

import {
  NgxOvCardComponent,
  NgxOvNoDataComponent
} from 'projects/ngx-ov-ui/src/public-api';
import { PortalSearchInfoDirective } from '../../../../projects/ngx-ov-ui/src/lib/fundamentals/search/portals/portal-search-info.directive';

// =============================================================================
// Config
// =============================================================================
@Component({
  selector: 'page-search',
  templateUrl: './page-search.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgxOvSearchComponent,
    PortalSearchFilterDirective,
    PortalSearchInfoDirective,
    NgxOvControlPanelItemComponent,
    NgxOvInputFieldComponent,
    NgxOvCheckboxComponent,
    PortalSearchResultFilterDirective,
    NgxOvSelectComponent,
    PortalSearchButtonContainerDirective,
    NgxOvLinkComponent,
    ClickEqualsEnterDirective,
    NgxOvExpansionPanelListComponent,
    PortalExpansionPanelListEmptyStateDirective,
    NgTemplateOutlet,
    NgxOvExpansionPanelItemComponent,
    PortalTitleContentDirective,
    PortalRightContentDirective,
    PortalSearchResultFooterDirective,
    NgxOvAlertComponent,
    NgxOvButtonComponent,
    NgxSkeletonLoaderModule,
    AsyncPipe,
    NgxOvNoDataComponent,
    NgxOvCardComponent
  ]
})

// =============================================================================
// Definition
// =============================================================================
export class PageSearchComponent implements OnInit {
  // -------------------------------------------------------------------------
  // public variables
  // -------------------------------------------------------------------------
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  public _trajecten: TrajectOutputRestDto[] = trajecten;
  public trajecten: TrajectOutputRestDto[];

  public filters: UntypedFormGroup;
  public showError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  public displayFilterMobile = false;

  public graden = [
    {
      code: 'G1',
      omschrijving: '1ste graad'
    },
    {
      code: 'G2',
      omschrijving: '2de graad'
    },
    {
      code: 'G3',
      omschrijving: '3de graad'
    }
  ];
  trackByFn = trackItemByIndex;

  // -------------------------------------------------------------------------
  // Init
  // -------------------------------------------------------------------------
  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.trajecten = [...this._trajecten];

    this.filters = this.fb.group({
      naam: [null],
      empty_state: [false],
      show_error: [false]
    });
  }

  // -------------------------------------------------------------------------
  // Events
  // -------------------------------------------------------------------------
  onApplyFilter() {
    this.showError$.next(false);
    this.isLoading$.next(true);
    const timerLoading = timer(3000);
    timerLoading.subscribe(() => {
      if (this.filters.controls['empty_state'].value === true) {
        this.trajecten = [];
      } else if (this.filters.controls['show_error'].value === true) {
        this.showError$.next(true);
      } else if (this.filters.controls['naam'].value) {
        this.trajecten = this._trajecten.filter((tr) => {
          return tr.naam
            .toLowerCase()
            .includes(this.filters.controls['naam'].value.toLowerCase());
        });
      } else {
        this.trajecten = this._trajecten;
      }
      this.isLoading$.next(false);
    });
  }

  onClearFilter() {
    this.trajecten = this._trajecten;
    this.showError$.next(false);
    this.filters.reset({ naam: '', empty_state: false, show_error: false });
  }

  openModal() {
    // eslint-disable-next-line no-console
    console.log('tooltip');
  }

  closeFilterMobile() {
    this.displayFilterMobile = false;
  }

  displayFilterMobileChange(value) {
    this.displayFilterMobile = value;
  }
}
