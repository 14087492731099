import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

// =============================================================================
// Component
// =============================================================================
@Component({
    selector: 'ngx-ov-input-validation',
    templateUrl: './input-validation.html',
    standalone: true
})
export class NgxOvInputValidationComponent implements OnChanges {
  _singleMessage: string | string[];
  // -------------------------------------------------------------------------
  // Variables
  // -------------------------------------------------------------------------
  @Input() classes: string;
  @Input() messages: Array<string> | string;
  @Input() isAnnotation: boolean;
  @Input() isError: boolean;
  @Input() isSuccess: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.messages) {
      this._singleMessage = this.getSingleMessage();
    }
  }

  // -------------------------------------------------------------------------
  // Events
  // -------------------------------------------------------------------------
  getSingleMessage() {
    if (this.messages instanceof Array && this.messages.length > 0) {
      return this.messages.slice(0, 1);
    }

    return this.messages;
  }
}
