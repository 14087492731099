<ngx-ov-upload
  [attr.data-cy]="'upload-kortingsbon'"
  [columns]="columns"
  [rows]="rows$ | async"
  [title]="'Laad kortingsbon op'"
  [allowMultiple]="false"
  [maxNumberFiles]="1"
  [emptyTableText]="'Nog geen kortingsbon opgeladen'"
  [accept]="['jpg', 'jpeg', 'png']"
  (uploadChange)="onUploadChange($event)"
></ngx-ov-upload>
