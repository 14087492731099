import { Pipe, PipeTransform } from '@angular/core';

import { INgxOvDataTableColumn } from '../data-table.utils';
import { NgxOvDataTableSortService } from '../services/data-table-sort.service';

@Pipe({
    name: 'sortDataTable', pure: false,
    standalone: true
})
export class SortDataTablePipe implements PipeTransform {
  constructor(protected sortService: NgxOvDataTableSortService) {}
  transform(
    data: any[],
    sortBy: INgxOvDataTableColumn,
    sortOrder: string
  ): any[] {
    if (sortBy) {
      return this.sortService.sortData(data, sortBy, sortOrder);
    }
    return data;
  }
}
