import { Component, Input } from '@angular/core';
import { trackItemByIndex } from '../../../../../../projects/ngx-ov-ui/src/lib/helper';

@Component({
  selector: 'app-wizard-step-4',
  templateUrl: './wizard-step-4.component.html',
  standalone: true
})
export class PageWizardStep4Component {
  @Input() opleiding: string;
  @Input() toelagen: any[];

  trackByFn = trackItemByIndex;
}
