<div class="container">
  <h1>Content Blokken</h1>
  <h2>Cards</h2>

  <div class="my-10">
    <div class="grid grid-cols-12 gap-medium">
      <div class="col-span-12 desktop:col-span-6">
        <ngx-ov-card
          [attr.data-cy]="'CARD_01'"
          [title]="'Card 01'"
          [borderColor]="'#ccc'"
          [alignment]="'centered'"
        >
          Test card zonder cardClicked event of url. Klik op card doet niets.
          <ng-template ngxOvCardTitle
            ><ngx-ov-pill [isSuccess]="true">Ongoing</ngx-ov-pill></ng-template
          >
        </ngx-ov-card>
      </div>
      <div class="col-span-12 desktop:col-span-6">
        <ngx-ov-card
          [attr.data-cy]="'CARD_02'"
          (cardClicked)="testClick('testCardClicked')"
          [title]="'Card 02'"
        >
          Test card met cardClicked event zonder url. Klik op card toont een
          alert.
        </ngx-ov-card>
      </div>
      <div class="col-span-12 desktop:col-span-6">
        <ngx-ov-card
          [attr.data-cy]="'CARD_03'"
          (cardClicked)="testClick('testCardClicked')"
          [title]="'Card 03'"
          [url]="'https://www.google.be'"
        >
          Test card met cardClicked event en url. Klik op card opent url in
          nieuw tabblad.
        </ngx-ov-card>
      </div>
      <div class="col-span-12 desktop:col-span-6">
        <ngx-ov-card
          [title]="'Card 04'"
          [url]="'https://www.google.be'"
          [attr.data-cy]="'CARD_04'"
        >
          Test card met url zonder cardClicked event. Klik op card opent url in
          nieuw tabblad.
        </ngx-ov-card>
      </div>

      <div class="col-span-12 desktop:col-span-6">
        <ngx-ov-card
          [attr.data-cy]="'CARD_05'"
          [title]="'Voeg een opleiding toe'"
          [isSecondary]="true"
          [isExpandable]="true"
          (cardClicked)="testClick('test expandable CardClicked')"
        >
          <p class="ov-ui-p-small">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea.
          </p>

          <ng-template ngxOvCardFooter>
            <div class="text-right">
              <ngx-ov-button
                label="Start je aanvraag"
                iconAfter="fas fa-angle-right"
              ></ngx-ov-button>
            </div>
          </ng-template>
        </ngx-ov-card>
      </div>

      <div class="col-span-12 desktop:col-span-6">
        <ngx-ov-card
          [attr.data-cy]="'CARD_06'"
          [title]="'Nog een aanvraag'"
          [isSecondary]="true"
          [isExpandable]="true"
        >
          <div>
            <ngx-ov-input-field
              [attr.data-cy]="'input-field-card-6'"
              [label]="'Naam opleiding'"
            ></ngx-ov-input-field>
            <ngx-ov-checkbox
              [attr.data-cy]="'checkbox-card-6'"
              [label]="'Test checkbox'"
              [classes]="'mt-medium'"
            ></ngx-ov-checkbox>
          </div>
          <ng-template ngxOvCardFooter>
            <div class="text-right">
              <ngx-ov-button
                label="Start je aanvraag"
                iconAfter="fas fa-angle-right"
              ></ngx-ov-button>
            </div>
          </ng-template>
        </ngx-ov-card>
      </div>
    </div>
  </div>
  <h2>Progress Block</h2>
  <div class="my-10">
    <div class="grid grid-cols-12 gap-medium">
      <div class="col-span-12 desktop:col-span-6">
        <ngx-ov-progress-block
          [attr.data-cy]="'PROGRESS_01'"
          [title]="'Vul je gegevens aan 1'"
          url="/progress-block"
          [isCompleted]="true"
        >
        </ngx-ov-progress-block>
      </div>
      <div class="col-span-12 desktop:col-span-6">
        <ngx-ov-progress-block
          [attr.data-cy]="'PROGRESS_02'"
          [title]="'Vul je gegevens aan 2'"
          [percentage]="66"
          (progressClicked)="onProgressBlockClick('ProgressClicked')"
          [isCompleted]="false"
        >
        </ngx-ov-progress-block>
      </div>
      <div class="col-span-12 desktop:col-span-6">
        <ngx-ov-progress-block
          [attr.data-cy]="'PROGRESS_03'"
          [title]="'Vul je gegevens aan 3'"
          url="/progress-block"
          [isDisabled]="true"
        >
        </ngx-ov-progress-block>
      </div>
    </div>
  </div>
</div>
