// eslint-disable-next-line import/no-extraneous-dependencies
import { getActionTypeFromInstance } from '@ngxs/store';
import { NgxOvActionPhase } from './actions.phases';
import { NgxOvStoreActions } from './actions';
import { geefActieFase, geefActieNaam, geefActieType } from '../store-utils';

export function actionsPlugin(state, action, next) {
  const actionTypeFromInstance = getActionTypeFromInstance(action);

  const actionName = geefActieNaam(action);
  const actionType = geefActieType(action);
  const actionPhase = geefActieFase(action);

  let newState = { ...state };
  try {
    if (
      actionTypeFromInstance ===
        NgxOvStoreActions.ResetErrormessages.toString() ||
      actionTypeFromInstance ===
        NgxOvStoreActions.ResetSuccessMessages.toString()
    ) {
      return next(newState, action);
    }

    const combineName = actionType + '-' + actionName;

    switch (actionPhase) {
      case NgxOvActionPhase.Request: {
        newState = {
          ...state,
          actions: {
            ...state.actions,
            [combineName]: {
              loading: true,
              name: combineName,
              action
            }
          }
        };
        break;
      }

      case NgxOvActionPhase.Success: {
        newState = {
          ...state,
          actions: {
            ...state.actions,
            [combineName]: {
              ...state.actions[combineName],
              loading: false,
              success: true,
              successMessage: action.successMessage,
              name: combineName
            }
          }
        };
        break;
      }

      case NgxOvActionPhase.Failure: {
        let errorMessage;
        if (
          typeof action.errorMessage === 'object' &&
          'error' in action.errorMessage
        ) {
          errorMessage = action.errorMessage.error;
        } else if (
          typeof action.errorMessage === 'object' &&
          'uitzonderingen' in action.errorMessage
        ) {
          errorMessage = action.errorMessage;
        } else {
          errorMessage = action.errorMessage;
        }
        newState = {
          ...state,
          actions: {
            ...state.actions,
            [combineName]: {
              ...state.actions[combineName],
              loading: false,
              success: false,
              failure: true,
              errorMessage: errorMessage,
              name: combineName
            }
          }
        };
        break;
      }
      default: {
        break;
      }
    }

    return next(newState, action);
  } catch (e) {
    return next(newState, action);
  }
}
