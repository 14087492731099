/**
 * Naric
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.3.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import {
  AbstractControlOptions,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn
} from '@angular/forms';

export interface IBeroepOutputRestDto {
  actief?: boolean;
  code?: string;
  naam?: string;
  sector_code?: BeroepOutputRestDto.SectorCodeEnum;
}


export class BeroepOutputRestDto implements IBeroepOutputRestDto {
  actief?: boolean;
  code?: string;
  naam?: string;
  sector_code?: BeroepOutputRestDto.SectorCodeEnum;
}

export class BeroepOutputRestDtoFormHelper {

  static generateFormWithSwaggerValidators(formBuilder: UntypedFormBuilder, controlsConfig: { [key: string]: any; }, options?: AbstractControlOptions | { [key: string]: any; } | null): UntypedFormGroup {
    if (controlsConfig["actief"] != undefined && !(controlsConfig["actief"] instanceof UntypedFormGroup) && !(controlsConfig["actief"] instanceof UntypedFormArray)) {
      controlsConfig["actief"][1] = controlsConfig["actief"][1].concat(BeroepOutputRestDtoValidator.actief_swagger_validator());
    }
    if (controlsConfig["code"] != undefined && !(controlsConfig["code"] instanceof UntypedFormGroup) && !(controlsConfig["code"] instanceof UntypedFormArray)) {
      controlsConfig["code"][1] = controlsConfig["code"][1].concat(BeroepOutputRestDtoValidator.code_swagger_validator());
    }
    if (controlsConfig["naam"] != undefined && !(controlsConfig["naam"] instanceof UntypedFormGroup) && !(controlsConfig["naam"] instanceof UntypedFormArray)) {
      controlsConfig["naam"][1] = controlsConfig["naam"][1].concat(BeroepOutputRestDtoValidator.naam_swagger_validator());
    }
    if (controlsConfig["sector_code"] != undefined && !(controlsConfig["sector_code"] instanceof UntypedFormGroup) && !(controlsConfig["sector_code"] instanceof UntypedFormArray)) {
      controlsConfig["sector_code"][1] = controlsConfig["sector_code"][1].concat(BeroepOutputRestDtoValidator.sector_code_swagger_validator());
    }
    if (options) {
      return formBuilder.group(controlsConfig, options);
    }
    return formBuilder.group(controlsConfig);
  }
}

export class BeroepOutputRestDtoValidator {

  /**
   * Active Validators for actief:
   */
  static actief_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    return validators;
  };

  /**
   * Active Validators for code:
   */
  static code_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    return validators;
  };

  /**
   * Active Validators for naam:
   */
  static naam_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    return validators;
  };

  /**
   * Active Validators for sector_code:
   */
  static sector_code_swagger_validator(): Array<ValidatorFn> {
    let validators = [];
    return validators;
  };

}


export namespace BeroepOutputRestDto {
  export type SectorCodeEnum =
    'SECT001'
    | 'SECT002'
    | 'SECT003'
    | 'SECT004'
    | 'SECT005'
    | 'SECT006'
    | 'SECT007'
    | 'SECT008'
    | 'SECT009'
    | 'SECT010'
    | 'SECT011';
  export const SectorCodeEnum = {
    SECT001: 'SECT001' as SectorCodeEnum,
    SECT002: 'SECT002' as SectorCodeEnum,
    SECT003: 'SECT003' as SectorCodeEnum,
    SECT004: 'SECT004' as SectorCodeEnum,
    SECT005: 'SECT005' as SectorCodeEnum,
    SECT006: 'SECT006' as SectorCodeEnum,
    SECT007: 'SECT007' as SectorCodeEnum,
    SECT008: 'SECT008' as SectorCodeEnum,
    SECT009: 'SECT009' as SectorCodeEnum,
    SECT010: 'SECT010' as SectorCodeEnum,
    SECT011: 'SECT011' as SectorCodeEnum
  }
}

