<form #adresForm="ngForm" novalidate>
  <div class="my-10">
    <h3>Adres gegevens</h3>
    <div class="grid grid-cols-12 gap-medium">
      <div class="col-span-12">
        <ngx-ov-input-field
          [label]="'Straatnaam'"
          [(ngModel)]="adres.straat"
          [required]="true"
          [maxlength]="20"
          [placeholder]="'Geef straatnaam hier in'"
          name="straat"
        >
        </ngx-ov-input-field>
      </div>
      <div class="col-span-12 col-span-3@at:desktop">
        <ngx-ov-input-field
          [label]="'Postcode'"
          [(ngModel)]="adres.postcode"
          [required]="true"
          [placeholder]="'Geef postcode hier in'"
          [maskExpr]="'0000'"
          name="postcode"
        >
        </ngx-ov-input-field>
      </div>
      <div class="col-span-12 col-span-9@at:desktop">
        <ngx-ov-input-field
          [label]="'Gemeente'"
          [(ngModel)]="adres.gemeente"
          [required]="true"
          [placeholder]="'Geef gemeente hier in'"
          name="gemeente"
        >
        </ngx-ov-input-field>
      </div>

      <div class="col-span-12">
        <ngx-ov-select
          [items]="landen"
          [placeholder]="'Selecteer land'"
          [label]="'Land'"
          [bindLabel]="'omschrijving'"
          [(ngModel)]="adres.land"
          [required]="true"
          name="land"
        >
        </ngx-ov-select>
      </div>
    </div>
  </div>
  <div class="my-10">
    <div class="u-position-btn-prim-sec">
      <ngx-ov-button
        [label]="'Adresgegevens opslaan'"
        [isDisabled]="!adresForm.valid"
      ></ngx-ov-button>
    </div>
  </div>
</form>
