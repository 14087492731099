import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {Configuration} from './configuration';

import {AanvraagbegeleidingService} from './api/aanvraagbegeleiding.service';
import {BeroepService} from './api/beroep.service';
import {DiplomaniveauService} from './api/diplomaniveau.service';
import {DocumentdetailsService} from './api/documentdetails.service';
import {DocumentsoortService} from './api/documentsoort.service';
import {DocumentstatusEnumService} from './api/documentstatusEnum.service';
import {DocumenttypecontextService} from './api/documenttypecontext.service';
import {EnumService} from './api/enum.service';
import {GeoService} from './api/geo.service';
import {GeslachtService} from './api/geslacht.service';
import {LandService} from './api/land.service';
import {OpenAPIApplicatieService} from './api/openAPIApplicatie.service';
import {OpenRaadpleegGUINameValuePairsService} from './api/openRaadpleegGUINameValuePairs.service';
import {TelefoonLandprefixService} from './api/telefoonLandprefix.service';
import {VakgebiedService} from './api/vakgebied.service';
import {VrijstellingsredenService} from './api/vrijstellingsreden.service';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  declarations: [],
  exports: [],
  providers: [
    AanvraagbegeleidingService,
    BeroepService,
    DiplomaniveauService,
    DocumentdetailsService,
    DocumentsoortService,
    DocumentstatusEnumService,
    DocumenttypecontextService,
    EnumService,
    GeoService,
    GeslachtService,
    LandService,
    OpenAPIApplicatieService,
    OpenRaadpleegGUINameValuePairsService,
    TelefoonLandprefixService,
    VakgebiedService,
    VrijstellingsredenService]
})
export class ApiModule {
  constructor(@Optional() @SkipSelf() parentModule: ApiModule) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import your base AppModule only.');
    }
  }

  public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [{provide: Configuration, useFactory: configurationFactory}]
    }
  }
}
