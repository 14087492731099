<article class="c-spotlight {{ classes }}" [class.c-spotlight__text]="content">
  <div class="c-spotlight__content">
    <div class="c-spotlight__title">
      <div (click)="onClick()">{{ title }}</div>
    </div>

    @if (content) {
      <p class="c-spotlight__text">
        <ng-content></ng-content>
      </p>
    }
  </div>
</article>
