import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild
} from '@angular/core';
import {
  FormsModule,
  NG_ASYNC_VALIDATORS,
  NG_VALIDATORS,
  NgControl,
  NgModel
} from '@angular/forms';
import { FormsBase } from '../forms-base';
import { TranslationsService } from '../../../services/translations.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { NgxOvInputValidationComponent } from '../input-validation/input-validation.component';
import { AsyncPipe } from '@angular/common';
import { NgxOvInputLabelComponent } from '../input-label/input-label.component';

let identifier = 0;

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-3-way-toggle',
  templateUrl: './3-way-toggle.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgxOvInputLabelComponent,
    FormsModule,
    NgxOvInputValidationComponent,
    AsyncPipe
  ]
})
export class NgxOv3WayToggleComponent
  extends FormsBase<boolean>
  implements OnInit, OnChanges
{
  // -------------------------------------------------------------------------
  // Private variables
  // -------------------------------------------------------------------------
  private _iconOnTitle: string;
  private _iconOffTitle: string;
  private _iconNeutralTitle: string;
  // -------------------------------------------------------------------------
  // Input variables
  // -------------------------------------------------------------------------

  @Input() isLightMode = false;
  @Input() label: string;

  @Input() isReadonly: boolean;

  // -------------------------------------------------------------------------
  // Input variables/Setters
  // -------------------------------------------------------------------------
  @Input() set iconOnTitle(value: string) {
    this._iconOnTitle = value;
  }

  @Input() set iconOffTitle(value: string) {
    this._iconOffTitle = value;
  }

  @Input() set iconNeutralTitle(value: string) {
    this._iconNeutralTitle = value;
  }

  @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  // -------------------------------------------------------------------------
  // Getters
  // -------------------------------------------------------------------------
  get iconOnTitle(): string {
    return this._iconOnTitle;
  }

  get iconOffTitle(): string {
    return this._iconOffTitle;
  }

  get iconNeutralTitle(): string {
    return this._iconNeutralTitle;
  }

  // -------------------------------------------------------------------------
  // Other variables
  // -------------------------------------------------------------------------
  @ViewChild(NgModel, { static: true }) model: NgModel;
  public identifier = `form-3-way-toggle-${(identifier += 1)}`;

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
    @Self() @Optional() ngControl: NgControl,
    translationsService: TranslationsService,
    @Self() @Optional() translateService: TranslateService
  ) {
    super(
      validators,
      asyncValidators,
      ngControl,
      translateService,
      translationsService
    );
  }

  // -------------------------------------------------------------------------
  // Lifecycle methods
  // -------------------------------------------------------------------------
  ngOnInit(): void {
    this.getLabels();
    if (this.translateService) {
      this.translateService.onLangChange
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.getLabels();
        });
    }
  }

  ngOnChanges() {
    super.init();
  }

  // -------------------------------------------------------------------------
  // Translation
  // -------------------------------------------------------------------------
  getLabels() {
    this._iconOffTitle = this._iconOffTitle
      ? this._iconOffTitle
      : this.getNgxOvUiTranslation('OFF');
    this._iconOnTitle = this._iconOnTitle
      ? this._iconOnTitle
      : this.getNgxOvUiTranslation('ON');
    this._iconNeutralTitle = this._iconNeutralTitle
      ? this._iconNeutralTitle
      : this.getNgxOvUiTranslation('NEUTRAL');
  }

  getNgxOvUiTranslation(code: string) {
    const translationKey = `FORMS.THREE_WAY_TOGGLE.${code.toUpperCase()}`;
    return this.translationsService.getDefaultComponentTranslation(
      translationKey
    );
  }

  getTranslationText(value: boolean) {
    if (value === true) {
      return this._iconOnTitle;
    }
    if (value === false) {
      return this._iconOffTitle;
    }
    return this._iconNeutralTitle;
  }
}
