import { Pipe, PipeTransform } from '@angular/core';

import { INgxOvDataTableColumn } from '../data-table.utils';

@Pipe({
  name: 'filterVisibleColumns',
  pure: false,
  standalone: true
})
export class FilterVisibleColumnsPipe implements PipeTransform {
  transform(columns: INgxOvDataTableColumn[]): INgxOvDataTableColumn[] {
    return columns.filter((column) => column.visible !== false);
  }
}
