import {
  Component,
  ContentChildren,
  Inject,
  Input,
  OnChanges,
  Optional,
  Self,
  ViewChild
} from '@angular/core';
import {
  FormsModule,
  NG_ASYNC_VALIDATORS,
  NG_VALIDATORS,
  NgControl,
  NgModel
} from '@angular/forms';
import * as R from 'ramda';
import { FormsBase } from '../../forms-base';
import { NgxOvRadioComponent } from '../radio-item/radio.component';
import { trackItemByIndex } from '../../../../helper';
import { NgxOvInputValidationComponent } from '../../input-validation/input-validation.component';
import { NgxOvExtraInfoComponent } from '../../extra-info/extra-info.component';
import { PortalModule } from '@angular/cdk/portal';
import {
  PortalRadioContentDirective,
  PortalRadioLabelDirective
} from '../portals/portal-radio-content.directive';
import { NgxOvInputLabelComponent } from '../../input-label/input-label.component';
import { AsyncPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { TranslationsService } from '../../../../services/translations.service';

let identifier = 0;

@Component({
  selector: 'ngx-ov-radio-group',
  templateUrl: './radio-group.component.html',
  standalone: true,
  imports: [
    FormsModule,
    NgxOvInputLabelComponent,
    NgxOvRadioComponent,
    PortalRadioContentDirective,
    PortalModule,
    PortalRadioLabelDirective,
    NgxOvExtraInfoComponent,
    NgxOvInputValidationComponent,
    AsyncPipe
  ]
})
export class NgxOvRadioGroupComponent
  extends FormsBase<any>
  implements OnChanges
{
  private _name: string;
  private _label: string;
  private _options: any[];
  private _isReadonly: boolean;
  private _selectedValueLabel: string;
  private _showAsBlock: boolean;
  private _id: string;
  private _extraInfo: string;
  private _bindLabel: string | string[];
  private _bindExtraInfo = 'extraInfo';
  private _bindIcon = 'icon';
  private _bindValue: string | string[];
  private _bindIsDisabled: string | string[];
  private _radioGroupTooltip: string;

  trackByFn = trackItemByIndex;

  // -------------------------------------------------------------------------
  // Input getters / setters
  // -------------------------------------------------------------------------

  get name(): string {
    return this._name;
  }
  @Input() set name(name: string) {
    this._name = name;
  }

  get options(): any[] {
    return this._options;
  }
  @Input() set options(options: any[]) {
    this._options = options;
  }

  get isReadonly(): boolean {
    return this._isReadonly;
  }
  @Input() set isReadonly(value: boolean) {
    this._isReadonly = value;
  }

  get selectedValueLabel(): string {
    return this._selectedValueLabel;
  }
  get label(): string {
    return this._label;
  }
  @Input() set label(label: string) {
    this._label = label;
  }

  get showAsBlock(): boolean {
    return this._showAsBlock;
  }
  @Input() set showAsBlock(showAsBlock: boolean) {
    this._showAsBlock = showAsBlock;
  }

  get extraInfo(): string {
    return this._extraInfo;
  }

  @Input() set extraInfo(extraInfo: string) {
    this._extraInfo = extraInfo;
  }

  get id(): string {
    return this._id ? this._id : this.identifier;
  }
  @Input() set id(id: string) {
    this._id = id;
  }

  get bindLabel(): string | string[] {
    return this._bindLabel;
  }
  @Input() set bindLabel(bindLabel: string | string[]) {
    this._bindLabel = bindLabel;
  }

  get bindExtraInfo(): string {
    return this._bindExtraInfo;
  }
  @Input() set bindExtraInfo(bindExtraInfo: string) {
    this._bindExtraInfo = bindExtraInfo || 'label';
  }

  get bindIcon(): string {
    return this._bindIcon;
  }
  @Input() set bindIcon(bindIcon: string) {
    this._bindIcon = bindIcon || null;
  }

  get bindValue(): string | string[] {
    return this._bindValue;
  }
  @Input() set bindValue(bindValue: string | string[]) {
    if (!Array.isArray(bindValue)) {
      this._bindValue = [bindValue];
    } else {
      this._bindValue = bindValue;
    }
  }

  get bindIsDisabled(): string | string[] {
    return this._bindIsDisabled;
  }
  @Input() set bindIsDisabled(bindIsDisabled: string | string[]) {
    this._bindIsDisabled = bindIsDisabled;
  }

  get radioGroupTooltip(): string {
    return this._radioGroupTooltip;
  }

  @Input() set radioGroupTooltip(radioGroupTooltip: string) {
    this._radioGroupTooltip = radioGroupTooltip;
  }

  // -------------------------------------------------------------------------
  // Other variables
  // -------------------------------------------------------------------------
  @ContentChildren(NgxOvRadioComponent) radioButtons: NgxOvRadioComponent[];
  @ViewChild(NgModel, { static: true }) model: NgModel;

  public identifier = `form-radio-${(identifier += 1)}`;

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
    @Self() @Optional() @Inject(NgControl) ngControl: NgControl,
    @Optional() translateService: TranslateService,
    translationsService: TranslationsService
  ) {
    super(
      validators,
      asyncValidators,
      ngControl,
      translateService,
      translationsService
    );
  }

  ngOnChanges() {
    super.init();
  }

  radioSelected(value: any) {
    if (this.model) {
      this.model.update.emit(value);

      const checkedRadio = this.radioButtons.find(
        (radio: NgxOvRadioComponent) =>
          this.getOptionValue(radio.value) === value
      );
      if (checkedRadio) {
        this._selectedValueLabel = checkedRadio.label;
      }
    }
  }

  isRadioChecked(value: any) {
    if (value instanceof NgxOvRadioComponent) {
      return R.equals(value.value, this.value);
    }

    return R.equals(value, this.value);
  }

  get selectedLabel() {
    if (this.value !== null && this.options) {
      const filtered = this.options.filter(
        (option) => this.getOptionValue(option) === this.value
      );
      if (filtered.length) {
        return this.getOptionLabel(filtered[0]);
      }
    }
    return '-';
  }

  getOptionValue(option: any) {
    let getValue;
    let optionValue;

    if (this.bindValue) {
      if (!Array.isArray(this.bindValue)) {
        this.bindValue = [this.bindValue];
      }

      getValue = R.path(this.bindValue);
      optionValue = getValue(option);
    } else {
      optionValue = option;
    }
    return optionValue;
  }

  getOptionLabel(option: any) {
    let getValue;
    let optionValue;

    if (this.bindLabel) {
      if (!Array.isArray(this.bindLabel)) {
        this.bindLabel = [this.bindLabel];
      }

      getValue = R.path(this.bindLabel);
      optionValue = getValue(option);
    } else {
      optionValue = option;
    }
    return optionValue;
  }

  getOptionIsDisabled(option: any) {
    let getValue;
    let optionValue;

    if (this.bindIsDisabled) {
      if (!Array.isArray(this.bindIsDisabled)) {
        this.bindIsDisabled = [this.bindIsDisabled];
      }

      getValue = R.path(this.bindIsDisabled);
      optionValue = getValue(option);
    } else {
      optionValue = option;
    }
    return optionValue;
  }
}
