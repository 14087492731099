import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class FilesService {
  createClickableLink(headers: HttpHeaders, body: any) {
    let contentDisposition = headers.get('content-disposition');
    if (!contentDisposition) {
      contentDisposition = headers.get('Content-Disposition');
    }
    const filename = this.getFilenameFromContentDisposition(contentDisposition);
    const url = window.URL.createObjectURL(body);
    const anchor = document.createElement('a');
    anchor.download = filename;
    anchor.href = url;
    anchor.target = '_blank';
    return anchor;
  }

  getFilenameFromContentDisposition(contentDisposition: string): string {
    const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i;
    const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

    let fileName: string = 'file';
    if (utf8FilenameRegex.test(contentDisposition)) {
      fileName = decodeURIComponent(
        utf8FilenameRegex.exec(contentDisposition)[1]
      );
    } else {
      // prevent ReDos attacks by anchoring the ascii regex to string start and
      //  slicing off everything before 'filename='
      const filenameStart = contentDisposition
        .toLowerCase()
        .indexOf('filename=');
      if (filenameStart >= 0) {
        const partialDisposition = contentDisposition.slice(filenameStart);
        const matches = asciiFilenameRegex.exec(partialDisposition);
        if (matches != null && matches[2]) {
          fileName = matches[2];
        }
      }
    }
    return fileName;
  }
}
