import { ModuleWithProviders, NgModule } from '@angular/core';
import { NgxOvGlobalMigratieAlertComponent } from './global-migratie-alert/global-migratie-alert.component';
import { NgxOvWisselOmgevingComponent } from './wissel-omgeving/ngx-ov-wissel-omgeving.component';

@NgModule({
  imports: [NgxOvGlobalMigratieAlertComponent, NgxOvWisselOmgevingComponent],
  exports: [NgxOvGlobalMigratieAlertComponent, NgxOvWisselOmgevingComponent],
  providers: []
})
export class NgxOvMigratieModule {
  static forRoot(): ModuleWithProviders<NgxOvMigratieModule> {
    return {
      ngModule: NgxOvMigratieModule
    };
  }
}
