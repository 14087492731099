import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

const iconExclamation = 'exclamation-circle';
const iconCheck = 'check-circle';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-global-alert',
  templateUrl: './global-alert.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: []
})
export class NgxOvGlobalAlertComponent {
  // -------------------------------------------------------------------------
  // private variables
  // -------------------------------------------------------------------------
  private _classes: string;
  private _message: string;
  private _type: string;
  private _icon: string = iconExclamation;
  private _showIcon = true;

  // -------------------------------------------------------------------------
  // Input variables/Setters
  // -------------------------------------------------------------------------
  @Input() set classes(classes: string) {
    this._classes = classes;
  }

  @Input() set message(message: string) {
    this._message = message;
  }

  @Input() set type(type: string) {
    this._type = type.toUpperCase();
  }

  @Input() set icon(icon: string) {
    if (icon) {
      this._icon = icon;
    } else {
      switch (this.type) {
        case 'ERROR': {
          this._icon = iconExclamation;
          break;
        }

        case 'WARNING': {
          this._icon = iconExclamation;
          break;
        }

        case 'SUCCESS': {
          this._icon = iconCheck;
          break;
        }

        default: {
          this._icon = iconExclamation;
        }
      }
    }
  }

  @Input() set showIcon(value: boolean) {
    this._showIcon = value;
  }

  // -------------------------------------------------------------------------
  // Getters
  // -------------------------------------------------------------------------
  get classes(): string {
    return this._classes;
  }

  get message(): string {
    return this._message;
  }

  get type(): string {
    return this._type;
  }

  get icon(): string {
    return this._icon;
  }

  get showIcon(): boolean {
    return this._showIcon;
  }
}
