import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output
} from '@angular/core';
import { NgxOvComponentBaseClass } from '../../../ngx-ov-component-base-class';
import { TranslationsService } from '../../../../services/translations.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxOvTooltipComponent } from '../../../meldingen/tooltip/tooltip.component';
import { ClickEqualsEnterDirective } from '../../../../directives/click-equals-enter';

@Component({
  selector: 'ngx-ov-control-panel-item',
  templateUrl: './control-panel-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ClickEqualsEnterDirective, NgxOvTooltipComponent]
})
export class NgxOvControlPanelItemComponent extends NgxOvComponentBaseClass {
  // -------------------------------------------------------------------------
  // public variables
  // -------------------------------------------------------------------------
  public tooltipIconTitle: string;
  public headerCloseIconTitle: string;
  public headerOpenIconTitle: string;
  // -------------------------------------------------------------------------
  // Input variables
  // -------------------------------------------------------------------------
  @Input() title = '';
  @Input() isTitleHiddenDesktop = false;
  @Input() isCollapsed = true;
  @Input() tooltip: string;
  // -------------------------------------------------------------------------
  // Output variables
  // -------------------------------------------------------------------------
  @Output() tooltipClicked: EventEmitter<any> = new EventEmitter();
  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  constructor(
    public translationsService: TranslationsService,
    @Optional() public translateService: TranslateService
  ) {
    super(translationsService, translateService);
  }
  onTooltipClick() {
    this.tooltipClicked.emit();
  }

  setLabels(): void {
    const componentPrefix = 'DATA_VISUALISATIE.CONTROL_PANEL.ITEM';
    this.tooltipIconTitle = this.getTranslation(
      componentPrefix,
      'TOOLTIP_ICON.TITLE'
    );
    this.headerCloseIconTitle = this.getTranslation(
      componentPrefix,
      'HEADER.CLOSE'
    );
    this.headerOpenIconTitle = this.getTranslation(
      componentPrefix,
      'HEADER.OPEN'
    );
  }
}
