<div class="c-search grid gap-medium grid-cols-12">
  <div
    class="c-search__filter-container tablet:col-span-4 col-span-12"
    [class.c-search__filter-container--visible]="displayFilterMobile"
  >
    <ngx-ov-control-panel-mobile
      (filterClick)="onClickFilter()"
      (filterClear)="onClickClearFilter()"
      (filterCancel)="onClickCancelFilter()"
      [labelClear]="labelClear"
      [labelCta]="labelCta"
      [titlePanelSearch]="titlePanelSearch"
      [showFooterButtons]="showFooterButtons"
    >
      <ng-template [cdkPortalOutlet]="filterPortal"></ng-template>
      <div class="c-search__extra-filter-container">
        <ng-template [cdkPortalOutlet]="filterResultPortal"></ng-template>
      </div>
      <ng-template ngxOvSearchButtonContainer>
        <ng-template [cdkPortalOutlet]="buttonContainer"></ng-template>
      </ng-template>
    </ngx-ov-control-panel-mobile>

    @if (infoPortal) {
      <div class="c-search__info-container">
        <ng-template [cdkPortalOutlet]="infoPortal"></ng-template>
      </div>
    }
  </div>
  <div class="c-search__results-container tablet:col-span-8 col-span-12">
    <div class="hidden tablet:block">
      @if (filterResultPortal) {
        <ngx-ov-panel [title]="titlePanelFilterResultaten">
          <div class="c-search__results-filter-container">
            <ng-template [cdkPortalOutlet]="filterResultPortal"></ng-template>
          </div>
        </ngx-ov-panel>
      }
    </div>
    <ngx-ov-panel
      [title]="titlePanelResultaten"
      [subTitle]="subTitlePanelResultaten"
    >
      <ng-template ngxOvPanelActions>
        <ngx-ov-button
          class="c-search__results-container__filter-top mt-small"
          [label]="labelFilter"
          [isSecondary]="true"
          (buttonClick)="onShowFilterMobile()"
        ></ngx-ov-button>
      </ng-template>

      <ng-content></ng-content>
    </ngx-ov-panel>

    <div class="c-search__results-container__filter-buttons">
      <ngx-ov-button
        [label]="labelFilter"
        [iconAfter]="'fas fa-filter'"
        (buttonClick)="onShowFilterMobile()"
      ></ngx-ov-button>
    </div>
  </div>
</div>
