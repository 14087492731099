@if (status$ | async; as status) {
  @if (status && !status.isLoading) {
    @if (!status.isLoadFailure) {
      <div>
        @if (!uiService.uiIsLoading()) {
          <ng-content></ng-content>
        }
      </div>
    } @else if (showError) {
      <ngx-ov-alert
        [title]="errorLoadingCopy"
        [type]="'error'"
        [classes]="alertClasses"
      >
      </ngx-ov-alert>
    }
  }
}

<div
  [class.hidden]="!(status$ | async).isLoading && !uiService.uiIsLoading()"
  class="{{ loaderClasses }}"
>
  <ngx-ov-loader [text]="loadingCopy"></ngx-ov-loader>
</div>
