import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input
} from '@angular/core';
import { PortalAgendaActionsDirective } from './portals/portal-panel-actions.directive';
import { LinkService } from '../../../services/link.service';
import { ClickEqualsEnterDirective } from '../../../directives/click-equals-enter';
import { PortalModule } from '@angular/cdk/portal';
import { DatePipe } from '@angular/common';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-agenda-item',
  templateUrl: './agenda-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PortalModule, ClickEqualsEnterDirective, DatePipe]
})
export class NgxOvAgendaItemComponent {
  // -------------------------------------------------------------------------
  // Input variables
  // -------------------------------------------------------------------------
  @Input() title: string;
  @Input() url: string;
  @Input() date: Date = new Date();

  // -------------------------------------------------------------------------
  // Public variables
  // -------------------------------------------------------------------------
  public _formattedDate = null;

  public _dateColor = '#ffffff';
  @Input() set dateColor(color: string) {
    this._dateColor = color;
  }
  get dateColor(): string {
    return this._dateColor;
  }

  public _dateBackgroundColor = '#0055cc';
  @Input() set dateBackgroundColor(color: string) {
    this._dateBackgroundColor = color;
  }
  get dateBackgroundColor(): string {
    return this._dateBackgroundColor;
  }

  // -------------------------------------------------------------------------
  // Content children
  // -------------------------------------------------------------------------
  @ContentChild(PortalAgendaActionsDirective, { static: false })
  actionsPortal: PortalAgendaActionsDirective;

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  constructor(private linkService: LinkService) {
    // Parse in readable format (format according to specs of HTML time-element)
    this._formattedDate = this.getValidFormattedDate();
  }
  getValidFormattedDate(): string {
    const leadingZero = (num, places) => String(num).padStart(places, '0');

    // Get days, months and years
    const day = leadingZero(this.date.getDate(), 2);
    const month = leadingZero(this.date.getMonth() + 1, 2);
    const year = leadingZero(this.date.getFullYear(), 2);

    return `${year}-${month}-${day}`;
  }

  onClick() {
    this.linkService.navigate(this.url);
  }
}
