import { Component, Input } from '@angular/core';
import { NgxOvTooltipComponent } from '../../meldingen/tooltip/tooltip.component';
import { NgxOvRequiredMarkComponent } from '../required-mark/required-mark.component';

@Component({
  selector: 'ngx-ov-input-label',
  templateUrl: './input-label.component.html',
  standalone: true,
  imports: [NgxOvRequiredMarkComponent, NgxOvTooltipComponent]
})
export class NgxOvInputLabelComponent {
  @Input() text: string;
  @Input() required: boolean;
  @Input() for: string;
  @Input() isReadonly = false;
  @Input() tooltip: string;
}
