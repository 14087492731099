/**
 * papyrus-movis
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
// @ts-nocheck
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from './encoder';

import { Observable } from 'rxjs';

import { Configuration } from './configuration';
import { InputStreamResource } from '../../models/swagger/inputStreamResource';
import { DocumentResponseRestDto } from '../../models/papyrus/documentResponseRestDto';
import { PapyrusConfig, PAPYRUS_CONFIG } from './papyrus.config';

@Injectable({
  providedIn: 'root'
})
export class PapyrusDocumentService {
  protected urlOvbaseDownload = '/papyrus-api/document/v1/document/download/';
  protected urlOvbaseUpload = '/papyrus-api/document/v1/document/upload';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(PAPYRUS_CONFIG) papyrusConfig: PapyrusConfig,
    @Optional() configuration: Configuration
  ) {
    if (papyrusConfig) {
      this.urlOvbaseDownload = papyrusConfig.urlOvbaseDownload;
      this.urlOvbaseUpload = papyrusConfig.urlOvbaseUpload;
    }
    if (configuration) {
      this.configuration = configuration;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (let consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Leest Document van de bestandsopslag. Geeft het bestand terug.
   *
   * @param document_key document_key
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */

  public getDocumentUsingGET(
    document_key: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Blob>;
  public getDocumentUsingGET(
    document_key: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Blob>>;
  public getDocumentUsingGET(
    document_key: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Blob>>;
  public getDocumentUsingGET(
    document_key: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (document_key === null || document_key === undefined) {
      throw new Error(
        'Required parameter document_key was null or undefined when calling getDocumentUsingGET.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/octet-stream',
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<InputStreamResource>(
      `${this.urlOvbaseDownload}/${encodeURIComponent(String(document_key))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        responseType: 'blob'
      }
    );
  }

  /**
   * Schrijf Document naar de bestandsopslag. Geeft de document sleutel terug waarmee het document erna terug kan opgehaald worden.
   *
   * @param file file
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */

  public saveDocumentUsingPOST(
    file: Blob,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<DocumentResponseRestDto>;
  public saveDocumentUsingPOST(
    file: Blob,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<DocumentResponseRestDto>>;
  public saveDocumentUsingPOST(
    file: Blob,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<DocumentResponseRestDto>>;
  public saveDocumentUsingPOST(
    file: Blob,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (file === null || file === undefined) {
      throw new Error(
        'Required parameter file was null or undefined when calling saveDocumentUsingPOST.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json;charset=UTF-8'];
    let httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['multipart/form-data'];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): void | HttpParams };
    let useForm = false;
    let convertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({
        encoder: new CustomHttpUrlEncodingCodec()
      });
    }

    if (file !== undefined) {
      formParams = formParams.append('file', <any>file) || formParams;
    }
    return this.httpClient.post<DocumentResponseRestDto>(
      `${this.urlOvbaseUpload}`,
      convertFormParamsToString ? formParams.toString() : formParams,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
