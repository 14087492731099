import { Component, Input } from '@angular/core';
import { UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxOvSelectComponent } from '../../../../../../projects/ngx-ov-ui/src/lib/components/forms/select/select.component';

// =============================================================================
// Config
// =============================================================================
@Component({
    selector: 'app-stepper-form-kies-betaalmethode',
    templateUrl: './kies-betaalmethode.component.html',
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgxOvSelectComponent]
})

// =============================================================================
// Definition
// =============================================================================
export class StepperFormKiesBetaalmethodeComponent {
  @Input() betaalmethode: UntypedFormGroup;

  betaalmethoden = ['Bancontact', 'Betalen bij levering', 'Kredietkaart'];
}
