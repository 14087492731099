<div class="c-action-list-item">
  <div class="c-action-list-item__main">
    @if (itemClickUsed) {
      <div
        class="c-action-list-item__content cursor-pointer"
        (click)="onItemClick($event)"
        aria-role="button"
      >
        <ng-template [ngTemplateOutlet]="actionTitle"></ng-template>
      </div>
    } @else {
      <div class="c-action-list-item__content">
        <ng-template [ngTemplateOutlet]="actionTitle"></ng-template>
      </div>
    }

    @if (actionsContent) {
      <div class="c-action-list-item__action">
        <ng-template [cdkPortalOutlet]="actionsContent"></ng-template>
      </div>
    }
  </div>

  @if (detailContent) {
    <div class="c-action-list-item__detail">
      <ng-template [cdkPortalOutlet]="detailContent"></ng-template>
    </div>
  }
</div>

<ng-template #actionTitle>
  <div class="c-action-list-item__content-title">{{ title }}</div>
  <div>{{ subtitle }}</div>
</ng-template>
