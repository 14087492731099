import { Component, Input } from '@angular/core';

// =============================================================================
// Config
// =============================================================================
@Component({
    selector: 'ngx-ov-login-page-login-button',
    templateUrl: './login-page-login-button.component.html',
    standalone: true
})

// =============================================================================
// Definition
// =============================================================================
export class NgxOvLoginPageLoginButtonComponent {
  @Input() label: string;
  @Input() icon: string;
}
