import { Component, OnInit, Optional } from '@angular/core';
import { NgxOvModalComponent } from '../../../../../../projects/ngx-ov-ui/src/lib/components/meldingen/modal/modal.component';
import { ProfileService } from '../../services/profile.service';
import { NgxOvSelectComponent } from '../../../../../../projects/ngx-ov-ui/src/lib/components/forms/select/select.component';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'profile-modal-image',
    templateUrl: './profile-modal-image.component.html',
    standalone: true,
    imports: [FormsModule, NgxOvSelectComponent]
})
export class ProfileModalImageComponent implements OnInit {
  public images = [
    {
      description: 'eendje',
      imageUrl:
        'https://www.wowamazing.com/wp-content/uploads/2015/06/collection-of-cute-baby-duck.jpg'
    },
    {
      description: 'hondje',
      imageUrl:
        'https://pbs.twimg.com/profile_images/962170088941019136/lgpCD8X4_400x400.jpg'
    },
    {
      description: 'vrouw',
      imageUrl:
        'https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260'
    }
  ];
  public newImageUrl;
  constructor(
    private profileService: ProfileService,
    @Optional() public modal?: NgxOvModalComponent
  ) {}

  ngOnInit(): void {
    this.profileService.imageUrl = null;
  }

  wijzigImageUrl() {
    if (this.newImageUrl) {
      this.profileService.imageUrl = this.newImageUrl;
    }
  }
}
