import { Component, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'ngx-ov-snackbar-container',
  template: `<div class="c-snackbar-container">
    <ng-container #ref></ng-container>
  </div>`,
  standalone: true
})
export class NgxOvSnackbarContainerComponent {
  @ViewChild('ref', { read: ViewContainerRef, static: true })
  vcRef: ViewContainerRef;
}
