import { ControlValueAccessor } from '@angular/forms';
import { BaseClass } from '../../baseclass';

export abstract class ValueAccessorBase<T>
  extends BaseClass
  implements ControlValueAccessor {
  protected innerValue: T;

  private changed = new Array<(value: T) => void>();
  private touched = new Array<() => void>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  propagateChange = (_: any) => {};

  get value(): T {
    return this.innerValue;
  }

  set value(value: T) {
    this.innerValue = value;
    this.changed.forEach((f) => f(value));
  }

  writeValue(value: T) {
    this.innerValue = value;
  }

  registerOnChange(fn: (value: T) => void) {
    this.propagateChange = fn;
    this.changed.push(fn);
  }

  registerOnTouched(fn: () => void) {
    this.touched.push(fn);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange(value: any) {}

  touch() {
    this.touched.forEach((f) => f());
  }
}
