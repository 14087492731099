import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input
} from '@angular/core';
import { PortalPanelActionsDirective } from './portals/portal-panel-actions.directive';
import { PortalModule } from '@angular/cdk/portal';

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-panel',
  templateUrl: './panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PortalModule]
})
export class NgxOvPanelComponent {
  // -------------------------------------------------------------------------
  // private variables
  // -------------------------------------------------------------------------
  private _title: string;
  private _subTitle: string;

  // -------------------------------------------------------------------------
  // Content children
  // -------------------------------------------------------------------------
  @ContentChild(PortalPanelActionsDirective, { static: false })
  actionsPortal: PortalPanelActionsDirective;

  // -------------------------------------------------------------------------
  // Getters & Setters
  // -------------------------------------------------------------------------
  get title(): string {
    return this._title;
  }
  @Input() set title(title: string) {
    this._title = title;
  }

  get subTitle(): string {
    return this._subTitle;
  }
  @Input() set subTitle(subTitle: string) {
    this._subTitle = subTitle;
  }
}
