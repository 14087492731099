<div
  class="c-alert {{ classes }}"
  [class.c-alert--error]="type === 'ERROR'"
  [class.c-alert--warning]="type === 'WARNING'"
  [class.c-alert--success]="type === 'SUCCESS'"
>
  @if (icon) {
    <div class="c-alert__icon">
      <span class="c-icon fas fa-{{ icon }}"></span>
      @if (title) {
        <div class="c-alert__title hidden-desktop">{{ title }}</div>
      }
    </div>
  }

  <div class="c-alert__content">
    @if (title) {
      <div class="c-alert__title hidden-mobile">{{ title }}</div>
    }
    <p class="c-alert__message">
      <span [innerHTML]="message"></span>
      <ng-content></ng-content>
    </p>
  </div>
</div>
